/**
 * @summary nodeSettings.js
 * @file node settings form component for new/edit project page
 * @returns {JSX}
 * @usedBy newForm.js
 * @author Dj Ritchey
 * @since 02/17/2021
 * @lastUpdated 08/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect, useCallback } from 'react';
import store from '../../../store/store';
import { useSelector } from 'react-redux';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { toast } from 'react-toastify';
import { text, dropDown, multiSelect } from '../../../utility/constants';
import {
  updateNodeProjectSettings,
  setNodesSelectedValues,
  removeCheckedValue
} from '../../../store/ProjectSetup/ProjectSetupActions';
import {
  updateCheckboxValues,
  updateSelectedDataType,
  updateMultiSelect,
  updateQtyValue,
  normalizeName,
  isChecked,
  shouldDisable
} from '../../../utility/updateFields';
import {
  getSubjects,
  getFrameworks,
  getFrameworkLevels,
  getDimensions
} from '../../../utility/getMetaData';
import ValueHeader from '../shared/valueHeader';
import { setSelectedValues } from '../../../utility/setSelectedValues';
import { useLocation } from 'react-router-dom';

const messageOptions = {
  messsageType: {
    success: 'Success'
  },
  messages: {
    errorOnCreate: 'Field name already in use!',
    underConstruction: 'This Function is Under Construction'
  }
};

const NodeSettings = () => {
  const location = useLocation();

  const subjects = useSelector((state) => state.metadataReducer.subject);
  const frameworks = useSelector((state) => state.metadataReducer.frameworkType);
  const cognitiveTaxonomies = useSelector((state) => state.metadataReducer.cognitiveTaxonomy);
  const dimensions = useSelector((state) => state.metadataReducer.cognitiveCategory);
  const mathematicalPractice = useSelector((state) => state.metadataReducer.mathematicalPractice);
  const frameworkLevel = useSelector((state) => state.metadataReducer.frameworkLevel);
  const settings = useSelector((state) => state.projectSetupReducer.settings);
  const selectedValues = useSelector((state) => state.projectSetupReducer.nodesSelectedValues);
  const project = useSelector((state) => state.projectSetupReducer);


  const [defaultValues, setDefaultValues] = useState([
    'observation',
    'citation',
    'citation_category',
    'subject',
    'content_framework_type',
    'cognitive_taxonomy',
    'dimension',
    'mathematical_practice',
    'science_and_engineering_practice',
    'text_complexity',
    'vision',
    'hearing',
    'speech',
    'mobility',
    'dual_sensory',
    'tags'
  ]);

  // default selection of values/categories for selections
  const defaultCheckBoxObj = {
    node_details: ['observation', 'citation', 'citation_category'],
    content_metadata: ['subject', 'content_framework_type'],
    taxonomies_and_dimensions: [
      'cognitive_taxonomy',
      'dimension',
      'mathematical_practice',
      'science_and_engineering_practice',
      'text_complexity'
    ],
    accessibility_flags: [
      'vision',
      'hearing',
      'speech',
      'mobility',
      'dual_sensory'
    ],
    tags: ['tags']
  };

  // obj structure for checkboxes, listed under category names as keys
  const [checkboxValues, setCheckBoxValue] = useState(defaultCheckBoxObj);

  // ordering for rendering selected values, custom attrs are added as they are created
  const [order, setOrder] = useState(defaultValues);

  const [newField, setNewField] = useState({
    name: '',
    isTypeChangable: true,
    dataType: '',
    category: '',
    checked: true,
    hasQty: false,
    qty: 1,
    value: [],
    isNewField: true
  });

  const [selectedTaxonomies, setSelectedTaxonomies] = useState([]);

  // structure metadata by categories
  const [metadataValues, setMetadataValues] = useState({
    subject: getSubjects(subjects, settings),
    content_framework_type: getFrameworks(frameworks, settings),
    frameworkLevels: getFrameworkLevels(frameworkLevel, frameworks, settings),
    dimension: getDimensions(dimensions, selectedTaxonomies)
  });

  // available data types for selected values
  const changableTypes = [text, dropDown, multiSelect];

  // wrapper fn for updating what attrs are selected in UI
  const updateCheckboxValuesFn = (fieldName) => {
    const list = updateCheckboxValues(
      fieldName.category,
      fieldName,
      checkboxValues
    );
    setCheckBoxValue({ ...list });
  };

  // wrapper fn for updating selected value data type
  const updateSelectedDataTypeFn = ({ value }, obj) => {
    const newSelection = updateSelectedDataType(selectedValues, value, obj);
    store.dispatch(setNodesSelectedValues([...newSelection]));
  };

  // update selected values array, checks if the value is newly selected or unselected (checked property is updated prior to this fn invoking)
  const updateSelectedValues = (attribute) => {
    if (attribute.checked) {
      store.dispatch(
        setNodesSelectedValues([
          ...selectedValues,
          setSelectedValues(attribute)
        ])
      );
    } else {
      store.dispatch(removeCheckedValue('nodesSelectedValues', attribute));
    }
  };

  // if name exists in the selected values array, render details to UI
  const shouldRender = (selectedValues, name) => {
    const foundObj = selectedValues.find((selected) => selected.name === name);
    return foundObj;
  };

  // wrapper for updating qty of selected value
  const updateQtyValueFn = (value, obj) => {
    const list = updateQtyValue(selectedValues, value, obj);
    store.dispatch(setNodesSelectedValues([...list]));
  };

  // update potential values for the attribute (shown in multiselect)
  const updateValuesForFieldFn = (e, attribute) => {
    const list = updateMultiSelect(selectedValues, e, attribute);
    store.dispatch(setNodesSelectedValues([...list]));

    if (attribute.name === 'cognitive_taxonomy') {
      setSelectedTaxonomies(e.value);
    }
  };

  // make sure all fields exist in the order state
  const addCustomFields = useCallback(() => {
    selectedValues.forEach((val) => {
      updateCheckboxValues(val.category, val, checkboxValues);
      const inOrder = order.includes(val.name);
      if (!inOrder) {
        order.push(val.name);
      }
    });
  }, [order, checkboxValues, selectedValues]);

  // keep selectedValues and nodeProjectSettings in sync (should have back end look at selectedValues, then nodeProjectSettings can be removed)
  const updateProject = useCallback(() => {
    store.dispatch(updateNodeProjectSettings({ attributes: selectedValues }));
  }, [selectedValues]);

  // click event to save custom fields to selected values array
  const saveField = () => {
    const existingField = selectedValues.find(
      (selected) => selected.name.toLowerCase() === newField.name.toLowerCase()
    );
    if (existingField) {
      toast.error(messageOptions.messages.errorOnCreate);
    } else {
      updateSelectedValues(newField);
      setOrder([...order, newField.name]);
      setDefaultValues([...defaultValues, newField.name]);
      updateCheckboxValuesFn(newField);
      setNewField({ ...newField, name: '', category: '' });
    }
  };

  //render delete icon only for custom fields in checkbox area
  const renderRemoveIcon = (attr) => {
    return !defaultValues.some(
      (valueName) => valueName.toLowerCase() === attr.toLowerCase()
    );
  };

  // find custom attr, and remove references from order, checkboxes, and selectedValues
  const removeCustomField = (attr, key, isChecked) => {
    const copyCheckboxValues = [...checkboxValues[key]];
    const findCheckboxValueIdx = checkboxValues[key].findIndex(
      (attributeName) => attributeName.toLowerCase() === attr.toLowerCase()
    );
    // if selected, need to manually remove checked value
    if (isChecked) {
      const attrObj = selectedValues.find(
        (attribute) => attribute.name === attr.toLowerCase()
      );
      attrObj.checked = false;
      updateSelectedValues(attrObj);
    }
    setOrder(defaultValues);
    copyCheckboxValues.splice(findCheckboxValueIdx, 1);
    setCheckBoxValue({ ...checkboxValues, [key]: copyCheckboxValues });
  };

  // get selectable values based on metadata values
  const getComplexValues = (attribute) => {
    switch (attribute.name) {
      case 'cognitive_taxonomy':
        return cognitiveTaxonomies;
      case 'mathematical_practice':
        return mathematicalPractice;
      default:
        return [];
    }
  };

  // keep metadata in sync, dependent on other settings outside nodeSettings
  useEffect(() => {
    setMetadataValues({
      ...metadataValues,
      subject: getSubjects(subjects, settings),
      content_framework_type: getFrameworks(frameworks, settings),
      frameworkLevels: getFrameworkLevels(frameworkLevel, frameworks, settings),
      dimension: getDimensions(dimensions, selectedTaxonomies)
    });
    // add custom fields to checkboxValues
    addCustomFields();
  }, [settings, selectedValues, addCustomFields, location.pathname]);

  // update project redux state when selected values are changed
  useEffect(() => {
    updateProject();
  }, [selectedValues, updateProject]);

  useEffect(() => {
    setCheckBoxValue(defaultCheckBoxObj);
    setOrder(defaultValues);
  }, [location.pathname, project]);

  // checkbox section HTML
  const renderCheckboxesMarkup = () => {
    const keys = Object.keys(checkboxValues);
    return (
      <div>
        {keys.map((key, idx) => (
          <div key={`nodekeys-${idx}`} className="col">
            <label className="form-check-label">{normalizeName(key)}</label>
            {checkboxValues[key].map((attribute, $idx) => {
              return (
                <div
                  key={`nodecheckboxes-${$idx}`}
                  className="row-1 form-check"
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isChecked(selectedValues, attribute)}
                    id={`node-${attribute}`}
                    onChange={() =>
                      updateSelectedValues({
                        name: attribute,
                        checked: !isChecked(selectedValues, attribute),
                        category: key
                      })
                    }
                    disabled={shouldDisable(selectedValues, attribute)}
                  ></input>
                  <label
                    className="form-check-label"
                    htmlFor={`node-${attribute}`}
                  >
                    {normalizeName(attribute)}
                  </label>
                  {renderRemoveIcon(attribute, key) &&
                    !shouldDisable(selectedValues, attribute) && (
                      <span
                        onClick={() => {
                          removeCustomField(
                            attribute,
                            key,
                            isChecked(selectedValues, attribute)
                          );
                        }}
                        className="k-icon k-i-minus-outline"
                        style={{ hidden: true }}
                      />
                    )}
                </div>
              );
            })}
          </div>
        ))}

        <div className="row">
          <label className="form-label">New Field</label>
        </div>

        <div className="row">
          <div className="col">
            <label className="form-label" htmlFor="new-field-name">
              Name:
            </label>
            <input
              id="new-field-name"
              value={newField.name}
              onChange={(e) => {
                setNewField({ ...newField, name: e.target.value });
              }}
            />
          </div>
        </div>

        <div className="d-flex p-2 justify-content-between">
          <div className="text-end">
            <label className="form-label" htmlFor="new-field-type">
              Field Type:
              <i className="text-danger"> *</i>
            </label>
          </div>
          <div className="col">
            <select
              id="new-field-type"
              name="fieldCat"
              className="form-select form-select-sm"
              required
              value={newField.category}
              style={{ width: '200px' }}
              onChange={(e) => {
                setNewField({ ...newField, category: e.target.value });
              }}
            >
              <option value="">Select a Field Type</option>
              {Object.keys(checkboxValues).map((cat) => (
                <option key={`nodecat${cat}`} value={normalizeName(cat)}>
                  {normalizeName(cat)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className='col-1'>
            <button
              onClick={(e) => {
                saveField();
              }}
              disabled={newField.name === '' || newField.category === ''}
            >
              Create Field
            </button>
          </div>
        </div>
      </div>
    );
  };

  // data type drop down HTML
  const renderDataTypeInputMarkup = (attribute) => {
    if (attribute.isTypeChangable) {
      return (
        <select onChange={(e) => updateSelectedDataTypeFn(e.target, attribute)}>
          {attribute.id !== null || attribute.dataType ? (
            <option value={attribute.dataType}>{attribute.dataType}</option>
          ) : (
            <option value="0">Select a data type</option>
          )}
          {changableTypes
            .filter((type) => type !== attribute.dataType)
            .map((type) => (
              <option key={`nodetype-${type}`} value={type}>
                {type}
              </option>
            ))}
        </select>
      );
    }
    return <div style={{ marginLeft: '8px' }}>{attribute.dataType}</div>;
  };

  // qty drop down HTML
  const renderQtyInputMarkup = (attribute) => {
    if (!project.id || !attribute.id) {
      if (attribute.hasQty && attribute.dataType === 'Text') {
        return (
          <input
            type="number"
            min="1"
            max="100"
            value={attribute.qty}
            onChange={(e) => {
              updateQtyValueFn(parseInt(e.target.value), attribute);
            }}
          />
        );
      } else if (attribute.hasQty) {
        return (
          <input
            type="number"
            min="1"
            max="100"
            value={attribute.qty}
            onChange={(e) => {
              updateQtyValueFn(parseInt(e.target.value), attribute);
            }}
          />
        );
      }
    }
    return <div style={{ marginLeft: '8px' }}>{attribute.qty}</div>;
  };

  // wrapper to create mutliselects based on options each type needs
  const multiSelectMarkup = (
    idx,
    attribute,
    data,
    popupSettings,
    fieldName,
    dataKey
  ) => (
    <MultiSelect
      key={`node-multi-${idx}`}
      id={`field-name-${attribute.name}`}
      data={data}
      textField={fieldName}
      allowCustom
      dataItemKey={dataKey}
      value={attribute.value}
      popupSettings={popupSettings}
      onChange={(e) => {
        updateValuesForFieldFn(e.target, attribute);
      }}
    />
  );

  // wrapper to render correct mutliselect with options based on if it needs complex metadataValues
  const renderValuesMultiSelectMarkup = (attribute) => {
    const fromMetaDataState = metadataValues[attribute.name];
    if (attribute.dataType === 'Text') return;
    if (
      !fromMetaDataState &&
      !attribute.complexValues &&
      attribute.name !== 'cognitive_taxonomy' &&
      attribute.name !== 'mathematical_practice'
    ) {
      return [...Array(attribute.qty)].map((attr, idx) => (
        <MultiSelect
          key={`node-multi-${idx}`}
          id={`field-name-${attribute.name}`}
          allowCustom={true}
          value={attribute.value}
          popupSettings={{ popupClass: 'hidden-popup' }}
          onChange={(e) => {
            updateValuesForFieldFn(e.target, attribute);
          }}
        />
      ));
    }

    if (!fromMetaDataState) {
      return [...Array(attribute.qty)].map((attr, idx) =>
        multiSelectMarkup(
          idx,
          attribute,
          getComplexValues(attribute),
          {},
          'name',
          'id'
        )
      );
    } else {
      return [...Array(attribute.qty)].map((attr, idx) =>
        multiSelectMarkup(
          idx,
          attribute,
          metadataValues[attribute.name],
          {},
          'name',
          'id'
        )
      );
    }
  };

  // HTML to render selected value rows
  const getSelectableValueRows = () => {
    const selectedAttrs = order
      .map((name) => shouldRender(selectedValues, name))
      .filter(Boolean);
    return selectedAttrs.map((attribute, idx) => (
      <div key={`nodeselectedattr${attribute.name}`}>
        <div className="row p-2 justify-content-between">
          <div className="col">
            <div className="p-2">
              <label htmlFor={`field-name-${attribute.name}`}>
                {normalizeName(attribute.name)}
              </label>
            </div>
          </div>

          <div className="col">
            <div className="p-2">
              <div>{renderDataTypeInputMarkup(attribute)}</div>
            </div>
          </div>

          <div className="col-1">
            <div className="p-2">
              <div>{renderQtyInputMarkup(attribute)}</div>
            </div>
          </div>
          {attribute.name !== 'content_codes' ? (
            <div className="col-6">
              <div className="p-2">
                <div>{renderValuesMultiSelectMarkup(attribute)}</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    ));
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col p-2 justify-content-between">
          <fieldset className="bg-light p-3 rounded mt-1">
            <legend>Select Fields to add</legend>
            <div className="row p-2">{renderCheckboxesMarkup()}</div>
          </fieldset>

          <fieldset className="bg-light p-3 rounded mt-1">
            <legend>Data Types and Selectable Values</legend>
            <div className="row p-2">
              <ValueHeader />
              {getSelectableValueRows()}
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default NodeSettings;
