/**
 * @summary MergeModal.jsx
 * @file Modal component that handles merging logic based on user's action
 * @returns {JSX}
 * @usedBy BranchListGrid.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useEffect } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ProgressBar } from "@progress/kendo-react-progressbars";
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';


const MergeConfirmation = ({ showModal, setShowModal, mergeFn, modalType, deactivateFn, isLoading, useInterval }) => {
  const { value, start, stop, reset } = useInterval(50);
  
  if (isLoading) {
    start();
  }
  if (showModal) {
    return (
      <div>
        {!isLoading ? (
          <Modal show={showModal} backdrop="static" centered size="md">
          <Modal.Header>
            <Modal.Title>
              <h2 className='text-white text-center'>
                Please Confirm
              </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className='text-center pt-3 pb-3'>
              Are you sure you want to {modalType} this branch?
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <div className='col-12'>
              <div className='row'>
                <div className='col-6'>
                  <div className='ms-2 text-right'>
                    <button
                      className="btn btn-danger btn-sm text-white w-75"
                      onClick={() => setShowModal(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='me-2 text-left'>
                    <button
                      className="btn btn-primary btn-sm text-white w-75 "
                      // disabled={branchName.length < 1}
                      onClick={() => {
                        reset();
                        if (modalType === 'deactivate') {
                        deactivateFn()
                        } else {
                        mergeFn();
                        }
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        // <Dialog title="Please confirm" onClose={() => setShowModal(false)}>
        //   <p style={{ margin: '25px', textAlign: 'center' }}>
        //     Are you sure you want to {modalType}?
        //   </p>
        //   <DialogActionsBar>
        //     <button
        //       type="button"
        //       className="btn btn-primary"
        //       onClick={() => setShowModal(false)}
        //     >
        //       No
        //     </button>
        //     <button
        //       type="button"
        //       className={modalType === 'deactivate' ? ("btn btn-danger") : ("btn btn-primary")}
        //       onClick={() => {
        //         reset();
        //         if (modalType === 'deactivate') {
        //         deactivateFn()
        //         } else {
        //         mergeFn();
        //         }
        //       }}
        //     >
        //       {modalType === 'deactivate' ? (<i className="bi bi-file-minus me-2" />) : (<i className="bi bi-file-plus me-2" />)}
        //       Yes
        //     </button>
        //   </DialogActionsBar>
        // </Dialog>
      ) : (
        <>
          <Dialog title="Merging" onClose={() => setShowModal(false)}>
          <p style={{ margin: '25px', textAlign: 'center' }}>
            Merge Compiling
          </p>
          <ProgressBar
            max={99}
            value={value}
          />
        </Dialog>
      </>
      )}
      </div>
    )
  }
  return null;
}

export default MergeConfirmation;

MergeConfirmation.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  mergeFn: PropTypes.func,
  modalType: PropTypes.string,
  deactivateFn: PropTypes.func,
  isLoading: PropTypes.bool,
  useInterval: PropTypes.func,
}