/**
 * @summary radiusUtil.js
 * @file Function finds all nodes and links from user's source node and then uses GOJS to find all nodes connected to the source nodes according to node radius
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { formatMapData } from "../formatMapData";
import { toast } from 'react-toastify';

export const getNodeRadius = (nodes, connections, selectedNodeKey, radius, branchType) => {
  const {nodeDataArray, linkDataArray} = formatMapData(nodes, connections)
  const $ = go.GraphObject.make;
  const diagram = $(go.Diagram, // no div!
      {
        model: $(go.GraphLinksModel,
          {   
              nodeKeyProperty: 'nodeKey',
              linkKeyProperty: 'key' 
          })
      });
  
  // need to merge in the data since the props are immutable and GoJS normally adds some internal properties
  
  diagram.model.commit(m => {
      m.mergeNodeDataArray(nodeDataArray);
    if (linkDataArray !== undefined && m instanceof go.GraphLinksModel) {
      m.mergeLinkDataArray(linkDataArray);
    }
  });
  
  ////////////////////////////////////////
  // REPORTING - RADIUS FILTER - FOR DJ //
  ////////////////////////////////////////
  // INPUT DATA FROM USER //
  let node = diagram.findNodeForKey(selectedNodeKey)
  if(node !== null){

    let radiusNumber = radius
    // RADIUS FILTER FUNCTION STARTS //
    let nodeCollectionArray = [];

    // GOJS fn that finds all Nodes and Links from out selected Node
    let startingNodeList = new go.List(node.findNodesConnected());

    // set them to an array
    let startingNodeArray = startingNodeList.toArray();

    // add our starting node to the array, because GOJS findNodesConnected disregards the selected Node
    startingNodeArray.unshift(node);
    nodeCollectionArray.push(startingNodeArray);

    // Flattens the Arrays Returned Below
    const flatten = function (collectionArray, result = []) {
        for (let i = 0, length = collectionArray.length; i < length; i++) {
          const value = collectionArray[i];
          if (Array.isArray(value)) {
            flatten(value, result);
          } else {
            result.push(value);
          }
        }
        return result;
      };

    //if there is a radius of 1, only send back the starting arrays of data
    if (radiusNumber > 1) {
      //we loop for each number in our radius
      for (let i = 1; i < radiusNumber; i++) {
        //GOJS handles the data as arrays of arrays, so we need to flatten it down
        //to just one array inorder to calculate
        let flattenedNodes = flatten(nodeCollectionArray);
        flattenedNodes.forEach((nodeCollection) => {
          // each node we want to find all nodes connected
          //Yes this causes duplicates, we clean those up later
          let recurviseNodeList = new go.List(
            nodeCollection.findNodesConnected()
          );
          let recursiveNodeArray = recurviseNodeList.toArray();

          nodeCollectionArray.push(recursiveNodeArray);

        });
      }
    }

    //Once again, properly format the GOJS array of arrays into a format we can process
    let mergedNodeArray = flatten(nodeCollectionArray);

    //find all the nodes from out GOJS calculations that match our nodeDataArray
    let nodeMatches = mergedNodeArray
      .map((x) => {
        let item = diagram.model.nodeDataArray.find(
          (item) => item.nodeKey === x.key
        );
        if (item) {
          return item;
        }
      })
      .filter((item) => item !== undefined);
    //here we remove any duplicates our loop created at the top wit the same key
    let newNodeArray = nodeMatches.reduce((unique, o) => {
      if (!unique.some((obj) => obj.nodeKey === o.nodeKey)) {
        unique.push(o);
      }
      return unique;
    }, []);
    
    // Removing Selected Node the User Entered
    let finalNodeArray = newNodeArray.filter(node => node.nodeKey !== selectedNodeKey)

    // Final Return
    if(finalNodeArray.length > 0){
      const radiusReturnData = {
        nodeDataArray: finalNodeArray,
        errorMessage: null
      }
      return (radiusReturnData)
    } else {
      return ({
        nodeDataArray: [],
        errorMessage: "This node has no connections."
      })
    }

  } else {
    if(branchType === "branch" || branchType === "published branch"){
      return ({
        nodeDataArray: [],
        errorMessage: "This node key does not exist in this branch. You can view this branch's node keys on the Table View page."
      })
    } else {
      return ({
        nodeDataArray: [],
        errorMessage: "This node key does not exist in this view. You can see what's available in this view by loading it in Canvas."
      })
    }
  }
}


