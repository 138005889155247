/**
 * @summary ProfileCheckHeaderRender.js
 * @file This component provides column headers for the generated report
 * @returns {JSX}
 * @usedBy ProfileCheckReturn.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react'
import PropTypes from 'prop-types';
//////////////////////////
// PROFILE CHECK RETURN //
//////////////////////////
const ProfileCheckHeaderRender = () => {
    return(
        <div
        className="row k-listview-item p-2 border-bottom align-middle"
        style={{
            marginTop: 0,
            marginBottom: 0,
        }}
        >
                <div className="col-2" style={{padding: 0}}>
                    <div
                        style={{
                            fontSize: 24,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 25,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                       Accessibility Flags
                    </div>
                </div>
                <div className="col-1" style={{padding: 0}}>
                    <div
                        style={{
                            fontSize: 24,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 25,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                       Matches
                    </div>
                </div>
                <div className="col-8" style={{padding: 0}}>
                    <div
                        style={{
                            fontSize: 24,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 25,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                       Nodes with Matching Accessibility Flags
                    </div>
                </div>
                
        </div>
    )
}

export default ProfileCheckHeaderRender;

ProfileCheckHeaderRender.propTypes = {
    dataItem: PropTypes.object,
}

