/**
 * @summary Metadata Container for executing actions
 * @file MetadataContainer.js
 * @returns {JSX}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { retrieveMetadata } from './MetadataAction';
import store from '../../store/store';

/**
 * Component for retrieving metadata from the DB and put it in the redux-store.
 *
 * @summary
 * Based on the assessment program selected by user will retrieve the metadata and put it in redux store. *
 * @example
 * state ={
 * metadata:{module:value, attr_code:value}
 * }
 *
 */
export default function MetadataContainer({ assessmentId }) {
  const dispatch = useDispatch();
  dispatch(retrieveMetadata(assessmentId));
  return null;
}

MetadataContainer.propTypes = {
  assessmentId: PropTypes.number
};

/**
 * @method
 * This will be used to get the metadata value for the given key which are stored in the redux store.
 * @param {string} key
 *
 * @returns {string} value
 *
 */
export const getMetadata = (metadataTypeCode) => {
  const metadataValues = store.getState().metadataReducer.metadataDetails;
  if (metadataValues !== undefined) {
    const metadataTypeInfo = metadataValues.filter(
      (metadata) => metadata.metadata_type_code === metadataTypeCode
    );
    return metadataTypeInfo;
  }
  return 'Did not find list of metadata';
};

/**
 * @method
 * This will be used to get the metadata info for the given metadata key which are stored in the redux store.
 * @param {string} key
 *
 * @returns {array} list of metadata_info
 *
 */
export const getMetadataInfo = (metadataTypeCode) => {
  const lists = getMetadata(metadataTypeCode);
  if (
    lists &&
    Array.isArray(lists) &&
    lists.length > 0 &&
    lists[0].metadata_info &&
    Array.isArray(lists[0].metadata_info) &&
    lists[0].metadata_info.length > 0
  ) {
    return lists[0].metadata_info;
  }
  return [];
};
