/**
 * @summary branchesDetailsReducer.jsx
 * @file Reducer for Branch State
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import {
  GET_BRANCHESDETAILSLIST,
  GET_BRANCHESINACTIVEDETAILSLIST,
  CLEAR_BRANCHESDETAILS,
  CLEAR_BRANCHESINACTIVEDETAILS,
  ADD_BRANCH
} from './branchesDetailsTypes';

const initialState = { activeBranches: [], inactiveBranches: [] };

const branchDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BRANCHESDETAILSLIST:
      return { ...state, activeBranches: action.branchesDetails };

    case CLEAR_BRANCHESDETAILS:
      return {
        activeBranches: []
      };

    case GET_BRANCHESINACTIVEDETAILSLIST:
      return { ...state, inactiveBranches: action.branchesDetails };

    case CLEAR_BRANCHESINACTIVEDETAILS:
      return {
        inactiveBranches: []
      };

    case ADD_BRANCH:
      return {
        ...state,
        activeBranches: [...state.activeBranches, action.branchData]
      };
    default:
      return state;
  }
};

export default branchDetailsReducer;
