/**
 * @summary ColorPicker.js
 * @file Basic Color Selector - Used by all the options in CanvasThemeMenu.js that involve color change
 * @returns {JSX}
 * @usedBy CanvasThemeMenu.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import PropTypes from 'prop-types';
import "./colorPickerStyle.scss"

const CustomColorPicker = ({
    pickerId, 
    handleNodeBackgroundColorChange, 
    handleNodeBorderColorChange, 
    handleNeighborhoodBackgroundColorChange, 
    handleNeighborhoodBorderColorChange, 
    handleConnectionColorChange, 
    handleNodeFontColorChange, 
    handleNeighborhoodFontColorChange
}) => {
    function chooseColor(e){

        function rgbToHex(rgb) {
            const [r, g, b] = rgb.match(/\d+/g);
            const hexR = parseInt(r).toString(16).padStart(2, '0');
            const hexG = parseInt(g).toString(16).padStart(2, '0');
            const hexB = parseInt(b).toString(16).padStart(2, '0');
            return `#${hexR}${hexG}${hexB}`;
        }

        const hexValue = rgbToHex(e.target.style.backgroundColor)
        if(e.target.id === "node-color"){
            handleNodeBackgroundColorChange(hexValue)
          } else if (e.target.id === "node-border-color") {
            handleNodeBorderColorChange(hexValue);
          } else if (e.target.id === "node-font-color") {
            handleNodeFontColorChange(hexValue)
          } else if (e.target.id === "neighborhood-color") {
            handleNeighborhoodBackgroundColorChange(hexValue);
          } else if (e.target.id === "neighborhood-border-color") {
            handleNeighborhoodBorderColorChange(hexValue);
          } else if (e.target.id === "neighborhood-font-color") {
            handleNeighborhoodFontColorChange(hexValue)
          } else if (e.target.id === "connection-color") {
            handleConnectionColorChange(hexValue);
          } 
        
    }
    return(
        <div className="color-pallette-container">
            <div style={{backgroundColor: '#E91700'}} onClick={chooseColor} id={pickerId} className="color-block" >
            
            </div>
            <div style={{backgroundColor: '#B85A1B'}} onClick={chooseColor} id={pickerId} className="color-block" >

            </div>
            <div style={{backgroundColor: '#FAF03B'}} onClick={chooseColor} id={pickerId} className="color-block">

            </div>
            <div style={{backgroundColor: '#3E8438'}} onClick={chooseColor} id={pickerId} className="color-block" >

            </div>
            <div style={{backgroundColor: '#3A73D6'}} onClick={chooseColor} id={pickerId} className="color-block">

            </div>
            <div style={{backgroundColor: '#A154BD'}} onClick={chooseColor} id={pickerId} className="color-block">

            </div>
            <div style={{backgroundColor: '#47B2B5'}} onClick={chooseColor} id={pickerId} className="color-block">

            </div>
            <div style={{backgroundColor: '#B65A53'}} onClick={chooseColor} id={pickerId} className="color-block">

            </div>
            <div style={{backgroundColor: '#707A66'}} onClick={chooseColor} id={pickerId} className="color-block">

            </div>
            <div style={{backgroundColor: '#A76444'}} onClick={chooseColor} id={pickerId} className="color-block">

            </div>
            <div style={{backgroundColor: '#34D625'}} onClick={chooseColor} id={pickerId} className="color-block">

            </div>
            <div style={{backgroundColor: '#F848BA'}} onClick={chooseColor} id={pickerId} className="color-block">

            </div>
            <div style={{backgroundColor: '#ED7D31'}} onClick={chooseColor} id={pickerId} className="color-block">

            </div>
            <div style={{backgroundColor: '#000000'}} onClick={chooseColor} id={pickerId} className="color-block">

            </div>
            <div style={{backgroundColor: '#FFFFFF'}} onClick={chooseColor} id={pickerId} className="color-block">

            </div>
        </div>
    )
}

CustomColorPicker.propTypes = {
    pickerId: PropTypes.string,
    handleNodeBackgroundColorChange: PropTypes.func,
    handleNodeBorderColorChange: PropTypes.func,
    handleNeighborhoodBackgroundColorChange: PropTypes.func,
    handleNeighborhoodBorderColorChange: PropTypes.func,
    handleConnectionColorChange: PropTypes.func,
    handleNodeFontColorChange: PropTypes.func,
    handleNeighborhoodFontColorChange: PropTypes.func
  };

export default CustomColorPicker;