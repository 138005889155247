/**
 * @summary Project Actions
 * @file ProjectActions.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios';
import {
  CREATE_PROJECT,
  GET_PROJECTS,
  GRID_DATA_ACTIONS_SELECTIONS_FULLFILLED,
  CLEAR_SELECTION,
  CLEAR_PROJECTS,
  UPDATE_PROJECT
} from './ProjectTypes';

import { SET_PROJECT_ID } from '../ProjectSetup/ProjectSetupTypes';

export const createProject = () => async (dispatch, getState) => {
  const projectSetup = getState().projectSetupReducer;
  const resp = await axios.post(
    `/api/${projectSetup.assessmentProgramId}/projects`,
    { ...projectSetup }
  );
  dispatch({
    type: CREATE_PROJECT,
    project: resp.data.data,
    meta: resp.data.data.id
  });

  // set projectSetup data to data that was saved
  dispatch({
    type: SET_PROJECT_ID,
    id: resp.data.data.id
  });

  return resp;
};

export const updateProject = () => async (dispatch, getState) => {
  const projectSetup = getState().projectSetupReducer;
  const user = getState().authReducer.userObj;
  const resp = await axios.put(
    `/api/${projectSetup.assessmentProgramId}/projects/${projectSetup.id}/branch/${user.selectedBranch[0]?.id}`,
    { ...projectSetup }
  );
  if (resp.status === 201) {
    dispatch({
      type: UPDATE_PROJECT,
      project: projectSetup,
      meta: resp.data
    });
  }
  return resp;
};

export function dispatchGetProjects(projectsList) {
  return {
    type: GET_PROJECTS,
    projectsList
  };
}

export function dispatchGetProject(projectsList) {
  return {
    type: GET_PROJECT,
    projectsList
  };
}

export const getProjects =
  (assessmentProgramId, params) => async (dispatch) => {
    const { data } = await axios.get(
      `/api/${assessmentProgramId}/projects`,
      params
    );
    dispatch(dispatchGetProjects(data));
  };

export const getProject =
  (assessmentProgramId, projectId, branchId, params) => async (dispatch) => {
    const { data } = await axios.get(
      `/api/${assessmentProgramId}/projects/:projectId/branch/:branchId`,
      params
    );
    dispatch(dispatchGetProject(data));
  };

const updateSelectedGrid = (data) => ({
  type: GRID_DATA_ACTIONS_SELECTIONS_FULLFILLED,
  project: {
    data
  }
});

export const gridSelectedDataActions = (data) => (dispatch) => {
  dispatch(updateSelectedGrid(data));
};

export const deleteProject = (projectId) => async (dispatch) => {
  // TODO add dispatch call to back end when route avail
};

export const clearSelectedProject = () => ({
  type: CLEAR_SELECTION
});

export const clearAllProjects = () => ({
  type: CLEAR_PROJECTS
});
