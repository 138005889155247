/**
 * @summary ChangeLogInputs.js
 * @file Input Options and Functionality for Change Log Report. Change Log returns node changes within a date range
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import store from 'store/store';
import PropTypes from 'prop-types';
import { getViews } from 'store/views/ViewActions'
import axios from 'axios'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// IMPORTED COMPONENTS: Autosuggest for NodeKey Input
import NodeKeyAutosuggest from '../reportingUtils/NodeKeyAutosuggest'

const ChangeLogInputs = ({
    generateReport,
    project,
    userObj,
}) => {
    // USE SELECTOR: Pulls Branch and Project Data from Redux
    const branches = useSelector((state) => {
        const branchesList = { ...state.branchesDetailsReducer.activeBranches };
        delete branchesList?.error;
        delete branchesList?.selectedData;
        return Object.values(branchesList).filter(Boolean);
    }, shallowEqual)

    const views = useSelector((state) => {
        const views = { ...state.viewReducer };
        delete views?.selectedData;
        return Object.values(views).filter((view) => view.isActive)
    }, shallowEqual)
    // USE EFFECT: Gets VIews for the Use Selector (for Menus)
    useEffect(() => {
        store.dispatch(getViews(userObj.selectedProject[0].id))
    }, [])

    /////////////////////////
    // PRIMARY STATE HOOKS //
    /////////////////////////
    // Map Dropdown States (Full and Selected)
    const [mapDropdownOptions, setMapDropdownOptions] = useState([])
    const [selectedMap, setSelectedMap] = useState(null)
    // Node Key Selector States used in AutoSuggest
    const [allNodeKeysAvailable, setAllNodeKeysAvailable] = useState([])
    const [nodeKeySuggestions, setNodeKeySuggestions] = useState([])
    const [selectedNodeKey, setSelectedNodeKey] = useState(null)
    // Report Data: Start and End Dates
    const [startingDate, setStartingDate] = useState(null)
    const [endingDate, setEndingDate] = useState(new Date())

    /////////////////////////////////////////////////////////
    // BUILDS MAP DROPDOWN OPTIONS WITH IDENTIFYING VALUES //
    /////////////////////////////////////////////////////////
    useEffect(() => {
        setSelectedNodeKey(null)
        if(branches.length > 0){
            let branchOptions = branches.map(branch => {
                if(branch.name === "published"){
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        menuName: "Published Branch",
                        name: branch.name,
                        type: "published branch"
                    }
                    return branchObj
                } else {
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        menuName: "Branch: " + branch.name,
                        name: branch.name,
                        type: "branch"
                    }  
                    return branchObj
                }
            })
            // Adds a Placeholder to the Front with an ID of 0
            branchOptions.unshift({
                id: 0,
                menuId: 0,
                menuName: "Select a Map",
                name: "placeholder",
                type: "placeholder"
            })
            setMapDropdownOptions(branchOptions)
            setStartingDate(null)
            setSelectedNodeKey(null)
        } else {
            setMapDropdownOptions([])
            setStartingDate(null)
            setSelectedNodeKey(null)
        }
    }, [project])
    
    // SELECTS MAP FROM DROPDOWN OPTIONS: Branch or View //
    const handleMapSelection = (selectedMenuId) => {
        if(selectedMenuId !== "0"){
            let selection = mapDropdownOptions.find(branch => branch.menuId === selectedMenuId)
            setSelectedMap(selection)
            setStartingDate(null)
            setSelectedNodeKey(null)
        } else {
            setSelectedMap(null)
            setAllNodeKeysAvailable([])
            setStartingDate(null)
            setSelectedNodeKey(null)
        }
    }
    ///////////////////////////////////////////////////////////////////
    // Axios Request for Autosuggest Nodes Based on Branch Selection //
    ///////////////////////////////////////////////////////////////////
    useEffect(() => {
        if(selectedMap !== null){
            // IF BRANCH
            // if(selectedMap.type === "branch" || selectedMap.type === "published branch"){
            setAllNodeKeysAvailable(null)
            axios.get(`/api/projects/${project.id}/branch/${selectedMap.id}/nodes`).then(nodes => {
                const branchNodes = nodes.data.filter(node => node.branchId === selectedMap.id)
                setAllNodeKeysAvailable(branchNodes)
            })
            // IF VIEW
            // } else if(selectedMap.type === "view") {
            //     setAllNodeKeysAvailable(null)
            //     axios.get(`/api/projects/${project.id}/views/${selectedMap.id}`).then(selectedView => {
            //         setAllNodeKeysAvailable(selectedView.data.savedNodes)
            //     })
            // }   
        }
    // CHANGES WHEN MAP IS RESELECTED
    }, [selectedMap])

    // DATE HANDLERS //
    const handleStartDate = (e) => {
        const browserOffset = new Date().getTimezoneOffset() * 60000;
        const adjustedDate = new Date(new Date(e.target.value) - browserOffset);
        setStartingDate(e.target.value)
    }

    const handleEndDate = (e) => {
        setEndingDate(e.target.value)
    }

    // REDIRECT LINKS //
    let navigate = useNavigate()
    function revisionsRedirect(){
        navigate('/revisions/branchlist')
    }

    ///////////////////////////////////////////////
    // FINAL RETURN OBJ: Data Sent to ReportPage //
    ///////////////////////////////////////////////
    const generateChangeLogObj = () => {
        const sendData = {
            nodeKey: selectedNodeKey,
            startDate: startingDate,
            endDate: endingDate,
            project,
            branchSelection: selectedMap
        }
        generateReport(sendData)
    }

    useEffect(() => {
        if(allNodeKeysAvailable !== null){
            setSelectedNodeKey(null)
            setNodeKeySuggestions([])
            if(allNodeKeysAvailable.length === 0 && selectedMap !== null){
                toast.error('There are no nodes in the map you selected.')
            }
        }
    }, [allNodeKeysAvailable])

    if(mapDropdownOptions.length > 0){
    return(
        <Row>
            <Col>
                <div className="reporting-input-section">
                    <div className='reporting-instructions'>
                        <div className="selected-report-header">
                            {'Change Log Report: ' + project.name}
                        </div>
                        <div>
                            <p>
                                This report will generate a list of all the changes made to a node within the date range you enter below.
                            </p>
                            <p>
                                Changes are only associated with the branch you select below, 
                                and changes will not appear in the Published branch until you merge an edited branch 
                                through the <strong onClick={revisionsRedirect} style={{color: "#1560b7", cursor: "pointer"}}>Revisions</strong> page.  
                            </p>
                        </div>
                    </div>
                    <div className='reporting-input-menus-container'>
                        <Row>
                            <div className='col-3'>
                                <div className="reporting-option-header">
                                    Select a Map Option
                                </div>
                                <select
                                    className="form-select form-select-sm"
                                    aria-label=".form-select-sm example"
                                    value={selectedMap?.menuId}
                                    style={{width: '90%', height: '37px', width: '90%', marginLeft: '-2px'}}
                                    onChange={(e) => {
                                        handleMapSelection(e.target.value)
                                    }}
                                    name="mapSelection"
                                >       
                                    {mapDropdownOptions.map((map) => (
                                        <option key={map.menuId} value={map.menuId}>
                                            {map.menuName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-3'>
                                <div className="reporting-option-header">
                                    Enter Node Key
                                </div>
                                {/* NODE KEY AUTOSUGGEST */}
                                <NodeKeyAutosuggest
                                    selectedNodeKey={selectedNodeKey}
                                    allNodeKeysAvailable={allNodeKeysAvailable}
                                    setSelectedNodeKey={setSelectedNodeKey}
                                    setNodeKeySuggestions={setNodeKeySuggestions}
                                    nodeKeySuggestions={nodeKeySuggestions}
                                />
                                
                            </div>
                            <div className='col-2'>
                                <div className="reporting-option-header">
                                    Starting Date
                                </div>
                                <DatePicker 
                                    className='reporting-date-picker'
                                    placeholder="Choose a date..." 
                                    onChange={handleStartDate} value={startingDate}
                                    format={"MM/dd/yyyy"}
                                />
                            </div>
                            <div className='col-2'>
                                <div className="reporting-option-header">
                                    Ending Date
                                </div>
                                <DatePicker 
                                    className='reporting-date-picker'
                                    placeholder="Choose a date..." 
                                    onChange={handleEndDate} value={endingDate}
                                    format={"MM/dd/yyyy"}
                                />
                            </div>  
                            
                        </Row>
                    </div>
                    <Row>
                        <div className="col-3">
                            <div className='generate-report-button-container'>
                                <button
                                    type="button"
                                    className="generate-report-button k-button k-primary text-white"
                                    onClick={generateChangeLogObj}
                                    disabled={!selectedMap || !selectedNodeKey || !startingDate || !endingDate}
                                >
                                    Generate Report
                                </button>
                            </div>
                        </div>
                        <div className='col-9'>
                            <div className='map-options-fine-print-container'>
                                <p className="map-options-fine-print">
                                    {`Map options are limited to the project you have selected under your Profile Settings in the upper right corner. You currently have "` + project.name + `" selected.`}
                                </p>
                            </div>
                        </div>
                    </Row>
                </div>
            </Col>
        </Row>
    )
    } else {
        return null
    }
}

export default ChangeLogInputs;

ChangeLogInputs.propTypes = {
    generateReport: PropTypes.func,
    setMapType: PropTypes.func,
    mapType: PropTypes.any,
    branch: PropTypes.any,
    project: PropTypes.object,
    branches: PropTypes.array,
    views: PropTypes.array,
    userObj: PropTypes.obj
};