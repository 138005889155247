/**
 * @summary Schema for creating new Projects
 * @file newProject.schema.js
 * @returns {Schema}
 * @usedBy Application
 * @author Andrew Popp
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as yup from 'yup';
////////////////////////
// NEW PROJECT SCHEMA //
////////////////////////
const newProjectSchema = yup.object().shape({
  // TOP GENERAL SECTION //
  name: yup.string().required('Name is required'),
  displayTitle: yup.string().required('Display Title is required'),
  description: yup.string().required('Description is required'),
  projectPurpose: yup.array().notRequired(),
  cloneForm: yup.string().notRequired(),
  // PROJECT SETTINGS SECTION //
  nodeSettings: yup.object({
    attributes: yup.array().of(
      yup.object().shape({
        dataType: yup
          .string()
          .required('All node attributes must have a selected data type')
      })
    )
  }),
  connectionSettings: yup.object({
    attributes: yup.array().of(
      yup.object().shape({
        dataType: yup
          .string()
          .required('All connection attributes must have a selected data type')
      })
    )
  }),
  neighborhoodSettings: yup.object({
    attributes: yup.array().of(
      yup.object().shape({
        dataType: yup
          .string()
          .required(
            'All neighborhood attributes must have a selected data type'
          )
      })
    )
  }),
  settings: yup.object({
    coreMetaData: yup.array().of(
      yup.object().shape({
        prefixCode: yup.string().required('Prefix Code is required'),
        subject: yup.string().required('Subject is required'),
        frameworks: yup
          .array()
          .min(1, 'At least one Framework is required')
          .of(yup.number())
          .required('At least one Framework is required'),
        eduLevels: yup
          .array()
          .min(1, 'At least one Education Level is required')
          .of(yup.number())
          .required('At least one Education Level is required')
      })
    ),
    minAge: yup.number().required('Minimum Age is required'),
    maxAge: yup
      .number()
      .min(0, 'Minimum Age is required')
      .test({
        name: 'min',
        exclusive: false,
        params: {},
        message: 'Maximum Age must be higher than or equal to Minimum Age',
        test: function (value) {
          // You can access the price field with `this.parent`.
          return value >= parseFloat(this.parent.minAge);
        }
      })
      .required('Minimum Age is required'),
    applicationUsage: yup
      .array()
      .of(yup.string())
      .min(1, 'At least one Kite Suite Usage is required'),
    themeId: yup.number().nullable().required('Choosing a Theme is required')
  }),
  // MISCELLANEOUS //
  assessmentProgramId: yup.number().notRequired(),
  branch: yup.string().notRequired(),
  connectionsSelectedValues: yup.array().notRequired(),
  id: yup.number().nullable().notRequired(),
  lastUpdated: yup.string().notRequired(),
  neighborhoodSelectedValues: yup.array().notRequired(),
  nodeSelectedValues: yup.array().notRequired(),
  fontName: yup.string().notRequired(),
  layout: yup.string().notRequired(),
  linkageLevels: yup.array().notRequired()
});

export default newProjectSchema;
