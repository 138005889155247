/**
 * @summary ShortestPathReturn.js
 * @file Component that provides a layout/view for the results after the report is generated
 * @returns {JSX}
 * @usedBy GenerateReport.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, {useRef} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import {
  ListView,
} from "@progress/kendo-react-listview";
import { PDFExport } from "@progress/kendo-react-pdf";
import ShortestPathItemRender from './ShortestPathItemRender'
import ShortestPathHeaderRender from './ShortestPathHeaderRender'

const ShortestPathReturn = ({ reportData }) => {
  
  const pdfRef = useRef(null);
  
  const exportReport = () => {
    if (pdfRef.current) {
      pdfRef.current.save();
    }
  }

  return (
    <PDFExport
      ref={pdfRef}
      paperSize="auto"
      margin={40}
      fileName={`Shortest Path Report for ${new Date().getFullYear()}`}
    >
      <Row>
        <div className='generated-report-header' >
          Shortest Path
        </div>
        <div className='generated-report-subhead'>
          {"Shortest Connected Node Path to Navigate from " + reportData.startingNode + " to " + reportData.destinationNode}
        </div>
        <div className='generated-report-button-container'>
            {
              <button
                type="button"
                className="download-report-button"
                style={{fontSize: 18}}
                onClick={exportReport}
              >
                Download Report
              </button>
            }
        </div>
      </Row>
      <Row >
        <Col>
          <ListView 
            data={reportData.nodeArray}
            item={ShortestPathItemRender}
            header={ShortestPathHeaderRender}
          />
        </Col>
      </Row>
    </PDFExport>
  )
}


export default ShortestPathReturn;

ShortestPathReturn.propTypes = {
  reportData: PropTypes.array,
  dataItem: PropTypes.object,
}
