/**
 * @summary LinkageLevelActions.js
 * @file LinkageLevel actions
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios';
import {
  GET_LINKAGE_LEVELS,
  CLEAR_LINKAGE_LEVELS,
  GET_LINKAGE_LEVEL
} from './LinkageLevelTypes';

export const getLinkageLevels = (projectId, branchId) => async (dispatch) => {
  const { data } = await axios.get(
    `/api/project/${projectId}/branch/${branchId}/linkageLevel`
  );
  if (data) {
    dispatch({
      type: GET_LINKAGE_LEVELS,
      linkageLevels: data
    });
  } else {
    dispatch(clearLinkageLevels());
  }
};

export const getLinkageLevel =
  (projectId, branchId, neighborhoodId, linkageLevelId) => async (dispatch) => {
    const { data } = await axios.get(
      `/api/project/${projectId}/branch/${branchId}/neighborhood/${neighborhoodId}/linkageLevel/${linkageLevelId}`
    );
    if (data) {
      dispatch({
        type: GET_LINKAGE_LEVEL,
        selectedData: data
      });
    } else {
      dispatch(clearLinkageLevels());
    }
  };

export const clearLinkageLevels = () => ({ type: CLEAR_LINKAGE_LEVELS });
