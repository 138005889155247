/**
 * @summary formatPCResponse.js
 * @file Function iterates over response, formattedFlags, subjectName and pushes them to arrays, which is then stored and returned in object variable called profileCheckReportData
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const formatPCResponse = (response, formattedFlags, subjectName) => {

    // Returns an Array of Objects that Need to Be Formatted a Bit to Map
    if (Object.keys(response).length > 0) {
        let objectKeys = Object.keys(response)
        let formattedDataArray = []

        for(let i =0; i < objectKeys.length; i++){
            let flaggedNodes = []
            response[objectKeys[i]].forEach(issue => {
                flaggedNodes.push(issue.nodeKey)
            })
            let flagItem = {
                flag: objectKeys[i],
                flaggedNodes,
                subject: subjectName,
                amount: response[objectKeys[i]].length
            }
            formattedDataArray.push(flagItem)
        }
        let profileCheckReportData = {
            dataArray: formattedDataArray,
            flagNames: formattedFlags,
            errorMessage: null
        }
        return profileCheckReportData;
    } else {
        return {errorMessage: "No nodes could be found within this map that match this Subject with these Accessibility Flags."}
    }
}