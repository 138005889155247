/**
 * @summary ProfileCheckReturn.js
 * @file Component that provides a layout for the results after the report is generated
 * @returns {JSX}
 * @usedBy GenerateReport.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, {useRef} from 'react'
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import {
  ListView,
} from "@progress/kendo-react-listview";
import { startCase } from 'lodash';
import ProfileCheckItemRender from './ProfileCheckItemRender'
import ProfileCheckHeaderRender from './ProfileCheckHeaderRender'
import { PDFExport } from "@progress/kendo-react-pdf";

const ProfileCheckReturn = ({ reportData }) => {

  const pdfRef = useRef(null);
  
  const exportReport = () => {
    if (pdfRef.current) {
      pdfRef.current.save();
    }
  }

  // FORMAT SUBHEAD TEXT
  let formattedFlagText = []
  for(let i=0; i < reportData.flagNames.length; i++){
    if(formattedFlagText.length === 0){
      formattedFlagText.push(startCase(reportData.flagNames[i]))
    } else {
      formattedFlagText.push(", " + startCase(reportData.flagNames[i]))
    }
  }
  return (
    <PDFExport
      ref={pdfRef}
      paperSize="auto"
      margin={40}
      fileName={`Profile Check Report for ${new Date().getFullYear()}`}
    >
    <Row>
      <div className='generated-report-header' >
        Profile Check
      </div>
      <div className='generated-report-subhead'>
          {formattedFlagText}
      </div>
      <div className='generated-report-button-container'>
          {
            <button
              type="button"
              className="download-report-button"
              style={{fontSize: 18}}
              onClick={exportReport}
            >
              Download Report
            </button>
          }
      </div>
    </Row>
    <Row>
      <ListView
        data={reportData.dataArray}
        item={ProfileCheckItemRender}
        header={ProfileCheckHeaderRender}
      />
    </Row>
  
  </PDFExport>
  )
    
}

export default ProfileCheckReturn;

ProfileCheckReturn.propTypes = {
  reportData: PropTypes.array,
  dataItem: PropTypes.obj,
}
