/**
 * @summary SnapshotSummary.js
 * @file Component that returns the report's headers as well as snapshot summaries of node, connection, and neighborhood
 * @returns {JSX}
 * @usedBy SnapshotComparisonReturn.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
// Element Section Components
import NodeSummary from './NodeSummary'
import NeighborhoodSummary from './NeighborhoodSummary';
import ConnectionSummary from './ConnectionSummary';

const SnapshotSummary = ({
    comparisonLogsObj
}) => {

    let showNodeSummary = false;
    let showNeighborhoodSummary = false;
    let showConnectionSummary = false;
    if(comparisonLogsObj.nodeLogs.uniqueElements.length +
        comparisonLogsObj.nodeLogs.elementIncongruencies.length +
        comparisonLogsObj.nodeLogs.uniqueAttributes.length +
        comparisonLogsObj.nodeLogs.attributeIncongruencies.length 
        > 0){
        showNodeSummary = true
    } else {
        showNodeSummary = false
    }
    if(comparisonLogsObj.neighborhoodLogs.uniqueElements.length +
        comparisonLogsObj.neighborhoodLogs.elementIncongruencies.length +
        comparisonLogsObj.neighborhoodLogs.uniqueAttributes.length +
        comparisonLogsObj.neighborhoodLogs.attributeIncongruencies.length 
        > 0){
        showNeighborhoodSummary = true
    } else [
        showNeighborhoodSummary = false
    ]
    if(comparisonLogsObj.connectionLogs.uniqueElements.length +
        comparisonLogsObj.connectionLogs.elementIncongruencies.length +
        comparisonLogsObj.connectionLogs.uniqueAttributes.length +
        comparisonLogsObj.connectionLogs.attributeIncongruencies.length 
        > 0){
        showConnectionSummary = true
    } else {
        showConnectionSummary = false
    }
    
    return(
        <div className="col-12"
            style={{
                marginTop: 0,
                marginBottom: 0,
            }}
        >
            <div
            className="row k-listview-item p-1 border-bottom "
            style={{
                marginTop: 0,
                marginBottom: 0,
            }}
            >
                <div className="col-2">
                    <div
                        style={{
                            fontSize: 18,
                            fontWeight: 700,
                            color: "#1560b7",
                            alignItems: "left",
                            marginLeft: 0,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                    >
                        Property
                    </div>
                </div>
                <div className="col-2" >
                    <div
                        style={{
                            fontSize: 18,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 0,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                    >
                        Incongruency
                    </div>
                </div>
                <div className="col-2">
                    <div
                        style={{
                            fontSize: 18,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 0,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                    >
                        Element Key
                    </div>
                </div>
                
                <div className="col-3" >
                    <div
                        style={{
                            fontSize: 18,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 0,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                    >
                        Snapshot A
                    </div>
                    
                </div>
                <div className="col-3" >
                    <div
                        style={{
                            fontSize: 18,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 0,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                    >
                        Snapshot B
                    </div>
                </div>
            </div>
            {showNodeSummary === true &&
            <NodeSummary 
                uniqueElements={comparisonLogsObj.nodeLogs.uniqueElements}
                elementIncongruencies={comparisonLogsObj.nodeLogs.elementIncongruencies}
                uniqueAttributes={comparisonLogsObj.nodeLogs.uniqueAttributes}
                attributeIncongruencies={comparisonLogsObj.nodeLogs.attributeIncongruencies}
            />
            }
            {showNeighborhoodSummary === true && 
            <NeighborhoodSummary 
                uniqueElements={comparisonLogsObj.neighborhoodLogs.uniqueElements}
                elementIncongruencies={comparisonLogsObj.neighborhoodLogs.elementIncongruencies}
                uniqueAttributes={comparisonLogsObj.neighborhoodLogs.uniqueAttributes}
                attributeIncongruencies={comparisonLogsObj.neighborhoodLogs.attributeIncongruencies}
            />
            }
            {showConnectionSummary === true && 
            <ConnectionSummary 
                uniqueElements={comparisonLogsObj.connectionLogs.uniqueElements}
                elementIncongruencies={comparisonLogsObj.connectionLogs.elementIncongruencies}
                uniqueAttributes={comparisonLogsObj.connectionLogs.uniqueAttributes}
                attributeIncongruencies={comparisonLogsObj.connectionLogs.attributeIncongruencies}
            />
            }
        </div>
    )
}

export default SnapshotSummary;

SnapshotSummary.propTypes = {
    comparisonLogsObj: PropTypes.object,
}
