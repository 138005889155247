/**
 * @summary Validator wrapper to be used in components
 * @file validator.js
 * @returns {Promise}
 * @usedBy Application
 * @author Andrew Popp
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import { toast } from 'react-toastify';
import ValidationMsg from './ValidationMsg';

const validator = async (yupSchema, inputToValidate, ctx = {}) => {
  let isValid = false;
  // Validate item against itemSchema
  await yupSchema
    .validate(inputToValidate, { abortEarly: false, context: ctx })
    .then(function (value) {
      return (isValid = true);
    })
    .catch((e) => {
      if (e.errors) {
        const message =
          ctx?.isItemStatusFinal && 'Item has been set with a draft status !';
        toast.error(
          <ValidationMsg errorsArray={e.errors} message={message} />,
          { autoClose: 5000 }
        );
        return (isValid = false);
      }
      return (isValid = false);
    });

  return isValid;
};

export default validator;
