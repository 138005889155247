/**
 * @summary Views Reducer
 * @file ViewReducer.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import {
  CREATE_VIEW,
  GET_VIEWS,
  GET_VIEW,
  GRID_DATA_ACTIONS_SELECTIONS_VIEWS_FULLFILLED,
  CLEAR_VIEWS,
  EDIT_VIEWS,
  RESET_SELECTED_VIEW,
  DELETE_VIEW,
  ACTIVATE_VIEW
} from './ViewTypes';

const views = {
  selectedData: {}
};

/**
 * @summary
 * This redux reducer is used for view CRUD based on the action type
 *
 * @param {views} state
 * @param {reduxAction} action
 */
const viewReducer = (state = views, action) => {
  switch (action.type) {
    case GET_VIEWS:
      return {
        selectedData: state.selectedData,
        // keep all incoming nodes inside a single level
        ...action.views.reduce((newObj, view) => {
          newObj[view.id] = view;
          return newObj;
        }, {})
      };

    /// NEW ///
    case GET_VIEW:
      return {
        ...state,
        selectedData: action.views
      };
    /// NEW ///

    case CREATE_VIEW:
      return {
        ...state,
        [action.meta]: {
          ...action.view,
          id: action.meta
        },
        selectedData: {
          ...action.view,
          id: action.meta
        }
      };

    case EDIT_VIEWS:
      return {
        ...state,
        [action.meta.id]: {
          ...action.view,
          id: action.meta.id
        },
        selectedData: {
          ...action.view,
          id: action.meta.id
        }
      };

    case GRID_DATA_ACTIONS_SELECTIONS_VIEWS_FULLFILLED:
      let copySelectedData = [...state.selectedData];
      const isArr = Array.isArray(action.view.data);
      if (isArr && action.view.data[0].selected) {
        copySelectedData = action.view.data;
      } else if (!isArr) {
        const idx = copySelectedData.findIndex(
          (view) => view.id === action.view.data.id
        );
        idx < 0
          ? copySelectedData.push(action.view.data)
          : copySelectedData.splice(idx, 1);
      } else {
        copySelectedData = [];
      }

      return {
        ...state,
        selectedData: copySelectedData,
        error: null
      };

    case CLEAR_VIEWS:
      return {
        selectedData: undefined
      };

    case RESET_SELECTED_VIEW:
      return {
        ...state,
        selectedData: undefined
      };

    case DELETE_VIEW:
      let copyState = { ...state };
      copyState[action.views.id].isActive = false;
      return {
        ...copyState,
        selectedData: undefined
      };

    case ACTIVATE_VIEW:
      copyState = { ...state };
      copyState[action.views.id].isActive = true;
      return {
        ...copyState,
        selectedData: undefined
      };

    default:
      return state;
  }
};
export default viewReducer;
