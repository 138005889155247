/**
 * @summary useMedia.js
 * @file Utility file that returns an array of supported file types in a media upload
 * @returns {JSX}
 * @usedBy Uploader.js, MediaModal.js, 
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const allowedExtensions = (a, b) => {
  return [
    '.doc',
    '.docx',
    '.png',
    '.pdf',
    'hmtl',
    '.xlsx',
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.svg',
    '.svgz',
    '.mp4',
    '.webm',
    '.mp3',
    '.wav'
  ];
};