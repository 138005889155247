/**
 * @summary formatSelectedChangeLog.js
 * @file This function is responsible for comparing the "Before and After" pairs to check for changes
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import cloneDeep from 'lodash/cloneDeep';

export const formatSelectedChangeLog = (unformattedArray) => {
    // ALL PAIRS FORMATTED INTO OBJECT AND PUSHED TO THIS ARRAY
    let unorderedArrayOfPairObjs = []
    for(let i=0; i < unformattedArray.length; i++){
        
        const left = cloneDeep(unformattedArray[i].pair[0])
        const right = cloneDeep(unformattedArray[i].pair[1])
        let leftAttributes = []
        let rightAttributes = []

        
        left?.nodeSettings?.forEach(attribute => {
            let values = []
            if(left.selectedValue !== null){
                left.selectedValue.forEach(selectedValueObj => {
                    if(selectedValueObj.attrId === attribute.attributeId){
                        selectedValueObj.values.forEach(value => {
                            values.push(value.selectedValue)
                        })
                    }
                })

                if(values.length){
                    // Capitalizes Attributes and Remove Underscores   
                    let newAttName = attribute.name.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                    let attributeObj = {
                        name: newAttName,
                        category: attribute.category,
                        value: values
                    }
                    leftAttributes.push(attributeObj)
                }
            } else {
                values.push([])
            }

        })

    right?.nodeSettings?.forEach(attribute => {
        let values = []
        if(right.selectedValue !== null){
            right.selectedValue.forEach(selectedValueObj => {
                if(selectedValueObj.attrId === attribute.attributeId){
                    selectedValueObj.values.forEach(value => {
                        values.push(value.selectedValue)
                    })
                }
            })
            if(values.length){
                let newAttName = attribute.name.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                let attributeObj = {
                    name: newAttName,
                    category: attribute.category,
                    value: values
                }
                rightAttributes.push(attributeObj)
            }
        } else {
            values.push([])
        }            
    })

        left.attributes = leftAttributes
        right.attributes = rightAttributes
        
        const logData = {
            origin: unformattedArray[i].origin,
            leftNode: left,
            rightNode: right,
            longFormDate: unformattedArray[i].longFormDate,
            shortFormDate: unformattedArray[i].shortFormDate,
            timeStamp: unformattedArray[i].timeStamp
        }

        unorderedArrayOfPairObjs.push(logData)
    }
    let orderedArrayOfPairObjs = unorderedArrayOfPairObjs.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));
    return orderedArrayOfPairObjs

}