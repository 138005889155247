/**
 * @summary Panel.js
 * @file Component which displays content in the accordion based on the props passed down
 * @returns {JSX}
 * @usedBy ConnectionForm.js, newForm.js, LinkageLevelsSettings.js, NeighborhoodForm.js, NodeForm.js, conflictResolution.js, BranchListGrid.js, DjBranchListGrid.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import uuid from '../../utility/uuid';
import PropTypes from 'prop-types';
import HelpWindow from './HelpWindow';

const Panel = ({ title, body, panel_type, help_code }) => {
  const uniqueId = uuid(); // each accordion instance requires unique id
  const [renderChild, setRenderChild] = useState(false);

  return (
    <div className="accordion-item">
      <h2
        className="accordion-header"
        id={uniqueId}
        onClick={() => setRenderChild(!renderChild)}
      >
        <button
          className="accordion-button bg-primary text-white collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#panelsStayOpen-${uniqueId}`}
          aria-expanded="false"
          aria-controls={`panelsStayOpen-${uniqueId}`}
        >
          <div className={'col'}>{title}</div>
        </button>
        <div className={'help-wrapper'}>
          <HelpWindow
            title={title}
            panel_type={panel_type}
            help_code={help_code}
          />
        </div>
      </h2>
      <div
        id={`panelsStayOpen-${uniqueId}`}
        className="accordion-collapse collapse"
        aria-labelledby={uniqueId}
      >
        {renderChild && <div className="accordion-body">{body}</div>}
      </div>
    </div>
  );
};

Panel.propTypes = {
  title: PropTypes.string,
  body: PropTypes.any,
  panel_type: PropTypes.string,
  help_code: PropTypes.string
};

export default Panel;
