/**
 * @summary ShortestPathItemRender.js
 * @file This component provides rows for nodes that are included in the shortest path report
 * @returns {JSX}
 * @usedBy ShortestPathReturn.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react'
import PropTypes from 'prop-types';
//////////////////////////
// PROFILE CHECK RETURN //
//////////////////////////

const ShortestPathItemRender = ({dataItem}) => {
    return (
        
        <div
            className="row k-listview-item p-2 border-bottom align-middle"
            style={{
                marginTop: 0,
                marginBottom: 0,
            }}
        >
            <div className="col-2" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {dataItem.position}
                </div>
            </div>
            <div className="col-2" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {dataItem.nodeKey}
                </div>
            </div>

            <div className="col-2" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {dataItem.title}
                </div>
            </div>

            <div className="col-6" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {dataItem.description}
                </div>
            </div>

        </div>
    );
};

export default ShortestPathItemRender;

ShortestPathItemRender.propTypes = {
    dataItem: PropTypes.object,
}