/**
 * @summary EditMediaFileNameModal.js
 * @file Modal that allows users to edit a media's file name (and potentially further properties)
 * @returns {JSX}
 * @usedBy NodeMediaGrid.js, NeighborhoodMediaGrid.js
 * @author Sam Lee
 * @since 4/5/2024
 * @lastUpdated 04/2024
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

const EditMediaFileNameModal = ({ show, setShow, selectedMedia, setEditedMedia }) => {
  const [editFileName, setEditFileName] = useState('');

  const handleEditFileNameChange = (e) => {
    setEditFileName(e.target.value)
  };

  // Note: Saving the input to db is handled in NodeForm.js and NeighborhoodForm.js in the onSave fn //
  const handleSubmit = async (e) => {
    e.preventDefault();
    const editedFileName = await [...selectedMedia];
    editedFileName[0].name = editFileName;
    setEditedMedia(editedFileName);
    setShow(false);
  };

  return (
    <Modal show={show} backdrop='static' centered>
      <Modal.Header>
        <Modal.Title style={{ color: 'white' }}>
          Edit Media File Name
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className='p-2'>
          <h4
            style={{
              color: '#1560b7',
              fontSize: '16px',
              fontWeight: '600'
            }}
          >
            Supported file name characters
          </h4>
          <p>
            <strong>Alphanumeric characters</strong>
          </p>
          <p>0-9 a-z A-Z</p>
          <p>
            <strong>Special characters</strong>
          </p>
          <p>
            Exclamation point (!), Hyphen (-), Underscore (_), Period (.),
            Asterisk (*), Open parenthesis ((), Close parenthesis ())
          </p>
          <div className='d-flex flex-column w-50 h-50 my-4'>
            <h4
              style={{
                color: '#1560b7',
                fontSize: '16px',
                fontWeight: '600'
              }}
            >
              Input New File Name
            </h4>
            <div className='d-flex'>
              <p><em>Current File Name:</em></p>
              <p className='mx-2'>{selectedMedia[0]?.name}</p>
            </div>
            <input 
              required 
              className='form-control form-control-sm' 
              onChange={handleEditFileNameChange}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
      <div className='container'>
        <div className='row'>
            <div className='col-6'>
              <button
                onClick={() => setShow(false)}
                className='btn btn-danger'
              >
                Close
              </button>
            </div>
            <div className='col-6'>
              <button
                className='btn btn-primary'
                disabled={!(editFileName.length > 0)}
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
        </div>
      </div>
      </Modal.Footer>
    </Modal>
  )
}

EditMediaFileNameModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  selectedMedia: PropTypes.array,
  setEditedMedia: PropTypes.func,
}

export default EditMediaFileNameModal