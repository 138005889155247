/**
 * @summary getDegreeHistogram.js
 * @file Function finds each nodes' incoming/outgoing connections, checks to see if it falls under the user's specified range of min/max connections, and then returns the result
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { formatMapData } from "../formatMapData";

export const getDegreeHistogram = (nodes, connections, minConnections, maxConnections) => {
    const {nodeDataArray, linkDataArray} = formatMapData(nodes, connections)
    const $ = go.GraphObject.make;
    const diagram = $(go.Diagram, // no div!
      {
        model: $(go.GraphLinksModel,
          {   
              nodeKeyProperty: 'nodeKey',
              linkKeyProperty: 'key' 
          })
      });
  
    // need to merge in the data since the props are immutable and GoJS normally adds some internal properties
    diagram.model.commit(m => {
        m.mergeNodeDataArray(nodeDataArray);
        if (linkDataArray !== undefined && m instanceof go.GraphLinksModel) {
        m.mergeLinkDataArray(linkDataArray);
        }
    });

    let histogramArray = []
    let totalIncomingCount = []
    let totalOutgoingCount = []
    
    diagram.nodes.each(node => {
        let nodesInto = node.findNodesInto()
        let nodesOutOf = node.findNodesOutOf()
        let toNodeArray = [];
        let fromNodeArray = [];

        nodesInto.each(connectedNode => {
            toNodeArray.push(connectedNode.data)
        })

        nodesOutOf.each(connectedNode => {
            fromNodeArray.push(connectedNode.data)
        })

        if(toNodeArray.length > 0){
            totalIncomingCount.push(node.data)
        }

        if(fromNodeArray.length > 0){
            totalOutgoingCount.push(node.data)
        }
        
        if((toNodeArray.length >= minConnections && toNodeArray.length <= maxConnections) || (fromNodeArray.length >= minConnections && fromNodeArray.length <= maxConnections)){
            let returnNode = {
                node: node.data,
                incomingNodes: toNodeArray,
                incomingCount: toNodeArray.length,
                outgoingNodes: fromNodeArray,
                outgoingCount: fromNodeArray.length
            }
            histogramArray.push(returnNode)
        }

    })

    let incomingSum = totalIncomingCount.length;
    let outgoingSum = totalOutgoingCount.length;

    histogramArray.forEach(histogramObj => {
        if(histogramObj.incomingCount > 0){

        }
    })

    
    ////////////////////////////////////////
    // REPORTING - RADIUS FILTER - FOR DJ //
    ////////////////////////////////////////
      
    return {
      degreeHistogramData: histogramArray,
      totalIncomingConnections: incomingSum,
      totalOutgoingConnections: outgoingSum,
      minConnections: minConnections,
      maxConnections: maxConnections
    };
}


