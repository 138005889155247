/**
 * @summary InspectorTool.js
 * @file Parent Modal for Adding a Neighborhood on Canvas
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2024
 * @lastUpdated 8/8/2024
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../canvasPage.scss';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import NodeKeyAutosuggest from '../dataEditorUtils/NodeKeyAutosuggest';
import { MultiSelect } from "@progress/kendo-react-dropdowns";


const AddNeighborhoodCanvasModal = ({
    handleAddModalVisibility,
    handleCrud,
    handleTableviewNavigation,
    selectedNodesIncluded,
    tableNodeSelections,
  }) => {

    // REDUX DATA
    const userObj = useSelector((state) => state.authReducer.userObj) 
    const projectObj = useSelector((state) => state.authReducer.userObj.selectedProject[0])
    const branchObj = useSelector((state) => state.authReducer.userObj.selectedBranch[0])
    const reduxNeighborhoods = useSelector((state) => state.neighborhoodReducer);
    const reduxNodes = useSelector((state) => Object.values(state.nodeReducer));
    const attributeValues = useSelector((state) => {
        return state.projectSetupReducer.neighborhoodsSelectedValues
    });

    // MODAL SELECTIONS BY USER
    // User Changes these States Throughout the Modal
    // Attributes are handles in states below, and formatted into saveNodeAttributes through a useEffect
    const [saveNeighborhoodKey, setSaveNeighborhoodKey] = useState(null)
    const [saveNeighborhoodName, setSaveNeighborhoodName] = useState(null)
    const [saveNeighborhoodDescription, setSaveNeighborhoodDescription] = useState(null)
    const [saveNeighborhoodAttributes, setSaveNeighborhoodAttributes] =useState({
        details: [],
        metadata: [],
        taxonomies: [],
        flags: []
    })

    // MEMBER NODE STATES
    const [selectedNodeKeys, setSelectedNodeKeys] = useState([])
    const [allNodeKeysAvailable, setAllNodeKeysAvailable] = useState([])

    // ATTRIBUTE STATES
    // These Set the Correct Available Attributes and Handle Selections
    // Attributes are formatted in a useEffect and set into "saveNodeAttributes" above
    const [availableSubjects, setAvailableSubjects] = useState([])
    const [availableFrameworks , setAvailableFrameworks] = useState([])
    const [selectedSubjects, setSelectedSubjects] = useState([])
    const [selectedFrameworks, setSelectedFrameworks] = useState([])

    // USE EFFECT FOR GETTING ATTRIBUTES
    // Filters Subjects and Frameworks from Redux Attributes, and Formats them for save-ability
    useEffect(() => {
        if(attributeValues.filter(setting => setting.name === "subject")){
            const subject = attributeValues.filter(setting => setting.name === "subject")[0];
            if(subject){
                let formattedSubjects = subject.value.map(subjectValues => {
                    return ({
                        attrId: subject.id,
                        value: [
                            {
                                id: subjectValues.id,
                                value: subjectValues.name
                            }
                        ]
                    })
                })
                setAvailableSubjects(formattedSubjects)
            }
        }
        if(attributeValues.filter(setting => setting.name === "content_framework_type")){
            const framework = attributeValues.filter(setting => setting.name === "content_framework_type")[0];
            if(framework){
                let formattedFrameworks = framework.value.map(frameworkValues => {
                    return ({
                        attrId: framework.id,
                        value: [
                            {
                                id: frameworkValues.id,
                                value: frameworkValues.name
                            }
                        ]
                    })
                })
                setAvailableFrameworks(formattedFrameworks)
            }
        }
    
    }, [])

    // FINDING ALL AVAILABLE NODES FOR NEIGHBORHOOD
    useEffect(() => {
        setAllNodeKeysAvailable([])
        axios.get(`/api/projects/${projectObj.id}/branch/${branchObj.id}/nodes`).then(nodes => {
            const branchNodes = nodes.data.filter(node => node.branchId === branchObj.id)
            const formattedBranchNodes = nodes.data.map((node) => ({name: node.nodeKey, id: node.id}))
            setAllNodeKeysAvailable(formattedBranchNodes)
        })
    }, [])

    // FORMATTING PRE-SELECTED NODES OF NEIGHBORHOOD
    useEffect(() => {
        if(selectedNodesIncluded){
            setAllNodeKeysAvailable([])
            if(tableNodeSelections.length > 0){
                const formattedBranchNodes = tableNodeSelections.map((node) => ({name: node.nodeKey, id: node.id}))
                setSelectedNodeKeys(formattedBranchNodes)
            }
            const branchNodes = reduxNodes.filter(node => node.branchId === userObj.selectedBranch[0].id)
            const formattedBranchNodes = branchNodes.map((node) => ({name: node.nodeKey, id: node.id}))
            setAllNodeKeysAvailable(formattedBranchNodes)
        }
    },[selectedNodesIncluded])

    // FORMAT ATTRIBUTES INTO NEIGHBORHOOD
    useEffect(()=>{
        let settings = {
            details: [],
            metadata: [],
            taxonomies: [],
            flags: []
        }
        if(selectedSubjects){
            settings.metadata.push(...selectedSubjects)
        }
        if(selectedFrameworks){
            settings.metadata.push(...selectedFrameworks)
        }
        setSaveNeighborhoodAttributes(settings)
    },[selectedSubjects,selectedFrameworks])

    // FORMATS SAVED NEIGHBORHOOD NAME
    const formatInputString = (inputString) => {
        if(inputString !== null && inputString.length){
            inputString.trim()
            if(inputString.trim().length > 0){
                return inputString.trim()
            } else {
                return null
            }         
        } else {
            return null
        }
    }

    // JSON OBJECT THAT GETS SAVED
    const jsonNeighborhood = {
        action: 'create',
        elementType: 'neighborhood',
        id: -1,
        projectId: projectObj.id,
        branchId: branchObj.id,
        name: formatInputString(saveNeighborhoodName),
        description: saveNeighborhoodDescription || "",
        nodes: selectedNodeKeys.map(node=> node.id),
        settings: saveNeighborhoodAttributes
    }

    // HANDLE SAVE NEIGHBORHOOD
    const handleSaveNewNeighborhood = (e) => {
        delete reduxNeighborhoods.selectedData
        let reduxNeighborhoodArray = Object.values(reduxNeighborhoods);
        if(saveNeighborhoodName){
            if(reduxNeighborhoodArray.find(node => {return node?.name === saveNeighborhoodName}) === undefined){       
                handleCrud(jsonNeighborhood)         
                handleAddModalVisibility()
            } else {
                return toast.error("Duplicate Neighborhood Name")
            }
        } else {
            if(saveNeighborhoodName === null){
                return toast.error("Enter a Neighborhood Name")
            }
        }
    }

    // NEIGHBORHOOD NAME CHANGE
    const handleNeighborhoodNameInputChange = (e) => {
        if(e.target.value.trim().length > 0){
            setSaveNeighborhoodName(e.target.value)
        }else {
            setSaveNeighborhoodName(null)
        }
    }

    // NEIGHBORHOOD DESCRIPTION CHANGE
    const handleNeighborhoodDescriptionInputChange = (e) => {
        setSaveNeighborhoodDescription(e.target.value)
    }

    // SUBJECT INPUTS
    const handleSubjectInputChange = (attributeInputs) => {
        if(attributeInputs.length > 0){
            let formattedInputs = attributeInputs.map(attributeInput => {
                let foundSubject = availableSubjects.find(subject => subject.value[0].id === attributeInput.id)
                let formattedInput = {
                    attrId: foundSubject.attrId,
                    value: [
                        {
                            id: attributeInput.id,
                            value: attributeInput.value
                        }
                    ]
                }
                return formattedInput;
            })
            setSelectedSubjects(formattedInputs)
        } else {
            setSelectedSubjects([])
        }
    }

    // FRAMWORK INPUTS
    const handleFrameworkInputChange = (attributeInputs) => {
        if(attributeInputs.length > 0){
            let formattedInputs = attributeInputs.map(attributeInput => {
                let foundFramework = availableFrameworks.find(framework => framework.value[0].id === attributeInput.id)
                let formattedInput = {
                    attrId: foundFramework.attrId,
                    value: [
                        {
                            id: attributeInput.id,
                            value: attributeInput.value
                        }
                    ]
                }
                return formattedInput;
            })
            setSelectedFrameworks(formattedInputs)
        } else {
            setSelectedFrameworks([])
        }
    }

    
    // FORMAT ATTRIBUTE DROPDOWNS AND INPUTS - They're a little complex to unpack
    let subjectDropdownOptions = availableSubjects.length > 0 ? availableSubjects?.map(subject => {
        return subject.value[0]
    }) : []
    let subjectInputs = selectedSubjects.length > 0 ? selectedSubjects.map(subject => {
        let subjectInput = {
            attrId: subject.attrId,
            value: subject.value[0].value,
            id: subject.value[0].id,
        }
        return subjectInput
    }) : []
    let frameworkDropdownOptions = availableFrameworks.length > 0 ? availableFrameworks?.map(subject => {
        return subject.value[0]
    }) : []
    let frameworkInputs = selectedFrameworks.length > 0 ? selectedFrameworks.map(framework => {
        let frameworkInput = {
            attrId: framework.attrId,
            value: framework.value[0].value,
            id: framework.value[0].id,
        }
        return frameworkInput
    }) : []

    // SORTS THE NODE KEYS IN ALPHABETICAL ORDER FOR THE "ADD NODE TO NEIGHBORHOOD" DROPDOWN
    // A bit long because we needed to add specifications for hyphens, numbers, and spacing
    function sortByNodeKey(arr) {
        return arr.sort((a, b) => {
            if (!a.name || !b.name) return 0; // Handle missing nodeKey
    
            // Remove hyphens and spaces, then concatenate parts into a single string
            const aKey = a.name.replace(/[-\s]/g, '');
            const bKey = b.name.replace(/[-\s]/g, '');
    
            // Compare the concatenated strings
            return aKey.localeCompare(bKey, undefined, { numeric: true, sensitivity: 'base' });
        });
    }
    
    const alphabeticalNodeKeys = allNodeKeysAvailable.length ? sortByNodeKey(allNodeKeysAvailable) : [];

    return (
        <>
            <Modal.Header>
                <Modal.Title>
                    <div className='modal-header-text'>
                        Add New Neighborhood
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-12'>
                        <div className='instructional-blue pt-3 pb-3'>
                            <div className='col-12'>
                                Enter the data for your neighborhood. Redirect to Tableview for more configuration options on the tableview page.
                            </div>
                            
                        </div>
                        <div className='ps-3 pb-4'>
                            <div className='row mt-1'>
                                
                                <div className='col-9'>
                                    <div className='ms-3 pt-3 bold-instruction'>
                                            Redirecting to the Tableview Page will open a new tab where you can 
                                            customize your node with more in-depth options.
                                    </div>
                                </div>
                                <div className="col-3 pe-5">   
                                    <button onClick={handleTableviewNavigation} className="btn btn-success w-100 w-75 h-75">Redirect to Tableview</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container pb-4 px-4'>
                    <div className='col-12'>
                        <div className="data-editor-section">
                            <div className="col-12 data-editor-subheader">
                                Neighborhood Name
                            </div>
                            <div className="col-12">
                                    <input 
                                        placeholder="Enter a Name" 
                                        onChange={handleNeighborhoodNameInputChange}
                                        type="text">
                                    </input>            
                            </div>
                        </div>
                        <div className="data-editor-section">
                            <div className="col-12 data-editor-subheader">
                                Neighborhood Description
                            </div>
                            <div className="col-12">
                                <textArea 
                                    placeholder="Enter a Description" 
                                    className='data-editor-description-input'
                                    // style={{ maxHeight: "300px", maxWidth: "100%"}}
                                    onChange={handleNeighborhoodDescriptionInputChange}
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="data-editor-section">
                            <div className='"col-12 data-editor-subheader"'>
                                Neighborhood Member Nodes
                            </div>
                            <div className="col-12">
                                <MultiSelect
                                    data={alphabeticalNodeKeys}
                                    style={{color: "black"}}
                                    dataItemKey='id'
                                    textField='name'
                                    value={selectedNodeKeys}
                                    placeholder={'Enter the Node Keys you would like added to this neighborhood.'}
                                    onChange={(e) => {
                                        setSelectedNodeKeys(e.target.value)
                                    }}
                                >
                                </MultiSelect>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="data-editor-section col-12">
                                    <div className='col-2 d-inline data-editor-subheader pe-1'>
                                        Subject:
                                    </div>
                                    <MultiSelect
                                        data={subjectDropdownOptions}
                                        style={{color: "black"}}
                                        dataItemKey='id'
                                        textField='value'
                                        value={subjectInputs}
                                        placeholder={'Enter the Subjects You Would Like to Add.'}
                                        onChange={(e) => {
                                            handleSubjectInputChange(e.target.value)
                                        }}
                                    >
                                    </MultiSelect>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="data-editor-section col-12">
                                    <div className='col-2 d-inline  data-editor-subheader pe-1'>
                                        Content Framework:
                                    </div>
                                    <MultiSelect
                                        data={frameworkDropdownOptions}
                                        style={{color: "black"}}
                                        dataItemKey='id'
                                        textField='value'
                                        value={frameworkInputs}
                                        placeholder={'Enter the Frameworks You Would Like to Add.'}
                                        onChange={(e) => {
                                            handleFrameworkInputChange(e.target.value)
                                        }}
                                    >
                                    </MultiSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6'>
                            <button 
                                className="btn btn-primary btn-sm text-white"
                                onClick={handleSaveNewNeighborhood}
                            >
                                Save and Close
                            </button>
                        </div>
                        <div className='col-6'>
                            <button 
                                className="btn btn-danger btn-sm text-white" 
                                onClick={handleAddModalVisibility}
                            >
                                Cancel
                            </button>
                        </div>             
                    </div>
                </div>
            </Modal.Footer>
        </>
    );
} 

AddNeighborhoodCanvasModal.propTypes = {
  handleAddModalVisibility: PropTypes.func,
  handleCrud: PropTypes.func,
  handleTableviewNavigation: PropTypes.func,
  selectedNodesIncluded: PropTypes.bool,
  tableNodeSelections: PropTypes.array
};

export default AddNeighborhoodCanvasModal;