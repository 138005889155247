/**
 * @summary FrameworkType.jsx
 * @file Framework action string types
 * @returns {String}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const LOAD_FRAMEWORKDETAIL = 'LOAD_FRAMEWORKDETAIL';

export const LOAD_SELECTED_CONTENT_CODES = 'LOAD_SELECTED_CONTENT_CODES';

export const CLEAR_CODES = 'CLEAR_CODES';
