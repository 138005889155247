/**
 * @summary ListComponentsInputs.js
 * @file Input Options for List Component Report. This report is currently not active until we can solve it. 
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import store from 'store/store';
import { getViews } from 'store/views/ViewActions'
import PropTypes from 'prop-types';
import {
    Card,
    CardHeader,
    CardBody,
} from "@progress/kendo-react-layout";
import {
    NumericTextBox 
  } from "@progress/kendo-react-inputs";
import Row from 'react-bootstrap/Row';

const ListComponentsInputs = ({
    generateReport,
    project,
    userObj,
}) => {
    const [mapDropdownOptions, setMapDropdownOptions] = useState([])
    const [selectedMap, setSelectedMap] = useState(null)
    const [maxCluster, setMaxCluster] = useState(3)

    // USE SELECTOR: Pulls branch and project data from Redux
    const branches = useSelector((state) => {
        const branchesList = { ...state.branchesDetailsReducer.activeBranches };
        delete branchesList?.error;
        delete branchesList?.selectedData;
        return Object.values(branchesList).filter(Boolean);
    }, shallowEqual)
    
    const views = useSelector((state) => {
        const views = { ...state.viewReducer };
        delete views?.selectedData;
        return Object.values(views).filter((view) => view.isActive)
    }, shallowEqual)

    // USE EFFECT: Gets VIews for the Use Selector (for Menus)
    useEffect(() => {
        store.dispatch(getViews(userObj.selectedProject[0].id))
    }, [])

    useEffect(() => {
        if(branches.length > 0 || views.length > 0){
            let branchOptions = branches.map(branch => {
                if(branch.name === "published"){
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        menuName: "Published Branch",
                        name: branch.name,
                        type: "published branch"
                    }
                    return branchObj
                } else {
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        menuName: "Branch: " + branch.name,
                        name: branch.name,
                        type: "branch"
                    }  
                    return branchObj
                }
            })
            let viewOptions = views.map(view => {
                let viewObj = {
                    id: view.id,
                    menuId: "view" + view.id.toString(),
                    menuName: "View: " + view.name,
                    name: view.name,
                    type: "view"
                }
                return viewObj
            })
            let mapOptions = branchOptions.concat(viewOptions)
            setMapDropdownOptions(mapOptions)
        } else {
            setMapDropdownOptions([])
        }
        setSelectedMap(null)
    }, [project])

    const handleMapSelection = (selectedMenuId) => {
        let selection = mapDropdownOptions.find(branch => branch.menuId === selectedMenuId)
        if(selection.type === "branch"){
            setSelectedMap(selection)
        } else {
            let viewMatch = mapDropdownOptions.find(view => view.menuId === selection.menuId)
            setSelectedMap(viewMatch)
        }
    }

    const onClickCycleCheck = () => {
        let sendData = {
            project,
            branchSelection: selectedMap,
            maxCluster
        }
        generateReport(sendData)
    }

    let projectName = project.name

    if(mapDropdownOptions.length > 0){
        return(
            <div>
                <Card
                    style={{
                        width: "99vw",
                        minHeight: "175px",
                        paddingBottom: "20px",
                    }}
                >
                <CardHeader>
                    <div className='selected-report-header'>
                        {'List Components Report: ' + project.name}
                    </div>
                    <div>
                        <p>
                            This report will generate a list of node clusters, which entails all the nodes connected to each node within a maximum radius you set below.</p>            
                        <p>
                            Choose a branch or view you would like to inspect, narrow your results to a maximum number of connected nodes, 
                            and click Generate Report to see your results.
                        </p>
                    </div>        </CardHeader>
                <CardBody>
                    <Row>
                        <div className='col-3'>
                            <div className="reporting-option-header">
                                Select a Map
                            </div>
                            <select
                                className="form-select form-select-sm reporting-map-selection-dropdown"
                                aria-label=".form-select-sm example"
                                value={selectedMap?.menuId}
                                style={{width: '90%'}}
                                onChange={(e) => {
                                    handleMapSelection(e.target.value)
                                }}
                                name="mapSelection"
                            >
                                <option value="0">Select Map Branch or View</option>
                                {mapDropdownOptions.map((map) => (
                                <option key={map.menuId} value={map.menuId}>
                                    {map.menuName}
                                </option>
                                ))}
                            </select>
                        </div>
                        <div className='col-3'>
                            <div className="reporting-option-header">
                                Max Cluser Radius
                            </div>
                            <NumericTextBox
                                max={10}
                                min={1}
                                className="numeric-text-box"
                                value={maxCluster}
                                onChange={(e) => {
                                    setMaxCluster(e.target.value)
                                }}
                            />
                        </div>  
                    </Row>
                    <Row>
                        <div className='col-2'>
                            <div className="generate-report-button-container">
                                <button
                                    type="button"
                                    className="k-button k-primary generate-report-button"
                                    onClick={onClickCycleCheck}
                                    disabled={!selectedMap || !maxCluster}
                                >
                                    Generate Report
                                </button>
                            </div>
                        </div>
                        <div className='col-10'>
                            <div className='map-options-fine-print-container'>
                                <p className="map-options-fine-print">
                                    {`Map options are limited to the project you have selected under your Profile Settings in the upper right corner. You currently have "` + projectName + `" selected.`}
                                </p>
                            </div>
                        </div>
                    </Row>
                </CardBody>
                </Card>
                
                
            </div>
        )
    } else {
        return null
    }
}

export default ListComponentsInputs;

ListComponentsInputs.propTypes = {
    generateReport: PropTypes.func,
    mapType: PropTypes.any,
    project: PropTypes.object,
    branches: PropTypes.array,
    views: PropTypes.array,
    userObj: PropTypes.obj
};