/**
 * @summary DeleteConfirmation.jsx
 * @file Modal that asks for user confirmation on deletion. Once confirmed, the modal will run the deleteFn that was passed down by the parent component
 * @returns {JSX}
 * @usedBy ConnectionCollectionGrid.js, ConnectionFormHeader.js, NeighborhoodCollectionGrid.js, NeighborhoodFormHeader.js, NeighborhoodNodesSettings, NodeCollectionGrid.js, NodeFormHeader.js, ViewCollectionGrid.js
 * @author Sam Lee
 * @since 2/17/2023
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const DeleteConfirmation = ({ showModal, setShowModal, deleteFn }) => {
  return (
    <div>
      {showModal && (
      <Modal show={showModal} backdrop="static" centered size="md">
        <Modal.Header>
          <Modal.Title>
            <h2 className='text-white text-center'>
              Please Confirm
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className='text-center pt-3 pb-3'>
            Are you sure you want to delete this item?
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <div className='col-12'>
            <div className='row'>
              <div className='col-6'>
                <div className='ms-2 text-right'>
                  <button
                    className="btn btn-danger btn-sm text-white w-75"
                    onClick={() => setShowModal(false)}
                  >
                    No
                  </button>
                </div>
              </div>
              <div className='col-6'>
                <div className='me-2 text-left'>
                  <button
                    className="btn btn-primary btn-sm text-white w-75 "
                    // disabled={branchName.length < 1}
                    onClick={() => {
                      deleteFn()
                      setShowModal(false)
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    )}
    </div>
  )
}

export default DeleteConfirmation;

DeleteConfirmation.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  deleteFn: PropTypes.func,
}