/**
 * @summary axiosRadius.js
 * @file Function returns a formatted axios response of connections of nodes in relation to a user's specified source node and node radius
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios'
export const axiosRadius = async (projectId, branchSelection, ) => {
  ///////////////////////////////////////////////////////////////////////////////////////////
  // IF SELECTION IS THE PUBLISHED BRANCH, RETURN THE AXIOS RESPONSE NODES AND CONNECTIONS //
  ///////////////////////////////////////////////////////////////////////////////////////////
  if(branchSelection.type === "published branch" || branchSelection.type === "branch"){
    const nodes = await axios.get(`/api/projects/${projectId}/branch/${branchSelection.id}/nodes`)
    const connections = await axios.get(`/api/projects/${projectId}/branch/${branchSelection.id}/connections`)
    if(branchSelection.type === "published branch"){
      
      let errorMessages = []
      if(nodes.data.length === 0){
        errorMessages.push("There are no nodes in this branch.")
      } else if(connections.data.length === 0){
        errorMessages.push("None of the nodes in this branch are connected. Try choosing a branch with connected nodes.")
      }
      
      const formattedAxiosResp = {
        nodes: nodes.data,
        connections: connections.data,
        branch: branchSelection.id,
        errorMessages,
        branchType: 'branch'
      }

      return formattedAxiosResp
    ////////////////////////////////////////////////////////////////////////////
    // IF SELECTION IS A CHECKED OUT BRANCH, NEED TO FILTER BRANCH ID MATCHES //
    ////////////////////////////////////////////////////////////////////////////
    } else if(branchSelection.type === "branch"){

      const branchNodes = nodes.data.filter(node => node.branchId === branchSelection.id)
      const branchConnections = connections.data.filter(connection => connection.branchId === branchSelection.id)
      
      let errorMessages = []
      if(branchNodes.length === 0){
        errorMessages.push("There are no nodes in this branch.")
      } else if(branchConnections.length === 0){
        errorMessages.push("None of the nodes in this branch are connected. Try choosing a branch with connected nodes.")
      }

      const formattedAxiosResp = {
        nodes: branchNodes,
        connections: branchConnections,
        branch: branchSelection.id,
        errorMessages,
        branchType: 'branch'
      }
      return formattedAxiosResp
    }
    ///////////////////////////////////////////////////////////////////////////
    // IF SELECTION IS A VIEW, RUN DIFFERENT AXIOS GET REQUESTS ON THAT VIEW //
    ///////////////////////////////////////////////////////////////////////////
  } else if (branchSelection.type === "view"){
    const view = await axios.get(`/api/projects/${projectId}/views/${branchSelection.id}`)

    let errorMessages = []

    if(view.data.savedNodes.length === 0){
      errorMessages.push("There are no nodes in this view.")
    } else if(view.data.savedConnections.length === 0){
      errorMessages.push("None of the nodes in this view are connected. Try saving a view with connected nodes.")
    }

    const formattedAxiosResp = {
      nodes: view.data.savedNodes,
      connections: view.data.savedConnections,
      branch: branchSelection.id,
      errorMessages,
      branchType: 'view'
    }

    return formattedAxiosResp
  }
}