/**
 * @summary Tab.js
 * @file This component shows certain links by mapping through items.pages and also checking if item includes 'project' and 'branch'
 * @returns {JSX}
 * @usedBy NavBar.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';

const Tab = (item, index, user) => {
  const navigate = useNavigate();
  const location = useLocation();
  const shouldDisplay = (item) => {
    if (item.dependsOn) {
      if (
        item.dependsOn.includes('project') &&
        item.dependsOn.includes('branch')
      ) {
        return (
          user?.selectedProject[0]?.id > 0 && user?.selectedBranch[0]?.id > 0
        );
      }
      return false;
    }
    return true;
  };

  if (!shouldDisplay(item)) {
    return undefined;
  }

  if (item.pages) {
    return (
      <li key={`tab-${index}`} className="nav-item dropdown">
        <a
          href="#"
          id="menu"
          data-bs-toggle="dropdown"
          className="nav-link nav-link-text dropdown-toggle"
          data-bs-display="static"
        >
          {item.label}
        </a>
        <ul className="dropdown-menu">
          {item.pages.filter(shouldDisplay).map((child, $index) => {
            const currentItem = child.path === location.pathname;
            return (
              <li key={`sub${child.path}-${index}`}>
                <Link
                  to={child.path}
                  style={{ pointerEvents: currentItem ? 'none' : 'auto' }}
                  className={`dropdown-item `}
                >
                  {child.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </li>
    );
  }

  return (
    <li className="nav-item" key={`nav-${index}`}>
      <NavLink key={index} to={item.path} className="nav-link nav-link-text">
        {' '}
        <i className={item.icon}></i>
        {item.label}
      </NavLink>
    </li>
  );
};

export default Tab;
