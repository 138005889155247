/**
 * @summary InspectorTool.js
 * @file Modal for Containing Injuey Data for Selected Element(s). Opened through CanvasInterface.js After at least one Element is Selected
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../canvasPage.scss';
import CanvasTableview from './dataEditorSubmenus/CanvasTableview';
import cloneDeep from 'lodash/cloneDeep';


const CanvasDataEditor = ({
    handleCrud,
    branchChangeData,
    multiSelectArray,
    show, 
    handleShowDataEditorMenu,
    handleTableviewNavigation,
    renderMapData
  }) => {
    const [elementTabSelection, setElementTabSelection] = useState("nodes")
    
    const [canvasNodeSelections, setCanvasNodeSelections] = useState([]);
    const [canvasConnectionSelections, setCanvasConnectionSelections] = useState([]);
    const [canvasNeighborhoodSelections, setCanvasNeighborhoodSelections] = useState([]);

    const [tableNodeSelections, setTableNodeSelections] = useState([])
    const [tableConnectionSelections, setTableConnectionSelections] = useState([])
    const [tableNeighborhoodSelections, setTableNeighborhoodSelections] = useState([])

    useEffect(() => {
        let nodes = []
        let connections = []
        let neighborhoods = []
        if(multiSelectArray){
            multiSelectArray.forEach(element => {
                if(element.data.type === "Connection"){
                    let formattedConnection = cloneDeep(renderMapData.linkDataArray.find(connection => connection.id === element.data.id));
                    formattedConnection.selected = false;
                    connections.push(formattedConnection)
                } else if (element.data.nodeKey && element.data.category !== "Super") {
                    let formattedNode = cloneDeep(renderMapData.nodeDataArray.find(node => node.nodeKey === element.data.nodeKey));
                    formattedNode.selected = false;
                    // formattedNode = {
                    //     ...formattedNode,
                    //     selected: false,
                    // }
                    nodes.push(formattedNode)
                } else if(element.data.category === "Super") {
                    let formattedNeighborhood = element.data;
                    formattedNeighborhood.selected = false;
                    neighborhoods.push(formattedNeighborhood)
                }
            })
        }
        setCanvasNodeSelections(nodes)
        setCanvasNeighborhoodSelections(neighborhoods)
        setCanvasConnectionSelections(connections)
    }, [multiSelectArray])

    useEffect(() => {
        if(canvasNodeSelections.length > 0){
            let newTableNodeSelections = canvasNodeSelections.filter(node => node.selected === true);
            setTableNodeSelections(newTableNodeSelections)
        }
    }, [canvasNodeSelections])

    useEffect(() => {
        if(canvasConnectionSelections.length > 0){
            let newTableConnectionSelections = canvasConnectionSelections.filter(node => node.selected === true);
            setTableConnectionSelections(newTableConnectionSelections)
        }
    }, [canvasConnectionSelections])

    useEffect(() => {
        if(canvasNeighborhoodSelections.length > 0){
            let newTableNeighborhoodSelections = canvasNeighborhoodSelections.filter(node => node.selected === true);
            setTableNeighborhoodSelections(newTableNeighborhoodSelections)
        }
    }, [canvasNeighborhoodSelections])

    return (
        <>
        <div className={`${show ? 'disabled-background ' : null}`}>
            <div className={`submenu-container ${show ? 'open-data-editor-menu' : 'close-data-editor-menu'}`}>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='submenu-top-bar-blue'>
                                <div className='white-menu-header'>
                                    Element Data Editor
                                </div>
                                <div
                                    className="bi bi-x submenu-close-x"
                                    onClick={handleShowDataEditorMenu}
                                >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='instructional-blue'>
                                This menu provides you the tools to Add, Edit, Delete, and Inspect elements. 
                                The table below will display any Nodes, Neighborhoods, or Connections you selected on the map.
                                Check a box to edit, inspect, or delete an element. Or add an element to the map.
                            </div>
                        </div>
                    </div>
                    <CanvasTableview 
                        elementTabSelection={elementTabSelection}
                        setElementTabSelection={setElementTabSelection}
                        canvasNodeSelections={canvasNodeSelections}
                        canvasConnectionSelections={canvasConnectionSelections}
                        canvasNeighborhoodSelections={canvasNeighborhoodSelections}
                        tableNodeSelections={tableNodeSelections}
                        tableConnectionSelections={tableConnectionSelections}
                        tableNeighborhoodSelections={tableNeighborhoodSelections}
                        setCanvasNodeSelections={setCanvasNodeSelections}
                        setCanvasNeighborhoodSelections={setCanvasNeighborhoodSelections}
                        setCanvasConnectionSelections={setCanvasConnectionSelections}
                        renderMapData={renderMapData}
                        handleCrud={handleCrud}
                        branchChangeData={branchChangeData}
                        handleTableviewNavigation={handleTableviewNavigation}
                    />
                    
                </div>
            </div>
        </div>
        
        </>
    );
  } 

CanvasDataEditor.propTypes = {
  handleCrud: PropTypes.func,
  branchChangeData: PropTypes.obj,
  multiSelectArray: PropTypes.array,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  handleShowDataEditorMenu: PropTypes.func,
  handleTableviewNavigation: PropTypes.func,
  renderMapData: PropTypes.obj
};

export default CanvasDataEditor;