/**
 * @summary ProfileCheckItemRender.js
 * @file This component provides rows for nodes that match subject/accessibility flags specified by user
 * @returns {JSX}
 * @usedBy ProfileCheckReturn.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react'
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import Row from 'react-bootstrap/Row';

//////////////////////////
// PROFILE CHECK RETURN //
//////////////////////////
const ProfileCheckItemRender = ({dataItem}) => {
    return (
        
        <div
            className="row k-listview-item p-2 border-bottom align-middle"
            style={{
                marginTop: 0,
                marginBottom: 0,
            }}
        >
            <div className="col-2" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 20,
                        fontWeight: 500,
                        color: "black",
                        marginLeft: 50,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {startCase(dataItem.flag)}
                </div>
            </div>

            <div className="col-1" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 20,
                        fontWeight: 500,
                        color: "black",
                        marginLeft: 50,
                        paddingTop: 10,
                        paddingBottom: 10,
                    }}
                >
                    {dataItem.amount}
                </div>
            </div>

            <div className="col-8" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 50,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {dataItem.flaggedNodes.map(node => {
                        return(
                            <Row key={dataItem.flaggedNodes.indexOf(node)}>
                                {node}
                            </Row>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default ProfileCheckItemRender;

ProfileCheckItemRender.propTypes = {
    dataItem: PropTypes.object,
}