/**
 * @summary CanvasNeighborhoodLegend.js
 * @file Modal that contains functionality for Loading Views. Opened through CanvasInterface.js.
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { toast } from 'react-toastify';
import '../canvasPage.scss';

const CanvasNeighborhoodLegend = ({
  neighborhoodLegendArray,
  showNeighborhoodLegendMenu,
  setVisibleNeighborhoodArray
}) => {
  // State for All Available Neighborhoods 
  const [legendOptions, setLegendOptions] = useState([]);
  const [neighborhoodInputArray , setNeighborhoodInputArray ] = useState([]);

  // Takes Nodes and Finds Neighborhoods Based on Category: "Super"
  useEffect(() => {
    let updatedLegendOptions = []
    let neighborhoodInputs = []
    neighborhoodLegendArray?.forEach(neighborhood => {;
      if(neighborhood.visible === true){
        neighborhoodInputs.push(neighborhood.name)
      }
      updatedLegendOptions.push(neighborhood.name)
    })
    setNeighborhoodInputArray(neighborhoodInputs)
    setLegendOptions(updatedLegendOptions);
  }, [neighborhoodLegendArray]);
  
  // Handles the Character Input to Filter Neighborhood Options Down to Typed Input
  const filterChange = (event) => {
    let updatedLegendOptions = legendOptions?.map(
      (neighborhood) => neighborhood
    );
    setLegendOptions(filterBy(updatedLegendOptions, event.filter));
  };

  // Handles Selected Neighborhood
  const handleInputChange = (inputChange) => {
    let newInputArray = []
    inputChange.value.forEach(input => {
      const selectedNeighborhood = neighborhoodLegendArray.find(neighborhood => neighborhood.name === input)
      if(selectedNeighborhood?._members.length === 0){
        toast.error("This neighborhood has no nodes and cannot be rendered in Canvas.")
      }
      newInputArray.push(selectedNeighborhood.name)
    })
    setNeighborhoodInputArray(newInputArray)
    setVisibleNeighborhoodArray(newInputArray);
  };
  return (
    <div
      className={`neighborhood-legend-menu ${
        showNeighborhoodLegendMenu
          ? 'open-neighborhood-legend'
          : 'close-neighborhood-legend'
      }`}
    >
      <div className="neighborhood-legend">
        <MultiSelect
          className="neighborhoodMultiselect"
          data={legendOptions}
          value={neighborhoodInputArray}
          filterable={true}
          onFilterChange={filterChange}
          onChange={handleInputChange}
          removeTag={handleInputChange}
          placeholder="Enter Neighborhoods"
          style={{
            width: '245px',
            minHeight: '60px',
            padding: '0px'
          }}
          popupSettings={{
            height: '500px'
          }}
        />
      </div>
    </div>
  );
};

CanvasNeighborhoodLegend.propTypes = {
  neighborhoodLegendArray: PropTypes.array,
  showNeighborhoodLegendMenu: PropTypes.bool,
  setVisibleNeighborhoodArray: PropTypes.func
};

export default CanvasNeighborhoodLegend;
