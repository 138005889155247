/**
 * @summary formatCanvasChangeReview.js
 * @file Formats Changes made in a Branch for CanvasPage's Data Editor and Review Mode
 * @returns {JSX}
 * @usedBy CanvasWrapper.js
 * @author Andy Greenhaw
 * @since 08/14/2024
 * @lastUpdated 08/14/2024
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

const formatCanvasChangeReview = (
    project,
    branch,
    branchNodes,
    branchConnections,
    branchNeighborhoods,
    publishedNodes,
    publishedConnections,
    publishedNeighborhoods,
    deletedNodes,
    deletedConnections,
    deletedNeighborhoods
) => {

    let allBranchChanges = {
        adds: {
            nodes: [],
            connections: [],
            neighborhoods: [],
        },
        edits: {
            nodes: [],
            connections: [],
            neighborhoods: [],
        },
        deletes: {
            nodes: [],
            connections: [],
            neighborhoods: [],
        }
    }

    const branchMapData = {
        nodes: branchNodes.length > 0 ? branchNodes.sort(((a, b) => a.nodeKey - b.nodeKey)) : [],
        connections: branchConnections.length > 0 ? branchConnections.sort(((a, b) => a.id - b.id)) : [],
        neighborhoods: branchNeighborhoods.length > 0 ? branchNeighborhoods.sort(((a, b) => a.id - b.id)) : []
    }

    const publishedMapData = {
        nodes: publishedNodes.length > 0 ? publishedNodes.sort(((a, b) => a.nodeKey - b.nodeKey)) : [],
        connections: publishedConnections.length > 0 ? publishedConnections.sort(((a, b) => a.id - b.id)) : [],
        neighborhoods: publishedNeighborhoods.length > 0 ? publishedNeighborhoods.sort(((a, b) => a.id - b.id)) : []
    }
    //////////////////
    // NODE CHANGES //
    //////////////////
    branchMapData.nodes.forEach(branchNode => {
        // FIND ADDED NODES
        let originalNode = publishedMapData.nodes.find(publishedNode => publishedNode.nodeKey === branchNode.nodeKey)
        if(!originalNode){
            allBranchChanges.adds.nodes.push(branchNode)
        // FIND EDITED NODES
        } else {
            let editObj = {
                new: branchNode,
                original: originalNode,
                changes: [],
                message: ""
            }
            if(branchNode.name !== originalNode.name){
                editObj.changes.push("Name")
                allBranchChanges.edits.nodes.push(editObj)
            }
            if(branchNode.description !== originalNode.description){
                editObj.changes.push("Description")
                allBranchChanges.edits.nodes.push(editObj)
            }

            if(!editObj.changes.includes("Attributes")){
                if(branchNode?.settings?.attributes?.length > 0 && originalNode?.settings?.attributes?.length > 0){

                    branchNode?.settings?.attributes.forEach(attribute => {
                        let branchAttribute = Object.values(attribute)[0][0]
                        if(!originalNode?.settings?.attributes?.find(originalAttribute => Object.values(originalAttribute)[0][0].value === branchAttribute.value)){
                            if(!editObj.changes.includes("Attributes")){
                                editObj.changes.push("Attributes")
                                allBranchChanges.edits.nodes.push(editObj)
                            }
                        }
                    })

                // If Attributes Are in Node Branch But None in Published, Push Add Change
                } else {
                    if(branchNode?.settings?.attributes?.length > 0){
                        editObj.changes.push("Attributes")
                        allBranchChanges.edits.nodes.push(editObj)
                    }
                }
            }
            // Format Change Wording to Include Series
            if(editObj.changes.length > 0){
                let formattedString = editObj.changes.join(", ");
                editObj.message = formattedString
                allBranchChanges.edits.nodes.push(editObj)
            }
            
        }
    })

    // FIND DELETED NODES
    deletedNodes?.forEach(deletedNode => {
        let formattedNode = {
            ...deletedNode,
            // DEFAULT THEMES
            shape: 'RoundedRectangle',
            color: '#FFFFFF',
            borderColor: '#1560b7',
            borderStyle: ['stroke', 'color'],
            borderThickness: 2,
            highlightColor: 'red',
            primaryFont: 'Bolder 16px Arial',
            secondaryFont: '100 14px Arial',
            fontColor: '#000000',
            corners: 'Rounded',
            // CHANGE PROPERTIES
            added: false,
            edited: false,
            deleted: true
        }
        allBranchChanges.deletes.nodes.push(formattedNode)
    })

    ////////////////////////
    // CONNECTION CHANGES //
    ////////////////////////
    branchMapData.connections.forEach(branchConnection => {
        // FIND ADDED CONNECTIONS
        let originalConnection = publishedMapData.connections.find(publishedConnection => publishedConnection.id === branchConnection.id)
        let replacedConnection = publishedMapData.connections.find(publishedConnection => branchConnection.sourceNodeKey === publishedConnection.sourceNodeKey && branchConnection.destinationNodeKey === publishedConnection.destinationNodeKey)

        if(!originalConnection){
            // DETERMINE IF THEY REPLACED AN OLD CONNECTION
            if(replacedConnection){
                let editObj = {
                    new: branchConnection,
                    original: replacedConnection,
                    change: "Replaced"
                }
                allBranchChanges.edits.connections.push(editObj)
            // IF NOT REPLACED, CONSIDER THEM ADDED
            } else {
                allBranchChanges.adds.connections.push(branchConnection)
            }
        }
    })

    // FIND DELETED CONNECTIONS
    if(deletedConnections){
        let filteredDeletedConnections = deletedConnections?.filter(deletedConnection => {
            let match = allBranchChanges.edits.connections.find(editedConnection => {
                if(editedConnection.new.destinationNodeKey === deletedConnection.destinationNodeKey && editedConnection.new.sourceNodeKey === deletedConnection.sourceNodeKey){
                    return editedConnection;
                }
            })
            if(match === undefined){
                return(deletedConnection)
            }
        })
        filteredDeletedConnections?.forEach(deletedConnection => {
            let formattedConnection = {
                ...deletedConnection,
                added: false,
                edited: false,
                deleted: true,
                
            }
            allBranchChanges.deletes.connections.push(formattedConnection)
        })
    }

    //////////////////////////
    // NEIGHBORHOOD CHANGES //
    //////////////////////////
    branchMapData.neighborhoods.forEach(branchNeighborhood => {
        // FIND ADDED NEIGHBORHOODS
        let originalNeighborhood = publishedMapData.neighborhoods.find(publishedNeighborhood => publishedNeighborhood.id === branchNeighborhood.id)
        if(!originalNeighborhood){
            allBranchChanges.adds.neighborhoods.push(branchNeighborhood)
        // FIND EDITED NEIGHBORHOODS
        } else {
            let editObj = {
                new: branchNeighborhood,
                original: originalNeighborhood,
                changes: [],
                message: ""
            }
            if(branchNeighborhood.name !== originalNeighborhood.name){
                editObj.changes.push("Name")
                // allBranchChanges.edits.neighborhoods.push(editObj)
            }
            if(branchNeighborhood.description !== originalNeighborhood.description){
                editObj.changes.push("Description")
                // allBranchChanges.edits.neighborhoods.push(editObj)
            }

            if(!editObj.changes.includes("Attributes")){
                if(branchNeighborhood?.settings?.attributes?.length > 0 && originalNeighborhood?.settings?.attributes?.length > 0){

                    branchNeighborhood?.settings?.attributes.forEach(attribute => {
                        let branchAttribute = Object.values(attribute)[0][0]
                        if(!originalNeighborhood?.settings?.attributes?.find(originalAttribute => Object.values(originalAttribute)[0][0].value === branchAttribute.value)){
                            if(!editObj.changes.includes("Attributes")){
                                editObj.changes.push("Attributes")
                                // allBranchChanges.edits.nodes.push(editObj)
                            }
                        }
                    })

                // If Attributes Are in Node Branch But None in Published, Push Add Change
                } else {
                    if(branchNeighborhood?.settings?.attributes?.length > 0){
                        editObj.changes.push("Attributes")
                        // allBranchChanges.edits.nodes.push(editObj)
                    }
                }
            }
            // Format Change Wording to Include Series with Comma
            if(editObj.changes.length > 0){
                let formattedString = editObj.changes.join(", ");
                editObj.message = formattedString
                allBranchChanges.edits.neighborhoods.push(editObj)
            }
        }
    })

    // FIND DELETED NEIGHBORHOODS
    deletedNeighborhoods?.forEach(deletedNeighborhood => {
        let formattedNeighborhood = {
            ...deletedNeighborhood,
            category: "Super",
            added: false,
            edited: false,
            deleted: true,
            // THEMES 
            shape: 'RoundedRectangle',
            color: '#FAF03B',
            borderColor: '#1560b7',
            borderStyle: ['stroke', 'color'],
            borderThickness: 2,
            highlightColor: 'red',
            primaryFont: 'Bolder 24px Arial',
            secondaryFont: '100 14px Arial',
            fontColor: '#000000',
            corners: 'Rounded',
        }
        allBranchChanges.deletes.neighborhoods.push(formattedNeighborhood)
    })

    return(allBranchChanges)

}

export default formatCanvasChangeReview;