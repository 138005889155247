/**
 * @summary Schema for creating new Neighborhoods
 * @file newNeighborhood.schema.js
 * @returns {Schema}
 * @usedBy Application
 * @author Andrew Popp
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as yup from 'yup';

// Schema
const newNeighborhoodSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().notRequired()
});

export default newNeighborhoodSchema;
