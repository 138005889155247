/**
 * @summary ConnectionFormHeader.js
 * @file Form header component that allows you to edit Node Name/Description and save & delete the changes
 * @returns {JSX}
 * @usedBy ConnectionForm.js
 * @author Sam Lee
 * @since 2/17/2023
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { formatDateTime } from '../../../utility/formatDateTime';
import store from 'store/store';
import DeleteConfirmation from '../modal/DeleteConfirmation';
import { deleteConnection } from 'store/connections/ConnectionActions';

const options = {
  messsageType: {
    success: 'Success'
  },
  messages: {
    successOnSave: 'Node saved Successfuly',
    successOnDelete: 'Node Deleted',
    errorOnSave: 'Unable to Save Node',
    errorOnDelete: 'Unable to Delete Node',
    errorOnFetch: 'Unable to load Node'
  }
};

const ConnectionFormHeader = ({
  onSave,
  connection,
  connectionName,
  setConnectionName
}) => {
  const userObj = useSelector((state) => state.authReducer.userObj);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const currentProject = userObj.selectedProject[0].name;
  
  const deleteConnectionHandler = async () => {
    const resp = await store.dispatch(deleteConnection({...connection, projectId: userObj.selectedProject[0].id, branchId: userObj.selectedBranch[0].id}))
    if (resp.status === 201) {
      navigate('/tableview');
    } else {
      toast.error('Not able to delete Connection')
    }
  }

  const handleClose = () => {
    navigate('/tableview');
  };

  const onDeleteClick = () => {
    setShowModal(true);
  };

  const handleSave = () => {
    onSave();
  };

  
  return (
    <div className="container-fluid locked-header">
      <DeleteConfirmation showModal={showModal} setShowModal={setShowModal}  deleteFn={deleteConnectionHandler}/>
      {/* TOP ROW */}
      <div className="row row-cols-2 pt-2 pe-2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3">
        <div className="col pt-1 pb-1 hstack gap-2">
          <label className="form-label">Project Name:</label>
          <label className="form-label">{currentProject}</label>
        </div>

        <div className="col pt-1 pb-1 hstack gap-2">
          <label className="form-label">Connection Name:</label>
          {connection?.id > 0 ? (
            <label className="form-label">{connection?.name || ''}</label>
          ) : null}
        </div>

        <div className="col pt-1 pb-1 hstack gap-2"></div>

        <div className="col pt-1 pb-1 hstack gap-2" />

        {/* TOP ROW ACTION BUTTONS */}
        <div className="col-12 pt-1 pb-1 col-md-8 col-lg-12 hstack gap-2 justify-content-end">
          <button
            type="button"
            disabled={userObj.selectedBranch[0].branchStatus === 'published'}
            className="btn btn-primary btn-sm"
            onClick={handleSave}
          >
            <i className="bi bi-save2"></i> Save
          </button>
          <button
            type="button"
            disabled={connection?.id === ''}
            className="btn btn-danger btn-sm text-white"
            onClick={onDeleteClick}
          >
            {' '}
            <i className="bi bi-trash"></i> Delete
          </button>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={handleClose}
          >
            <i className="bi bi-x"></i> Close
          </button>
        </div>
      </div>

      {/* BOTTOM ROW */}
      <div className="row row-cols-2 pb-2 pe-2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3">
        <div className="col pt-1 pb-1 hstack gap-2">
          <label className="form-label">Branch:</label>
          <label className="form-label">
            {userObj.selectedBranch[0].name || ''}
          </label>
        </div>
        <div className="col pt-1 pb-1 hstack gap-2">
          {connection?.id && (
            <>
              <label className="form-label text-nowrap">Last Saved:</label>
              <label className="form-label col-md-8">
                {
                  <label className="form-label col-md-8">
                    {connection.lastUpdated
                      ? formatDateTime(new Date(connection?.lastUpdated))
                      : ''}{' '}
                  </label>
                }
              </label>
            </>
          )}
        </div>

        <div className="col pt-1 pb-1 hstack gap-2"></div>

        <div className="col pt-1 pb-1 hstack gap-2"></div>
      </div>

      <div className="row row-cols-2 pt-2 pe-2">
        <div className="col p-2 justify-content-between">
          <legend>Origin</legend>
          <div className="col pt-1 pb-1 hstack gap-2">
            <label className="form-label">Origin Node Key:</label>
            <label className="form-label">{connection?.sourceNodeKey}</label>
          </div>
          <div className="col pt-1 pb-1 hstack gap-2">
            <label className="form-label">Origin Name:</label>
            <label className="form-label">{connection?.sourceNodeName}</label>
          </div>
        </div>
        <div className="col p-2 justify-content-between">
          <legend>Destination</legend>
          <div className="col pt-1 pb-1 hstack gap-2">
            <label className="form-label">Destination Node Key:</label>
            <label className="form-label">
              {connection?.destinationNodeKey}
            </label>
          </div>
          <div className="col pt-1 pb-1 hstack gap-2">
            <label className="form-label">Destination Name:</label>
            <label className="form-label">
              {connection?.destinationNodeName}
            </label>
          </div>
        </div>
      </div>

      {/* BOTTOM ROW ACTION BUTTONS */}
      <div className="col-12 pt-1 pb-1 col-md-8 col-lg-12 hstack gap-2 justify-content-end">
        <div className='col-1'>
          <button type="button" className="btn btn-secondary btn-sm text-white">
            Version History
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConnectionFormHeader;

ConnectionFormHeader.propTypes = {
  onSave: PropTypes.func.isRequired,
  connection: PropTypes.object,
  connectionName: PropTypes.string,
  setConnectionName: PropTypes.func,
};
