/**
 * @summary branchesDetailTypes.js
 * @file Action type string constants
 * @returns {String}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const GET_BRANCHESDETAILSLIST = 'GET_BRANCHESDETAILSLIST';

export const GET_BRANCHESINACTIVEDETAILSLIST =
  'GET_BRANCHESINACTIVEDETAILSLIST';

export const CLEAR_BRANCHESDETAILS = 'CLEAR_BRANCHESDETAILS';

export const CLEAR_BRANCHESINACTIVEDETAILS = 'CLEAR_BRANCHESINACTIVEDETAILS';

export const ADD_BRANCH = 'ADD_BRANCH';
