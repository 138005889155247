/**
 * @summary ResetPw.jsx
 * @file Allows user to reset their password for login
 * @returns {JSX}
 * @usedBy routes.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ResetPassword } from 'ats-react-login';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function ResetPw() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ResetPassword
      logo={process.env.PUBLIC_URL + '/kite-logo.svg'}
      background={`${process.env.PUBLIC_URL}/Kite_Login_BG.svg`}
      onSubmit={(event, verificationCode, newPassword) => {
        //get email entered on the forgot password page
        let email = location.state.data;
        axios
          .post('/api/passwordReset', {
            email,
            verificationCode,
            newPassword
          })
          .then((res) => {
            const statusCode = res.status;
            if (statusCode === 200) {
              // redirect to the login page
              toast.success(
                'Password reset successfully. Please login using new password.'
              );
              navigate('/login');
            } else {
              navigate('/resetPassword');
              return toast.error(
                'Unsuccessful reset request. Please try again.'
              );
            }
          })
          .catch((error) => {
            navigate('/resetPassword');
            return toast.error('Unsuccessful reset request. Please try again.');
          });
      }}
    />
  );
}
