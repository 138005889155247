/**
 * @summary HelpWindow.js
 * @file Component which shows HelpPortal once button is clicked by user
 * @returns {JSX}
 * @usedBy panel.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import HelpPortal from './HelpPortal';
import parse from 'html-react-parser';

const HelpWindow = React.memo(({ title, help_code, panel_type }) => {
  const [visible, setVisible] = useState(false);
  const [tipsText, setTipsText] = useState([]);
  const [instructionText, setInstructionText] = useState('');

  const no_tips = ['No Tips Found'];
  const no_instructions = 'No Instructions Found';

  const userObj = useSelector((store) => store.authReducer.userObj);

  const handleHelp = async () => {
    if (!visible) {
      let assessment_id = userObj.currentAssessmentProgram;
      let tips_data = '';
      let instructions_data = '';
      if (assessment_id !== undefined) {
        const response = await axios.get(
          `/api/help/${assessment_id}/${help_code}/${panel_type}`
        );
        if (response && response.length > 0) {
          for (let tip of response[0]) {
            tips_data = tip.tips_value;
            instructions_data = tip.instructions_value;
            if (tips_data === null || tips_data === '') {
              setTipsText(no_tips);
            } else {
              setTipsText(tips_data.split('|$|'));
            }
            if (instructions_data === null || instructions_data === '') {
              setInstructionText(no_instructions);
            } else {
              setInstructionText(parse(instructions_data));
            }
          }
        } else {
          setTipsText(no_tips);
          setInstructionText(no_instructions);
        }
      }
    }
    setVisible(!visible);
  };

  //Help window method for close icon
  const closeHelp = () => {
    setVisible(false);
  };

  return (
    <>
      <button
        className={'help-button'}
        onClickCapture={(e) => {
          e.stopPropagation();
          handleHelp();
        }}
        title="Open Help Window"
      >
        <i className={'bi bi-question-circle'}></i>
      </button>
      {visible && (
        <HelpPortal closeWindowPortal={closeHelp}>
          <div className={'container mt-2'}>
            <div className="row">
              <div className="help-header">Tips</div>
              <ul className={'list-group list-group-flush'}>
                {tipsText.map((tip, index) => {
                  return (
                    <li className={'list-group-item'} key={`tip_${index}`}>
                      {tip}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="row">
              <div className="help-header">Instructions</div>
              <div>{instructionText}</div>
            </div>
          </div>
        </HelpPortal>
      )}
    </>
  );
});

HelpWindow.propTypes = {
  title: PropTypes.string,
  help_code: PropTypes.string,
  panel_type: PropTypes.string
};
HelpWindow.displayName = 'Help';

export default HelpWindow;
