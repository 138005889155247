/**
 * @summary InspectorTool.js
 * @file Modal for Containing Injuey Data for Selected Element(s). Opened through CanvasInterface.js After at least one Element is Selected
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../canvasPage.scss';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

const DeleteNodeCanvasModal = ({
    selectedNodes,
    handleCrud,
    handleDeleteModalVisibility
  }) => {

    const userObj = useSelector((state) => state.authReducer.userObj);
    const jsonNode = selectedNodes.map(node => node.id)

    const handleNodeDelete = () => {
        let publishedNodesAndNeighborhoods = selectedNodes.filter(node => node.isCheckedOut === false)
        if(publishedNodesAndNeighborhoods.length === 0){
            let saveJson = {
                action: 'delete',
                elementType: 'node',
                source: 'Canvas Deleted',
                project: userObj.selectedProject[0],
                branch: userObj.selectedBranch[0],
                data: jsonNode
            }
            handleCrud(saveJson)
        } else {
            publishedNodes.forEach(node => {
                toast.error(node.nodeKey + " is not checked out.")
            })
        }
    }

    return (
        <>
            <Modal.Header>
                <Modal.Title>
                    <div className='modal-header-text'>
                        Please Confirm
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5 className='text-center pt-3 pb-3'>
                    Are you sure you want to delete this Node?
                </h5>
            </Modal.Body>
            <Modal.Footer>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6 text-center'>
                            <button 
                                className="btn btn-primary btn-sm text-white w-50 "
                                onClick={handleDeleteModalVisibility}
                            >
                                No
                            </button>
                        </div>
                        <div className='col-6 text-center'>
                            <button 
                                className="btn btn-danger btn-sm text-white w-50" 
                                onClick={handleNodeDelete}
                            >
                                Yes
                            </button>
                        </div>             
                    </div>
                </div>
            </Modal.Footer>
        </>
    );
} 

DeleteNodeCanvasModal.propTypes = {
  selectedNodes: PropTypes.array,
  handleCrud: PropTypes.func,
  handleDeleteModalVisibility: PropTypes.func
};

export default DeleteNodeCanvasModal;