/**
 * @summary ElementIncongruencies.js
 * @file Component breaks down a element incongruency down to its properties in order to populate columns with info according to its header
 * @returns {JSX}
 * @usedBy ConnectionSummary.js, NeighborhoodSummary.js, NodeSummary.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import PropTypes, { element } from 'prop-types';

const ElementIncongruencies = ({
    elementIncongruency
}) => {
    // Not being displayed on the UI //
    return(
        <div
            className="row k-listview-item p-1 border-bottom"
            style={{
                marginTop: 0,
                marginBottom: 0,
            }}
        >
            <div className="col-2">
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                >
                    {elementIncongruency.property}
                </div>
            </div>
            <div className="col-2" >
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                >
                    {elementIncongruency.change}
                </div>
                
            </div>
            <div className="col-2">
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                >
                    {elementIncongruency.key}
                </div>
            </div>
            <div className="col-3" >
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                >
                {elementIncongruency.snapshotA}
                </div>
            </div>
            <div className="col-3" >
            <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                >
                {elementIncongruency.snapshotB}
                </div>
            </div>
            
        </div>
    )
}

export default ElementIncongruencies;

ElementIncongruencies.propTypes = {
    elementIncongruency: PropTypes.array,
}
