/**
 * @summary InspectorTool.js
 * @file Contains All the Data Editor MiniGrids for Node, Neighborhood, and Connection CRUD Functionality
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/05/2024
 * @lastUpdated -8/6/2024
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../canvasPage.scss';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import axios from 'axios';
import NodeMiniGrid from './NodeMiniGrid'
import ConnectionMiniGrid from './ConnectionMiniGrid';
import NeighborhoodMiniGrid from './NeighborhoodMiniGrid';

const CanvasTableview = ({
    elementTabSelection,
    setElementTabSelection,
    canvasNodeSelections,
    canvasNeighborhoodSelections, 
    canvasConnectionSelections,
    tableNodeSelections,
    tableConnectionSelections,
    tableNeighborhoodSelections,
    setCanvasNodeSelections,
    setCanvasConnectionSelections,
    setCanvasNeighborhoodSelections,
    renderMapData,
    handleCrud,
    branchChangeData,
    handleTableviewNavigation
  }) => {


    const userObj = useSelector((state) => state.authReducer.userObj) 
    let onPublishedBranch = userObj.selectedBranch[0].branchStatus === "published"
    
    // CREATE CONNECTION FROM NODES HANDLER
    // When "Connect Nodes" is clicked inside NodeMiniGrid, we pass up two selected nodes.
    // This function opens AddConnectionCanvasModal.js and passes those two selected nodes as preselected.
    // NOTE: AddConnectionCanvasModal.js has its own handlers for creating connections from scratch.
    const handleConnectNodes = () => {
        let publishedNodes = tableNodeSelections.filter(node => node.isCheckedOut === false)
        if(tableNodeSelections.length !== 2){
            toast.error("You must select two nodes to connect.")
        } else if(publishedNodes.length === 1){
            toast.error(publishedNodes[0].nodeKey + " is not checked out.")
        } else if(publishedNodes.length === 2){
            toast.error(publishedNodes[0].nodeKey + " and " + publishedNodes[1].nodeKey + " are not checked out.")
        } else {
            setElementTabSelection("connect nodes")
        }
    }
    // CREATE NEIGHBORHOOD FROM NODES HANDLER
    // When "Create Neighborhoods" is clicked inside NodeMiniGrid, we pass up all selected nodes.
    // This function opens AddNeighborhoodCanvasModal.js and passes those selected nodes as preselected.
    // NOTE: AddConnectionCanvasModal.js has its own handlers for creating connections from scratch.
    const handleCreateNeighborhoodFromNodes = () => {
        if(tableNodeSelections.length){
            setElementTabSelection("neighborhoods with nodes")
        }
    }
    
    return (
        <div className='col-12'>
            {/* <div className='row'> */}
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link ${elementTabSelection === 'nodes' ? 'active' : null}`}
                        id="node-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nodes"
                        type="button"
                        role="tab"
                        onClick={() => {
                            setElementTabSelection("nodes");
                        }}
                        aria-controls="nodes"
                        aria-selected="true"
                    >
                        Nodes
                    </button>
                    </li>
                    <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link ${elementTabSelection === 'connections' ? 'active' : null}`}
                        id="connections-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#connections"
                        type="button"
                        role="tab"
                        onClick={() => {
                            setElementTabSelection("connections");
                        }}
                        aria-controls="profile"
                        aria-selected="false"
                    >
                        Connections
                    </button>
                    </li>
                    <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link ${elementTabSelection === 'neighborhoods' ? 'active' : null}`}
                        id="neighborhoods-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#neighborhoods"
                        type="button"
                        role="tab"
                        onClick={() => {
                            setElementTabSelection("neighborhoods");
                        }}
                        aria-controls="neighborhoods"
                        aria-selected="false"
                    >
                        Neighborhoods
                    </button>
                    </li>
                </ul>
            {/* </div> */}
            {elementTabSelection === "nodes" ? 
                // NODE MINI GRID
                // Contains Node CRUD Functionality
                <NodeMiniGrid 
                    handleCrud={handleCrud}
                    branchChangeData={branchChangeData}
                    canvasNodeSelections={canvasNodeSelections}
                    tableNodeSelections={tableNodeSelections}
                    setCanvasNodeSelections={setCanvasNodeSelections}
                    handleCreateNeighborhoodFromNodes={handleCreateNeighborhoodFromNodes}
                    handleConnectNodes={handleConnectNodes}
                    handleTableviewNavigation={handleTableviewNavigation}
                    renderMapData={renderMapData}
                    onPublishedBranch={onPublishedBranch}
                /> : null
            }
            {elementTabSelection === "connections" || elementTabSelection === "connect nodes" ? 
                // CONNECTION MINI GRID
                // Contains Connection CRUD Functionality
                <ConnectionMiniGrid 
                    handleCrud={handleCrud}
                    canvasConnectionSelections={canvasConnectionSelections}
                    tableConnectionSelections={tableConnectionSelections}
                    setCanvasConnectionSelections={setCanvasConnectionSelections}
                    tableNodeSelections={tableNodeSelections}
                    handleTableviewNavigation={handleTableviewNavigation}
                    renderMapData={renderMapData}
                    selectedNodesIncluded={elementTabSelection === "connect nodes"}
                    onPublishedBranch={onPublishedBranch}
                /> : null
            }
            {elementTabSelection === "neighborhoods" || elementTabSelection === "neighborhoods with nodes"? 
                // NEIGHBORHOOD MINI GRID
                // Contains Neighborhood CRUD Functionality
                <NeighborhoodMiniGrid 
                    handleCrud={handleCrud}
                    branchChangeData={branchChangeData}
                    canvasNeighborhoodSelections={canvasNeighborhoodSelections}
                    tableNeighborhoodSelections={tableNeighborhoodSelections}
                    tableNodeSelections={tableNodeSelections}
                    setCanvasNeighborhoodSelections={setCanvasNeighborhoodSelections}
                    handleTableviewNavigation={handleTableviewNavigation}
                    renderMapData={renderMapData}
                    selectedNodesIncluded={elementTabSelection === "neighborhoods with nodes"}
                    onPublishedBranch={onPublishedBranch}
                /> : null
            }
            
        </div>

    );
  } 

  CanvasTableview.propTypes = {
    canvasNodeSelections: PropTypes.array,
    canvasConnectionSelections: PropTypes.array,
    canvasNeighborhoodSelections: PropTypes.array,
    tableNodeSelections: PropTypes.array,
    tableConnectionSelections: PropTypes.array,
    tableNeighborhoodSelections: PropTypes.array,
    handleElementTabSelection: PropTypes.func,
    elementTabSelection: PropTypes.string,
    setElementTabSelection: PropTypes.func,
    setCanvasNodeSelections: PropTypes.func,
    setCanvasConnectionSelections: PropTypes.func,
    setCanvasNeighborhoodSelections: PropTypes.func,
    renderMapData: PropTypes.obj,
    handleCrud: PropTypes.func,
    branchChangeData: PropTypes.obj,
    handleTableviewNavigation: PropTypes.func,
};

export default CanvasTableview;