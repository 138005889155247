/**
 * @summary gojsFormatter.js
 * @file Filter Util: Quickly Formats Raw Data into GoJS Data Obj where GoJS Advanced Options are Accessible - Specifically Used by Filters
 * @returns {JSX}
 * @usedBy CanvasFilter.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const gojsFormatter = (
    diagramData,
  ) => {
    const {nodeDataArray, linkDataArray} = diagramData
    const $ = go.GraphObject.make;
    const diagram = $(go.Diagram, // no div!
        {
          model: $(go.GraphLinksModel,
            {   
                nodeKeyProperty: 'nodeKey',
                linkKeyProperty: 'key' 
            })
        });
    
    // need to merge in the data since the props are immutable and GoJS normally adds some internal properties
    
    diagram.model.commit(m => {
        m.mergeNodeDataArray(nodeDataArray);
      if (linkDataArray !== undefined && m instanceof go.GraphLinksModel) {
        m.mergeLinkDataArray(linkDataArray);
      }
    });
    return diagram;
  };
  
  