/**
 * @summary FrameworkReducer.jsx
 * @file Framework Reducer
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import {
  LOAD_FRAMEWORKDETAIL,
  LOAD_SELECTED_CONTENT_CODES,
  CLEAR_CODES
} from './FrameworkType';

const frameworkDetails = {
  frameworkDetails: [],
  contentCodes: []
};

/**
 * @summary
 * This redux reducer is used for load the metadata based on the action type
 *
 * @param {metadata} state
 * @param {reduxAction} action
 */
const FrameworkDetailsReducer = (state = frameworkDetails, action) => {
  switch (action.type) {
    case LOAD_FRAMEWORKDETAIL:
      return {
        ...state,
        frameworkDetails: action.frameworkDetails
      };
    case LOAD_SELECTED_CONTENT_CODES:
      return {
        ...state,
        contentCodes: [...action.contentCodes]
      };

    case CLEAR_CODES:
      return {
        ...state,
        contentCodes: []
      };
    default:
      return state;
  }
};
export default FrameworkDetailsReducer;
