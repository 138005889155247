/**
 * @summary ProtectedRoute.jsx
 * @file Protects routes by validating if user is logged. If not, re-directs them to login page
 * @returns {JSX}
 * @usedBy LinkageLevelMediaGrid.js, NeighborhoodMediaGrid.js, NodeMediaGrid.js, MapUploadGrid.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

export const ProtectedRoute = ({ children }) => {
  const user = useSelector((store) => (store.authReducer.userObj));
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user.id && location.pathname !== '/login') {
      // user is not authenticated and not already on the login page
      navigate('/login');
    }
  }, [user.id, location.pathname]);

  if (user.id) {
    return children;
  }
  
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  children: PropTypes.object
};