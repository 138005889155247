/**
 * @summary App.js
 * @file Main landing component for the application
 * @returns {JSX}
 * @usedBy Index.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AllRoutes from './shared/ui/routes/routes';
import Layout from './pages/layout';
import { getProjects } from 'store/projects/ProjectActions';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from './shared/ui/Spinner';
import { retrieveMetadata } from './store/metadata/MetadataAction';
import { getUser, userStorage } from './store/auth/AuthActions';
import { getNodes } from 'store/nodes/NodeActions';
import { getNeighborhoods } from 'store/neighborhoods/NeighborhoodActions';
import { getConnections } from 'store/connections/ConnectionActions';
import '@progress/kendo-theme-bootstrap/dist/all.css';
import { resetCanvasSelection } from 'store/canvas/CanvasActions';

const App = () => {
  const [appLoaded, setAppLoaded] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer.userObj);
  const selectedProject = useSelector((state) => state.authReducer.userObj.selectedProject);
  const selectedBranch = useSelector((state) => state.authReducer.userObj.selectedBranch);

  useEffect(() => {
    setAppLoaded(true);
    if (user.currentAssessmentProgram) {
      dispatch(getProjects(user.currentAssessmentProgram));
      dispatch(retrieveMetadata(user.currentAssessmentProgram));
    }
  }, [user]);

  useEffect(() => {
    if (user.emailAddress) {
      dispatch(getUser(user.emailAddress));
    }
  }, []);

  useEffect(() => {
    // Before starting app, look at sessionStorage for user
    dispatch(userStorage());
    setAppLoaded(true);
  }, [dispatch]);

  useEffect(() => {
    // Sets nodes, neighborhoods, and connections as soon as user logs in //
    async function getDataOnFirstRender() {
      const userObj = await user;
      const awaitSelectedBranch = await selectedBranch;
      const awaitSelectedProject = await selectedProject;
      if (userObj && awaitSelectedBranch && awaitSelectedProject) {
        dispatch(
          getNodes(awaitSelectedProject[0].id, awaitSelectedBranch[0].id)
        );
        dispatch(
          getNeighborhoods(
            awaitSelectedProject[0].id,
            awaitSelectedBranch[0].id
          )
        );
        dispatch(
          getConnections(awaitSelectedProject[0].id, awaitSelectedBranch[0].id)
        );
      }
    }
    getDataOnFirstRender();
    resetCanvasSelection();
  }, [user, selectedBranch, selectedProject]);

  if (!appLoaded) {
    return <Spinner />;
  }

  return (
    <>
      <Router>
        <Layout user={user}>
          <AllRoutes />
        </Layout>
      </Router>

      <ToastContainer
        position="bottom-right"
        autoClose={7000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        draggable
        theme="colored"
      />
    </>
  );
};

export default App;
