/**
 * @summary resolutionForm.js
 * @file Component maps out all conflicts and provides users the ability to resolve
 * @returns {JSX}
 * @usedBy conflictResolution.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ResolutionForm = ({ conflicts }) => {
  const [result, setResult] = useState([...Array(conflicts.length)]);
  const onClickSource = (conflict, idx, parent) => {
    const newResults = [...result];
    newResults[idx] = conflict[parent];
    setResult(newResults);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col p-2 justify-content-between">
          <fieldset className="bg-light p-3 rounded mt-1">
            <div className="row p-2 justify-content-between">
              <div className="col-1">ID</div>
              <div className="col-3">Current Branch (Source)</div>
              <div className="col-1" />
              <div className="col-3">Result (Destination)</div>
              <div className="col-1" />
              <div className="col-3">Other Branch (Selected)</div>
            </div>
            {conflicts.map((conflict, idx) => {
              return (
                <div key={conflict.name} className="row p-1">
                  <div className="col-1">{conflict.name}</div>
                  <div className="col-3">
                    <input readOnly value={conflict.source} />
                  </div>
                  <div className="col-1">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-dark bi bi-arrow-right"
                      onClick={() => onClickSource(conflict, idx, 'source')}
                    ></button>
                  </div>
                  <div className="col-3">
                    <input readOnly value={result[idx]} />
                  </div>
                  <div className="col-1">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-dark reso-arrow"
                      onClick={() =>
                        onClickSource(conflict, idx, 'destination')
                      }
                    >
                      <i className="bi bi-arrow-left"></i>
                    </button>
                  </div>
                  <div className="col-3">
                    <input readOnly value={conflict.destination} />
                  </div>
                </div>
              );
            })}
          </fieldset>
        </div>
      </div>
    </div>
  );
};

ResolutionForm.propTypes = {
  conflicts: PropTypes.array
};

export default ResolutionForm;
