/**
 * @summary ColumnMenuFilter.js
 * @file Component receives an array of items and filters them down to menu components
 * @returns {JSX}
 * @usedBy KendoGridBase.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import {
  GridColumnMenuSort,
  GridColumnMenuFilter,
  GridColumnMenuItemGroup,
  GridColumnMenuItem,
  GridColumnMenuItemContent
} from '@progress/kendo-react-grid';
import PropTypes from 'prop-types';

const ColumnMenuFilter = ({
  gridColumns,
  onColumnsSubmit,
  onCloseMenu,
  sortable,
  ...props
}) => {
  const [gridColumnsState, setColumnsState] = useState(gridColumns);
  const [columnsExpanded, setColumnExpanded] = useState(false);
  const [filterExpanded, setFilterExpanded] = useState(false);

  const onToggleColumn = (id) => {
    const newColState = gridColumnsState.map((column, idx) =>
      idx === id ? { ...column, show: !column.show } : column
    );
    setColumnsState(newColState);
  };

  const onFilterExpandChange = (value) => {
    setFilterExpanded(value);
    setColumnExpanded(value ? false : columnsExpanded);
  };

  const onMenuItemClick = () => {
    const value = !columnsExpanded;
    setColumnExpanded(value);
    setFilterExpanded(value ? false : filterExpanded);
  };

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    onColumnsSubmit(gridColumnsState);
    if (onCloseMenu) {
      onCloseMenu();
    }
  };

  const onReset = (event) => {
    event.preventDefault();
    const allColumns = gridColumnsState.map((col) => ({
      ...col,
      show: true
    }));

    setColumnsState(allColumns);
    onColumnsSubmit(allColumns);
    if (onCloseMenu) {
      onCloseMenu();
    }
  };

  return (
    <div>
      <GridColumnMenuItem
        title="Hide Columns"
        iconClass="k-i-columns"
        onClick={onMenuItemClick}
      />
      <GridColumnMenuItemContent show={columnsExpanded}>
        <div className="k-column-list-wrapper">
          <form onSubmit={onSubmit} onReset={onReset}>
            <div className="k-column-list">
              {gridColumnsState &&
                gridColumnsState.map((column, idx) => {
                  if (column.title) {
                    return (
                      <div key={column.title} className="k-column-list-item">
                        <span>
                          <input
                            id={`column-visiblity-show-${idx}`}
                            className="k-checkbox"
                            type="checkbox"
                            readOnly
                            checked={column.show}
                            onClick={() => {
                              onToggleColumn(idx);
                            }}
                          />
                          <label
                            htmlFor={`column-visiblity-show-${idx}`}
                            className="k-checkbox-label"
                            style={{ userSelect: 'none' }}
                          >
                            {column.title}
                          </label>
                        </span>
                      </div>
                    );
                  }
                  return null;
                })}
            </div>
            <div className="k-columnmenu-actions">
              <button type="button" className="k-button" onClick={onReset}>
                Reset
              </button>
              <button
                className="k-button k-primary"
                onClick={onSubmit}
                type="button"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </GridColumnMenuItemContent>

      {sortable && <GridColumnMenuSort {...props} />}
      {gridColumnsState &&
        gridColumnsState.map((column) => {
          if (props.column.field === column.field && column.filterable) {
            if (column.filterCell) {
              return (
                <column.filterCell
                  key={column.field}
                  data={column.filterCellValues}
                  defaultValue={
                    column.defaultFilterCellValue
                      ? column.defaultFilterCellValue
                      : 'Select'
                  }
                  onCloseMenu={props.onCloseMenu}
                  {...props}
                />
              );
            }
            return (
              <GridColumnMenuFilter
                key={column.field}
                {...props}
                hideSecondFilter
                onExpandChange={onFilterExpandChange}
                expanded={filterExpanded}
              />
            );
          }
        })}
    </div>
  );
};

ColumnMenuFilter.propTypes = {
  gridColumns: PropTypes.array,
  onColumnsSubmit: PropTypes.func,
  onCloseMenu: PropTypes.func,
  sortable: PropTypes.boolean,
  column: PropTypes.any,
  field: PropTypes.any
};

export default ColumnMenuFilter;
