/**
 * @summary MediaTypes.js
 * @file Media String types
 * @returns {String}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const CREATE_MEDIA = 'CREATE_MEDIA';

export const GET_MEDIAS = 'GET_MEDIAS';

export const DELETE_MEDIA = 'DELETE_MEDIA';

export const GRID_MEDIA_DATA_ACTIONS_SELECTIONS_FULLFILLED =
  'GRID_MEDIA_DATA_ACTIONS_SELECTIONS_FULLFILLED';

export const CLEAR_SELECTION = 'CLEAR_SELECTION';

export const CLEAR_MEDIAS = 'CLEAR_MEDIAS';

export const UPDATE_MEDIA = 'UPDATE_MEDIA';

export const ADD_MEDIA = 'ADD_MEDIA';
