/**
 * @summary Header.jsx
 * @file Component displays Kite logo, name of application, user name, and user's branch
 * @returns {JSX}
 * @usedBy layout.jsx
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useEffect, useRef } from 'react';
import Profile from './Profile';
import PropTypes from 'prop-types';
import Breadcrumb from './Breadcrumb';
import './header.scss';
import { Link, useLocation } from 'react-router-dom';

let oldPath;
const Header = ({ children }) => {
  const ref = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    if (oldPath !== pathname) {
      const height = window
        .getComputedStyle(ref.current)
        .getPropertyValue('height');

      document
        .querySelector(':root')
        .style.setProperty('--header-height', height);
    }

    oldPath = pathname;
  }, [pathname]);

  return (
    <header ref={ref} className="kite_header">
      <div className="kite_header__inner container-fluid">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <Link to="/">
              <img
                alt="logo"
                src={process.env.PUBLIC_URL + '/kite-logo.svg'}
                style={{ height: 60 }}
              ></img>
            </Link>
            <h1 className="fs-5 align-self-end">Learning Maps</h1>
          </div>
          <Profile />
        </div>
      </div>
      {children}
      <Breadcrumb />
    </header>
  );
};

export default Header;

Header.propTypes = {
  children: PropTypes.object
};
