/**
 * @summary QuickLinks.js
 * @file Under construction. Link component meant to provide users a quick way to navigate to different parts of the application
 * @returns {JSX}
 * @usedBy HomePage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2023 University of Kansas
 */

import React from 'react';
import { Link } from 'react-router-dom';

export default function QuickLinks() {
  const links = [
    {
      id: 1,
      text: 'Recent Map Projects',
      icon: 'bi-diagram-3',
      path: '/'
    }
  ];

  const buildLinks = (links) => {
    const linkList = links.map((item) => {
      if (item.path != '#')
        return (
          <li className="nav-item" key={item.id}>
            <i className={`bi ${item.icon} me-2`} />
            <Link to={item.path} key={item.id}>
              {item.text}
            </Link>
          </li>
        );
    });
    return linkList;
  };

  return (
    <div className="card shadow">
      <h5 className="card-header">Quick Links</h5>
      <div className="card-body">
        <ul className="nav flex-column link-text">{buildLinks(links)}</ul>
      </div>
    </div>
  );
}
