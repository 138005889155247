/**
 * @summary ChangeLogHeaderRender.js
 * @file Once report is generated, this component provides column headers for the report
 * @returns {JSX}
 * @usedBy ChangeLogReturn.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react'
///////////////////////////////
// CHANGE LOG REPORT HEADERS //
///////////////////////////////
// This component overlays the list of available changes/timeStamps with column headlines.
// It needs to be passed into the <ListView> component to format the display correctly.
const ChangeLogHeaderRender = () => {
    return(
        <div
        className="row k-listview-item p-2 border-bottom align-middle"
        style={{
            marginTop: 0,
            marginBottom: 0,
        }}
        >
                <div className="col-2" style={{padding: 0}}>
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 25,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                        Property
                    </div>
                </div>
                <div className="col-2" style={{padding: 0}}>
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 25,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                        Change
                    </div>
                </div>
                <div className="col-2" style={{padding: 0}}>
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 25,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                        Checked Out By
                    </div>
                </div>
                <div className="col-3" style={{padding: 0}}>
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 25,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                        Before
                    </div>
                </div>
                <div className="col-3" style={{padding: 0}}>
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 25,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                        After
                    </div>
                </div>
        </div>
    )
}

export default ChangeLogHeaderRender;

