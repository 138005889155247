/**
 * @summary valueHeader.js
 * @file provides column headers for the neighborhood, connection, and node settings' panels
 * @returns {JSX}
 * @usedBy connectionSettings.js, neighborhoodSettings.js, nodeSettings.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';

const ValueHeader = () => (
  <div className="row p-2 justify-content-between">
    <div className="col">Field Name</div>
    <div className="col">Data Type</div>
    <div className="col-1">Qty of Fields</div>
    <div className="col-6">Potential Values</div>
  </div>
);

export default ValueHeader;
