/**
 * @summary UniqueAttributes.js
 * @file Component breaks down a unique attribute down to its properties in order to populate columns with info according to its header
 * @returns {JSX}
 * @usedBy ConnectionSummary.js, NeighborhoodSummary.js, NodeSummary.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';

const UniqueAttributes = ({
    uniqueAttribute
}) => {
    let renderSnapshotLocationText = () => {
        if(uniqueAttribute.snapshotA.length > 0 && uniqueAttribute.snapshotB.length > 0 ){
            return "Both Maps"
        } else if(uniqueAttribute.snapshotA.length > 0 && uniqueAttribute.snapshotB.length === 0){
            return "Snapshot A"
        } else if (uniqueAttribute.snapshotA.length === 0 && uniqueAttribute.snapshotB.length > 0){
            return "Snapshot B"
        }
    }
    let snapshotLocationText = renderSnapshotLocationText()
    // Not being displayed on the UI //
    return(
        <div
            className="row k-listview-item p-1 border-bottom"
            style={{
                marginTop: 0,
                marginBottom: 0,
            }}
        >
            <div className="col-2">
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                    className="text-camelcase"
                >
                    {uniqueAttribute.property}
                </div>
            </div>
            <div className="col-2" >
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                >
                    {uniqueAttribute.change}
                </div>
                
            </div>
            <div className="col-2">
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                    className="text-camelcase"
                >
                    {uniqueAttribute.key}
                </div>
            </div>
            <div className="col-3" >
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                    className="text-camelcase"
                >
                {uniqueAttribute.snapshotA.length > 0 ? 
                    <>
                    {uniqueAttribute.snapshotA.map(element=>{
                         let attributeName = element.attributeName.split('_').map(function(word) {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          }).join(' ');
                        return(
                            <div key={uniqueAttribute.snapshotA.indexOf(element)}>
                                {attributeName}
                            </div>
                        )
                    })} 
                    </> : 
                    <div>
                        No Unique {uniqueAttribute.property}
                    </div>
                }
                </div>
            </div>
            <div className="col-3" >
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                    className="text-camelcase"
                >
                {uniqueAttribute.snapshotB.length > 0 ? 
                    <>
                    {uniqueAttribute.snapshotB.map(element=>{
                        let attributeName = element.attributeName.split('_').map(function(word) {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          }).join(' ');
                        return(
                            <div key={element.attributeName}>
                                {attributeName}
                            </div>
                        )
                    })} 
                    </> : 
                    <div>
                        No Unique {uniqueAttribute.property}
                    </div>
                }
                </div>
            </div>
            
        </div>
    )
}

export default UniqueAttributes;

UniqueAttributes.propTypes = {
    uniqueAttribute: PropTypes.array,
}
