/**
 * @summary Canvas.js
 * @file Renders the LM Map into to the Canvas
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

////////////////////////////////////////////////////////////////////////////////////
// THIS FILE CONTAINS SEVERAL COMMENTED CODES THAT WE NEED FOR QUICK MIND CHANGES //
// THEY ALL INVOLVE EXTREMELY SPECIFIC STYLE AND FORMATTING PARAMETERS //
//////////////////////////////////////////////////////////

import React from 'react';
import { useEffect, useRef } from 'react';
import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import PropTypes from 'prop-types';
import { SuperNodeLayout } from './utils/superNodeLayout';
import { CustomDragTool } from './utils/customDragTool';
import './canvasPage.scss';

const Canvas = ({
  nodeDataArray,
  linkDataArray,
  modelData,
  onModelChange,
  handleElementSelection,
  neighborhoodLegendArray,
  setMultiSelectActive,
  setIsNodeSelected,
  setSelectedNode,
  setMultiSelectArray,
  skipsDiagramUpdate,
  setDiagramAccess,
  reviewModeOpen
}) => {
  const diagramRef = useRef(null); 

  /////////////////////////
  // USE EFFECT HANDLERS //
  /////////////////////////
  
  useEffect(()=>{
    if(diagramRef.selection){
      let selectedElements = []
      diagramRef.selection.each(selection => {
        selectedElements.push(selection)
      })
    }
  },[diagramRef])

  // ROTATION AND LAYER VIEW HANDLER //
  useEffect(() => {
    if (diagramRef.current === null) return;
    const diagram = diagramRef.current.getDiagram();
    if (
      diagram instanceof go.Diagram &&
      (modelData.layoutDirection !== undefined ||
        modelData.layeringOption !== undefined)
    ) {
      diagram.commit((d) => {
        let lay = d.layout;
        lay.direction = modelData.layoutDirection;
        switch (modelData.layeringOption) {
          case 'LayerLongestPathSource':
            lay.layeringOption = go.LayeredDigraphLayout.LayerLongestPathSource;
            break;
          case 'LayerLongestPathSink':
            lay.layeringOption = go.LayeredDigraphLayout.LayerLongestPathSink;
            break;
          case 'resetLayerLongestPathSource':
            lay.layeringOption = () => {
              diagram.layoutDiagram = true;
              return go.LayeredDigraphLayout.LayerLongestPathSource;
            };
            break;
          case 'resetLayerLongestPathSink':
            lay.layeringOption = () => {
              diagram.layoutDiagram = true;
              return go.LayeredDigraphLayout.LayerLongestPathSink;
            };
            break;
          default:
            lay.layeringOption = go.LayeredDigraphLayout.LayerLongestPathSource;
            break;
        }
        lay.isValidLayout = false;
      }, null);
      diagram.commandHandler.zoomToFit();
    }

    // NEIGHBORHOOD LEGEND HANDLER //
    diagram.commit((d) => {
      d.nodes.each(node => {
        for(let i = 0; i < neighborhoodLegendArray.length; i++){
          if(node.data.nodeKey === neighborhoodLegendArray[i].nodeKey){
            if(neighborhoodLegendArray[i].visible ===true){
              return node.visible = true;
            };
          }
        }
      })
    });
  }, [modelData, neighborhoodLegendArray]);

  // useEffect(() => {
  //   if (diagramRef.current === null) return;
  //   const diagram = diagramRef.current.getDiagram();
  //   if (
  //     diagram instanceof go.Diagram
  //   ) {

  //   }
  // }, [reviewModeOpen])

  //////////////////////
  // MAP BUILD STARTS //
  //////////////////////

  function initDiagram() {
    ////////////////////
    // DIAGRAM STARTS //
    ////////////////////
    const $ = go.GraphObject.make;

    go.Diagram.licenseKey = process.env.REACT_APP_GOJS_KEY || '';

    const diagram = $(go.Diagram, {
      initialAutoScale: go.Diagram.Uniform,
      allowCopy: false,
      allowDelete: false,
      padding: 100,
      
      'panningTool.isEnabled': false,
      'undoManager.isEnabled': true, // must be set to allow for model change listening
      draggingTool: $(CustomDragTool),
      layout: $(SuperNodeLayout, {
        alignOption: go.LayeredDigraphLayout.AlignAll,
        isOngoing: false,
        layerSpacing: 20,
        columnSpacing: 20
      }),
      model: $(go.GraphLinksModel, {
        nodeKeyProperty: 'nodeKey',
        linkKeyProperty: 'id'
      })
    });
    
    ///////////////////////////
    // ELEMENT CLICK HANDLER //
    ///////////////////////////
    function click(e, obj) {
      if(obj.data.type !== "Connection"){
        if (obj.data.category !== 'Super') {
          if (e.shift === false && e.control === false) {
            diagram.clearHighlighteds();
          } 
          diagram.findNodeForKey(obj.data.nodeKey).isHighlighted = true;
          if (obj.data._members) {
            obj.data._members.forEach((member) => {
              diagram.findNodeForKey(member).isHighlighted = true;
            });
          }
        }
        diagram.findNodeForKey(obj.data.nodeKey).isHighlighted = true;
        if (obj.data._members) {
          obj.data._members.forEach((member) => {
            if(diagram.findNodeForKey(member) !== null){
            diagram.findNodeForKey(member).isHighlighted = true;
            }
          });
        }
      }
    }
    //////////////////////////
    // NODE TEMPLATE DESIGN //
    //////////////////////////

    diagram.nodeTemplate = $(
      go.Node,
      'Auto', // the Shape will go around the TextBlock
      {
        click: click
      },
      new go.Binding('visible', 'visible', function (h) {
        return h === true ? true : false;
      }),
      new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      $(
        go.Shape,
        {
          name: 'SHAPE',
          parameter1: 8, // corner radius
          portId: '',
          fromSpot: go.Spot.AllSides,
          toSpot: go.Spot.AllSides,
        },

        new go.Binding('figure', 'shape'),
        new go.Binding('fill', 'color'), 
        new go.Binding('strokeWidth', 'strokeWidth'),
        new go.Binding('strokeDashArray', 'dash'),
        new go.Binding('stroke', 'border'),
        new go.Binding('fill', 'isHighlighted', function (h, obj) {
          return h ? 'yellow' : obj.panel.data.color;
        })
      ),
      $(
        go.Panel,
        'Table',
        {
          defaultAlignment: go.Spot.Left,
          margin: 2
        },
        $(
          go.TextBlock,
          {
            row: 0,
            column: 0,
            alignment: go.Spot.Center
          },
          {
            margin: 4,
            editable: false,
            maxSize: new go.Size(600, NaN),
            name: 'TEXT'
          }, // some room around the text
          new go.Binding('font', 'fontPrimary'),
          new go.Binding('text', 'nodeKey'),
          new go.Binding('stroke','fontColor'),
          new go.Binding('stroke', 'isHighlighted', function (h, obj) {
            return h ? 'red' : obj.part.data.fontColor;
          })
        ),
        $(
          go.TextBlock,
          {
            row: 1,
            column: 0
          },
          {
            margin: 4,
            editable: false,
            maxSize: new go.Size(200, NaN),
            name: 'TEXT'
          }, // some room around the text
          new go.Binding('font', 'fontSecondary'),
          new go.Binding('text', 'name'),
          new go.Binding('stroke','fontColor')
        )
      ),
      // LEAVE THIS CODE FOR A POSSIBLE HIGHLIGHT FEATURE WE BRING BACK
      // {
      //   mouseEnter: function (e, obj) {
      //     obj.part.findObject('SHAPE').stroke = 'red';
      //   },
      //   mouseLeave: function (e, obj) {
      //     let originalColor = modelData.nodeBorderColor;
      //     obj.part.findObject('SHAPE').stroke = originalColor;
      //   }
      // },
      {
        // define a tooltip for each node that displays the color as text
        toolTip: $(
          'ToolTip',
          $(
            go.Panel,
            'Table',
            {
              defaultAlignment: go.Spot.Left,
              margin: 4
            },
            $(
              go.TextBlock,
              {
                row: 0,
                column: 0,
                alignment: go.Spot.Center
              },
              {
                font: 'bolder 18px Arial',
                stroke: '#1560b7',
                margin: 4,
                editable: false,
                maxSize: new go.Size(200, NaN),
                name: 'TEXT'
              }, // some room around the text
              new go.Binding('text', 'nodeKey').makeTwoWay()
            ),
            $(
              go.TextBlock,
              {
                row: 2,
                column: 0
              },
              {
                margin: 4,
                editable: false,
                maxSize: new go.Size(200, NaN),
                font: 'bold 12px Arial',
                name: 'TEXT'
              }, // some room around the text
              new go.Binding('text', 'title').makeTwoWay()
            ),
            $(
              go.TextBlock,
              {
                row: 3,
                column: 0
              },
              {
                margin: 4,
                editable: false,
                maxSize: new go.Size(200, NaN),
                font: '100 12px Arial',
                name: 'TEXT'
              }, // some room around the text
              new go.Binding('text', 'description')
            )
          ) // end of Adornment
        )
      }
    );
    //////////////////////////////////
    // NEIGHBORHOOD TEMPLATE DESIGN //
    //////////////////////////////////
    diagram.nodeTemplateMap.add(
      'Super',
      $(
        go.Node,
        'Auto',
        {
          locationObjectName: 'BODY',
          layerName: 'Background',
          movable: false,
          click: click
        },
        new go.Binding('visible', 'visible', function (h) {
          return h === true ? true : false;
        }),
        $(
          go.Shape,
          {
            name: 'SHAPE',
            parameter1: 20,
            spot1: go.Spot.TopLeft,
            spot2: go.Spot.BottomRight,
            minSize: new go.Size(50, 50),
            // strokeWidth: 1
          },
          new go.Binding('figure', 'shape'),
          new go.Binding('fill', 'backgroundColor'),
          new go.Binding('strokeWidth', 'strokeWidth'),
          new go.Binding('stroke', 'border'),
          new go.Binding('fill','isHighlighted', function (h, obj) {
            return h ? 'orange' : obj.panel.data.backgroundColor;
          })
        ),
        $(
          go.Panel,
          'Vertical',
          { margin: 20 },
          $(
            go.TextBlock,
            {
              // font: '700 20px sans-serif',
              margin: new go.Margin(5, 5, 10, 5),
              name: 'TEXT'
            },
            new go.Binding('font', 'fontPrimary'),
            new go.Binding('text', 'title'),
            new go.Binding('stroke','fontColor')
          ),
          $(go.Shape, { name: 'BODY', opacity: 0 })
        )
      )
    );

    ////////////////////////////////
    // CONNECTION TEMPLATE DESIGN //
    ////////////////////////////////
    diagram.linkTemplate = $(
      go.Link, // the whole link panel
      {
        routing: go.Link.Orthogonal,
        corner: 10,
        click: click
      },
      // CONTROLS THE CONNECTION COLOR AND THICKNESS
      $(
        go.Shape, // the link shape
        new go.Binding("fill", "color"),
        new go.Binding('strokeWidth', 'strokeWidth'),
        new go.Binding('stroke', "color"),
        // new go.Binding('stroke','isHighlighted', function (h, obj) {
        //   return h ? 'red' : obj.panel.data.color;
        // }),
        // new go.Binding("stroke", "defaultColor"),
        // new go.Binding('strokeWidth', 'strokeWidth'),
        new go.Binding('strokeDashArray', 'dash'),
      ),
      // CONTROLS THE ARROW HEAD SHAPE, COLOR AND SIZE
      $(
        go.Shape, // the arrowhead
        { stroke: null },
        new go.Binding('fill', 'color'),
        new go.Binding('scale', 'strokeWidth'),
        new go.Binding('stroke', 'strokeWidth'),
        new go.Binding('toArrow', 'arrowHeadStyle')
      )
    );


    diagram.commandHandler.selectAll();

    // BOX SELECTION
    // Need to add selections
    diagram.toolManager.dragSelectingTool.box =
      $(go.Part,
        { layerName: "Tool", selectable: false },
        $(go.Shape,
          { name: "SHAPE", fill: null, stroke: "#613d7c", strokeWidth: 6 }));
    
    diagram.addDiagramListener('ChangedSelection', function () {
      let selectedElements = []
      diagram.selection.each(selectedElement =>{
          selectedElements.push(selectedElement)
      })
      handleElementSelection(selectedElements)
    })

    diagram.grid.visible = false;
    diagram.padding = new go.Margin(100, 100, 100, 100),


    diagram.addDiagramListener('BackgroundSingleClicked', function (e) {
      if (e.shift === false && e.control === false) {
        diagram.clearHighlighteds();
        setMultiSelectActive(false)
        setIsNodeSelected(false);
        setSelectedNode(null)
        setMultiSelectArray([])
      }
    });

    
    // EXTRA SETTINGS
      setDiagramAccess(diagram);
    return diagram;
      
  }
  
  // LEAVE THIS - ITS USED FOR MANUALLY REDUCING THE MAP TO SMALLER NUMBER FOR TESTING
  // nodeDataArray = nodeDataArray.slice(0, 3000)
  // let firstHalf = linkDataArray.slice(0, 2000)
  // let secondHalf = linkDataArray.slice(36, 37)
  // let formattedConnections = [...firstHalf, ...secondHalf]

  //////////////////////////
  // RETURN DIAGRAM BUILD //
  //////////////////////////
  return (
    <div className={reviewModeOpen ? "review-mode-background" : "gsfBackground"}>
        {diagramRef !== null ? 
        <>
        <ReactDiagram
          divClassName="diagram-component"
          ref={diagramRef}
          nodeDataArray={nodeDataArray}
          linkDataArray={linkDataArray}
          skipsDiagramUpdate={skipsDiagramUpdate}
          modelData={modelData}
          onModelChange={onModelChange}
          initDiagram={initDiagram}
        />
        </>
        : 
        <div>Processing Map Data...</div>
        }
    </div>

  );
};

Canvas.propTypes = {
  nodeDataArray: PropTypes.array,
  linkDataArray: PropTypes.array,
  modelData: PropTypes.object,
  onModelChange: PropTypes.func,
  handleElementSelection: PropTypes.func,
  neighborhoodLegendArray: PropTypes.array,
  setMultiSelectActive: PropTypes.func,
  setIsNodeSelected: PropTypes.func,
  setSelectedNode: PropTypes.func,
  setMultiSelectArray: PropTypes.func,
  skipsDiagramUpdate: PropTypes.bool,
  setDiagramAccess: PropTypes.func,
  reviewModeOpen: PropTypes.bool
};

export default Canvas;