/**
 * @summary Map Upload Reducer
 * @file MapUploadReducer.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { GET_BRANCHESLIST, GET_UPLOADSTATSLIST } from './MapUploadTypes';

const initialState = { projectsList: [] };

const NodeUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BRANCHESLIST:
      return { ...state, branchesList: action.branchesList };
    case GET_UPLOADSTATSLIST:
      return { ...state, uploadStatsList: action.uploadStatsList };
    default:
      return state;
  }
};

export default NodeUploadReducer;
