/**
 * @summary Theme Reducer
 * @file ThemeReducer.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import {
  CREATE_THEME,
  GET_THEMES,
  DELETE_THEME,
  CLEAR_THEMES
} from './ThemeTypes';

const themes = {
  themes: undefined,
  error: null
};

/**
 * @summary
 * This redux reducer is used for project CRUD based on the action type
 *
 * @param {themes} state
 * @param {reduxAction} action
 */
const themeReducer = (state = themes, action) => {
  switch (action.type) {
    case GET_THEMES:
      return {
        ...state,
        ...action.themesList?.reduce((newObj, theme) => {
          newObj[theme.id] = theme;
          return newObj;
        }, {})
      };

    case CREATE_THEME:
      action.theme.id = action.meta.id;
      return {};

    case CLEAR_THEMES:
      return {
        themes: undefined,
        error: null
      };

    default:
      return state;
  }
};
export default themeReducer;
