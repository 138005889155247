/**
 * @summary HistogramHeadlineRender.js
 * @file This component provides column headers for the generated report
 * @returns {JSX}
 * @usedBy DegreeHistogramReturn.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react'
///////////////////////
// LIST VIEW HEADERS //
///////////////////////
// This component overlays the list of available changes/timeStamps with column headlines.
// It needs to be passed into the <ListView> component to format the display correctly.
const HistogramHeadlineRender = () => {
    return(
        <>
        <div
        className="row k-listview-item p-2 border-bottom align-middle"
        style={{
            marginTop: 0,
            marginBottom: 0,
        }}
        >
            <div className='col-2'>
                {/* EMPTY COLUMN SPACING FOR SOURCE NODES  */}
            </div>
            <div className='col-3'>
                    <div
                        style={{
                            fontSize: 36,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 12,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                        Incoming Connections
                    </div>
            </div>
            <div className='col-3'>
                    <div
                        style={{
                            fontSize: 36,
                            fontWeight: 700,
                            color: "#003459",
                            marginLeft: 12,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                        Outgoing Connections
                    </div>
            </div>
        </div>
        <div
        className="row k-listview-item p-2 border-bottom align-middle"
        style={{
            marginTop: 0,
            marginBottom: 0,
        }}
        >
                <div className="col-2" style={{padding: 0}}>
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 25,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                        Source Nodes
                    </div>
                </div>
                <div className="col-1" style={{padding: 0}}>
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 25,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                        Incoming
                    </div>
                </div>
                <div className="col-2" style={{padding: 0}}>
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: "#1560b7",
                            marginLeft: 25,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                        Connected Nodes
                    </div>
                </div>
                <div className="col-1" style={{padding: 0}}>
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: "#003459",
                            marginLeft: 25,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                        Outgoing
                    </div>
                </div>
                <div className="col-2" style={{padding: 0}}>
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: "#003459",
                            marginLeft: 25,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}
                        className="text-camelcase"
                    >
                        Connected Nodes
                    </div>
                </div>
        </div>
        </>
    )
}

export default HistogramHeadlineRender;

