/**
 * @summary ChangeLogItemRender.js
 * @file This component provides rows for the nodes that have been changed within the specified time frame
 * @returns {JSX}
 * @usedBy ChangeLogReturn.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react'
import PropTypes from 'prop-types';

const ChangeLogItemRender = ({ dataItem }) => {
    return (
        
        <div
        key={dataItem.timeStamp}    
        className="row k-listview-item p-2 border-bottom align-middle"
            style={{
                marginTop: 0,
                marginBottom: 0,
            }}
        >
            <div className="col-2" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {dataItem.property}
                </div>
            </div>
            <div className="col-2" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {dataItem.change}
                </div>
            </div>
            <div className="col-2" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {dataItem.checkedOutBy}
                </div>
            </div>
            {typeof dataItem.original === 'string' ? 
            <>
            <div className="col-3" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                        {dataItem.original}
                </div>
            </div>
            <div className="col-3" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                    >
                        {dataItem.updated}
                </div>
            </div> 
            </>
            :
            <>
            <div className="col-3" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {Array.isArray(dataItem.original.value) ? 
                        dataItem.original.value.map(attVal => {
                            return(
                            <div key={dataItem.original.name + attVal}>
                                {attVal}
                            </div>
                        )})
                        :
                        <div>
                            {dataItem.original.value}
                        </div>    
                    }
                </div>
            </div>
            <div className="col-3" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                    >
                    {Array.isArray(dataItem.updated.value) ? 
                       dataItem.updated.value.map(attVal => {
                        return(
                        <div key={dataItem.updated.name + attVal}>
                            {attVal}
                        </div>
                        )})
                        :
                        <div>
                            {dataItem.updated.value}
                        </div>    
                    }
                </div>
            </div> 
            </>
            }

        </div>
    );
};

export default ChangeLogItemRender;

ChangeLogItemRender.propTypes = {
    dataItem: PropTypes.object,
}