/**
 * @summary MapUpload.js
 * @file Page component that handles map uploads
 * @returns {JSX}
 * @usedBy c3Routes.js, routes.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

/* eslint-disable react/jsx-key */
/* eslint-disable prefer-promise-reject-errors */
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!TODO: need to revisit this entire feature !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import Uploader from './Uploader';
import {
  getAllProjects,
  getBranchesByProjectId
} from '../../../store/uploads/MapUploadActions';
import NodeUploadGrid from './MapUploadGrid';
import { commonUpload } from './fileUploadService';
import { toast } from 'react-toastify';
import { getMediaUrlFromPath } from './uploadDisplayComponent';
import { normalizeName } from '../../../utility/normalizeName';
import { camelCase } from 'lodash';
import store from 'store/store';
import { shallowEqual } from 'react-redux';

const MapUpload = () => {
  const [mapUploadState, setMapUploadState] = useState({
    messages: [],
    formData: {
      nodeProject: 0,
      nodeBranch: 0
    },
    isGridRefreshOnUpload: false,
    showHideTemplateBtn: true,
    templateHeadersInfo: [],
    nodeBranchListState: [],
    templateAttachment: {},
    uploadedDate: null
  });
  const filesInfo = [];
  // I still have to do my research on how this is being used in readExcel before I can think of putting this in the obj state variable //
  const [id, setId] = useState(null);
  const [file, setFile] = useState([]);

  //////////////////////////////////////////////////////////////
  // Couldn't put this state variable into mapUploadState.   //
  // The setter function is being passed into NodeUploadGrid //
  const [isRefresh, setRefresh] = useState(false);
  /////////////////////////////////////////////////////////////
  const navigate = useNavigate();

  const userObj = useSelector((state) => state.authReducer.userObj);
  const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);
  const assessmentProgramId = useSelector((state) => state.authReducer.userObj.currentAssessmentProgram);
  const nodeProjectsList = useSelector((state) => {
    delete state.projectReducer.selectedData;
    delete state.projectReducer.error;
    return Object.values(state.projectReducer);
  }, shallowEqual);
  const nodeBranchesList = useSelector((state) => state.nodeUploadReducer.branchesList);
  const roleCode = useSelector((state) => state.authReducer.userObj.assessmentPrograms[0].role[0].id);

  const defaultNodeAttrs = [
    'Name',
    'Description',
    'Action',
    'Node Key',
    'Status',
    'Tags',
    'Observation',
    'Citation',
    'Citation Category',
    'Subject',
    'Content Framework Type',
    'Content Codes',
    'Linkage Level',
    'Cognitive Taxonomy',
    'Dimension',
    'Mathematical Practice',
    'Science And Engineering Practice',
    'Text Complexity',
    'Vision',
    'Hearing',
    'Speech',
    'Mobility',
    'Dual Sensory'
  ];
  const defaultConnectionAttrs = [
    'Name',
    'Action',
    'Citation',
    'Citation Category',
    'Connection Type',
    'Destination Node Key',
    'Observation',
    'Origin Node Key',
    'Tags'
  ];
  const defaultNeighborhoodAttrs = [
    'Name',
    'Action',
    'Cognitive Taxonomy',
    'Content Codes',
    'Content Framework Type',
    'Description',
    'Dimension',
    'Linkage Level',
    'Mathematical Practice',
    'Node Keys',
    'Science And Engineering Practice',
    'Subject',
    'Tags',
    'Text Complexity',
    'Observation',
    'Citation',
    'Citation Category',
    'Associated ee'
  ];

  if (!isAuthenticated) {
    navigate('/login');
  }
  useEffect(() => {
    const params = { assessmentProgramId };
    store.dispatch(getAllProjects(assessmentProgramId, params));
  }, []);
  const fetchFilesInfo = (files) => {
    setFile(files);
    setMapUploadState({ ...mapUploadState, file: files });
  };

  /**
   * Checks for ownership of branch
   */
  const branchOwnerCheck = (formData) => {
    if (!formData.nodeBranch) {
      setMapUploadState({ ...mapUploadState, showHideTemplateBtn: true });
    } else {
      const selectedBranch = mapUploadState.nodeBranchListState.find(
        (branch) => branch.id === formData.nodeBranch
      );
      if (selectedBranch.user !== userObj.screenName) {
        toast.error(
          `${userObj.screenName} does not own Branch: ${selectedBranch.name}`
        );
        setMapUploadState({ ...mapUploadState, showHideTemplateBtn: true });
      } else {
        setMapUploadState({ ...mapUploadState, showHideTemplateBtn: false });
      }
    }
  };

  /**
   * This method is used to make map upload backend endpoint, takes file contents as json and perform actions accordingly
   */
  const submitData = () => {
    if (filesInfo.length > 0) {
      axios
        .post('/api/upload', filesInfo, {
          params: {
            assessmentProgramId,
            roleCode
          }
        })
        .then((/* res */) => {
          setFile(null);
          setMapUploadState({ ...mapUploadState, file: null });
          // setGridRefreshOnUpload(true);
          setMapUploadState({ ...mapUploadState, isGridRefreshOnUpload: true });
          filesInfo.length = 0;
        })
        .catch((err) => {
          const { response } = err;
          const { data } = response;
          if (data) {
            setMapUploadState.messages({
              ...mapUploadState,
              messages: [{ message: [data] }]
            });
          } else {
            setMapUploadState.messages({
              ...mapUploadState,
              messages: [
                {
                  message:
                    JSON.stringify(response.statusText) || 'Unknown Error'
                }
              ]
            });
          }
        });
    }
  };
  /**
   * This method is used to read the file contents and convert it to JSON consumed by backend endpoint
   */

  const readExcel = (file) => {
    const rawFile = file.getRawFile();
    const promise = new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(rawFile);
      const workBookData = {};
      workBookData.filename = file.name;
      workBookData.userId = userObj.id;
      workBookData.projectId = mapUploadState.formData.nodeProject;
      workBookData.branchId = mapUploadState.formData.nodeBranch;
      workBookData.contentCodes = [];
      fileReader.onload = () => {
        const bufferArray = fileReader.result;
        const workbook = XLSX.read(bufferArray, { type: 'buffer' });
        workbook.SheetNames.forEach((sheet) => {
          const worksheet = workbook.Sheets[sheet];
          workBookData[sheet] = XLSX.utils.sheet_to_json(worksheet, {
            raw: true,
            defval: ''
          }); // array of object
        });
        if (workBookData.nodes.length > 0) {
          workBookData.nodes = workBookData.nodes.map((node, idx) => {
            Object.keys(node).forEach((key) => {
              let formattedKey;
              if (defaultNodeAttrs.includes(key)) {
                formattedKey = key.replaceAll(/\s+/g, '_').toLowerCase();
              } else {
                formattedKey = key;
              }
              if (
                key === 'Name' ||
                key === 'Description' ||
                key === 'Action' ||
                key === 'Status' ||
                key === 'Node Key' ||
                key === 'Source'
              ) {
                workBookData.nodes[idx][camelCase(key)] =
                  workBookData.nodes[idx][key];
                if (key !== formattedKey) {
                  delete workBookData.nodes[idx][key];
                }
              }
              mapUploadState.templateHeadersInfo[0].groupHeader.map((md) => {
                const headerObjs = Object.values(md).flatMap((m) => m);
                const foundKey = headerObjs.find(
                  (headerItem) =>
                    headerItem.name === key ||
                    headerItem.name ===
                      key.replaceAll(/\s+/g, '_').toLowerCase()
                );
                if (foundKey) {
                  if (foundKey.type === 'Text') {
                    workBookData.nodes[idx][foundKey.name] = [
                      workBookData.nodes[idx][key]
                    ];
                    if (key !== formattedKey) {
                      delete workBookData.nodes[idx][key];
                    }
                  } else {
                    if (typeof workBookData.nodes[idx][key] === 'boolean') {
                      workBookData.nodes[idx][key] = [
                        normalizeName(workBookData.nodes[idx][key].toString())
                      ];
                      workBookData.nodes[idx][formattedKey] =
                        workBookData.nodes[idx][key];
                      if (key !== formattedKey) {
                        delete workBookData.nodes[idx][key];
                      }
                    } else if (
                      typeof workBookData.nodes[idx][key] === 'number'
                    ) {
                      workBookData.nodes[idx][key] =
                        workBookData.nodes[idx][key].toString();
                      const valueArr = workBookData.nodes[idx][key]
                        ?.split(',')
                        .map((w) => w.trim());
                      workBookData.nodes[idx][formattedKey] = valueArr;
                      if (key !== formattedKey) {
                        delete workBookData.nodes[idx][key];
                      }
                    }
                    if (
                      foundKey.type === 'Custom' &&
                      foundKey.name === 'Content Codes'
                    ) {
                      workBookData.contentCodes.push(node);
                      workBookData.nodes[idx][formattedKey] = [
                        workBookData.nodes[idx][key]
                      ];
                    } else {
                      if (typeof workBookData.nodes[idx][key] === 'boolean') {
                        workBookData.nodes[idx][key] = [
                          normalizeName(workBookData.nodes[idx][key].toString())
                        ];
                        workBookData.nodes[idx][formattedKey] =
                          workBookData.nodes[idx][key];
                        if (key !== formattedKey) {
                          delete workBookData.nodes[idx][key];
                        }
                      } else if (
                        typeof workBookData.nodes[idx][key] === 'number'
                      ) {
                        workBookData.nodes[idx][key] =
                          workBookData.nodes[idx][key].toString();
                        const valueArr = workBookData.nodes[idx][key]
                          ?.split(',')
                          .map((w) => w.trim());
                        workBookData.nodes[idx][formattedKey] = valueArr;
                        if (key !== formattedKey) {
                          delete workBookData.nodes[idx][key];
                        }
                      } else if (
                        typeof workBookData.nodes[idx][key] === 'array' ||
                        typeof workBookData.nodes[idx][key] === 'object'
                      ) {
                        const valueArr = workBookData.nodes[idx][key]
                          ? workBookData.nodes[idx][key][0]
                              ?.split(',')
                              .map((w) => w.trim())
                          : '';
                        workBookData.nodes[idx][formattedKey] = valueArr;
                        if (key !== formattedKey) {
                          delete workBookData.nodes[idx][key];
                        }
                      } else {
                        const valueArr = workBookData.nodes[idx][key]
                          ?.split(',')
                          .map((w) => w.trim());
                        workBookData.nodes[idx][formattedKey] = valueArr;
                        if (key !== formattedKey) {
                          delete workBookData.nodes[idx][key];
                        }
                      }
                    }
                  }
                }
              });
            });
            node.source = 'Upload';
            node.status = 'Draft';
            return node;
          });
        }
        if (workBookData.connections.length > 0) {
          workBookData.connections = workBookData.connections.map(
            (connection, idx) => {
              Object.keys(connection).forEach((key) => {
                let formattedKey;
                if (defaultConnectionAttrs.includes(key)) {
                  formattedKey = key.replaceAll(/\s+/g, '_').toLowerCase();
                } else {
                  formattedKey = key;
                }
                if (
                  key === 'Name' ||
                  key === 'Action' ||
                  key === 'Destination Node Key' ||
                  key === 'Origin Node Key' ||
                  key === 'Connection Type'
                ) {
                  workBookData.connections[idx][formattedKey] =
                    workBookData.connections[idx][key];
                }
                if (key !== formattedKey) {
                  delete workBookData.connections[idx][key];
                }
                mapUploadState.templateHeadersInfo[1].groupHeader.map((md) => {
                  const headerObjs = Object.values(md).flatMap((m) => m);
                  const foundKey = headerObjs.find(
                    (headerItem) => headerItem.name === key
                  );
                  if (foundKey) {
                    if (foundKey.type === 'Text') {
                      workBookData.connections[idx][formattedKey] = [
                        workBookData.connections[idx][key]
                      ];
                      if (key !== formattedKey) {
                        delete workBookData.connections[idx][key];
                      }
                    } else {
                      if (
                        typeof workBookData.connections[idx][key] === 'boolean'
                      ) {
                        workBookData.connections[idx][key] = [
                          normalizeName(
                            workBookData.connections[idx][key].toString()
                          )
                        ];
                        workBookData.connections[idx][formattedKey] =
                          workBookData.connections[idx][key];
                        if (key !== formattedKey) {
                          delete workBookData.connections[idx][key];
                        }
                      } else if (
                        typeof workBookData.connections[idx][key] === 'number'
                      ) {
                        workBookData.connections[idx][key] =
                          workBookData.connections[idx][key].toString();
                        const valueArr = workBookData.connections[idx][key]
                          ?.split(',')
                          .map((w) => w.trim());
                        workBookData.connections[idx][formattedKey] = valueArr;
                        if (key !== formattedKey) {
                          delete workBookData.connections[idx][key];
                        }
                      } else if (
                        typeof workBookData.connections[idx][key] === 'array' ||
                        typeof workBookData.connections[idx][key] === 'object'
                      ) {
                        const valueArr = workBookData.connections[idx][key]
                          ? workBookData.connections[idx][key][0]
                              ?.split(',')
                              .map((w) => w.trim())
                          : '';
                        workBookData.connections[idx][formattedKey] = valueArr;
                        if (key !== formattedKey) {
                          delete workBookData.connections[idx][key];
                        }
                      } else {
                        const valueArr = workBookData.connections[idx][key]
                          ?.split(',')
                          .map((w) => w.trim());
                        workBookData.connections[idx][formattedKey] = valueArr;
                        if (key !== formattedKey) {
                          delete workBookData.connections[idx][key];
                        }
                      }
                    }
                  }
                });
              });
              return connection;
            }
          );
        }

        if (workBookData.linkageLevels.length > 0) {
          workBookData.linkageLevels = workBookData.linkageLevels.map(
            (linkageLevel, idx) => {
              Object.keys(linkageLevel).forEach((key) => {
                const formattedKey = key.replaceAll(/\s+/g, '_').toLowerCase();

                if (key === 'Nodes') {
                  const valueArr = workBookData.linkageLevels[idx][key]
                    ?.split(',')
                    .map((w) => w.trim());
                  workBookData.linkageLevels[idx][formattedKey] = valueArr;
                  if (key !== formattedKey) {
                    delete workBookData.linkageLevels[idx][key];
                  }
                } else {
                  workBookData.linkageLevels[idx][formattedKey] =
                    workBookData.linkageLevels[idx][key];
                  delete workBookData.linkageLevels[idx][key];
                }
              });

              // eslint-disable-next-line no-prototype-builtins
              if (linkageLevel.node_keys)
                linkageLevel.node_keys =
                  linkageLevel.node_keys !== ''
                    ? linkageLevel.node_keys.split(',').map((w) => w.trim())
                    : [];
              return linkageLevel;
            }
          );
        }

        if (workBookData.neighborhoods.length > 0) {
          workBookData.neighborhoods = workBookData.neighborhoods.map(
            (neighborhoods, idx) => {
              Object.keys(neighborhoods).forEach((key) => {
                let formattedKey;
                if (defaultNeighborhoodAttrs.includes(key)) {
                  formattedKey = key.replaceAll(/\s+/g, '_').toLowerCase();
                } else {
                  formattedKey = key;
                }
                if (
                  key === 'Name' ||
                  key === 'Description' ||
                  key === 'Action' ||
                  key === 'Associated ee'
                ) {
                  workBookData.neighborhoods[idx][formattedKey] =
                    workBookData.neighborhoods[idx][key];
                  if (key !== formattedKey) {
                    delete workBookData.neighborhoods[idx][key];
                  }
                }
                if (key === 'Node Keys') {
                  const valueArr = workBookData.neighborhoods[idx][key]
                    ?.split(',')
                    .map((w) => w.trim());
                  workBookData.neighborhoods[idx][formattedKey] = valueArr;
                  if (key !== formattedKey) {
                    delete workBookData.neighborhoods[idx][key];
                  }
                }

                mapUploadState.templateHeadersInfo[2].groupHeader.map((md) => {
                  const headerObjs = Object.values(md).flatMap((m) => m);
                  const foundKey = headerObjs.find(
                    (headerItem) => headerItem.name === key
                  );
                  if (foundKey) {
                    if (foundKey.type === 'Text') {
                      workBookData.neighborhoods[idx][formattedKey] = [
                        workBookData.neighborhoods[idx][key]
                      ];
                      if (key !== formattedKey) {
                        delete workBookData.neighborhoods[idx][key];
                      }
                    }
                    if (
                      foundKey.type === 'Custom' &&
                      foundKey.name === 'Content Codes'
                    ) {
                      workBookData.contentCodes.push(neighborhoods);
                    } else {
                      if (
                        typeof workBookData.neighborhoods[idx][key] ===
                        'boolean'
                      ) {
                        workBookData.neighborhoods[idx][key] = [
                          normalizeName(
                            workBookData.neighborhoods[idx][key].toString()
                          )
                        ];
                        workBookData.neighborhoods[idx][formattedKey] =
                          workBookData.neighborhoods[idx][key];
                        if (key !== formattedKey) {
                          delete workBookData.neighborhoods[idx][key];
                        }
                      } else if (
                        typeof workBookData.neighborhoods[idx][key] === 'number'
                      ) {
                        workBookData.neighborhoods[idx][key] =
                          workBookData.neighborhoods[idx][key].toString();
                        const valueArr = workBookData.neighborhoods[idx][key]
                          ?.split(',')
                          .map((w) => w.trim());
                        workBookData.neighborhoods[idx][formattedKey] =
                          valueArr;
                        if (key !== formattedKey) {
                          delete workBookData.neighborhoods[idx][key];
                        }
                      } else if (
                        typeof workBookData.neighborhoods[idx][key] ===
                          'array' ||
                        typeof workBookData.neighborhoods[idx][key] === 'object'
                      ) {
                        const valueArr = workBookData.neighborhoods[idx][key]
                          ? workBookData.neighborhoods[idx][key][0]
                              ?.split(',')
                              .map((w) => w.trim())
                          : '';
                        workBookData.neighborhoods[idx][formattedKey] =
                          valueArr;
                        if (key !== formattedKey) {
                          delete workBookData.neighborhoods[idx][key];
                        }
                      } else {
                        const valueArr = workBookData.neighborhoods[idx][key]
                          ?.split(',')
                          .map((w) => w.trim());
                        workBookData.neighborhoods[idx][formattedKey] =
                          valueArr;
                        if (key !== formattedKey) {
                          delete workBookData.neighborhoods[idx][key];
                        }
                      }
                    }
                  }
                });
              });
              return neighborhoods;
            }
          );
        }
        resolve(workBookData);
        filesInfo.push(workBookData);
      };
      fileReader.onerror = (error) => {
        resolve(error);
      };
    });
    return promise;
  };

  const handleChange = (event) => {
    const formdata = mapUploadState.formData;
    const { name } = event.target;
    const value = parseInt(event.target.value, 10);
    formdata[name] = value;
    setMapUploadState({ ...mapUploadState, formData: formdata });
    if (name === 'nodeBranch') {
      getTemplateHeaders(formdata);
    }
  };

  /**
   * this method is used to fetch file headers for a selected project
   */
  const getTemplateHeaders = async (nodeData) => {
    const { data } = await axios.get(
      `/api/${nodeData.nodeProject}/branch/${nodeData.nodeBranch}/getUploadHeaders`
    );
    const HeadersArr = [];
    HeadersArr[0] = data.nodes;
    HeadersArr[1] = data.connections;
    HeadersArr[2] = data.neighborhoods;
    HeadersArr[3] = data.linkageLevels;
    setMapUploadState({ ...mapUploadState, templateHeadersInfo: HeadersArr });
  };
  useEffect(() => {
    if (
      mapUploadState.formData.nodeProject !== 0 &&
      mapUploadState.formData.nodeProject !== undefined
    ) {
      store.dispatch(
        getBranchesByProjectId(mapUploadState.formData.nodeProject)
      );
      if (
        mapUploadState.formData.nodeBranch !== undefined &&
        mapUploadState.formData.nodeBranch !== '' &&
        mapUploadState.formData.nodeBranch !== 0
      ) {
        branchOwnerCheck(mapUploadState.formData);
        setRefresh(true);
      } else {
        setMapUploadState({ ...mapUploadState, showHideTemplateBtn: false });
      }
    } else {
      const formdata = mapUploadState.formData;
      formdata.nodeBranch = '';
      setMapUploadState({
        ...mapUploadState,
        formData: formdata,
        showHideTemplateBtn: true,
        isGridRefreshOnUpload: false,
        nodeBranchListState: []
      });
      setRefresh(false);
    }
  }, [mapUploadState.formData.nodeProject, mapUploadState.formData.nodeBranch]);

  useEffect(() => {
    if (nodeBranchesList) {
      nodeBranchesList.shift();
      setMapUploadState({
        ...mapUploadState,
        nodeBranchListState: nodeBranchesList
      });
    }
  }, [nodeBranchesList]);
  const elementsRef = mapUploadState.templateHeadersInfo.map(() =>
    React.createRef()
  );
  // Exporter used for sample template Export file
  const save = (component1, component2, component3, component4) => {
    const workbookSheet1 = component1.workbookOptions();
    const worksheet2 = component2.workbookOptions();
    const worksheet3 = component3.workbookOptions();
    const worksheet4 = component4.workbookOptions();
    workbookSheet1.sheets[0].name = 'nodes';
    worksheet2.sheets[0].name = 'connections';
    worksheet3.sheets[0].name = 'neighborhoods';
    worksheet4.sheets[0].name = 'linkageLevels';
    workbookSheet1.sheets.push(worksheet2.sheets[0]);
    workbookSheet1.sheets.push(worksheet3.sheets[0]);
    workbookSheet1.sheets.push(worksheet4.sheets[0]);
    component1.save(workbookSheet1);
  };
  const handleTemplateDownload = () => {
    const _exporter0 = elementsRef[0].current;
    const _exporter1 = elementsRef[1].current;
    const _exporter2 = elementsRef[2].current;
    const _exporter3 = elementsRef[3].current;
    save(_exporter0, _exporter1, _exporter2, _exporter3);
  };
  // When the file is getting upload, to save in S3, need Template Id, and new Attachment Id, and this method will provide that information, if id is not available then this create it for us
  const generateNewIdsAndPath = (path, id, attachment) =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/map/upload/attachment/path', {
          id,
          attachment_json: attachment
        })
        .then((response) => {
          if (
            response &&
            response.status === 201 &&
            response.data &&
            response.data.attachment_json &&
            path
          ) {
            attachment = response.data.attachment_json;
            if (attachment.id !== null && attachment.version !== null) {
              const filePath = attachment.s3_file_upload_path;
              attachment.path = filePath;
              if (attachment.is_template) {
                setMapUploadState({
                  ...mapUploadState,
                  templateAttachment: attachment
                });
              }
              const date = new Date(attachment.uploaded_date).toLocaleString();
              setMapUploadState({ ...mapUploadState, uploadedDate: date });
              resolve(filePath);
            } else {
              reject(null);
            }
          } else {
            reject(null);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  // Common upload logic to save in S3
  const commonS3Upload = (
    validFile,
    files,
    path,
    id,
    attachment,
    isTemplate,
    callback
  ) => {
    const file = (files && files[0] && files[0].getRawFile()) || null;
    const fileDetails = (file && file.name && file.name.split('.')) || null;
    if (validFile && fileDetails) {
      if (!attachment) {
        attachment = {};
        attachment.id = null;
        attachment.version = null;
      }

      attachment.file_type = fileDetails.pop();
      attachment.file_name = fileDetails.join();
      attachment.file_modified_time =
        file.lastModifiedDate !== undefined ? file.lastModifiedDate : null;
      attachment.user_id = (userObj && userObj.id) || null;
      attachment.alternate_text = null;
      attachment.is_template = isTemplate;
      attachment.project_id = mapUploadState.formData.nodeProject;
      attachment.branch_id = mapUploadState.formData.nodeBranch;

      generateNewIdsAndPath(path, id, attachment).then((filePath) => {
        if (filePath) {
          commonUpload(
            filePath,
            file,
            attachment.file_name,
            attachment.file_type
          )
            .then((result) => {
              if (result) {
                let URL = null;
                try {
                  URL = getMediaUrlFromPath(filePath, true);
                  URL.then(() => {
                    callback(filePath);
                  }).catch((error) => {
                    toast.error('Template Error - Invalid File Path');
                  });
                } catch (e) {
                  toast.error('Template Error - Invalid Path');
                }
              }
            })
            .catch((error) => {
              console.log(error);
              toast.error('Template Error - Processing Map');
            });
        }
      });
    }
  };
  /**
   * This methid is used to read the file uploaded by the user,
   * convert it to the JSON which can be accepted by backend endpoint, and
   * store the file to s3
   */
  const handleUpload = () => {
    if (file && file.length) {
      const callback = (path) => {
        Promise.all(file.map(readExcel)).then(() => {
          if (filesInfo.length) {
            filesInfo[0].s3UploadPath = path;
            const pathArray = path.split('/');
            if (pathArray.length > 0) {
              filesInfo[0].attachmentId = pathArray[2];
              filesInfo[0].attachmentVersion = pathArray[3];
            }
            submitData();
          }
        });
      };
      const TEMPLATE_S3_PATH = 'templates/workflow';
      commonS3Upload(
        true,
        file,
        TEMPLATE_S3_PATH,
        id,
        mapUploadState.templateAttachment,
        true,
        callback
      );
    } else {
      // eslint-disable-next-line no-console
      console.log('No Files Found...!');
    }
  };

  // LEAVE THIS COMMENTED OUT UNTIL 4/1 - IF ITS NOT NEEDED BY THEN, WE CAN REMOVE IT
  // const branchDropdownOptions = () => {
  //   mapUploadState?.nodeBranchListState.filter(branch => {
  //     if(branch){
  //       if(branch.name?.includes("_draft")){
  //         return branch;
  //       }
  //     } else {
  //       return [];
  //     }
  //   })

  //   return mapUploadState
  // }

  return (
    <div className="container-fluid pt-5" id="map-upload-container">
      {/* <div className="col-xs-10"> */}

      <div className="row p-10">
        <div className='row'>
          <div className="col-xs-12 col-sm-3">
            <div>
              <label>Project:</label>
            </div>
            <div>
              <select
                className="form-select form-select-sm"
                aria-label=".form-select-sm example"
                value={mapUploadState.formData.nodeProject}
                onChange={handleChange}
                name="nodeProject"
              >
                <option value="0">Select a Project</option>
                {nodeProjectsList.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        
          <div className="col-xs-12 col-sm-3">
            <div>
              <label>Branch:</label>
            </div>
            <div>
              <select
                className="form-select form-select-sm"
                aria-label=".form-select-sm example"
                value={mapUploadState?.formData.nodeBranch}
                onChange={handleChange}
                name="nodeBranch"
              >
                <option value="0">Select a Branch</option>
                {mapUploadState.nodeBranchListState.map((branch) => {
                  return (
                    <option key={branch.id} value={branch.id}>
                      {branch.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className='row'></div>
        <div className="col-2 " id="download-templates-button">
          <button
            type="button"
            id="bootstrap-white-text"
            className="k-button k-primary"
            onClick={handleTemplateDownload}
            style={{color: "white !important"}}
            // Made a change here
            disabled={mapUploadState.showHideTemplateBtn}
          >
            Download Templates
          </button>
          {mapUploadState.templateHeadersInfo.map((el, index) => (
            <ExcelExport
              key={`exp-${index}`}
              data={[]}
              fileName="metadataUpload-test.xlsx"
              ref={elementsRef[index]}
            >
              {/* {el.statHeader.map((item) => <ExcelExportColumn field={item} title={ScreenLabel({ attrCode: item })} />)} */}
              {el.statHeader.map((item, idx) => (
                <ExcelExportColumn
                  key={`statheader-${idx}`}
                  field={item}
                  title={item}
                />
              ))}
              {el.groupHeader?.map((item, idx) => {
                if (item[Object.keys(item)].length === 1) {
                  return (
                    <ExcelExportColumn
                      key={`groupheader-${idx}`}
                      field={item[Object.keys(item)][0].name}
                      title={normalizeName(item[Object.keys(item)][0].name)}
                    />
                  );
                }
                return item[Object.keys(item).toString()].map(
                  (innerEl, idx) => (
                    <ExcelExportColumn
                      key={`inner-groupheader-${idx}`}
                      field={innerEl.name}
                      title={normalizeName(innerEl.name)}
                    />
                  )
                );
              })}
            </ExcelExport>
          ))}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-8 container p-10">
          <div className="p-10">
            <Uploader
              batch={false}
              multiple
              autoUpload={false}
              mediaType="resource"
              filesList={file}
              fetchFiles={fetchFilesInfo}
              uploadCallBack={handleUpload}
              // Made a change here
              disableUpload={mapUploadState.showHideTemplateBtn}
            />
          </div>
          <div className="col-2 .btn-xs p-10 text-right">
            <button
              type="button"
              id="bootstrap-white-text"
              className="k-button k-primary"
              onClick={handleUpload}
              // Made a change here
              disabled={
                mapUploadState.showHideTemplateBtn || !file || !file.length
              }
            >
              Upload Files
            </button>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4">
          <div
            style={{ color: '#1560b7', fontSize: '16px', fontWeight: '600' }}
          >
            Supported File Types
          </div>
          <div>xlsx, xlsm, xls, xltx, xltm</div>
        </div>
      </div>

      <ul>
        {mapUploadState.messages.map((message) => (
          <li key={message}>{message ? `${message.message}` : ''}</li>
        ))}
      </ul>

      <div className="row">
        <div className="col-xs-12">
          <NodeUploadGrid
            refresh={isRefresh}
            setRefresh={setRefresh}
            projectId={mapUploadState.formData.nodeProject || null}
            isGridRefreshOnUpload={mapUploadState.isGridRefreshOnUpload}
          />
        </div>
      </div>
    </div>
  );
};

export default MapUpload;

MapUpload.propTypes = {
  isAuthenticated: PropTypes.bool,
  userObj: PropTypes.object,
  programId: PropTypes.number,
  roleCode: PropTypes.number,
  assessmentProgramId: PropTypes.number,
  nodeProjectsList: PropTypes.array,
  nodeBranchesList: PropTypes.array
};
