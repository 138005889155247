/**
 * @summary Spinner.jsx
 * @file Loading icon that is shown to users when application is not finished loading
 * @returns {JSX}
 * @usedBy App.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';

export default function Spinner() {
  return (
    <div className="position-absolute top-50 start-50 translate-middle">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
