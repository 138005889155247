/**
 * @summary formHeader.js
 * @file Header component for new project page
 * @returns {JSX}
 * @usedBy newForm.jsx
 * @author Dj Ritchey
 * @since 02/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { formatDateTime } from '../../../utility/formatDateTime';
import CKEditor from 'shared/ckEditor/CKEditor';
import { updateProjectSetup } from '../../../store/ProjectSetup/ProjectSetupActions';
import { getCloneBranch } from '../../../store/branchesDetails/branchesDetailsActions';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import store from '../../../store/store';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';

const FormHeader = ({ onSave, onClone }) => {
  const projects = useSelector((state) => {
    const projectList = { ...state.projectReducer };
    delete projectList.error;
    delete projectList.selectedData;
    return Object.values(projectList).filter(Boolean)
  }, shallowEqual);
  const userObj = useSelector((state) => state.authReducer.userObj);
  const project = useSelector((state) => state.projectSetupReducer);

  const [visible, setVisible] = useState();

  const [cloneProject, setCloneProject] = useState();

  const [cloneBranch, setCloneBranch] = useState();

  const navigate = useNavigate();

  const toggleDialog = () => {
    setVisible(!visible);
  };

  // pull name from userObj in redux state
  const apName = userObj.assessmentPrograms
    ? userObj.assessmentPrograms.filter(
        (el) => el.id === userObj.currentAssessmentProgram
      )[0].abbreviatedName
    : '';

  const [allPurposes, setAllPurposes] = useState([]);

  const getProjectPurpose = async () => {
    const { data } = await axios.get('/api/projectPurposes');
    setAllPurposes(
      data.map((purpose) => ({ ...purpose, name: purpose.purpose_name }))
    );
  };

  const projectOnChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const newSelectedProject = projects.find((project) => project.id === value);
    if (newSelectedProject) {
      setCloneProject(newSelectedProject);
      store
        .dispatch(getCloneBranch(newSelectedProject.id))
        .then((data) => setCloneBranch(data));
    }
  };

  const handleClose = () => {
    navigate('/');
  };

  const handleDelete = () => {
    // TODO: Add delete on edit after VC in place
  };

  const handleSave = () => {
    onSave();
  };

  const handleClone = () => {
    onClone(cloneProject, cloneBranch);
    toggleDialog();
  };

  useEffect(() => {
    getProjectPurpose();
  }, []);
  return (
    <>
      <div className="newEditProjectHeaderContainer">
        {project.id ? 'Project Setup' : 'New Project'}
      </div>
      <div className="container-fluid locked-header">
        {/* TOP ROW */}
        <div className="row pt-2 pe-2">
          <div className="col-2">
            <label className="form-label">Assessment Program:</label>
            <label className="form-label">{apName}</label>
          </div>

          <div className="col-8">
            <label className="form-label">ID:</label>
            <label className="form-label">{project.id || ''}</label>
          </div>

          {/* TOP ROW ACTION BUTTONS */}
          <div className="col-2 pt-1 pb-1 hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={handleSave}
            >
              <i className="bi bi-save2"></i> Save
            </button>
            <button
              type="button"
              hidden={project.id < 1}
              className="btn btn-danger btn-sm text-white"
              onClick={handleDelete}
            >
              {' '}
              <i className="bi bi-trash"></i> Delete
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={handleClose}
            >
              <i className="bi bi-x"></i> Close
            </button>
          </div>
        </div>

        {/* BOTTOM ROW */}
        <div className="row row-cols-2 pb-2 pe-2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3">
          <div className="col-6 pb-1 hstack gap-2">
            <label
              className="form-label hstack gap-0"
              htmlFor="form-header-name"
            >
              Name:
              <i className="text-danger ms-1">*</i>
            </label>
            <input
              type="text"
              id="form-header-name"
              className="form-control form-control-sm"
              value={project.name}
              name="name"
              maxLength="100"
              onChange={(e) =>
                store.dispatch(
                  updateProjectSetup({
                    name: e.target.name,
                    value: e.target.value
                  })
                )
              }
            />
          </div>

          <div className="col-6 pb-1 hstack gap-2">
            <label
              className="form-label hstack gap-0"
              htmlFor="form-header-title"
            >
              Display Title:
              <i className="text-danger ms-1">*</i>
            </label>
            <input
              type="text"
              id="form-header-title"
              value={project.displayTitle}
              name="displayTitle"
              className="form-control form-control-sm"
              maxLength="100"
              onChange={(e) =>
                store.dispatch(
                  updateProjectSetup({
                    name: e.target.name,
                    value: e.target.value
                  })
                )
              }
            />
          </div>

          <div className="col pt-1 pb-1 hstack gap-2"></div>

          <div className="col pt-1 pb-1 hstack gap-2"></div>

          <div className="col pt-1 pb-1 hstack gap-2">
            {project.id && project.lastUpdated && (
              <>
                <label className="form-label text-nowrap">Last Saved:</label>
                <label className="form-label col-md-8">
                  {formatDateTime(new Date(project.lastUpdated + 'CST')) || ''}{' '}
                </label>
              </>
            )}
          </div>
        </div>

        <div className="row pb-2 pe-2">
          <div className="pb-1 gap-2">
            <label
              className="form-label hstack gap-0"
              htmlFor="form-header-desc"
            >
              Description:
              <i className="text-danger ms-1">*</i>
            </label>
            <CKEditor
              data={project.description}
              fieldName="description"
              maxWordCount={1000}
              onChange={(key, value) =>
                store.dispatch(updateProjectSetup({ name: key, value }))
              }
            />
          </div>

          <div className="pb-1 gap-2">
            <label
              className="form-label hstack gap-0"
              htmlFor="form-header-purpose"
            >
              Project Purpose:
            </label>
            <MultiSelect
              data={allPurposes}
              id="form-header-purpose"
              name="projectPurpose"
              dataItemKey="id"
              textField="name"
              module={module}
              deleteIconClass="k-icon k-i-close"
              value={project.projectPurpose}
              onChange={(e) => {
                store.dispatch(
                  updateProjectSetup({
                    name: e.target.name,
                    value: e.target.value
                  })
                );
              }}
            />
          </div>

          <div className="col-6 pt-1 pb-1 hstack gap-2"></div>

          <div className="col-6 pt-1 pb-1 hstack gap-2"></div>

          {project?.id < 1 && (
            <div className="col-6 pb-1 hstack gap-2">
              <label className="form-label" htmlFor="clone-project">
                Clone Project:{' '}
              </label>
              <select
                value={cloneProject?.id}
                name="cloneProject"
                className="form-select form-select-sm"
                onChange={projectOnChange}
                id="clone-project"
                style={{ width: '200px' }}
              >
                <option value="0">Select a Project</option>
                {projects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </select>

              <div className="col-1 pt-1 pb-1 hstack gap-2"></div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={toggleDialog}
                >
                  <i className="bi bi-box-arrow-in-right"></i> Clone
                </button>
                {visible && (
                  <Modal show={visible} backdrop="static" centered size="md">
                    <Modal.Header>
                      <Modal.Title>
                        <h2 className='text-white text-center'>
                          Please Confirm
                        </h2>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 className='text-center pt-3 pb-3'>
                        Are you sure you want to clone this project?
                      </h5>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className='col-12'>
                        <div className='row'>
                          <div className='col-6'>
                            <div className='ms-2 text-right'>
                              <button
                                className="btn btn-danger btn-sm text-white w-75"
                                onClick={() => toggleDialog(false)}
                              >
                                No
                              </button>
                            </div>
                          </div>
                          <div className='col-6'>
                            <div className='me-2 text-left'>
                              <button
                                className="btn btn-primary btn-sm text-white w-75 "
                                // disabled={branchName.length < 1}
                                onClick={() => {
                                  handleClone()
                                }}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FormHeader;

FormHeader.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired
};
