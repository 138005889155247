/**
 * @summary newConnectionModal.js
 * @file Modal component that allows user to create connections between nodes
 * @returns {JSX}
 * @usedBy NodeConnectionSettings.js
 * @author Sam Lee
 * @since 2/17/2023
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import store from '../../../store/store';
import { createConnection } from 'store/connections/ConnectionActions';
import { toast } from 'react-toastify';

import validator from 'store/validation/validator';
import newConnectionSchema from 'store/validation/newConnection.schema';
import ValidationMsg from 'store/validation/ValidationMsg';

const NewConnectionModal = ({ show, setShow, nodes, userObj }) => {
  const navigate = useNavigate();
  const [assignedNodes, setAssignedNodes] = useState([]);
  const [sourceNode, setSourceNode] = useState({});
  const [destinationNode, setDestinationNode] = useState({});
  const [connectionName, setConnectionName] = useState('');
  const [connectionType, setConnectionType] = useState('Linear');
  const [selectableNodes, setSelectableNodes] = useState([])
  const selectedNode = useSelector((state) => state.nodeReducer.selectedData[0]);

  const nodeCols = [
    {
      field: 'name',
      headerName: 'Node Name',
      checkboxSelection: false
    },
    { field: 'nodeKey', headerName: 'Node Key' },
    { field: 'description', headerName: 'Description' },
    { field: 'checkedOutBy', headerName: 'Checked Out By' }
  ];

  useEffect(() => {
    const findSelectableNodes = nodes?.filter(
      (node) => node.checkedOutBy === userObj.screenName
    );
    setSelectableNodes(findSelectableNodes)
  }, [])

  const gridRef = useRef();

  const onRowSelected = useCallback(() => {
    const selectedRow = gridRef.current.api.getSelectedRows();
    if (selectedRow) {
      setAssignedNodes(selectedRow);
    } else {
      setAssignedNodes([]);
    }
  }, []);

  // FORMATS SAVED CONNECTION NAME
  const formatInputString = (inputString) => {
    if(inputString !== null && inputString.length){
        if(inputString.trim().length > 0){
            return inputString.trim()
        } else {
            return null
        }         
    } else {
        return null
    }
  }

  const onSave = () => {
    const json = {
      name: formatInputString(connectionName),
      connectionType: connectionType,
      sourceNodeId: sourceNode?.id || selectedNode.id,
      destinationNodeId: destinationNode?.id || selectedNode.id,
      settings: {},
      projectId: userObj.selectedProject[0].id,
      branchId: userObj.selectedBranch[0].id
    };

    validator(newConnectionSchema, json).then((resp) => {
      if (resp) {
        store
          .dispatch(createConnection(json))
          .then(({ data: { connection } }) => {
            toast.success('Connection created');
            if (connection.id > 0) {
              navigate(`/connections/edit`);
            } else {
              toast.error('error saving connection');
            }
          })
          .catch((errors) => {
            // RETURNS ANY ERRORS FROM THE BACKENDF THAT PREVENT SAVING
            const errorMessages = errors.response.data;
            toast.error(
              <ValidationMsg
                errorsArray={errorMessages}
                message={errors.message}
              />,
              { autoClose: 5000 }
            );
          });
      }
    });
  };

  const updateRadioData = ({ target: { value, name } }) => {
    let selectedRadioNode;
    let unSelectedRadioNode;
    assignedNodes.forEach((node) => {
      if (node.id === parseInt(value)) {
        selectedRadioNode = node;
      } else {
        unSelectedRadioNode = node;
      }
    });
    if (name === 'source') {
      setSourceNode(selectedRadioNode);
      setDestinationNode(unSelectedRadioNode);
    }
    if (name === 'destination') {
      setDestinationNode(selectedRadioNode);
      setSourceNode(unSelectedRadioNode);
    }
  };

  return (
    <>
      <Modal show={show} backdrop="static" centered size="lg">
        <Modal.Header>
          <Modal.Title>Create Connection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <label
              className="col-sm-4 col-form-label"
              htmlFor={`create-connection-name`}
            >
              Connection Name:
              <i className="text-danger ms-1">*</i>
            </label>
            <div className="col">
              <input
                id="create-connection-name"
                className="form-control"
                style={{ maxWidth: '100%' }}
                type="text"
                name="connectionName"
                value={connectionName}
                onChange={(e) => {
                  setConnectionName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="form-group row">
          </div>
          <div
            className="ag-theme-alpine"
            style={{ height: 300, width: '100%', position: 'relative' }}
          >
            Available Nodes
            <i className="text-danger ms-1">*</i>
            <AgGridReact
              ref={gridRef}
              columnDefs={nodeCols}
              rowData={selectableNodes}
              rowSelection={"single"}
              onRowSelected={onRowSelected}
              selectable={true}
            />
          </div>
          <div
            className="row"
            style={{ marginTop: '20px' }}
            hidden={assignedNodes.length < 1}
          >
            <div>
              <label>
                Source Node:
                <i className="text-danger ms-1">*</i>
              </label>
              {assignedNodes?.map((node) => {
                return (
                  <>
                    <input
                      className="form-check-input-sm"
                      type="radio"
                      value={node?.id}
                      key={node?.id}
                      onChange={updateRadioData}
                      checked={node?.id === sourceNode?.id}
                      name="source"
                    />
                    <label className="form-check-label p-1">
                      {node?.nodeKey}
                    </label>
                  </>
                );
              })}
            </div>
            <div>
              <label>
                Destination Node:
                <i className="text-danger ms-1">*</i>
              </label>
              {assignedNodes?.map((node) => {
                return (
                  <>
                    <input
                      className="form-check-input-sm"
                      type="radio"
                      value={node?.id}
                      key={node?.id}
                      onChange={updateRadioData}
                      checked={node?.id === destinationNode?.id}
                      name="destination"
                    />
                    <label className="form-check-label p-1">
                      {node?.nodeKey}
                    </label>
                  </>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger btn-sm text-white"
            onClick={() => {
              setAssignedNodes([]);
              setSourceNode({});
              setDestinationNode({});
              setConnectionName('');
              setShow(false);
            }}
          >
            Close
          </button>
          <button
            className="btn btn-primary btn-sm text-white"
            onClick={() => {
              onSave();
            }}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

NewConnectionModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  nodes: PropTypes.array,
  userObj: PropTypes.object
};

export default NewConnectionModal;
