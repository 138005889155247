/**
 * @summary uploadDisplayComponent.js
 * @file File contains functions that generate API, parses, generates URL, validate, and return signed URLs from media inputs
 * @returns {JSX}
 * @usedBy MapUpload.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

/**
 * @file use to display the contents of a media component on the screen somewhere
 * @author Byron Burke
 */

import React, { memo, useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import memoizeFunction from '../../../utility/MemoizeFunction';
import AllPagesPDFViewer from '../../../utility/all-pages';
import PropTypes from 'prop-types';

/**
 * mediaDisplayUrl - Generate an API request that returns the temporary signed URL for fetching a media from S3.
 * @param {Object} { assessmendProgramId, id, attachmentId, attachmentVersion, fileName }
 * @returns {Promise} Resolves with the signed URL from the backend API, or rejects with an error from the axios.post().
 */
export const mediaDisplayUrl = memoizeFunction(
  ({
    contentType,
    assessmentProgramId,
    id,
    attachmentId,
    attachmentVersion,
    fileName
  }) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          `/api/signedgeturl/${contentType}/${assessmentProgramId}/${id}/${attachmentId}/${attachmentVersion}/${fileName}`
        )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
);

/**
 * mediaUrl - Generate a media URL.
 * @param {Object} { assessmendProgramId, id, attachmentId, attachmentVersion, fileName }
 * @returns {String} media URL.
 */
export const mediaUrl = ({
  assessmentProgramId,
  id,
  attachmentId,
  attachmentVersion,
  fileName
}) =>
  `/media/${assessmentProgramId}/${id}/${attachmentId}/${attachmentVersion}/${fileName}`;

/**
 * parseMediaUrl - Validate a media file location and separate out the things inside it.
 * @param {String} fileLocation
 * @returns { assessmentProgramId, id, attachmentId, attachmentVersion, fileName, fileType } in a format suitable for sending to mediaDisplayUrl().
 * @throws Error if the fileLocation doesn't start with "/media/", have the expected number of slash-delimited segments, or end
 *  with a filename that doesn't end in a period.
 */
export const parseMediaUrl = (fileLocation) => {
  let fileName;
  let fileType;
  let contentType;
  const [
    root,
    prefix,
    assessmentProgramId,
    id,
    attachmentId,
    attachmentVersion,
    fileNameWithType,
    ...extraSegs
  ] = fileLocation.split('/');
  if (root || prefix !== 'media' || !fileNameWithType || extraSegs.length) {
    throw new Error('Invalid media path.');
  } else {
    contentType = 'media';
    const fileNameSegs = fileNameWithType.split('.');
    if (fileNameSegs.length > 1) {
      // Apparently we can't count on our uploaded files being stored in S3 with extensions?
      fileType = fileNameSegs.pop();
      fileName = fileNameSegs.join('.');
      if (!fileName || !fileType) {
        throw new Error('Invalid media fileName.');
      }
    } else {
      fileName = fileNameWithType;
      fileType = '';
    }
  }
  return {
    contentType,
    assessmentProgramId,
    id,
    attachmentId,
    attachmentVersion,
    fileName,
    fileType
  };
};

/**
 * getMediaUrlFromPath -  Returns the signed URL of the media from media path
 * @param {String} mediaPath
 * @returns {Promise} Resolves with the signed URL from the backend API, or rejects with an error from the axios.post().
 * @throws Error if the values inside the mediapath is passed as undefined or empty
 *  with a filename with an extension.
 */
export const getMediaUrlFromPath = memoizeFunction((mediaPath, validPath) => {
  if (mediaPath !== '' && mediaPath !== undefined) {
    const splittedMediaPath = mediaPath.split('/');
    let validMediaPath = true;
    splittedMediaPath.forEach((element) => {
      if (element === 'undefined' || element === '') {
        validMediaPath = false;
      }
    });
    // Allow resources urls that have only 4/6 parts (or) when the validPath parameter is true (this will work fine for exsting logic as well).
    if (
      validMediaPath &&
      (splittedMediaPath.length === 6 ||
        splittedMediaPath.length === 4 ||
        validPath)
    ) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/signedgeturl', {
            params: {
              mediaPath
            }
          })
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
    throw new Error('Invalid path.');
  }
  return null;
});

const MediaDisplayComponent = ({
  altText,
  source,
  mediaUrl,
  mediaFormat,
  fileName,
  ...props
}) => {
  const [imgURL, setImgURL] = useState('/resources/media/NoImage.bmp');

  useEffect(() => {
    if (source && source.toLowerCase() === 'local_file_system') {
      setImgURL(mediaUrl);
    } else if (
      mediaFormat === 'Image/Graphic' ||
      mediaFormat === 'Audio' ||
      mediaFormat === 'Video' ||
      mediaFormat === 'Text'
    ) {
      // check for appropriate media format
      if (fileName) {
        mediaDisplayUrl(props)
          .then((response) => {
            setImgURL(response);
          })
          .catch((error) => {
            toast.error('Error Getting File');
          });
      } else if (mediaURL) {
        getMediaUrlFromPath(mediaURL)
          .then((response) => {
            setImgURL(response);
          })
          .catch((error) => {
            toast.error('Error Getting File');
          });
      }
    } else {
      throw new Error('Invalid media format.');
    }
  }, [source, mediaURL, mediaFormat, fileName]);

  return (
    <>
      {props.mediaFormat === 'Image/Graphic' && (
        <div className="mediaImage">
          <img alt={altText} src={imgURL} />
        </div>
      )}
      {props.mediaFormat === 'Audio' && (
        <audio controls src={imgURL} type={`audio/${props.fileType}`}>
          <track {...props} kind="captions" srcLang="en" label="English" />
          Your browser does not support audio files
        </audio>
      )}
      {props.mediaFormat === 'Video' && (
        <video
          width="350"
          height="200"
          controls
          src={imgURL}
          type={`video/${props.fileType}`}
        >
          <track {...props} kind="captions" srcLang="en" label="English" />
          Your browser does not support the video tag.
        </video>
      )}
      {props.mediaFormat === 'Text' &&
        (props.fileType === 'pdf' &&
        imgURL !== '/resources/media/NoImage.bmp' ? (
          <div
            className="all-page-container"
            style={{ maxWidth: '1400px', maxHeight: '400px' }}
          >
            <AllPagesPDFViewer pdf={imgURL} />
          </div>
        ) : (
          <object data={imgURL} width="200" height="200" aria-label={altText} />
        ))}
    </>
  );
};

export default memo(MediaDisplayComponent);

MediaDisplayComponent.propTypes = {
  altText: PropTypes.func,
  source: PropTypes.func,
  mediaUrl: PropTypes.func,
  mediaFormat: PropTypes.func,
  fileName: PropTypes.func,
  fileType: PropTypes.func
};
