/**
 * @summary MapDownload.js
 * @file Component that allows users to download their map in excel format
 * @returns {JSX}
 * @usedBy routes.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

/* eslint-disable react/jsx-key */
/* eslint-disable prefer-promise-reject-errors */
import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import { getBranchesDetails } from '../../../store/branchesDetails/branchesDetailsActions';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { camelCase, snakeCase } from 'lodash';
import store from '../../../store/store';
import { toast } from 'react-toastify';
import { normalizeName } from 'utility/normalizeName';
import Modal from 'react-bootstrap/Modal';

const MapDownload = (props) => {
  const [messages, setMessages] = useState([]);
  const [showHideExportBtn, setshowHideExportBtn] = useState(true);
  const [isProjectSelected, setIsProjectSelected] = useState(false);
  const [isBranchSelected, setIsBranchSelected] = useState(false);
  const [templateHeadersInfo, setTemplateHeaders] = useState([]);
  const [headers, setHeaders] = useState({});
  const [downloadDataInfo, setDownloadData] = useState([]);
  const [visible, setVisible] = useState();
  const [exportProject, setExportProject] = useState();
  const [exportBranch, setExportBranch] = useState();
  const [elementsRef, setElementsRef] = useState([]);

  const branches = useSelector((state) => {
    const branchesList = { ...state.branchesDetailsReducer.activeBranches };
    delete branchesList.error;
    delete branchesList.selectedData;
    return Object.values(branchesList).filter(Boolean)
  }, shallowEqual);

  const projects = useSelector((state) => {
    const projectList = { ...state.projectReducer };
    delete projectList.error;
    delete projectList.selectedData;
    return Object.values(projectList).filter(Boolean)
  }, shallowEqual);
  const userObj = useSelector((state) => state.authReducer.userObj);


  const toggleDialog = () => {
    setVisible(!visible);
  };

  const projectOnChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const newSelectedProject = projects.find((project) => project.id === value);
    if (newSelectedProject) {
      setExportProject(newSelectedProject);
      store
        .dispatch(getBranchesDetails(newSelectedProject.id))
        .then((data) => setExportBranch(data));
      return setIsProjectSelected(true), setIsBranchSelected(false);
    } else {
      return setIsProjectSelected(false);
    }
  };

  const branchOnChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const newSelectedBranch = branches.find((branch) => branch.id === value);
    if (newSelectedBranch) {
      setExportBranch(newSelectedBranch);
      getTemplateHeaders(newSelectedBranch);
      return setIsBranchSelected(true);
    } else {
      return setIsBranchSelected(false);
    }
  };

  useEffect(() => {
    if (isProjectSelected && isBranchSelected) {
      setshowHideExportBtn(false);
    } else {
      setshowHideExportBtn(true);
    }
  }, [isProjectSelected, isBranchSelected]);

  ///////////////////////////////////////////////////////////////////////
  // This method is used to fetch file headers for a selected project //
  /////////////////////////////////////////////////////////////////////
  const getTemplateHeaders = async (newSelectedBranch) => {
    try {
      const { data } = await axios.get(
        `/api/${exportProject?.id}/branch/${newSelectedBranch?.id}/getUploadHeaders`
      );
      const mapData = await axios.get(
        `/api/${userObj.currentAssessmentProgram}/projects/${exportProject?.id}/branch/${newSelectedBranch?.id}/downloadMap`
      );
      const HeadersArr = [];
      HeadersArr[0] = data.nodes;
      HeadersArr[1] = data.connections;
      HeadersArr[2] = data.neighborhoods;
      HeadersArr[3] = data.linkageLevels;

      const mappedElementsRef = await HeadersArr?.map(() => React.createRef());
      mapData.data.data.nodes = mapData.data.data.nodeData.nodes;
      mapData.data.data.connections =
        mapData.data.data.connectionData.connections;
      mapData.data.data.neighborhoods =
        mapData.data.data.neighborhoodData.neighborhoods;
      // Replacing connection keyname 'sourceNodeKey' with 'originNodeKey based on condition //
      if (mapData.data.data.connections !== undefined) {
        mapData.data.data.connections.forEach((connection) => {
          connection['originNodeKey'] = connection['sourceNodeKey'];
          delete connection['sourceNodeKey'];
        });
      }
      await setElementsRef(mappedElementsRef);
      await setTemplateHeaders(HeadersArr);
      setDownloadData(mapData.data);
      setHeaders(data);
    } catch (error) {
      console.log(error);
      toast.error('failed to get data from this map.');
    }
  };

  //////////////////////////////////////////////////////////////////
  // Each cell will need a value (what we see in the excel file) //
  // index will tell it which cell it will be populated to      //
  // take columns, create a single array, we                   //
  // will use this to apply the index value to each cell.     //
  /////////////////////////////////////////////////////////////
  const createCells = (data, columns) => {
    if (!data) {
      return [];
    }
    // take template headers and flatten out to string array, to grab values off object
    const flatCols = [];
    if (columns.groupHeader) {
      columns.groupHeader.forEach((setting) => {
        Object.values(setting)
          .flat()
          .forEach((attr) => {
            flatCols.push(attr.name);
          });
      });
    }

    const newCells = data?.map((object) => {
      const cellData = columns.statHeader.map((col, index) => {
        if (col === 'Action') {
          return {
            value: 'insert',
            index: 0
          };
        }
        const camelizedName = camelCase(col);
        return {
          value: object[camelizedName],
          index
        };
      });
      // iterate through flatCols to grab attributes to assign to a cell //
      flatCols.forEach((col) => {
        if (object[snakeCase(col)]) {
          cellData.push({
            value: object[snakeCase(col)],
            index: cellData.length
          });
        } else {
          cellData.push({
            value: '',
            index: cellData.length
          });
        }
      });
      return {
        cells: cellData
      };
    });
    return newCells;
  };

  const saveExportMapData = (parentTab, childrenTabArray) => {
    const dataInfoKeys = [
      'nodes',
      'connections',
      'neighborhoods',
      'linkageLevels'
    ];

    const workbookSheet1 = parentTab.workbookOptions();
    // generate sheets //
    const workSheets = childrenTabArray.map((tab) => {
      const workbookItem = tab.workbookOptions();
      return workbookItem.sheets[0];
    });
    workbookSheet1.sheets = [...workbookSheet1.sheets, ...workSheets];

    // for each sheet, we need to append objects from project to rows property //
    workbookSheet1.sheets.map((sheet, idx) => {
      sheet.name = dataInfoKeys[idx];
      const newRows = createCells(
        downloadDataInfo.data[dataInfoKeys[idx]],
        templateHeadersInfo[idx]
      );
      sheet.rows = [...sheet.rows, ...newRows];
    });
    parentTab.save(workbookSheet1);
  };

  // Export Map Button Handler
  const handleExportMap = () => {
    const _exporter0 = elementsRef[0].current;
    const _exporter1 = elementsRef[1].current;
    const _exporter2 = elementsRef[2].current;
    const _exporter3 = elementsRef[3].current;
    saveExportMapData(_exporter0, [_exporter1, _exporter2, _exporter3]);
    toggleDialog();
  };

  let date = new Date()
  let formattedDate = date.toISOString().split('T')[0].replace(/-/g, '_');

  return (
    <div className="container-fluid pt-5">
      <div className='col-12'>
        <div className="row">
          <div className='col-3 ms-1'>
            <label className="form-label" htmlFor="exportProject">
              Project:
            </label>
          
            <select
              id="exportProject"
              value={exportProject?.id}
              name="exportProject"
              className="form-select form-select-sm"
              onChange={projectOnChange}
            >
              <option value="0">Select a Project</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>

          <div className='col-3'>
            <label className="form-label" htmlFor="exportBranch">
              Branch:
            </label>
            <select
              id="exportBranch"
              value={exportBranch?.id}
              name="exportBranch"
              className="form-select form-select-sm"
              onChange={branchOnChange}
            >
              <option value="0">Select a Branch</option>
              {branches.map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              ))}
            </select>
          </div>

        </div>
        <div className='row'>
          <div className='col-2 ms-1'>
            <button
              type="button"
              id="bootstrap-white-text"
              className="k-button k-primary"
              onClick={toggleDialog}
              disabled={showHideExportBtn}
            >
              Export Map Data
            </button>
            {visible && (
               <Modal show={visible} backdrop="static" centered size="md">
                  <Modal.Header>
                    <Modal.Title>
                      <h2 className='text-white text-center'>
                        Please Confirm
                      </h2>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h5 className='text-center pt-3 pb-3'>
                      Are you sure you want to clone this project?
                    </h5>
                  </Modal.Body>
                  <Modal.Footer>
                      <div className='col-12'>
                        <div className='row'>
                          <div className='col-6'>
                            <div className='ms-2 text-right'>
                              <button
                                className="btn btn-danger btn-sm text-white w-75"
                                onClick={() => toggleDialog(false)}
                              >
                                No
                              </button>
                            </div>
                          </div>
                          <div className='col-6'>
                            <div className='me-2 text-left'>
                              <button
                                className="btn btn-primary btn-sm text-white w-75 "
                                // disabled={branchName.length < 1}
                                onClick={handleExportMap}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Footer>
               </Modal>  
            )}
            {templateHeadersInfo.map((el, index) => (
              <ExcelExport
                key={`exp-${index}`}
                data={[]}
                fileName={
                  "MapDownload_" + exportProject?.name + "_" + exportBranch?.name + "_" + formattedDate + ".xlsx"
                }
                ref={elementsRef[index]}
              >
                {el.statHeader.map((item, idx) => (
                  <ExcelExportColumn
                    key={`statheader-${idx}`}
                    field={item}
                    title={item}
                  />
                ))}
                {el.groupHeader?.map((item, idx) => {
                  if (item[Object.keys(item)].length === 1) {
                    return (
                      <ExcelExportColumn
                        key={`groupheader-${idx}`}
                        field={item[Object.keys(item)][0].name}
                        title={normalizeName(item[Object.keys(item)][0].name)}
                      />
                    );
                  }
                  return item[Object.keys(item).toString()].map((innerEl, idx) => (
                    <ExcelExportColumn
                      key={`inner-groupheader-${idx}`}
                      field={innerEl.name}
                      title={normalizeName(innerEl.name)}
                    />
                  ));
                })}
              </ExcelExport>
            ))}
          </div>
        </div>
      </div>
      <ul>
        {messages.map((message) => (
          <li key={message}>{message ? `${message.message}` : ''}</li>
        ))}
      </ul>
    </div>
  );
};

export default MapDownload;

MapDownload.propTypes = {
  getAllProjectsList: PropTypes.func,
  getBranchesByProjectID: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  userObj: PropTypes.object,
  programId: PropTypes.number,
  roleCode: PropTypes.number,
  assessmentProgramId: PropTypes.number,
  nodeProjectsList: PropTypes.array,
  nodeBranchesList: PropTypes.array
};
