/**
 * @summary InspectorTool.js
 * @file Modal for Containing Injuey Data for Selected Element(s). Opened through CanvasInterface.js After at least one Element is Selected
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../canvasPage.scss';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

const DeleteNeighborhoodCanvasModal = ({
    selectedNeighborhoods,
    handleCrud,
    handleDeleteModalVisibility,
    renderMapData
  }) => {

    const userObj = useSelector((state) => state.authReducer.userObj);
    const selectedNeighborhoodsFound = renderMapData.nodeDataArray.filter(node1 => selectedNeighborhoods.find(node2 => node1.nodeKey === node2.nodeKey))
    const neighborhoodIds = selectedNeighborhoods.map(neighborhood => neighborhood.id)

    const checkBranchIds = (neighborhoods) => {
        let neighborhoodStatus = neighborhoods.map(neighborhood => {
            if(neighborhood.branchId === userObj.selectedBranch[0].id){
                return { checkedOut: true, neighborhood: neighborhood }
            } else {
                return { checkedOut: false, neighborhood: neighborhood }
            }
        })
        if(neighborhoodStatus.find(neighborhood => neighborhood.checkedOut === false)){
            return {checkedOut: false, neighborhood: neighborhoodStatus.find(neighborhood => neighborhood.checkedOut === false)?.neighborhood}
        } else {
            return {checkedOut: true, neighborhood: null}
        }
    }
    
    const handleDeleteNeighborhood = () => {
        let branchCheck = checkBranchIds(selectedNeighborhoodsFound)
        if(branchCheck.checkedOut){
        let saveJson = {
            action: 'delete',
            elementType: 'neighborhood',
            source: 'Canvas Deleted',
            project: userObj.selectedProject[0],
            branch: userObj.selectedBranch[0],
            data: neighborhoodIds
        }
        handleCrud(saveJson)
        } else {
            return toast.error(branchCheck.neighborhood.name + " is not checked out.")
        }
    }

    return (
        <>
            <Modal.Header>
                <Modal.Title>
                    <div className='modal-header-text'>
                        Please Confirm
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5 className='text-center pt-3 pb-3'>
                    Are you sure you want to delete this Neighborhood?
                </h5>
            </Modal.Body>
            <Modal.Footer>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6 text-center'>
                            <button 
                                className="btn btn-primary btn-sm text-white w-50 "
                                onClick={handleDeleteModalVisibility}
                            >
                                No
                            </button>
                        </div>
                        <div className='col-6 text-center'>
                            <button 
                                className="btn btn-danger btn-sm text-white w-50" 
                                onClick={handleDeleteNeighborhood}
                            >
                                Yes
                            </button>
                        </div>             
                    </div>
                </div>
            </Modal.Footer>
        </>
    );
} 

DeleteNeighborhoodCanvasModal.propTypes = {
  selectedNeighborhoods: PropTypes.array,
  handleCrud: PropTypes.func,
  handleDeleteModalVisibility: PropTypes.func,
  renderMapData: PropTypes.obj
};

export default DeleteNeighborhoodCanvasModal;