/**
 * @summary Redux store for accessing redux store
 * @file store.js
 * @returns {ReduxStore}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 07/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import rootReducer from './RootReducer';
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
  withReduxStateSync
} from 'redux-state-sync';

const config = {};

const store = createStore(
  withReduxStateSync(rootReducer),
  composeWithDevTools(
    applyMiddleware(logger, thunk, createStateSyncMiddleware(config))
  )
);
initStateWithPrevTab(store);

export default store;
