/**
 * @summary ArrayCell.js
 * @file Customized component to display cell values in KendoGrid from an array of dataItems
 * @returns {JSX}
 * @usedBy KendoGridBase.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import PropTypes from 'prop-types';

const ArrayCell = ({ dataItem, field }) => (
  <td>{dataItem === null ? '' : dataItem[field].join(', ')}</td>
);
ArrayCell.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.any
};

export default ArrayCell;
