/**
 * @summary inactiveSettings.js
 * @file Grid component that provides a layout for inactive branches
 * @returns {JSX}
 * @usedBy BranchListGrid.js, DjBranchListGrid.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import KendoGridBase from '../../../shared/ui/kendoGridBase/KendoGridBase';
import { getBranchesInactiveDetails } from '../../../store/branchesDetails/branchesDetailsActions';
import axios from 'axios';
import PropTypes from 'prop-types';

const InactiveSettings = ({ gridState, setGridState, updateGridState }) => {
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 100 });
  const [, setLoading] = useState(false);
  const [, setSkipProcessing] = useState(0);
  const [selectedRowsStateArray, setSelectedStateArray] = useState([]);

  const messageOptions = {
    messsageType: {
      success: 'Success'
    },
    messages: {
      successOnActivate: 'Branch Activated',
      underConstruction: 'This Function is Under Construction'
    }
  };

  const selectedProject = useSelector(
    (state) => state.authReducer.userObj.selectedProject[0]
  );

  const handlePageChange = (e) => {
    e.page.skip = isNaN(e.page.skip) ? 1 : e.page.skip;
    setPage(e.page);
  };

  const handleGridFilterChange = (colFilter) => {
    setFilter(colFilter || {});
  };

  const handleGridSortChange = (obj) => {
    setSort(obj);
  };

  const selectionChange = (e) => {
    const data = gridState.branchcollections.data.map((collection) => {
      // Adds Selected Data to an Array to Control Button Visibility
      if (collection === e.dataItem) {
        if (selectedRowsStateArray.some((item) => item === collection)) {
          setSelectedStateArray([]);
          collection.selected = false;
        } else {
          setSelectedStateArray([collection]);
          collection.selected = true;
        }
      } else {
        collection.selected = false;
      }
      return collection;
    });

    const gridObjs = { ...gridState.branchcollections };
    gridObjs.data = data;
    setGridState({ ...gridState, branchcollections: gridObjs });
  };

  const actionHandler = (action, selectedRow) => {
    if (action === 'activate') {
      if (selectedRow.branchStatus === 'draft') {
        axios
          .put(
            `/api/${selectedProject.id}/branchDetails/${selectedRow.id}/activateBranch`
          )
          .then((res) => {
            if (res.status === 201) {
              if (res.data.message_type != 'success') {
                toast.error(
                  'An unexpected error occurred deactivating branch.'
                );
              } else {
                updateGridState();
                setSelectedStateArray([]);
                toast.success(messageOptions.messages.successOnActivate);
              }
            } else {
              toast.error('An unexpected error occurred deactivating branch.');
            }
          })
          .catch((err) => {
            toast.error('An unexpected error occurred deactivating branch.');
          });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    setLoading(false);
    setSkipProcessing(page.skip);
  }, [filter, sort, page, selectedProject]); // eslint-disable-line

  return (
    <>
      <div
        style={{
          height: '61vh',
          margin: '16px',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        <KendoGridBase
          data={gridState.branchcollections.data || []}
          gridColumns={gridState.gridDynamicColumns}
          setGridFilters={handleGridFilterChange}
          setGridSort={handleGridSortChange}
          onSelectionChange={selectionChange}
          onRowSingleClick={selectionChange}
          onPageChange={handlePageChange}
          sorter={sort}
          rowHeight={40}
          skip={page.skip}
          take={page.take}
          total={
            gridState.branchcollections ? gridState.branchcollections.total : 0
          }
          pageSize={100}
          selectable="selected"
          disableMultiSelect={true}
          pageable
          sortable
        />
        <div className="container-fluid p-0 d-flex justify-content-between mt-2">
          <div>
            {/* <button
              className="btn btn-primary btn-sm me-2"
              type="button"
              onClick={() => {
                actionHandler(
                  'activate',
                  selectedRowsStateArray[selectedRowsStateArray.length - 1]
                );
              }}
              disabled={
                !selectedRowsStateArray.length ||
                selectedRowsStateArray.length > 1 ||
                selectedRowsStateArray[selectedRowsStateArray.length - 1]
                  .branchStatus != 'draft'
              }
            >
              <i className="bi bi-plus me-2" />
              Activate Branch
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

InactiveSettings.propTypes = {
  getBranchesInactiveDetails: PropTypes.func,
  setGridState: PropTypes.func,
  gridState: PropTypes.object,
  updateGridState: PropTypes.func
};

export default connect(null, { getBranchesInactiveDetails })(InactiveSettings);
