/**
 * @summary ConnectionsTypes.jsx
 * @file Connection type strings
 * @returns {String}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const CREATE_CONNECTION = 'CREATE_CONNECTION';

export const GET_CONNECTIONS = 'GET_CONNECTIONS';

export const GET_CONNECTION = 'GET_CONNECTION';

export const DELETE_CONNECTION = 'DELETE_CONNECTION';

export const GRID_DATA_ACTIONS_SELECTIONS_CONNECTIONS_FULLFILLED =
  'GRID_DATA_ACTIONS_SELECTIONS_CONNECTIONS_FULLFILLED';

export const CLEAR_CONNECTIONS = 'CLEAR_CONNECTIONS';

export const EDIT_CONNECTIONS = 'EDIT_CONNECTIONS';

export const SET_NEW_CONNECTION = 'SET_NEW_CONNECTION';

export const EDIT_CONNECTION = 'EDIT_CONNECTION';

export const DELETE_MULTIPLE_CONNECTIONS = 'DELETE_MULTIPLE_CONNECTIONS';

export const REPLACE_TABLEVIEW_CONNECTIONS = 'REPLACE_TABLEVIEW_CONNECTIONS';

export const CLEAR_SELECTED_CONNECTIONS = 'CLEAR_SELECTED_CONNECTIONS';
