/**
 * @summary axiosMapData.js
 * @file Util: Runs Get Functions to Return Map Data for CanvasPage.js
 * @returns {JSX}
 * @usedBy CanvasWrapper.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios'
import { useSelector } from 'react-redux';

export const axiosCanvasMapData = async (profileSelectionObj) => {

    const {userObj, selectedProject, selectedBranch, publishedBranch} = profileSelectionObj;
    // ? in front of Dots Are to Stop This from Firing on Fresh Page Load When Data Isn't Available
    const nodes = await axios.get(`/api/projects/${selectedProject?.id}/branch/${selectedBranch?.id}/nodes`)
    const connections = await axios.get(`/api/projects/${selectedProject?.id}/branch/${selectedBranch?.id}/connections`)
    const neighborhoods = await axios.get(`/api/projects/${selectedProject?.id}/branch/${selectedBranch.id}/neighborhoods`)
    
    const publishedNodes = await axios.get(`/api/projects/${selectedProject?.id}/branch/${publishedBranch?.id}/nodes`)
    const publishedConnections = await axios.get(`/api/projects/${selectedProject?.id}/branch/${publishedBranch?.id}/connections`)
    const publishedNeighborhoods = await axios.get(`/api/projects/${selectedProject?.id}/branch/${publishedBranch?.id}/neighborhoods`)

    let errorMessages = []
    if(nodes.data.length === 0){
      errorMessages.push("There are no nodes in this branch.")
    } else if(connections.data.length === 0){
      errorMessages.push("None of the nodes in this branch are connected. Try checking out a branch with connected nodes.")
    }
    
    if(selectedBranch.name !== "published"){
      const deletedNodes = await axios.get(`/api/projects/${selectedProject?.id}/branch/${selectedBranch?.id}/deletedNodes`)
      const deletedConnections = await axios.get(`/api/projects/${selectedProject?.id}/branch/${selectedBranch?.id}/deletedConnections`)
      const deletedNeighborhoods = await axios.get(`/api/projects/${selectedProject?.id}/branch/${selectedBranch?.id}/deletedNeighborhoods`)
      
      //////////////////////////////////////////////////////////////////////////
      // IF SELECTION IS THE PUBLISHED BRANCH, RETURN THE FULL AXIOS RESPONSE //
      //////////////////////////////////////////////////////////////////////////
      // If No Nodes or Connections, Throw an Error Because the Report Will Have No Data
      
      // FINAL RESPONSE: PUBLISHED BRANCH //
      const axiosResponse = {
        userObj: userObj,
        nodes: nodes.data,
        connections: connections.data,
        neighborhoods: neighborhoods.data,
        publishedNodes: publishedNodes.data,
        publishedConnections: publishedConnections.data,
        publishedNeighborhoods: publishedNeighborhoods.data,
        deletedNodes: deletedNodes.data,
        deletedConnections: deletedConnections.data,
        deletedNeighborhoods: deletedNeighborhoods.data,
        errorMessages,
      }
      return axiosResponse

    } else {
      const axiosResponse = {
        userObj: userObj,
        nodes: nodes.data,
        connections: connections.data,
        neighborhoods: neighborhoods.data,
        publishedNodes: publishedNodes.data,
        publishedConnections: publishedConnections.data,
        publishedNeighborhoods: publishedNeighborhoods.data,
        deletedNodes: [],
        deletedConnections: [],
        deletedNeighborhoods: [],
        errorMessages,
      }
      return axiosResponse
    }
}