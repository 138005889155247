/**
 * @summary inspectorUtils.js
 * @file Util: Runs Get Functions to Return Node Data for CanvasInspectorTool
 * @returns {JSX}
 * @usedBy CanvasInspectorTool.js
 * @author Andy Greenhaw
 * @since 01/11/2021
 * @lastUpdated 01/11/2021
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */
import axios from 'axios'

const inspectorUtils = async (selectedNode, canvasMapData) => {

    const {selectedProject, selectedBranch} = canvasMapData;
  
    let element;
    if(selectedNode.category !== "Super"){
        element = await axios.get(`/api/projects/${selectedProject.id}/branch/${selectedBranch.id}/node/${selectedNode.id}`)
    } else {
        element = await axios.get(`/api/projects/${selectedProject.id}/branch/${selectedBranch.id}/neighborhood/${selectedNode.id}`)
    }

    return element.data
}

export default inspectorUtils;