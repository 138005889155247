/* eslint-disable func-names */
/**
 * @summary Map Upload Actions
 * @file MapUploadActions.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios';
import { GET_BRANCHESLIST, GET_UPLOADSTATSLIST } from './MapUploadTypes';
import { getProjects } from '../projects/ProjectActions';

export function getBranchesById(branchesList) {
  return {
    type: GET_BRANCHESLIST,
    branchesList
  };
}
export function dispatchUploadStats(uploadStatsList) {
  return {
    type: GET_UPLOADSTATSLIST,
    uploadStatsList
  };
}
export function getAllProjects(assessmentProgramId, params) {
  return async function (dispatch) {
    dispatch(getProjects(assessmentProgramId, params));
  };
}
export function getBranchesByProjectId(projectId) {
  return async function (dispatch) {
    const { data } = await axios.get(`/api/${projectId}/branchDetails`);
    dispatch(getBranchesById(data));
  };
}
export const getUploadStats = (projectId) => async (dispatch) => {
  const { data } = await axios.get(`/api/${projectId}/uploadStats`);
  dispatch(dispatchUploadStats(data));
};
