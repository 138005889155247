/**
 * @summary ChangeLogReturn.js
 * @file Function displays all changes made to a node in the form of time stamps
 * @returns {JSX}
 * @usedBy GenerateReport.jsx
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, {useRef} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import {
  ListView,
} from "@progress/kendo-react-listview";
import { PDFExport } from "@progress/kendo-react-pdf";

// LIST COMPONENTS
import ChangeLogHeaderRender from './ChangeLogHeaderRender'
import ChangeLogItemRender from './ChangeLogItemRender'

///////////////////////
// CHANGE LOG RETURN //
///////////////////////
// This function displays all the changes made to a node in the form of time stamps, when they were updated.
// When a Change is selected, this function sets state for the BEFORE and AFTER verisons of the node - "selectedLeft" and "selectedRight"
// When a Change is selected, it also passes these two arrays (formatted from objects into arrays) into the modal where VisualDiff formats the differences.
const ChangeLogReturn = ({ reportData }) => {

  const shortFormStartDate = { year: 'numeric', month: 'numeric', day: 'numeric'};
  const startDate = reportData.startDate.toLocaleString('en-US', shortFormStartDate);

  const shortFormEndDate = { year: 'numeric', month: 'numeric', day: 'numeric'};
  const endDate = reportData.endDate.toLocaleString('en-US', shortFormEndDate);

  const pdfRef = useRef(null);
  
  const exportReport = () => {
    if (pdfRef.current) {
      pdfRef.current.save();
    }
  }
  ////////////
  // RETURN //
  ////////////
  return (
    <PDFExport
      ref={pdfRef}
      paperSize="auto"
      margin={40}
      fileName={`Change Log Report for ${new Date().getFullYear()}`}
    >
        {/* //////////////////////////////////// */}
        {/* LIST OF ALL CHANGES WITH TIME STAMPS */}
        {/* //////////////////////////////////// */}
        <Row>
          <div className='generated-report-header'>
            Change Log History Report - {reportData.changeLogs[0].rightNode.nodeKey} 
          </div>
          <div className='generated-report-subhead'>
            {startDate + " - " + endDate} 
          </div>
          <div className='generated-report-button-container'>
              {
                <button
                  type="button"
                  className="download-report-button"
                  onClick={exportReport}
                  style={{fontSize: 18}}
                  // disabled={!createReport}
                >
                  Download Report
                </button>
              }
          </div>
        </Row>
        <Row>
          <Col>
            {/* This Components Above Are Passed into List View to Format a List of Changes */}
            {reportData.changeLogs.map(pairObj => {
              return(
                <>
                  <Row key={pairObj.timeStamp}>
                      <div
                        // className="row k-listview-item row p-2 border-bottom align-middle"
                        style={{marginTop: 20, marginBottom: 20, marginLeft: 5, backgroundColor: '#1560b7'}}
                      >
                        <Col 
                            style={{
                              fontSize: 24,
                              fontWeight: 700,
                              color: "white",
                              marginTop: '10px',
                              marginBottom: '10px',
                              marginLeft: 25,
                            }}
                            // className="text-uppercase"
                        >
                            {pairObj.longFormDate}
                        </Col>
                      </div>                    
                  </Row>
                  <Row>
                    <Col>
                      <div className='reporting-results-container'>
                        <ListView 
                          data={pairObj.changes}
                          item={ChangeLogItemRender}
                          header={ChangeLogHeaderRender}
                        />
                      </div>
                    </Col>
                  </Row>
                  
              </>
              )
            })}
          </Col>
        </Row>
      </PDFExport>
  )
}

export default ChangeLogReturn;

ChangeLogReturn.propTypes = {
  reportData: PropTypes.array,
  dataItem: PropTypes.object,
  exportReport: PropTypes.func
}
