/**
 * @summary DraggableCell.js
 * @file Allows column cells to be dragged for re-order purposes
 * @returns {JSX}
 * @usedBy KendoGridBase.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import { GridContext } from '../KendoGridBase';
import PropTypes from 'prop-types';

const DraggableCell = ({ dataItem }) => {
  const currentContext = React.useContext(GridContext);

  return (
    <td
      onDragOver={(e) => {
        currentContext.reorder(dataItem);
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
      }}
    >
      <span
        className="k-icon k-i-reorder"
        draggable="true"
        style={{ cursor: 'move' }}
        onDragStart={(e) => {
          currentContext.dragStart(dataItem);
          e.dataTransfer.setData('dragging', '');
        }}
      />
    </td>
  );
};

DraggableCell.propTypes = {
  dataItem: PropTypes.object
};

export default DraggableCell;
