/**
 * @summary ViewCollectionPage.js
 * @file Parent component for the ViewCollectionGrid.js
 * @returns {JSX}
 * @usedBy routes.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import ViewCollectionGrid from './ViewCollectionGrid';

const ViewCollectionPage = (props) => {
  const [refresh, setRefresh] = useState(false);
  return (
    <div>
      <ViewCollectionGrid refresh={refresh} setRefresh={setRefresh} />
    </div>
  );
};

export default ViewCollectionPage;
