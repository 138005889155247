/**
 * @summary layout.jsx
 * @file The parent component that provides a layout for all the pages in the app
 * @returns {JSX}
 * @usedBy App.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import Header from '../shared/ui/Header';
import NavBar from '../shared/ui/navigation/NavBar';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

export default function Layout({ children, user }) {
  const { pathname } = useLocation();

  return (
    <>
      {user.id && (
        <>
          <Header>
            <NavBar />
          </Header>
          <div className={`${pathname === '/' ? 'd-none' : 'd-block'}`}></div>
        </>
      )}

      <div
        className={`container-fluid ${
          pathname.includes('login') ? "" : "page-container"
        }`}
      >
        {children}
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.object,
  user: PropTypes.object
};
