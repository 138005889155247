/**
 * @summary Theme Actions
 * @file ThemeActions.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios';
import { GET_THEMES, CLEAR_THEMES } from './ThemeTypes';

export function dispatchGetThemes(themesList) {
  return {
    type: GET_THEMES,
    themesList
  };
}

export const getThemes = () => async (dispatch) => {
  const { data } = await axios.get('/api/projectThemes');
  dispatch(dispatchGetThemes(data));
};

export const clearAllThemes = () => ({
  type: CLEAR_THEMES
});
