/**
 * @summary CanvasTutorial.js
 * @file Modal containing turorial instructions on how to 
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 8/5/2024
 * @lastUpdated 8/5/2024
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import '../canvasPage.scss'
const CanvasTutorial = ({
    show, 
    setShow
}) => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return(
        <Modal show={show} backdrop="static" centered size="md">
        <Modal.Header className='inspector-header'>
            <div className="canvas-tutorial-header">
                Starting a New Map
            </div> 
        <div
            className="bi bi-x submenu-close-x"
            onClick={() => {
                setShow(false)
            }}
        >
        </div>
        </Modal.Header>
        <Modal.Body>

            <div className="col-12">
                    <div className='row instructional-blue' style={{marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0}}>
                        <div className='tutorial-instruction-header'>
                            <h5 className={"text-primary fw-bold text-center pb-2"}>Your Map Needs Nodes to Render</h5>
                            <p className={"text-secondary text-center"}>Open the Data Editor menu to start creating elements, or upload elements in mass on the Map Upload page.</p> 
                            <p className={"text-secondary text-center"}>The Data Editor is opened using the top-left button in the central set with the wrench icon. The Map Upload page can be accessed from under the Projects tab.</p> 
                        </div>
                    </div>

            </div>
        </Modal.Body>
        <Modal.Footer>
        <div className='container'>
            <div className='row'>
                <div className='text-center'>
                    <button
                        className="btn btn-danger btn-sm text-white bootstrap-footer-button-stylization"
                        onClick={() => {
                            setShow(false)
                        }}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
        </Modal.Footer>
    </Modal>
    )
}

CanvasTutorial.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
  };
  
  export default CanvasTutorial;