/**
 * @summary all-pages.js
 * @file Allows use of rendering PDF
 * @returns {JSX}
 * @usedBy uploadDisplayComponent.js
 * @author Andrew Popp
 * @since 4/01/2022
 * @lastUpdated 04/2022
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function AllPages(props) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPagesLoaded }) {
    setNumPages(numPagesLoaded);
  }

  // eslint-disable-next-line react/prop-types
  const { pdf } = props;

  return (
    <Document
      file={pdf}
      options={{ workerSrc: '/pdf.worker.js' }}
      // eslint-disable-next-line
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
  );
}
