/**
 * @summary RadiusAndDiameterReturn.js
 * @file Component that provides a layout for the results after the report is generated
 * @returns {JSX}
 * @usedBy GenerateReport.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, {useRef} from 'react'
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import {
  ListView,
} from "@progress/kendo-react-listview";
import { RadiusAndDiameterHeaderRender } from './RadiusAndDiameterHeaderRender'
import { RadiusAndDiameterItemRender }  from './RadiusAndDiameterItemRender'
import { PDFExport } from "@progress/kendo-react-pdf";

const RadiusAndDiameter = ({ reportData }) => {
  const pdfRef = useRef(null);
  
  const exportReport = () => {
    if (pdfRef.current) {
      pdfRef.current.save();
    }
  }
  const MyHeader = () => {
    return(
        <div
        className="row k-listview-item p-2 border-bottom align-middle"
        style={{
            marginTop: 0,
            marginBottom: 0,
        }}
        >
          <div className="col-2" style={{padding: 0}}>
              <div
                  style={{
                      fontSize: 24,
                      fontWeight: 700,
                      color: "#1560b7",
                      marginLeft: 25,
                      paddingTop: 10,
                      paddingBottom: 10
                  }}
                  className="text-camelcase"
              >
                Source Node
              </div>
          </div>
          <div className="col-2" style={{padding: 0}}>
              <div
                  style={{
                      fontSize: 24,
                      fontWeight: 700,
                      color: "#1560b7",
                      marginLeft: 25,
                      paddingTop: 10,
                      paddingBottom: 10
                  }}
                  className="text-camelcase"
              >
                Radius
              </div>
          </div>
          <div className="col" style={{padding: 0}}>
              <div
                  style={{
                      fontSize: 24,
                      fontWeight: 700,
                      color: "#1560b7",
                      marginLeft: 25,
                      paddingTop: 10,
                      paddingBottom: 10
                  }}
                  className="text-camelcase"
              >
                {reportData.nodeDataArray.length + " Nodes within Radius"}
              </div>
          </div>
          
                
        </div>
    )
  }
  const MyItemRender = ({ dataItem }) => {
    return (
      <div
        className="k-listview-item row p-2 border-bottom align-middle"
        style={{
          margin: 0,
        }}
      >
        <div className="col-2">
          <h2
            style={{
              fontSize: 18,
              color: "black",
              marginBottom: 0,
              marginLeft: 25,
              paddingTop: 10,
              paddingBottom: 10
            }}
          >
            {dataItem.selectedNodeKey}
          </h2>
        </div>
        <div className="col-2">
          <h2
            style={{
              fontSize: 18,
              color: "black",
              marginBottom: 0,
              marginLeft: 25,
              paddingTop: 10,
              paddingBottom: 10
            }}
          >
            {dataItem.radius}
          </h2>
        </div>
        <div className="col">
          <h2
            style={{
              fontSize: 18,
              color: "black",
              marginBottom: 0,
              marginLeft: 25,
              paddingTop: 10,
              paddingBottom: 10
            }}
          >
            <ul>
            {dataItem.nodeDataArray.map(node => {
              return(
                <li key={node.id}>
                  {node.nodeKey}
                </li>
              )
            })}
            </ul>
          </h2>
        </div>
        
      </div>
    );
  };

  return (
    <PDFExport
      ref={pdfRef}
      paperSize="auto"
      margin={40}
      fileName={`Radius and Diameter Report for ${new Date().getFullYear()}`}
    >
      <Row >
        <div className='generated-report-header' >
          Radius Report
        </div>
        <div className='generated-report-subhead'>
            {reportData.nodeDataArray.length + " Nodes Found within a Radius of " + reportData.radius + " Connections from " + reportData.selectedNodeKey}
        </div>
        <div className='generated-report-button-container'>
            {
              <button
                type="button"
                className="download-report-button"
                style={{fontSize: 18}}
                onClick={exportReport}
              >
                Download Report
              </button>
            }
        </div>
      </Row>
      <Row>
        <ListView 
          data={[reportData]}
          item={RadiusAndDiameterItemRender}
          header={RadiusAndDiameterHeaderRender}
        />
      </Row>
    </PDFExport>
  )
}

export default RadiusAndDiameter;

RadiusAndDiameter.propTypes = {
  reportData: PropTypes.array,
  dataItem: PropTypes.object,
}
