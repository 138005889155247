/**
 * @summary CanvasWrapper.js
 * @file Wrapper for CanvasPage - its purpose is to pull and format data that's used in CanvasPage (based on Profile.jsx inputs).
 * @returns {JSX}
 * @usedBy routes.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import CanvasPage from './CanvasPage';
import store from '../../store/store';
import { axiosCanvasMapData } from './utils/axiosMapData';
import formatCanvasMapData from './utils/formatCanvasMapData';
import formatCanvasChangeReview from './utils/formatCanvasChangeReview';
import { toast } from 'react-toastify';
import { resetCanvasSelection } from 'store/canvas/CanvasActions';
import { useLocation } from 'react-router-dom';
import {
  hydrateProject
} from '../../store/ProjectSetup/ProjectSetupActions';

const CanvasWrapper = () => {
  const location = useLocation();
  /////////////////////////////
  // MASTER STATE FOR CANVAS //
  /////////////////////////////
  // When branch or project selection change, this gets updates and resent to Canvas Page
  const [canvasMapData, setCanvasMapData] = useState(null);

  // USE SELECTOR GRABS PROFILE SELECTION DATA
  const selectSelectedBranch = state => state.authReducer.userObj.selectedBranch[0];
  const selectSelectedProject = state => state.authReducer.userObj.selectedProject[0];

  const selectUser = state => state.authReducer.userObj;

  const user = useSelector(selectUser);
  const selectedBranch = useSelector(selectSelectedBranch)
  const selectedProject = useSelector(selectSelectedProject)
  const publishedBranch = useSelector(state => state.branchesDetailsReducer.activeBranches.find(branch => branch.name === "published"))

  const profileSelectionObj = { 
      userObj: user,
      selectedBranch: selectedBranch,
      selectedProject: selectedProject,
      publishedBranch
  }

  useEffect(() => {
    if (location.state?.from !== 'tableViewPage') {
      resetCanvasSelection();
    }
  }, []);

  // HYDRATES PROJECT WITH LATEST DATA
  const loadProject = () => {
    if (user) {
        store.dispatch(hydrateProject(user));
    }
  };

  useEffect(() => {
      loadProject();
  }, []);

  // CANVAS GRABS NEW DATA IF BRANCH OR PROJECT CHANGE UNDER PROFILE SELECTIONS
  const formatEverything = () => {
    // Set Canvas to Null While New Data is Fetching so Canvas Knows to Reset
    if(selectedBranch, selectedProject, publishedBranch){
      setCanvasMapData(null);
      axiosCanvasMapData(profileSelectionObj).then((axiosResponse) => {
        // This Function Compares the Current Branch to the Published Branch to Find Differences
        const branchChanges = formatCanvasChangeReview(
          profileSelectionObj.selectedProject,
          profileSelectionObj.selectedBranch,
          axiosResponse.nodes,
          axiosResponse.connections,
          axiosResponse.neighborhoods,
          axiosResponse.publishedNodes,
          axiosResponse.publishedConnections,
          axiosResponse.publishedNeighborhoods,
          axiosResponse.deletedNodes,
          axiosResponse.deletedConnections,
          axiosResponse.deletedNeighborhoods,
        )
        // This Function Formats the Data for GoJS to Render on Canvas
        const formattedMapData = formatCanvasMapData(
          // PROJECT DATA
          profileSelectionObj.userObj,
          profileSelectionObj.selectedProject,
          profileSelectionObj.selectedBranch,
          // CURRENT BRANCH DATA
          axiosResponse.nodes,
          axiosResponse.connections,
          axiosResponse.neighborhoods,
          branchChanges,
        );
        // BRING RAW DATA ALONG FOR THE RIDE
        const rawData = {
          nodes: axiosResponse.nodes,
          neighborhoods: axiosResponse.neighborhoods,
          connections: axiosResponse.connections
        } 

        const finalDataObj = {
          data: formattedMapData,
          rawData: rawData,
          userObj: profileSelectionObj.userObj,
          selectedProject: profileSelectionObj.selectedProject,
          selectedBranch: profileSelectionObj.selectedBranch
        };
        setCanvasMapData(finalDataObj);
      });
    }
  }

  useEffect(() => {
    if (profileSelectionObj) {
      formatEverything()
    }
  }, [profileSelectionObj.selectedProject, profileSelectionObj.selectedBranch]);

  const handleRefresh = (refresh) => {
    if(refresh === true){
      formatEverything()
      
    }
  }

  // USE SELECTOR
  if (canvasMapData) {
    return (
      <>
        {canvasMapData ? (
          <CanvasPage 
            canvasMapData={canvasMapData} 
            handleRefresh={handleRefresh}
          />
        ) : (
          <div>Something went wrong. Try refreshing.</div>
        )}
      </>
    );
  } else {
    return (
      <>
        <div className="canvas-page-container">
          <div className="load-message">
            <h2 style={{ fontSize: '48px', fontWeight: 700 }}>One Moment</h2>
            <span style={{ fontSize: '36px', fontWeight: 500 }}>
              Formatting Data for Canvas...
            </span>
          </div>
        </div>
      </>
    );
  }
};

export default CanvasWrapper;
