/**
 * @summary Metadata types strings
 * @file MetadataTypes.js
 * @returns {String}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const LOAD_METADATA = 'LOAD_METADATA';

export const LOAD_CONTENT_CODES = 'LOAD_CONTENT_CODES';

export const CLEAR_CONTENT_CODES = 'CLEAR_CONTENT_CODES';
