/**
 * # memoizeFunction
 *
 * @param {Function} fn The function whose output you want to memoize. This should be a deterministic function, for what I hope are obvious reasons.
 * @param {Function} computeKey Optional parameter for when JSON.stringify() is not a good solution for your function's inputs.
 *  If you choose to provide your own computeKey function, it needs to accept the same inputs as the memoized function, and return a string.
 *
 *  * Avoid bad cache hits by making sure your key changes when the output would change.
 *  * Avoid bad cache misses by making sure your key _doesn't_ change when the output _would not_ change.
 *
 * @returns {Function} The returned function will accept the same inputs as the original function, and return the same values, but only
 *  actually execute when it is presented with a new set of inputs, as determined by comparing their calculated keys.
 *
 * @decription This is a higher-order function that takes a function and returns a function that remembers the output for each set of inputs,
 *  so it doesn't have to calculate them again on subsequent calls. It determines whether to recalculate the result by computing a cache key,
 *  either via JSON.stringify or an optional computeKey argument.
 *
 * The ideal candidate for memoization is a deterministic (outputs _only_ depend on inputs) function where neither the inputs nor the outputs
 * take up much memory, but the calculation is expensive. (Asking the backend to look up something in the database, for instance.)
 *
 * ## Warnings:
 *
 * * Your function can have absolutely no side effects!
 * * You're trading memory for speed, and as of this writing this function has no way to clean out its cache.
 * * The default computeKey function uses JSON.stringify, which can be expensive and/or just not work for some argument types.
 * * This does not replace React.memo() for memoizing an entire component.
 * * Inside a component, you may wish to use the React.useMemo() hook in a component; then the memoization is tied to the component's lifecycle.
 */
const memoizeFunction = (
  fn,
  computeKey = (...args) => JSON.stringify(args)
) => {
  const memoCache = {};
  return (...args) => {
    const memoKey = computeKey(...args);
    memoCache[memoKey] = memoCache[memoKey] || fn(...args);
    return memoCache[memoKey];
  };
};

export default memoizeFunction;
