/**
 * @summary formatDateTime.js
 * @file Human readable date format
 * @returns {JSX}
 * @usedBy NodeForm, ConnectionForm, NewForm, NeighborhoodForm
 * @author Dj Ritchey
 * @since 4/01/2022
 * @lastUpdated 04/2022
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const formatDateTime = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
  // hour: 'numeric',
  // minute: 'numeric'
}).format;
