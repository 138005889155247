/**
 * @summary MediaModal.js
 * @file Modal component that is responsible for uploading user media in details page
 * @returns {JSX}
 * @usedBy LinkageLevelMediaGrid.js, NeighborhoodMediaGrid.js, NodeMediaGrid.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import {
  VALID_FILE_NAME_REGEX,
  IMAGE_TYPES,
  VIDEO_TYPES,
  AUDIO_TYPES
} from 'app_code/fileTypeConstants';
import { Upload } from '@progress/kendo-react-upload';
import { allowedExtensions } from './utility/file-extensions';
import store from '../../../store/store';
import { createMedia, addNewMedia } from 'store/media/MediaActions';

import validator from 'store/validation/validator';
import newMediaSchema from 'store/validation/newMedia.schema';
import ValidationMsg from 'store/validation/ValidationMsg';

const MediaModal = ({
  show,
  setShow,
  setMedia,
  uploadToastTrigger,
  parentObj,
  incomingLinkageLevel,
  updateLinkageLevels,
  setReload
}) => {
  const [files, setFiles] = useState([]);
  const [showEditInputField, setShowEditInputField] = useState(false);
  const [editFileName, setEditFileName] = useState('');
  const metadata = useSelector((store) => store.metadataReducer);
  const userObj = useSelector((store) => store.authReducer.userObj);

  const onAdd = (event) => {
    const customValidationErrors = [];
    const fileName = event.newState[0].name.slice(
      0,
      event.newState[0].name.lastIndexOf('.')
    );
    const medias = event.newState.map((file) => {
      file.name = file.name.replaceAll(' ', '_');
      if (
        `${file.name.match(VALID_FILE_NAME_REGEX)}`.length !== file.name.length
      ) {
        customValidationErrors.push('invalidFiles');
        file.validationErrors = customValidationErrors;
      }
      return file;
    });
    setFiles(medias);
  };

  const onProgress = (event) => {
    let custNewState = event.newState;
    if (event.newState && event.newState.length > 0) {
      custNewState = event.newState.map((file) => {
        if (file.progress === 100 && file.status === 3)
          return { ...file, status: 4 };
        return file;
      });
    }
    setFiles(custNewState);
  };

  const onStatusChange = (event) => {
    setFiles(event.newState);
  };

  const onRemove = (event) => {
    setFiles(event.newState);
  };

  const handleEditFileNameInputChange = (e) => {
    setEditFileName(e.target.value);
  }

  const handleUpdateFileNameChange = () => {
    const editedFileName = [...files];
    editedFileName[0].name = editFileName;
    setFiles(editedFileName);
    setShowEditInputField(false);
  }

  const allowed = allowedExtensions(files, metadata);

  const handleSubmit = (e) => {
    // VALIDATOR CONFIRMS WHETHER SCHEMA MATCHES JSON (NODE) SAVE
    validator(newMediaSchema, files).then((resp) => {
      if (resp) {
        store
          .dispatch(
            createMedia(
              files,
              userObj,
            )
          )
          .then((media) => {
            setShow(!show);
            setFiles([]);
            uploadToastTrigger(media.id);
            if (parentObj === 'linkageLevel') {
              setReload(true);
              updateLinkageLevels(incomingLinkageLevel);
            } else {
              setMedia(media.id);
            }
            store.dispatch(addNewMedia(media, parentObj, incomingLinkageLevel?.id, incomingLinkageLevel?.linkageLevelId))
          })
      }
    });
  };

  return (
    <>
      <Modal show={show} backdrop="static" centered size="lg">
        <Modal.Header>
          <Modal.Title style={{ color: 'white' }}>Add Media</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row p-3'>
              
              <div className="col-6">
                <h4
                  style={{
                    color: '#1560b7',
                    fontSize: '16px',
                    fontWeight: '600'
                  }}
                >
                  Supported file name characters
                </h4>
                <p>
                  <strong>Alphanumeric characters:</strong> 
                  <ul>
                    <li>0-9 </li>
                    <li>a-z</li>
                    <li>A-Z</li>
                  </ul>
                </p>
                <p>
                  <strong>Special characters</strong>
                </p>
                <ul>
                  <li>Exclamation point (!)</li> 
                  <li>Hyphen (-)</li> 
                  <li>Underscore (_)</li> 
                  <li>Period (.)</li> 
                  <li>Asterisk (*)</li> 
                  <li>Open parenthesis (()</li> 
                  <li>Close parenthesis ())</li> 
                </ul>
              </div>
              <div className="col-6">
                <h4
                  style={{
                    color: '#1560b7',
                    fontSize: '16px',
                    fontWeight: '600'
                  }}
                >
                  Supported File Types
                </h4>
                <ul>
                  <li>
                    <strong>Image/Graphic:</strong>
                    {" " + IMAGE_TYPES.toString()}
                  </li>
                  <li>
                    <strong>Audio:</strong>
                    {" " + AUDIO_TYPES.toString()}
                  </li>
                  <li>
                    <strong>Video:</strong>
                    {" " + VIDEO_TYPES.toString()}
                  </li>
                  <li>
                    <strong>Text:</strong> pdf, html, doc, dox, txt</li>
                </ul>
              </div>
              <div className='container'>
                    <Upload
                      restrictions={{
                        allowedExtensions: allowed
                      }}
                      onAdd={onAdd}
                      onRemove={onRemove}
                      files={files}
                      multiple={false}
                      onProgress={onProgress}
                      showActionButtons={false}
                      onStatusChange={onStatusChange}
                      autoUpload={false}
                    />
                  </div>
                    {files.length > 0 && !showEditInputField && (
                      <button
                        onClick={() => setShowEditInputField(true)}
                        className='btn btn-block btn-primary btn-sm m-2 bootstrap-footer-button-stylization'
                      >
                        Edit File Name
                      </button>
                    )}
                    {showEditInputField && (
                      <div className='d-flex w-100 h-100 m-3'>
                        <input 
                          className='w-25 p-0 form-control form-control-sm'
                          onChange={handleEditFileNameInputChange}
                        />
                        <button
                          className='btn btn-danger btn-sm mx-2'
                          onClick={() => setShowEditInputField(false)}
                        >
                          Cancel  
                        </button>
                        <button
                          className='btn btn-primary btn-sm'
                          onClick={handleUpdateFileNameChange}
                        >
                          Update
                        </button>
                      </div>
                    )}
                  </div>
                </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='container-fluid'>
            <div className='row'>
              <div className='bootstrap-modal-footer-button-alignment'>
                <button
                  className="btn btn-danger btn-sm text-white bootstrap-footer-button-stylization"
                  onClick={() => setShow(false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-primary btn-sm text-white bootstrap-footer-button-stylization"
                  onClick={handleSubmit}
                  disabled={
                    files.length === 0 ||
                    files.some((f) => f.validationErrors?.length > 0)
                  }
                >
                  Upload Media
                </button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

MediaModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  setMedia: PropTypes.func,
  uploadToastTrigger: PropTypes.func,
  parentObj: PropTypes.string,
  incomingLinkageLevel: PropTypes.object,
  setReload: PropTypes.func,
  updateLinkageLevels: PropTypes.func
};

export default MediaModal;
