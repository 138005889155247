/**
 * @summary CanvasInterface.js
 * @file Contains the Canvas Interface Bar Along the Top, Opens Modals, and Connects advanced map-change functions (like rotate) to the CanvasPage.js
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { FaTools } from 'react-icons/fa';
import { ImFilter } from 'react-icons/im';
import { LuPaintbrush } from 'react-icons/lu';
import { LuGlasses } from 'react-icons/lu';
import { TbTableShare } from "react-icons/tb";
import { GoSidebarCollapse } from "react-icons/go";
import { VscOpenPreview } from "react-icons/vsc";

import { TbDeviceFloppy } from 'react-icons/tb';
import { BsFolder } from 'react-icons/bs';
import { FaDownload } from 'react-icons/fa'
import { FaRegQuestionCircle } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../canvasPage.scss';

const CanvasInterface = ({
  renderMapData,
  handleFilterDisplay,
  openGlobalSidebar,
  handleGlobalSidebarDisplay,
  handleShowDataEditorMenu,
  handleThemeMenuDisplay,
  handleRotate,
  handleLayeringOptionChange,
  nodeAlignment,
  handleDisplayNeighborhoodLegend,
  handleInspectionToolDisplay,
  handleTableviewRedirectDisplay,
  handleCanvasTutorialVisibility,
  handleViewSaveDisplay,
  handleViewLoadDisplay,
  handleReviewMode,
  handleCanvasExportDisplay,
  isNodeSelected,
  multiSelectArray,
  showDataEditorMenu,
  showThemeMenu,
  showFilterMenu,
  showMapDownloadModal,
  showNeighborhoodLegendMenu,
  showInspectorMenu,
  filtered,
  reviewModeOpen
}) => {
  const [preFilterOverrideActive, setPreFilterOverrideActive] = useState(false)
  const userObj = useSelector((state) => state.authReducer.userObj) 
  useEffect(() => {
    if(renderMapData.nodeDataArray.length > 1200){
      setPreFilterOverrideActive(true)
    } else {
      setPreFilterOverrideActive(false)
    }
  },[renderMapData])
  return (
    <>
      <div className="learning-map-interface-container">
        <div className="learning-map-interface">
          <div className="col-12">
            <div className="row">
              {/* ///////////////////////////// */}
              {/* /// PROFILE INFO SECTION ////*/}
              {/* ///////////////////////////// */}
              <div className="col-4">
                <div className="profile-container">
                  <div className="row">
                    <div className="profile-section">
                      <div className="profile-header">Project:</div>
                      <div className="profile-item">
                        {userObj ? userObj.selectedProject[0].name : ''}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="profile-section">
                      <div className="profile-header">Branch:</div>
                      <div className="profile-item">
                        {renderMapData ? renderMapData.selectedBranch : ''}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="profile-section">
                      <div className="profile-header">View:</div>
                      <div className="profile-item">
                        {renderMapData.viewName
                          ? renderMapData.viewName
                          : 'Original Branch View'}
                      </div>
                  <div className="row">
                    <div className="profile-section">
                      <div className="profile-header">Type:</div>
                        <div className="profile-item">
                          {renderMapData.parentSourceType
                            ? renderMapData.parentSourceType[0].toUpperCase() +
                              renderMapData.parentSourceType.substring(1)
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="profile-section">
                      <div className="profile-header">Source:</div>
                        <div className="profile-item">
                        {renderMapData.parentSource
                                ? renderMapData.parentSource
                                : 'Original Branch View'}
                        </div>
                      </div>
                    </div>
                  </div>         
                </div>
              </div>

              {/* ////////////////////////////////// */}
              {/* //////// MAP EDITING TOOLS ///////*/}
              {/* ////////////////////////////////// */}
              <div className="col-4">
                <div className="editing-button-toolset">
                  <div className="col-12">
                    {/* DATA EDITOR BUTTON */}
                    <button
                      className={!showDataEditorMenu ? 'soft-editing-button' : 'soft-editing-button-open'}
                      onClick={handleShowDataEditorMenu}
                      title="Map Editor: Provides a menu of options for adding, editing and deleting selected elements - as well as analyzing selected elements."
                      tabIndex={0}
                    >
                      <FaTools size={20} />
                    </button>
                    {/* THEME BUTTON */}
                    <button
                      disabled={preFilterOverrideActive || renderMapData.nodeDataArray.length === 0}
                      className= {!showThemeMenu ? "soft-editing-button" : 'soft-editing-button-open'}
                      onClick={handleThemeMenuDisplay}
                      title={!reviewModeOpen ? 
                        "Edit Theme: This button will open a submenu that enables you to edit your map's theme colors, fonts, weights and more in a variety of ways."
                        : "Themes are disabled while in Review Mode"
                      }
                      tabIndex={0}
                    >
                      <LuPaintbrush size={20} />
                    </button>
                    {/* FILTER BUTTON */}
                    <button
                      disabled={renderMapData.nodeDataArray.length === 0 && !filtered}
                      className={!showFilterMenu ? 'soft-editing-button' : 'soft-editing-button-open'}
                      onClick={handleFilterDisplay}
                      title="Filter Map Data: This submenu enables you to filter your map down to the data you're most interested in analyzing."
                      tabIndex={0}
                    >
                      <ImFilter size={20} />
                    </button>
                    {/* INSPECT ELEMENT BUTTON */}
                    <button
                      className={
                        !showInspectorMenu
                          ? 'soft-editing-button'
                          : 'soft-editing-button-open'
                      }
                      disabled={preFilterOverrideActive || !isNodeSelected}
                      onClick={handleInspectionToolDisplay}
                      title={
                        isNodeSelected
                          ? "Inspect this node's properties."
                          : 'Inspector Tool: Click a node or neighborhood to activate this button and inspect the properties of your selection.'
                      }
                      tabIndex={0}
                    >
                      <LuGlasses size={20} />
                    </button>
                    {/* TABLEVIEW REDIRECT */}
                    <button
                      className={
                        !showInspectorMenu
                          ? 'soft-editing-button'
                          : 'soft-editing-button-open'
                      }
                      disabled={preFilterOverrideActive || multiSelectArray.length === 0}
                      onClick={handleTableviewRedirectDisplay}
                      title={
                        isNodeSelected
                          ? "Tableview Redirect: Navigate to the Tableview Page with these elements selected."
                          : 'Tableview Redirect: Click one or more nodes or neighborhoods to activate this button and navigate to the Tableview Page with these elements selected.'
                      }
                      tabIndex={0}
                    >
                      <TbTableShare size={20} />
                    </button>
                    {/* TUTORIAL */}
                    {/* <button
                      className={
                        !showInspectorMenu
                          ? 'soft-editing-button'
                          : 'soft-editing-button-open'
                      }
                      // disabled={preFilterOverrideActive || multiSelectArray.length === 0}
                      onClick={handleCanvasTutorialVisibility}
                      title={
                        isNodeSelected
                          ? "Tableview Redirect: Navigate to the Tableview Page with these elements selected."
                          : 'Tableview Redirect: Click one or more nodes or neighborhoods to activate this button and navigate to the Tableview Page with these elements selected.'
                      }
                      tabIndex={0}
                    >
                      <FaRegQuestionCircle size={20} />
                    </button> */}
                  </div>
                  {/* SAVE VIEW AND LOAD VIEW */}
                  <div className='"col-12'>
                    <button
                      disabled={preFilterOverrideActive || renderMapData.nodeDataArray.length === 0}
                      className="view-save-load-buttons"
                      onClick={handleViewSaveDisplay}
                      title="Save View: This button opens a submenu for saving this map into a view, which can be loaded using the load button to the right."
                      tabIndex={0}
                    >
                      <TbDeviceFloppy size={20} /> Save
                    </button>
                    <button
                      disabled={preFilterOverrideActive || renderMapData.nodeDataArray.length === 0}
                      className="view-save-load-buttons"
                      onClick={handleViewLoadDisplay}
                      title="Load View: This button opens a submenu where you can load your saved views into the map display below."
                      tabIndex={0}
                    >
                      <BsFolder size={20} /> Load
                    </button>
                    {/* DOWNLOAD MAP IMAGE BUTTON */}
                    <button
                      disabled={preFilterOverrideActive || renderMapData.nodeDataArray.length === 0}
                      className={!showMapDownloadModal ? "image-download-button" : "image-download-button-open"}
                      onClick={handleCanvasExportDisplay}
                      title="Save Map as Image: Save this displayed canvas map as an image."
                      tabIndex={0}
                    >
                      <FaDownload size={20} />
                    </button>
                  </div>
                </div>
              </div>
              {/* ////////////////////////////////// */}
              {/* //////// VIEW OPTION TOOLS ///////*/}
              {/* ////////////////////////////////// */}
              <div className="col-4">
                <div className="view-button-toolset">
                  <div className="col-12">
                    {/* REVIEW MODE */}
                    <button
                      disabled={preFilterOverrideActive || renderMapData.nodeDataArray.length === 0}
                      className={reviewModeOpen ? "view-change-button-open" : "view-change-button"}
                      onClick={handleReviewMode}
                      title="Activate Review Mode"
                      tabIndex={0}
                    >
                      <VscOpenPreview size={20} />
                    </button>
                    {/* ROTATE LEFT BUTTON */}
                    <button
                      disabled={preFilterOverrideActive || renderMapData.nodeDataArray.length === 0}
                      id="rotateButton2"
                      className="bi bi-arrow-clockwise view-change-button"
                      title="Rotate Clockwise"
                      onClick={handleRotate}
                      tabIndex={0}
                    ></button>
                    {/* ROTATE RIGHT BUTTON */}
                    <button
                      disabled={preFilterOverrideActive || renderMapData.nodeDataArray.length === 0}
                      id="rotateButton1"
                      className="bi bi-arrow-counterclockwise view-change-button"
                      title="Rotate Counter-Clockwise"
                      onClick={handleRotate}
                      tabIndex={0}
                    ></button>
                    {/* REALIGN TO SOURCES BUTTON */}
                    <button
                      disabled={preFilterOverrideActive || renderMapData.nodeDataArray.length === 0}
                      id="LayerLongestPathSource"
                      className={
                        renderMapData.nodeDataArray.length > 0 &&
                        !preFilterOverrideActive && nodeAlignment === 'LayerLongestPathSource' ||
                        nodeAlignment === 'resetLayerLongestPathSource' ||
                        !nodeAlignment
                          ? 'bi bi-diagram-3 view-change-button-open'
                          : 'bi bi-diagram-3 view-change-button'
                      }
                      title="Layer Starting Nodes Evenly"
                      onClick={handleLayeringOptionChange}
                      tabIndex={0}
                    ></button>
                    {/* REALIGN TO DESTINATIONS BUTTON */}
                    <button
                      disabled={preFilterOverrideActive || renderMapData.nodeDataArray.length === 0}
                      id="LayerLongestPathSink"
                      className={
                        !preFilterOverrideActive && nodeAlignment === 'LayerLongestPathSink' ||
                        nodeAlignment === 'resetLayerLongestPathSink'
                          ? 'bi bi-diagram-3 view-change-button-open'
                          : 'bi bi-diagram-3 view-change-button'
                      }
                      title="Layer Ending Nodes Evenly"
                      onClick={handleLayeringOptionChange}
                      tabIndex={0}
                    ></button>
                    
                    {/* VIEW NEIGHBORHOODS BUTTON */}
                    <div className="col-12">
                      <button
                        disabled={preFilterOverrideActive || renderMapData.nodeDataArray.length === 0}
                        className={
                          !showNeighborhoodLegendMenu
                            ? 'neighborhood-legend-button'
                            : 'neighborhood-legend-button-open'
                        }
                        onClick={handleDisplayNeighborhoodLegend}
                        title="Enter and select the neighborhoods you would like to see displayed."
                        tabIndex={0}
                      >
                        View Neighborhoods
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`global-sidebar-button-container ${
          openGlobalSidebar === true ? 
          'global-sidebar-button-open' : 'global-sidebar-button-closed'}`} 
          onClick={handleGlobalSidebarDisplay}>
          <GoSidebarCollapse size={30}/>
        </div>
      </div>
    </>
  );
};

CanvasInterface.propTypes = {
  renderMapData: PropTypes.object,
  handleFilterDisplay: PropTypes.func,
  openGlobalSidebar: PropTypes.bool,
  handleGlobalSidebarDisplay: PropTypes.func,
  handleShowDataEditorMenu: PropTypes.func,
  handleThemeMenuDisplay: PropTypes.func,
  handleRotate: PropTypes.func,
  handleLayeringOptionChange: PropTypes.func,
  nodeAlignment: PropTypes.string,
  handleDisplayNeighborhoodLegend: PropTypes.func,
  handleInspectionToolDisplay: PropTypes.func,
  handleCanvasTutorialVisibility:PropTypes.func,
  handleTableviewRedirectDisplay: PropTypes.func,
  handleViewSaveDisplay: PropTypes.func,
  handleViewLoadDisplay: PropTypes.func,
  handleCanvasExportDisplay: PropTypes.func,
  handleReviewMode: PropTypes.func,
  isNodeSelected: PropTypes.bool,
  multiSelectArray: PropTypes.array,
  showDataEditorMenu: PropTypes.bool,
  showThemeMenu: PropTypes.bool,
  showFilterMenu: PropTypes.bool,
  showMapDownloadModal: PropTypes.bool,
  showNeighborhoodLegendMenu: PropTypes.bool,
  showInspectorMenu: PropTypes.bool,
  filtered: PropTypes.bool,
  reviewModeOpen: PropTypes.bool,
};

export default CanvasInterface;
