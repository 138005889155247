/**
 * @summary MediaReducer.js
 * @file Media reducer
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import {
  CREATE_MEDIA,
  GET_MEDIAS,
  DELETE_MEDIA,
  GRID_MEDIA_DATA_ACTIONS_SELECTIONS_FULLFILLED,
  CLEAR_SELECTION,
  CLEAR_MEDIAS,
  UPDATE_MEDIA,
  ADD_MEDIA
} from './MediaTypes';

const medias = {
  medias: [],
  selectedData: undefined,
  error: null
};

/**
 * @summary
 * This redux reducer is used for media CRUD based on the action type
 *
 * @param {medias} state
 * @param {reduxAction} action
 */
const mediaReducer = (state = medias, action) => {
  switch (action.type) {
    case GET_MEDIAS:
      return {
        ...state,
        medias: action.mediaList
      };

    case CREATE_MEDIA:
    case ADD_MEDIA:
      return {
        ...state,
        medias: [...state.medias, action.media]
      };

    case UPDATE_MEDIA:
      let idx = state?.media?.find((media) => media.id === action.meta.id);
      let newMedias = [...state.medias];
      newMedias[idx] = action.media;
      return {
        ...state,
        medias: newMedias
      };

    case GRID_MEDIA_DATA_ACTIONS_SELECTIONS_FULLFILLED:
      return {
        ...state,
        selectedData: action.media.data || [],
        error: null
      };

    case CLEAR_SELECTION:
      return {
        ...state,
        selectedData: undefined,
        error: null
      };

    case CLEAR_MEDIAS:
      return {
        medias: [],
        selectedData: undefined,
        error: null
      };

    default:
      return state;
  }
};
export default mediaReducer;
