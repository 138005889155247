/**
 * @summary NeighborhoodStylization.js
 * @file Contains All Theme Stylization Options for Neighborhoods - Displayed in CanvasThemeMenu.js
 * @returns {JSX}
 * @usedBy CanvasThemeMenu.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */


import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import '../../canvasPage.scss';

import ColorPicker from './ColorPicker'

{/* //////////////// */}
{/* NODE STYLIZATION */}
{/* //////////////// */}
const NeighborhoodStylization = ({
    handleColorPalletteVisibility, 
    neighborhoodBackgroundColorPallete, 
    neighborhoodBorderColorPallete, 
    neighborhoodBackgroundColor, 
    neighborhoodBorderColor, 
    handleNeighborhoodShapeSelector, 
    handleNeighborhoodBackgroundColorChange, 
    handleNeighborhoodBorderColorChange, 
    handleNeighborhoodBorderWidthSelector,
    neighborhoodShapeDropdownLabel, 
    neighborhoodBorderWidthDropdownLabel
}) => {
    return(
        <div className='col-4'>
            {/* NEIGHBORHOOD SHAPE */}
            <div className='theme-options-headers'>
                Neighborhoods
            </div>
            <div className='row'>
            <div className='theme-option'>
                Shape:
                <form className='shape-form'>
                    <select
                        id="neighborhood-shape-button"
                        className="shape-theme-dropdown"
                        onChange={handleNeighborhoodShapeSelector}
                        value={neighborhoodShapeDropdownLabel}
                    >
                        <option className="node-shape-option" value="RoundedRectangle">Rectangle</option>
                        <option className="node-shape-option" value="Ellipse">Oval</option>
                    </select>
                </form>
            </div>
            </div>
            {/* NEIGHBORHOOD BACKGROUND COLOR */}
            <div className='row'>
            <div className='theme-option'>
                Background:
            
                <button 
                    title={'Color Selector: Click this button to open the color pallette, select a color from the pallet, and click this button again to close the pallette.'}
                    onClick={handleColorPalletteVisibility} 
                    id="neighborhood-background-color-button" 
                    className={neighborhoodBackgroundColor === 'transparent' ? 'default-color-button' : 'selected-color-button'} 
                    style={{backgroundColor: neighborhoodBackgroundColor }}>

                </button>
                <div className={neighborhoodBackgroundColorPallete ? "pallette-visibility-on" : "pallette-visibility-off"}>
                    <ColorPicker 
                        pickerId={"neighborhood-color"}
                        color={neighborhoodBackgroundColor}
                        handleNeighborhoodBackgroundColorChange={handleNeighborhoodBackgroundColorChange}
                    />
                </div>
            </div>
            </div>
            {/* NEIGHBORHOOD BORDER COLOR */}
            <div className='row'>
            <div className='theme-option'>
                Border Color:
                <button 
                title={'Color Selector: Click this button to open the color pallette, select a color from the pallet, and click this button again to close the pallette.'}
                onClick={handleColorPalletteVisibility} 
                id="neighborhood-border-color-button" 
                className={neighborhoodBorderColor === 'transparent' ? 'default-color-button' : 'selected-color-button'} 
                style={{backgroundColor: neighborhoodBorderColor }}
                >
                </button>
                <div className={neighborhoodBorderColorPallete ? "pallette-visibility-on" : "pallette-visibility-off"}>
                    <ColorPicker 
                        pickerId={"neighborhood-border-color"}
                        color={neighborhoodBorderColor}
                        handleNeighborhoodBorderColorChange={handleNeighborhoodBorderColorChange}
                    />
                </div>
            </div>
            </div>
            {/* NEIGHBORHOOD BORDER WIDTH */}
            <div className='row'>
            <div className='theme-option'>
                Border Width:
                <form className='border-width-form'>
                    <select
                    id="node-border-width-button"
                    className="shape-theme-dropdown"
                    onChange={handleNeighborhoodBorderWidthSelector}
                    value={neighborhoodBorderWidthDropdownLabel}
                    >
                        <option className="neighborhood-border-width-option" value="1">1</option>
                        <option className="neighborhood-border-width-option" value="2">2</option>
                        <option className="neighborhood-border-width-option" value="3">3</option>
                        <option className="neighborhood-border-width-option" value="4">4</option>
                        <option className="neighborhood-border-width-option" value="5">5</option>
                        <option className="neighborhood-border-width-option" value="5">6</option>
                    </select>
                </form>
            </div>
            </div>
        </div>
    )
}

NeighborhoodStylization.propTypes = {
    handleColorPalletteVisibility: PropTypes.func,
    neighborhoodBackgroundColorPallete: PropTypes.bool,
    neighborhoodBorderColorPallete: PropTypes.bool,
    neighborhoodBackgroundColor: PropTypes.string,
    neighborhoodBorderColor: PropTypes.string,
    handleNeighborhoodShapeSelector: PropTypes.func,
    handleNeighborhoodBackgroundColorChange: PropTypes.func,
    handleNeighborhoodBorderColorChange: PropTypes.func,
    handleNeighborhoodBorderWidthSelector: PropTypes.func,
    neighborhoodShapeDropdownLabel: PropTypes.string,
    neighborhoodBorderWidthDropdownLabel: PropTypes.any,
};

export default NeighborhoodStylization;