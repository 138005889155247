/**
 * @summary ProjectObjTypeNames.js
 * @file frozen object to be used for string normalization in project form components
 * @returns {JSX}
 * @usedBy neighborhoodSettings.js, projectSettings.js
 * @author David R
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

const projectNames = {
  coreMetaData: 'coreMetaData',
  id: 'id',
  version: 'version',
  status: 'status',
  description: 'description',
  assessmentProgramId: 'assessmentProgramId',
  name: 'name',
  displayTitle: 'displayTitle',
  projectPurpose: 'projectPurpose',
  branch: 'branch',
  cloneFrom: 'cloneFrom',
  minAge: 'minAge',
  maxAge: 'maxAge',
  applicationUsage: 'applicationUsage',
  themeId: 'themeId',
  themeName: 'themeName',
  themeColors: 'themeColors',
  nodeBorderStyle: 'nodeBorderStyle',
  nodeBorderStylePx: 'nodeBorderStylePx',
  nodeBorderColor: 'nodeBorderColor',
  nodeFillColor: 'nodeFillColor',
  nodeConnectionLineStyle: 'nodeConnectionLineStyle',
  nodeConnectionLinePx: 'nodeConnectionLinePx',
  nodeConnectionLineColor: 'nodeConnectionLineColor',
  nodeConnectionArrows: 'nodeConnectionArrows',
  nodeConnectionCorners: 'nodeConnectionCorners',
  nodeHighlightColor: 'nodeHighlightColor',
  nodeShape: 'nodeShape',
  fontName: 'fontName',
  layout: 'layout',
  linkageLevels: 'linkageLevels'
};

export const projectNamesObj = Object.freeze(projectNames);
