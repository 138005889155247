/**
 * @summary News.js
 * @file Provides an introductory description about learning maps
 * @returns {JSX}
 * @usedBy HomePage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2023 University of Kansas
 */

import React from 'react';
import { useSelector } from 'react-redux';

export default function News() {
  const userObj = useSelector((state) => (state.authReducer.userObj));
  function createMarkup() {
    let news = `<h1 class="display-6">Welcome ${userObj.firstName}!</h1>
                    <p class="card-text"> Kite Learning Maps is a research-based data tool to visually 
                    provide mappings of skill sets based on content standards to be leveraged for instruction, 
                    research and assessment purposes. Here you can manage the entire learning map process. What will you map today?</p>`;
    return { __html: news };
  }

  return (
    <div className="card shadow">
      <h5 className="card-header">Home Page</h5>
      <div className="card-body">
        <div dangerouslySetInnerHTML={createMarkup()} />
      </div>
    </div>
  );
}
