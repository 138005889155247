/**
 * @summary Map Upload string Types
 * @file MapUploadTypes.js
 * @returns {String}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const GET_BRANCHESLIST = 'GET_BRANCHESLIST';
export const GET_UPLOADSTATSLIST = 'GET_UPLOADSTATSLIST';
