/**
 * @summary MiniCanvas.js
 * @file Displays the Preview Map that is Rendered Based on User Inputs in CanvasThemeMenu.js
 * @returns {JSX}
 * @usedBy CanvasThemeMenu.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useImmer } from 'use-immer';
import * as go from 'gojs';
import { ReactDiagram, ReactPalette } from 'gojs-react';
import PropTypes from 'prop-types';
import { SuperNodeLayout } from '../../utils/superNodeLayout';
import '../../canvasPage.scss';

const MiniCanvas = ({
  themeTemplate, 
  miniCanvasPreview
}) => {
  // LEAVE THESE STATES FOR NOW AS WE MAY NEED TO USE THEM LATER
  const [nodeRender, updateNodeRender] = useImmer()
  const [connectionRender, updateConnectionRender] = useImmer()

  const diagramRef = useRef(null);

  const previewNodes = [
    {
      key: 1,
      nodeKey: 'Node Key 1',
      title: 'Node Title 1',
      description: 'Node Description',
      color: themeTemplate.nodeBackgroundColor,
      border: themeTemplate.nodeBorderColor,
      strokeWidth: themeTemplate.nodeBorderWidth,
      shape: themeTemplate.nodeShape,
      fontPrimary: themeTemplate.nodePrimaryFont,
      fontSecondary: themeTemplate.nodeSecondaryFont,
      fontColor: themeTemplate.nodeFontColor, 
      supers: ['Neighborhood']
    },
    {
      key: 2,
      nodeKey: 'Node Key 2',
      title: 'Node Title 2',
      description: 'Node Description',
      color: themeTemplate.nodeBackgroundColor,
      border: themeTemplate.nodeBorderColor,
      strokeWidth: themeTemplate.nodeBorderWidth,
      shape: themeTemplate.nodeShape,
      fontPrimary: themeTemplate.nodePrimaryFont,
      fontSecondary: themeTemplate.nodeSecondaryFont,
      fontColor: themeTemplate.nodeFontColor, 
      supers: ['Neighborhood']
    },
    {
      key: 3,
      category: 'Super',
      nodeKey: 'Neighborhood',
      title: 'Neighborhood',
      description: 'Node Description',
      backgroundColor: themeTemplate.neighborhoodBackgroundColor,
      border: themeTemplate.neighborhoodBorderColor,
      borderWidth: themeTemplate.neighborhoodBorderWidth,
      shape: themeTemplate.neighborhoodShape,
      font: themeTemplate.neighborhoodFont,
      fontColor: themeTemplate.neighborhoodFontColor, 
      _members: ['Node Key 1','Node Key 2'],
    }
  ]
  const previewConnections = [{
    id: 4,
    key: 1,
    from: "Node Key 1",
    to: "Node Key 2",
    color: themeTemplate.connectionColor,
    arrowHeadStyle: themeTemplate.connectionArrowHeadStyle,
    arrowHeadThickness: 1,
    strokeWidth: themeTemplate.connectionWidth
  }]

  

  // REFRESHES DIAGRAM WHEN PREVIEW CHANGES
  // const mapNodeKeyIdx = new Map();
  // const mapLinkKeyIdx = new Map();

  useEffect(()=> {
    if(miniCanvasPreview === 'All Elements'){
      updateNodeRender(previewNodes)
      updateConnectionRender(previewConnections)
    } else if (miniCanvasPreview === 'Node'){
      updateNodeRender([previewNodes[1]])
      updateConnectionRender([])
    } else if(miniCanvasPreview === 'Neighborhood') {
      let newArray = [previewNodes[0]]
      newArray.push(previewNodes[2])
      updateNodeRender(newArray)
      updateConnectionRender([])
    } else if(miniCanvasPreview === 'Connection') {
      let newArray = [previewNodes[0]]
      newArray.push(previewNodes[1])
      updateNodeRender(newArray)
      updateConnectionRender(previewConnections)
    }
  },[miniCanvasPreview])

  // useEffect(() => {
  //   refreshNodeIndex(nodeRender);
  //   refreshLinkIndex(connectionRender);
  // }, [nodeRender, connectionRender]);

  // function refreshNodeIndex(nodeArr) {
  //   mapNodeKeyIdx.clear();
  //   if (nodeArr !== undefined) {
  //     nodeArr.forEach((n, idx) => {
  //       mapNodeKeyIdx.set(n.key, idx);
  //     });
  //   }
  // }
  // function refreshLinkIndex(linkArr) {
  //   mapLinkKeyIdx.clear();
  //   if (linkArr !== undefined) {
  //     linkArr.forEach((l, idx) => {
  //       mapLinkKeyIdx.set(l.key, idx);
  //     });
  //   }
  // }

  //////////////////////
  // MAP BUILD STARTS //
  //////////////////////
  

  function initDiagram() {
    ////////////////////
    // DIAGRAM STARTS //
    ////////////////////
    const $ = go.GraphObject.make;

    go.Diagram.licenseKey = process.env.REACT_APP_GOJS_KEY || '';

    const diagram = $(go.Diagram, {
      initialAutoScale: go.Diagram.Uniform,
      allowVerticalScroll: false,
      padding: 10,
      'panningTool.isEnabled': false,
      isReadOnly: true,
      layout: $(SuperNodeLayout, {
        isOngoing: false,
        layerSpacing: 50,
        columnSpacing: 50
      }),
      model: $(go.GraphLinksModel, {
        nodeKeyProperty: 'nodeKey',
        linkKeyProperty: 'id'
      })
    });
    
    //////////////////////////
    // NODE TEMPLATE DESIGN //
    //////////////////////////

    diagram.nodeTemplate = $(
      go.Node,
      'Auto', // the Shape will go around the TextBlock
      // LEAVE THIS IN AS WE SWITCH TO IT SOMETIMES - DEPENDING ON CLIENT FEEDBACK
    //   {
    //     click: click
    //   },
      new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      $(
        go.Shape,
        {
          name: 'SHAPE',
          parameter1: 8, // corner radius
          portId: '',
          fromSpot: go.Spot.AllSides,
          toSpot: go.Spot.AllSides,
        },
        new go.Binding('figure', 'shape'),
        new go.Binding('fill', 'color'),
        new go.Binding('stroke', 'border'),
        new go.Binding('strokeWidth', 'strokeWidth'),
        new go.Binding('strokeDashArray', 'dash'),
        // THIS MAY BE USED LATER TO IMPROVE HIGHLIGHT EMPHASIS
        // new go.Binding('stroke', 'isHighlighted', function (h, obj) {
        //   return h ? 'red' : 'nodeBorderColor';
        // }).ofModel()
      ),
      $(
        go.Panel,
        'Table',
        {
          defaultAlignment: go.Spot.Left,
          margin: 2
        },
        $(
          go.TextBlock,
          {
            row: 0,
            column: 0,
            alignment: go.Spot.Center
          },
          {
            // font: 'bold 16px Arial',
            margin: 4,
            editable: false,
            maxSize: new go.Size(200, NaN),
            name: 'TEXT'
          }, // some room around the text
          new go.Binding('font','fontPrimary'),
          new go.Binding('text', 'nodeKey').makeTwoWay(),
          new go.Binding('stroke', 'fontColor')
          // THIS MAY BE USED LATER TO IMPROVE HIGHLIGHT EMPHASIS
          // 'isHighlighted', function (h, obj) {
          //   return h ? 'red' : 'rgb(21,96,183)';
          // }).ofObject()
        ),
        $(
          go.TextBlock,
          {
            row: 1,
            column: 0
          },
          {
            margin: 4,
            editable: false,
            maxSize: new go.Size(200, NaN),
            name: 'TEXT'
          }, 
          
          new go.Binding('text', 'title').makeTwoWay(),
          new go.Binding('font', 'fontSecondary'),
          new go.Binding('stroke', 'fontColor'),
          new go.Binding('strokeWidth', 'strokeWidth'),
          // THIS MAY BE USED LATER TO IMPROVE HIGHLIGHT EMPHASIS
          // 'isHighlighted', function (h, obj) {
          //   return h ? 'red' : 'rgb(21,96,183)';
          // }).ofObject()
        )
      ),
      {
        // define a tooltip for each node that displays the color as text
        toolTip: $(
          'ToolTip',
          $(
            go.Panel,
            'Table',
            {
              defaultAlignment: go.Spot.Left,
              margin: 4
            },
            $(
              go.TextBlock,
              {
                row: 0,
                column: 0,
                alignment: go.Spot.Center
              },
              {
                font: 'bolder 18px Arial',
                stroke: '#1560b7',
                margin: 4,
                editable: false,
                maxSize: new go.Size(200, NaN),
                name: 'TEXT'
              }, // some room around the text
              new go.Binding('text', 'nodeKey').makeTwoWay()
            ),
            $(
              go.TextBlock,
              {
                row: 2,
                column: 0
              },
              {
                margin: 4,
                editable: false,
                maxSize: new go.Size(200, NaN),
                font: 'bold 12px Arial',
                name: 'TEXT'
              }, // some room around the text
              new go.Binding('text', 'title').makeTwoWay()
            ),
            $(
              go.TextBlock,
              {
                row: 3,
                column: 0
              },
              {
                margin: 4,
                editable: false,
                maxSize: new go.Size(200, NaN),
                font: '100 12px Arial',
                name: 'TEXT'
              }, // some room around the text
              new go.Binding('text', 'description')
            )
          ) // end of Adornment
        )
      }
    );
    ////////////////////////////////
    // NEIGHBORHOOD TEMPLATE DESIGN //
    ////////////////////////////////
    diagram.nodeTemplateMap.add(
      'Super',
      $(
        go.Node,
        'Auto',
        {
          locationObjectName: 'BODY',
          layerName: 'Background',
          movable: false,
          // click: click
        },
        new go.Binding('visible', 'visible', function (h) {
          return h ? true : false;
        }),
        $(
          go.Shape,
          {
            name: 'SHAPE',
            parameter1: 20,
            spot1: go.Spot.TopLeft,
            spot2: go.Spot.BottomRight,
            minSize: new go.Size(50, 50),
          },
          new go.Binding('figure', 'shape'),
          new go.Binding('fill', 'backgroundColor'),
          new go.Binding('stroke', 'border'),
          new go.Binding('strokeWidth', 'borderWidth')
        ),
        $(
          go.Panel,
          'Vertical',
          { margin: 20 },
          $(
            go.TextBlock,
            {
              margin: new go.Margin(5, 5, 10, 5),
              name: 'TEXT'
            },
            new go.Binding('font', 'font'),
            new go.Binding('text', 'title'),
            new go.Binding('stroke', 'fontColor')
          ),
          $(go.Shape, { name: 'BODY', opacity: 0 })
        )
      )
    );
    //////////////////////////////
    // CONNECTION TEMPLATE DESIGN //
    //////////////////////////////
    diagram.linkTemplate = $(
      go.Link, // the whole link panel
      {
        routing: go.Link.Orthogonal,
        corner: 10
      },
      new go.Binding("curve"),
      $(
        go.Shape, // the link shape
        new go.Binding('stroke', 'color'),
        new go.Binding ('strokeWidth', 'strokeWidth')
      ),
      new go.Binding('stroke', 'color'),

      $(
        go.Shape, // the arrowhead
        { stroke: null },
        new go.Binding('toArrow', 'arrowHeadStyle'),
        new go.Binding('fill', 'color'),
        new go.Binding('scale', 'strokeWidth'),
      )
    );
    return diagram;
  }
  //////////////////////////
  // RETURN DIAGRAM BUILD //
  //////////////////////////
  return (
    <div className="mini-canvas-wrapper">
      <div className="gsfBackground">
        <ReactDiagram
          divClassName="mini-diagram"
          ref={diagramRef}
          nodeDataArray={previewNodes}
          linkDataArray={previewConnections}
          skipsDiagramUpdate={false}
          initDiagram={initDiagram}
        />
      </div>
    </div>
  );
};

MiniCanvas.propTypes = {
  themeTemplate: PropTypes.any,
  miniCanvasPreview: PropTypes.string
};

export default MiniCanvas;
