/**
 * @summary FilterAutoSuggest.js
 * @file Basic Autosuggest Component Used By All Filter Inputs Except Global Filter 
 * @returns {JSX}
 * @usedBy CanvasFilter.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */


import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Input,
} from "@progress/kendo-react-inputs";
import { toast } from 'react-toastify';

const FilterAutoSuggest = ({
    autoSuggestInput,
    setAutoSuggestInput,
    handleSelection,
    allAutoSuggestOptions,
    allSelectableSuggestions,
    setAllSelectableSuggestions,
    autoSuggestPlaceholder
}) => {  
    ////////////////////
    // Node Key Input //
    ////////////////////
    const handleInput = (e) => {
        if(allAutoSuggestOptions !== null){
            // As User Types, This Function Matches Inputs to Node Keys in the Array
            let input = e.target.value;
            let matches = [];
            if (input.length >= 1) {
                const regex = new RegExp(`${input}`, "gi");
                for(let i=0; i<allAutoSuggestOptions.length; i++){
                    regex.lastIndex = -1;
                    if(regex.test(allAutoSuggestOptions[i]) === true){
                        matches.push(allAutoSuggestOptions[i])
                    }
                }
            }
            // ChatGPT-Generated Sort Function that 
            // Sorts "String" Characters Alphabetically and looks at "Numbers" with multiple digits instead of individually
            // Can sort any complex Node Key into an order that's more intuitive for humans
            let newOrder = matches.sort((a, b) => {
                let aParts = a.match(/(\D+)|(\d+)/g);
                let bParts = b.match(/(\D+)|(\d+)/g);
                let aLen = aParts.length;
                let bLen = bParts.length;
                for (let i = 0; i < aLen && i < bLen; i++) {
                    let aPart = aParts[i];
                    let bPart = bParts[i];
                    if (isNaN(aPart) && isNaN(bPart)) {
                        if (aPart < bPart) {
                            return -1;
                        } else if (aPart > bPart) {
                            return 1;
                        }
                    } else if (!isNaN(aPart) && !isNaN(bPart)) {
                        let aNum = parseInt(aPart);
                        let bNum = parseInt(bPart);
                        if (aNum < bNum) {
                            return -1;
                        } else if (aNum > bNum) {
                            return 1;
                        }
                    } else {
                        return isNaN(aPart) ? 1 : -1;
                    }
                }
                return aLen - bLen;
            });
            setAllSelectableSuggestions(newOrder);
            setAutoSuggestInput(input)
        } else {
            toast.error('One moment... We are retreiving nodes for this map.')
        }
    }
    const selectValue = (item) => {
        setAutoSuggestInput(item)
        setAllSelectableSuggestions([])
        handleSelection(item)
    }

    // const suggestions = renderSuggestions()

    return(
        
        <div className='autosuggest-form-wrapper'>
            <Input 
                // style={{minHeight: 37, width: "90%"}}
                placeholder={autoSuggestPlaceholder}
                value={autoSuggestInput}
                className='autosuggest-filter-input'
                onChange={(e) => {
                    handleInput(e)
                }}
            />
        
        {
            allSelectableSuggestions.length > 0 ? (
            <div className='autosuggest-suggestion-wrapper'>
                {
                    allSelectableSuggestions.map(item => {
                        return (
                            <div 
                                className='autosuggest-suggestions' 
                                key={item}
                                onClick={() => selectValue(item)}
                            >
                                {item}
                            </div>
                        )
                    })
                }
            </div>
            ) : null
        }
        </div>
    )
        
}

FilterAutoSuggest.propTypes = {
    autoSuggestInput: PropTypes.string,
    setAutoSuggestInput: PropTypes.func,
    handleSelection: PropTypes.func,
    allAutoSuggestOptions: PropTypes.array,
    allSelectableSuggestions: PropTypes.array,
    setAllSelectableSuggestions: PropTypes.func,
    autoSuggestPlaceholder: PropTypes.string
};
    
export default FilterAutoSuggest;