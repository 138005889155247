/**
 * @summary DeactivateModal.jsx
 * @file Modal component that is responsible for giving the user an option to deactivate an active view after selecting an active view
 * @returns {JSX}
 * @usedBy ViewCollectionGrid.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import PropTypes from 'prop-types';

const DeactivateModal = ({ showModal, setShowModal, deleteFn, actionType }) => {
  return (
    <div>
      {showModal && (
      <Dialog title="Please confirm" onClose={() => setShowModal(false)}>
        <p style={{ margin: '25px', textAlign: 'center' }}>
          Are you sure you want to {actionType}?
        </p>
        <DialogActionsBar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setShowModal(false)}
          >
            No
          </button>
          <button
            type="button"
            className={actionType === 'Deactivate' ? ( "btn btn-danger" ) : ( "btn btn-primary" )}
            onClick={() => {
              deleteFn()
              setShowModal(false)
            }}
          >
            {actionType === 'Deactivate' ? ( <i className="bi bi-trash me-2" /> ) : ( <i className="bi bi-arrow-counterclockwise me-2" /> )}
            Yes
          </button>
        </DialogActionsBar>
      </Dialog>
    )}
    </div>
  )
}

export default DeactivateModal;

DeactivateModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  deleteFn: PropTypes.func,
  actionType: PropTypes.string,
}