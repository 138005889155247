/**
 * @summary globalFilterUtil.js
 * @file Filter Util: Takes All Inputs from GlobalFilterMenu.js and Renders Map Data into a Filtered List of Results within Parameters of User Inputs
 * @returns {JSX}
 * @usedBy GlobalFilterMainMenu.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const globalFilterUtil = (diagramData, unfilteredMapData) => {
    let unfilteredNodeArray = []
    let unfilteredNeighborhoodArray = []
    let allNeighborhoods = []
    let unfilteredConnectionArray = []
    // IF NODES ARE CHECKED
    // GRAB ALL MATCHING NODES
    // IF NEIGHBORHOODS ARE CHECKED
    // GRAB ALL MATCHING NEIGHBORHOODS
    // IF A NODE PROPERTIES IS SET TO TRUE, GRAB MATCHING NODES
    if(
        unfilteredMapData.nodes.keys === true || 
        unfilteredMapData.nodes.names === true || 
        unfilteredMapData.nodes.descriptions === true ||
        unfilteredMapData.nodes.attributes === true ||
        unfilteredMapData.neighborhoods.keys === true || 
        unfilteredMapData.neighborhoods.names === true || 
        unfilteredMapData.neighborhoods.descriptions === true ||
        unfilteredMapData.neighborhoods.attributes === true ||
        unfilteredMapData.connections.keys === true ||
        unfilteredMapData.connections.names === true
    ){
        // RUN THROUGH ALL NODES IN THE DIAGRAM
        diagramData.nodeDataArray.forEach(node => {
            // IF ANY NODE PROPERTIES CHECKED TRUE
            if(node.category !== "Super"){
                if(
                    unfilteredMapData.nodes.keys === true || 
                    unfilteredMapData.nodes.names === true || 
                    unfilteredMapData.nodes.descriptions === true ||
                    unfilteredMapData.nodes.attributes === true
                ) {
                    // ADDING DATA
                    // Node Keys
                    if(unfilteredMapData.nodes.keys === true && !unfilteredNodeArray.includes(node)){    
                        let nodeKey = node.nodeKey.toLowerCase() 
                        if(typeof unfilteredMapData.keywordInput === 'string'){    
                            if(nodeKey.includes(unfilteredMapData.keywordInput.toLowerCase())){
                                unfilteredNodeArray.push(node)
                            }
                        } else {
                            for(let i=0; i < unfilteredMapData.keywordInput.length; i ++){
                                if(nodeKey.includes(unfilteredMapData.keywordInput[i].toLowerCase())){
                                    unfilteredNodeArray.push(node)
                                }
                            }
                        }
                    }
                    // Node Names
                    if(unfilteredMapData.nodes.names === true && !unfilteredNodeArray.includes(node)){
                        let nodeName = node.name.toLowerCase() 
                        if(typeof unfilteredMapData.keywordInput === 'string'){    
                            if(nodeName.includes(unfilteredMapData.keywordInput.toLowerCase())){
                                unfilteredNodeArray.push(node)
                            }
                        } else {
                            for(let i=0; i < unfilteredMapData.keywordInput.length; i ++){
                                if(nodeName.includes(unfilteredMapData.keywordInput[i].toLowerCase())){
                                    unfilteredNodeArray.push(node)
                                }
                            }
                        }
                    }
                    // Node Descriptions
                    if(unfilteredMapData.nodes.descriptions === true && !unfilteredNodeArray.includes(node)){
                        let nodeDesctiption = node.description.toLowerCase() 
                        if(typeof unfilteredMapData.keywordInput === 'string'){    
                            if(nodeDesctiption.includes(unfilteredMapData.keywordInput.toLowerCase())){
                                unfilteredNodeArray.push(node)
                            }
                        } else {
                            for(let i=0; i < unfilteredMapData.keywordInput.length; i ++){
                                if(nodeDesctiption.includes(unfilteredMapData.keywordInput[i].toLowerCase())){
                                    unfilteredNodeArray.push(node)
                                }
                            }
                        }
                    }
                    if(unfilteredMapData.nodes.attributes === true && !unfilteredNodeArray.includes(node)){
                        node?.settings?.attributes?.forEach(attributeProperty => {
                            if(Object.keys(attributeProperty).length){
                                attributeProperty[Object.keys(attributeProperty)[0]]?.forEach(attributeValue => {
                                    if(attributeValue.value !== "False") {
                                        if(typeof unfilteredMapData.keywordInput === 'string'){    
                                            if(!unfilteredNodeArray.includes(node) && Object.keys(attributeProperty)[0].toString().toLowerCase().includes(unfilteredMapData.keywordInput.toString().toLowerCase())){
                                                unfilteredNodeArray.push(node)
                                            }
                                            if(!unfilteredNodeArray.includes(node) && attributeValue.value.toString().toLowerCase().includes(unfilteredMapData.keywordInput.toString().toLowerCase())){
                                                unfilteredNodeArray.push(node)
                                            }
                                        } else {
                                            for(let i=0; i < unfilteredMapData.keywordInput.length; i ++){
                                                if(!unfilteredNodeArray.includes(node) && Object.keys(attributeProperty)[0].toString().toLowerCase().includes(unfilteredMapData.keywordInput[i].toString().toLowerCase())){
                                                    unfilteredNodeArray.push(node)
                                                }
                                                if(!unfilteredNodeArray.includes(node) && attributeValue.value.toString().toLowerCase().includes(unfilteredMapData.keywordInput[i].toString().toLowerCase())){
                                                    unfilteredNodeArray.push(node)
                                                }
                                            }
                                        }
                                        
                                    }
                                })
                            }
                        })

                    }
                } 
            } else {
                allNeighborhoods.push(node)
            }

            // IF ANY NEIGHBORHOOD PROPERTIES CHECKED TRUE
            if(
                unfilteredMapData.neighborhoods.keys === true || 
                unfilteredMapData.neighborhoods.names === true || 
                unfilteredMapData.neighborhoods.descriptions === true ||
                unfilteredMapData.neighborhoods.attributes === true
            ) {
                if(node.category === "Super"){
                    // Grab Matching Neighborhood Keys
                    if(unfilteredMapData.neighborhoods.keys === true){
                        let neighborhoodKey = node.nodeKey.toString().toLowerCase() 
                        if(typeof unfilteredMapData.keywordInput === 'string'){
                            if(neighborhoodKey.includes(unfilteredMapData.keywordInput.toString().toLowerCase() ) && !unfilteredNeighborhoodArray.includes(node.nodeKey)){
                                unfilteredNeighborhoodArray.push(node)
                            }
                        } else {
                            for(let i=0; i < unfilteredMapData.keywordInput.length; i ++){
                                if(neighborhoodKey.includes(unfilteredMapData.keywordInput[i].toLowerCase())){
                                    unfilteredNodeArray.push(node)
                                }
                            }
                        }
                    }
                    // Grab Matching Neighborhood Names
                    if(unfilteredMapData.neighborhoods.names === true){
                        let neighborhoodName = node.name.toString().toLowerCase() 
                        if(typeof unfilteredMapData.keywordInput === 'string'){
                            if(neighborhoodName.includes(unfilteredMapData.keywordInput.toString().toLowerCase() ) && !unfilteredNeighborhoodArray.includes(node.name)){
                                unfilteredNeighborhoodArray.push(node)
                            }
                        } else {
                            for(let i=0; i < unfilteredMapData.keywordInput.length; i ++){
                                if(neighborhoodName.includes(unfilteredMapData.keywordInput[i].toString().toLowerCase() ) && !unfilteredNeighborhoodArray.includes(node.name)){
                                    unfilteredNeighborhoodArray.push(node)
                                }
                            }
                        }
                    }
                    // Grab Matching Neighborhood Descriptions
                    if(unfilteredMapData.neighborhoods.descriptions === true){
                        let neighborhoodDescription = node.description.toString().toLowerCase() 
                        if(typeof unfilteredMapData.keywordInput === 'string'){
                            if(neighborhoodDescription.includes(unfilteredMapData.keywordInput.toString().toLowerCase()) && !unfilteredNeighborhoodArray.includes(node.description)){
                                unfilteredNeighborhoodArray.push(node)
                            }
                        } else {
                            for(let i=0; i < unfilteredMapData.keywordInput.length; i ++){
                                if(neighborhoodDescription.includes(unfilteredMapData.keywordInput[i].toString().toLowerCase()) && !unfilteredNeighborhoodArray.includes(node.description)){
                                    unfilteredNeighborhoodArray.push(node)
                                }
                            }
                        }
                    }
                    if(unfilteredMapData.neighborhoods.attributes === true){
                        node?.settings?.attributes?.forEach(attributeProperty => {
                            if(Object.keys(attributeProperty).length){
                                attributeProperty[Object.keys(attributeProperty)[0]]?.forEach(attributeValue => {
                                    if(attributeValue.value !== "False") {
                                        if(typeof unfilteredMapData.keywordInput === 'string'){
                                            if(!unfilteredNodeArray.includes(node) && 
                                            Object.keys(attributeProperty)[0].toString().toLowerCase().includes(unfilteredMapData.keywordInput.toString().toLowerCase()) ){
                                                unfilteredNodeArray.push(node)
                                            }
                                            if(!unfilteredNodeArray.includes(node) && 
                                            attributeValue.value.toString().toLowerCase().includes(unfilteredMapData.keywordInput.toString().toLowerCase())){
                                                unfilteredNodeArray.push(node)
                                            }
                                        } else {
                                            for(let i=0; i < unfilteredMapData.keywordInput.length; i ++){
                                                if(!unfilteredNodeArray.includes(node) && 
                                                Object.keys(attributeProperty)[0].toString().toLowerCase().includes(unfilteredMapData.keywordInput[i].toString().toLowerCase()) ){
                                                    unfilteredNodeArray.push(node)
                                                }
                                                if(!unfilteredNodeArray.includes(node) && 
                                                attributeValue.value.toString().toLowerCase().includes(unfilteredMapData.keywordInput[i].toString().toLowerCase())){
                                                    unfilteredNodeArray.push(node)
                                                }
                                            }
                                        }
                                    }
                                })
                            }
                        })
                    }
                }
            } 
        })

        // IF NODES NEED TO INCLUDE NEIGHBORHOODS OR VICE VERSA, GRAB RELEVANT DATA
        if(
            unfilteredMapData.nodes.includeNeighborhoods === true
        ){
            // Run Through All Neighborhoods Isolated Above
            allNeighborhoods.forEach(neighborhood => {
                neighborhood._members.forEach(memberNode => {
                     // Grab Any Neighborhoods Associated with Nodes if Checked
                    if(unfilteredMapData.nodes.includeNeighborhoods === true && unfilteredNodeArray.length > 0){
                        let nodeExists = unfilteredNodeArray?.find(node => node.nodeKey === memberNode)
                        if(nodeExists && !unfilteredNeighborhoodArray.includes(neighborhood)){
                            unfilteredNeighborhoodArray.push(neighborhood)
                        }
                    }
                })
            })
        }

        // Grab All Member Nodes of Each Neighborhood if Checked
        if(unfilteredMapData.neighborhoods.includeNodes === true && unfilteredNeighborhoodArray.length > 0) {
            unfilteredNeighborhoodArray.forEach(neighborhood => {
                neighborhood?._members.forEach(memberNode => {
                    let nodeFound = diagramData.nodeDataArray.find(node => node.nodeKey === memberNode)
                    if(nodeFound && !unfilteredNodeArray.includes(nodeFound)){
                        unfilteredNodeArray.push(nodeFound)
                    }
                })
            })
        }
                   
        // IF 'INCLUDE CONNECTIONS' OR ANY CONNECTION PROPERTY WAS CHECKED 
        if(
            unfilteredMapData.nodes.includeConnections === true || 
            unfilteredMapData.connections.keys === true ||
            unfilteredMapData.connections.names === true
        ){
            diagramData.linkDataArray.forEach(connection => {
                // Grab Connection Keys if True
                if(unfilteredMapData.connections.keys === true || unfilteredMapData.connections.names === true){
                    let destinationNode = diagramData.nodeDataArray.find(node => node.nodeKey === connection.to)
                    let sourceNode = diagramData.nodeDataArray.find(node => node.nodeKey === connection.from)
                    if(unfilteredMapData.connections.keys === true){
                        if(typeof unfilteredMapData.keywordInput === 'string'){    
                            if(connection.id.toString().includes(unfilteredMapData.keywordInput) && !unfilteredConnectionArray.includes(connection)){
                                if(!unfilteredConnectionArray.includes(connection)){
                                    unfilteredConnectionArray.push(connection)
                                }
                                if(!unfilteredNodeArray.includes(sourceNode)){
                                    unfilteredNodeArray.push(sourceNode)
                                }
                                if(!unfilteredNodeArray.includes(destinationNode)){
                                    unfilteredNodeArray.push(destinationNode)
                                }
                                
                            }
                        } else {
                            for(let i=0; i < unfilteredMapData.keywordInput.length; i ++){
                                if(connection.id.toString().toLowerCase().includes(unfilteredMapData.keywordInput[i].toString().toLowerCase()) && !unfilteredConnectionArray.includes(connection)){
                                    if(!unfilteredConnectionArray.includes(connection)){
                                        unfilteredConnectionArray.push(connection)
                                    }
                                    if(!unfilteredNodeArray.includes(sourceNode)){
                                        unfilteredNodeArray.push(sourceNode)
                                    }
                                    if(!unfilteredNodeArray.includes(destinationNode)){
                                        unfilteredNodeArray.push(destinationNode)
                                    }
                                    
                                }
                            }
                        }
                    }
                    // Grab Connection Names
                    if(unfilteredMapData.connections.names === true){
                        if(typeof unfilteredMapData.keywordInput === 'string'){
                            if(connection.name.toString().toLowerCase().includes(unfilteredMapData.keywordInput.toString().toLowerCase()) && !unfilteredConnectionArray.includes(connection)){
                                if(!unfilteredConnectionArray.includes(connection)){
                                    unfilteredConnectionArray.push(connection)
                                }
                                if(!unfilteredNodeArray.includes(sourceNode)){
                                    unfilteredNodeArray.push(sourceNode)
                                }
                                if(!unfilteredNodeArray.includes(destinationNode)){
                                    unfilteredNodeArray.push(destinationNode)
                                }
                                
                            }
                        } else {
                            for(let i=0; i < unfilteredMapData.keywordInput.length; i ++){
                                if(connection.name.toString().toLowerCase().includes(unfilteredMapData.keywordInput[i].toString().toLowerCase()) && !unfilteredConnectionArray.includes(connection)){
                                    if(!unfilteredConnectionArray.includes(connection)){
                                        unfilteredConnectionArray.push(connection)
                                    }
                                    if(!unfilteredNodeArray.includes(sourceNode)){
                                        unfilteredNodeArray.push(sourceNode)
                                    }
                                    if(!unfilteredNodeArray.includes(destinationNode)){
                                        unfilteredNodeArray.push(destinationNode)
                                    }
                                    
                                }
                            }
                        }
                    }   
                }
                // If Nodes "Include Connections" is Checked            
                if(unfilteredMapData.nodes.includeConnections === true && unfilteredNodeArray.length > 0){
                    let includedDestinationNode = unfilteredNodeArray?.find(node => node.nodeKey === connection.to)
                    let includedSourceNode = unfilteredNodeArray.find(node => node.nodeKey === connection.from)
                    if(includedDestinationNode !== undefined && includedSourceNode !== undefined && !unfilteredConnectionArray.includes(connection)){
                        unfilteredConnectionArray.push(connection)
                    }
                }
            })
        }
            
    } 

    // SUBTRACTING DATA
    let subtractions = []
    if(Array.isArray(unfilteredMapData.keywordInput)){
        let subtractionInputs = unfilteredMapData.keywordInput.filter(inputItem => {if(inputItem[0] === `-`){return inputItem}})
        subtractionInputs.forEach(input => subtractions.push(input.substring(1)))
    }
    let scrubbedNodeArray = unfilteredNodeArray.filter(node => {
        if (subtractions.length > 0) {
            let attributePropertyArray = [];
            let attributeValueArray = [];
            node?.settings?.attributes?.forEach(attributeProperty => {
            if (Object.keys(attributeProperty).length) {
                attributePropertyArray.push(Object.keys(attributeProperty)[0])
                attributeProperty[Object.keys(attributeProperty)[0]]?.forEach(attributeValue => {
                if (!attributeValueArray.includes(attributeValue)) {
                    attributeValueArray.push(attributeValue.value)
                }
                })
            }
            })
            if (subtractions.some(subtraction => node.nodeKey.toString().toLowerCase().includes(subtraction.toString().toLowerCase()) || node.name.toString().toLowerCase().includes(subtraction.toString().toLowerCase()) || node.description.toString().toLowerCase().includes(subtraction.toString().toLowerCase()))) {
                return false;
            } else if (attributePropertyArray.some(attribute => subtractions.some(subtraction => attribute.toString().toLowerCase().includes(subtraction.toString().toLowerCase())))) {
                return false;
            } else if (attributeValueArray.some(attribute => subtractions.some(subtraction => attribute.toString().toLowerCase().includes(subtraction.toString().toLowerCase())))) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    })

    let scrubbedNeighborhoodArray = unfilteredNeighborhoodArray.filter(node => {
        if (subtractions.length > 0) {
            let attributePropertyArray = [];
            let attributeValueArray = [];
            node?.settings?.attributes?.forEach(attributeProperty => {
            if (Object.keys(attributeProperty).length) {
                attributePropertyArray.push(Object.keys(attributeProperty)[0])
                attributeProperty[Object.keys(attributeProperty)[0]]?.forEach(attributeValue => {
                if (!attributeValueArray.includes(attributeValue)) {
                    attributeValueArray.push(attributeValue.value)
                }
                })
            }
            })
            if (subtractions.some(subtraction => node.nodeKey.toString().toLowerCase().includes(subtraction.toString().toLowerCase()) || node.name.toString().toLowerCase().includes(subtraction.toString().toLowerCase()) || node.description.toString().toLowerCase().includes(subtraction.toString().toLowerCase()))) {
                return false;
            } else if (attributePropertyArray.some(attribute => subtractions.some(subtraction => attribute.toString().toLowerCase().includes(subtraction.toString().toLowerCase())))) {
                return false;
            } else if (attributeValueArray.some(attribute => subtractions.some(subtraction => attribute.toString().toLowerCase().includes(subtraction.toString().toLowerCase())))) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    })

    let newGlobalFilterData = {
        nodeDataArray: scrubbedNodeArray.concat(scrubbedNeighborhoodArray),
        linkDataArray: unfilteredConnectionArray,
        keywordInput: unfilteredMapData.keywordInput,
        globalData: {
            keywordInput: unfilteredMapData.keywordInput,
            nodes: { 
                keys: unfilteredMapData.nodes.keys,
                names: unfilteredMapData.nodes.names,
                descriptions: unfilteredMapData.nodes.descriptions,
                includeNeighborhoods: unfilteredMapData.nodes.includeNeighborhoods,
                includeConnections: unfilteredMapData.nodes.includeConnections,
            },
            neighborhoods: {
                keys: unfilteredMapData.neighborhoods.keys,
                names: unfilteredMapData.neighborhoods.names,
                descriptions: unfilteredMapData.neighborhoods.descriptions,
                includeNodes: unfilteredMapData.neighborhoods.includeNodes,
            },
            connections: {
                keys: unfilteredMapData.connections.keys,
                names: unfilteredMapData.connections.names, 
            }
        },
        errorMessage: null,
    }
    return newGlobalFilterData;
}