/**
 * @summary BooleanCheckboxCell.js
 * @file Customized component to displays boolean value in KendoGridBase based on if dataItem is selected or not
 * @returns {JSX}
 * @usedBy KendoGridBase.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { Checkbox } from '@progress/kendo-react-inputs';
import React from 'react';
import { GridContext } from '../KendoGridBase';
import PropTypes from 'prop-types';

const BooleanCheckboxCell = ({ dataItem }) => {
  const currentContext = React.useContext(GridContext);
  const disabled = !!currentContext.isCheckboxDisabled(dataItem);

  return (
    <td>
      <Checkbox
        disabled={disabled}
        onChange={() => currentContext.onCheckboxToggle(dataItem)}
        value={currentContext.isCheckboxSelected(dataItem)}
        key={currentContext.isCheckboxSelected(dataItem)}
      />
    </td>
  );
};

BooleanCheckboxCell.propTypes = {
  dataItem: PropTypes.object
};

export default BooleanCheckboxCell;
