/**
 * @summary FormattedCell.js
 * @file Given a format, this function is to create a custom cell template component with Customized content (Div, DropDown, Manipulated Text) that can be passed to kendo cell props as a function.
 * @returns {JSX}
 * @usedBy LinkageLevelMediaGrid.js, NeighborhoodMediaGrid.js, NodeMediaGrid.js, MapUploadGrid.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

/* eslint-disable react/display-name */

/* eslint-disable react/destructuring-assignment */

import React from 'react';

const FormattedCell = (customCell) => (row) => {
  const cell = row?.dataItem && row?.dataItem[row?.field];
  return <td>{customCell ? customCell(cell, row) : undefined}</td>;
};

export default FormattedCell;
