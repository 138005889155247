
/**
 * @summary CanvasExportMap.js
 * @file Modal with input options that ultimately renders the map based on those options
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { toast } from 'react-toastify';

const CanvasExportMap = ({
    showMapDownloadModal, 
    setShowMapDownloadModal, 
    diagramAccess 
}) => {
    // FILE NAME AND TYPE SELECTED BY USER
    const [downloadFileName, setDownloadFileName] = useState(null);
    const [downloadFileType, setDownloadFileType] = useState("jpeg");

    function handleSelectedFileType(e) {
        setDownloadFileType(e.target.value)
    }

    function handleInputSaveName(e) {
        setDownloadFileName(e.target.value)
    }

    // THIS FUNCTION DOWNLOADS THE IMAGE OR PDF
    function downloadImage(image, fileName, isPdf){
        let url = window.URL.createObjectURL(image);

        let a = document.createElement("a");
        a.style = "display: none";
        a.href = url;
        a.download = fileName;

        // DETECTS IF THIS FILE CONTAINS DOWNLOADABLE DATA
        if (window.navigator.msSaveBlob !== undefined) {
            window.navigator.msSaveBlob(image, fileName);
            return;
        }

        document.body.appendChild(a);
        requestAnimationFrame(() => {
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        });
    }
    // THIS FUNCTION WILL GENERATE A PDF ONCE BACKEND IS SET UP
    // function generatePdf(image, fileName){
        // CREATE SVG
        // let svg = diagramAccess.makeSvg({ scale: 1, background: "white" });
        // let svgstr = new XMLSerializer().serializeToString(svg);

        // NEED A FUNCTION WILL RENDER THE SVG TO THE BACKEND SO IT CAN BE RETURNED AND ADDED TO THE PDF
        // 1) NEED TO ADD TABLE AND ROUTE ON THE BACKEND
        // 2) NEED TO WRITE FUNCTION THAT SENDS THIS SVG TO THE BACKEND
        // 3) NEED TO RETURN THE SVG - RENDERED - SO IT CAN BE ADDED TO PDF BELOW

        // BUILDS PDF AND ADDS SVG TO PDF
        // const pdf = new jsPDF()
        // pdf.addSvgAsImage(svgstr, 20, 20, svg.width.baseVal.value, svg.height.baseVal.value, downloadFileName + '.svg', 'NONE', 0)
        // pdf.save(downloadFileName + '.pdf')
    // }

    // THIS FUNCTION FORMATS THE FILE NAME, AND SENDS THE IMAGE AND FILE NAME TO BE DOWNLOADED
    function formatImage(image) {
        if(downloadFileType === 'svg') {
            let fileName = (downloadFileName + ".svg")
            downloadImage(image, fileName)
        } else if (downloadFileType === "png"){
            let fileName = (downloadFileName + ".png")
            downloadImage(image, fileName)
        } else if (downloadFileType === "jpeg"){
            let fileName = (downloadFileName + ".jpeg")
            downloadImage(image, fileName)
        } else if (downloadFileType === "pdf") {
            let fileName = (downloadFileName + ".jpeg")
            generatePdf(image, fileName)
        }
    }

    // THIS BUILDS THE SELECTED IMAGE FILE AND SENDS IT TO BE FORMATTED AND DOWNLOADED
    function handleDownloadCanvas() {
        if(downloadFileName !== null ) {
            if(downloadFileType === 'svg') {
                let svg = diagramAccess.makeSvg({ scale: 1, background: "white" });
                let svgstr = new XMLSerializer().serializeToString(svg);
                let image = new Blob([svgstr], { type: "image/svg+xml" });
                formatImage(image);
            } else if(downloadFileType === 'jpeg' || downloadFileType === 'png') {
                diagramAccess.makeImageData({ background: "white", returnType: "blob", callback: formatImage });
            } else if(downloadFileType === 'pdf') {
                // NEED TO BUILD FORMAT FUNCTIONALITY - BEING ADDED LATER
                // generatePdf()
            }
        } else {
            toast.error(
                'You need to enter a name for your download file.'
              );
        }


    }

    // THIS CLOSES THE MODAL 
    function handleCloseModal() {
        setShowMapDownloadModal(false)
    }

    // WAIT FOR MODAL TO BE VISIBLE (SO DATA WILL BE THERE)
    return(
        <Modal show={showMapDownloadModal} backdrop="static" centered size="lg">
        <Modal.Header>
            <Modal.Title className="view-save-modal-header text-white text-center">Download this Map as an Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="col-12 pt-3">
                <div className="text-center">
                    Select a file type, name your image, and click Save to download the file.
                </div>
            </div>
            <div className="col-12 p-3">  
                <div className="row">
                    <div className="col-1">
                        <form>
                            <select
                                className="canvas-download-dropdown"
                                onChange={handleSelectedFileType}
                                value={downloadFileType}
                            >
                                <option value="jpeg">JPEG</option>
                                <option value="png">PNG</option>
                                <option value="svg">SVG</option>
                                {/* <option value="pdf">PDF</option> */}
                            </select>
                        </form>
                    </div>
                    <div className="col-11">
                        <div className="save-filter-input-container">
                            <input placeholder="Select a File Format, Name Your File, and Click Save" onChange={handleInputSaveName} type="text"></input>            
                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer>
        <div className='container'>
                    <div className='row'>
                        <div className='col-6'>
                            <button 
                                className="btn btn-primary btn-sm text-white"
                                onClick={handleDownloadCanvas}
                            >
                                Save
                            </button>
                        </div>
                        <div className='col-6'>
                            <button 
                                className="btn btn-danger btn-sm text-white" 
                                onClick={handleCloseModal}
                            >
                                Cancel
                            </button>
                        </div>             
                    </div>
                </div>
        </Modal.Footer>
    </Modal>
    // handleCloseModal
    // handleDownloadCanvas

    )
}

CanvasExportMap.propTypes = {
    showMapDownloadModal: PropTypes.bool,
    setShowMapDownloadModal: PropTypes.func,
    diagramAccess: PropTypes.any
  };

export default CanvasExportMap;