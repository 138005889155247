/**
 * @summary axiosCycleCheck.js
 * @file Function is responsible for formatting nodes, memberships, and connections into arrays. Returns an object that contains that formatted data as well as current branch
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import cloneDeep from 'lodash/cloneDeep';

export const formatSnapshots = (unformattedProjectData) => {
    //////////////////////
    // FORMATTING NODES //
    //////////////////////
    let formattedNodes = []
    for(let i=0; i < unformattedProjectData.nodes.length; i++){
        
        let node = cloneDeep(unformattedProjectData.nodes[i])
        ////////////////////////////////
        // FORMATTING NODE ATTRIBUTES //
        ////////////////////////////////
        let nodeAttributes = []
        if(node.settings?.attributes !== undefined) {
            node.settings.attributes.forEach(attribute => {
                if(Object.keys(attribute).length > 0){
                    let attributeName = Object.keys(attribute)[0]
                    
                    // let attributeValues = 
                    let formattedAttribute = {
                        type: "node attribute",
                        attributeName,
                        attributeValues: attribute[attributeName].map(obj => obj.value)
                    }
                    nodeAttributes.push(formattedAttribute)
                }         
            })
        }
        /////////////////////////////////
        // FORMATTING NODE MEMBERSHIPS //
        /////////////////////////////////
        
        let alphabeticalOrder = nodeAttributes.sort((a,b) => (a.attributeName > b.attributeName) ? 1 : ((b.attributeName > a.attributeName) ? -1 : 0))
        let formattedNode = {
            type: "node",
            id: node.id,
            key: node.nodeKey,
            name: node.name,
            description: node.description,
            checkedOutBy: node.checkedOutBy,
            attributes: alphabeticalOrder
        }
        formattedNodes.push(formattedNode)
    }

    //////////////////////////////
    // FORMATTING NEIGHBORHOODS //
    //////////////////////////////
    let formattedNeighborhoods = [];
    for(let i=0; i < unformattedProjectData.neighborhoods.length; i++){
        let neighborhood = cloneDeep(unformattedProjectData.neighborhoods[i])
        let neighborhoodAttributes = []
        if(neighborhood.settings.attributes !== undefined) {
            neighborhood.settings.attributes.forEach(attribute => {
                if(Object.keys(attribute).length > 0){
                    let attributeName = Object.keys(attribute)[0]
                    // let attributeValues = 
                    let formattedAttribute = {
                        type: "neighborhood attribute",
                        attributeName,
                        attributeValues: attribute[attributeName].map(obj => obj.value)
                    }
                    neighborhoodAttributes.push(formattedAttribute)

                }         
            })
        }
        let formattedNeighborhood = {     
            type: "neighborhood",       
            key: neighborhood.id.toString(),
            id: neighborhood.id,
            name: neighborhood.name,
            description: neighborhood.description,
            checkedOutBy: neighborhood.checkedOutBy,
            attributes: neighborhoodAttributes,
            members: neighborhood.nodeKeys
        }
        formattedNeighborhoods.push(formattedNeighborhood)
    }

    let formattedConnections = [];
    for(let i=0; i < unformattedProjectData.connections.length; i++){
        let connection = unformattedProjectData.connections[i]
        let connectionAttributes = []
        if(connection.settings.attributes !== undefined) {
            connection.settings.attributes.forEach(attribute => {
                if(Object.keys(attribute).length > 0){
                    let attributeName = Object.keys(attribute)[0]
                    // let attributeValues = 
                    let formattedAttribute = {
                        type: "connection attribute",
                        attributeName,
                        attributeValues: attribute[attributeName].map(obj => obj.value)
                    }
                    connectionAttributes.push(formattedAttribute)

                }         
            })
        }
        let formattedConnection = {     
            type: "connection",       
            key: connection.id,
            name: connection.name,
            checkedOutBy: connection.checkedOutBy,
            attributes: connectionAttributes,
        }
        formattedConnections.push(formattedConnection)
    }

    let formattedData = {
        branch: unformattedProjectData.branch,
        nodes: formattedNodes,
        neighborhoods: formattedNeighborhoods,
        connections: formattedConnections
    }

    return formattedData
}