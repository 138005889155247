/**
 * @summary axiosSnapShot.js
 * @file Function uses axios requests to find nodes/connections/neighborhoods associated with user specified branches, and then returns an array of objects of both branches' nodes, connections, neighborhoods, and the branch
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios'
export const axiosSnapshots = async (projectId, branchA, branchB) => {
  
  const viewA = branchA.type === "view" ? await axios.get(`/api/projects/${projectId}/views/${branchA.id}`) : "Not a View"
  const viewB = branchB.type === "view" ? await axios.get(`/api/projects/${projectId}/views/${branchB.id}`) : "Not a View"

  const nodesA = branchA.type !== "view" ? await axios.get(`/api/projects/${projectId}/branch/${branchA.id}/nodes`) : viewA.data.savedNodes
  const connectionsA = branchA.type !== "view" ? await axios.get(`/api/projects/${projectId}/branch/${branchA.id}/connections`) : viewA.data.savedConnections
  const neighborhoodsA = branchA.type !== "view" ? await axios.get(`/api/projects/${projectId}/branch/${branchA.id}/neighborhoods`) : viewA.data.savedNeighborhoods
  // const linkageLevelsA = await axios.get(`/api/project/${projectId}/branch/${branchA.id}/linkageLevel`) 

  const nodesB = branchB.type !== "view" ? await axios.get(`/api/projects/${projectId}/branch/${branchB.id}/nodes`) : viewB.data.savedNodes
  const connectionsB = branchB.type !== "view" ? await axios.get(`/api/projects/${projectId}/branch/${branchB.id}/connections`) : viewB.data.savedConnections
  const neighborhoodsB = branchB.type !== "view" ? await axios.get(`/api/projects/${projectId}/branch/${branchB.id}/neighborhoods`) : viewB.data.savedNeighborhoods
  // const linkageLevelsB = await axios.get(`/api/project/${projectId}/branch/${branchB.id}/linkageLevel`) 

  const branchNodesA = branchA.type === "branch" ? nodesA.data.filter(node => node.branchId === branchA.id) : branchA.type === "published branch" ? nodesA.data : nodesA
  const branchConnectionsA = branchA.type === "branch" ? connectionsA.data.filter(connection => connection.branchId === branchA.id) : branchA.type === "published branch" ? connectionsA.data : connectionsA
  const branchNeighborhoodA = branchA.type === "branch" ? neighborhoodsA.data.filter(neighborhood => neighborhood.branchId === branchA.id) : branchA.type === "published branch" ? neighborhoodsA.data : neighborhoodsA

  const branchNodesB = branchB.type === "branch" ? nodesB.data.filter(node => node.branchId === branchB.id) : branchB.type === "published branch" ? nodesB.data : nodesB
  const branchConnectionsB = branchB.type === "branch" ? connectionsB.data.filter(connection => connection.branchId === branchB.id) : branchB.type === "published branch" ? connectionsB.data : connectionsB
  const branchNeighborhoodB = branchB.type === "branch" ? neighborhoodsB.data.filter(neighborhood => neighborhood.branchId === branchB.id) : branchB.type === "published branch" ? neighborhoodsB.data : neighborhoodsB

  let errorMessages = []
  if(branchNodesA.length === 0 && branchNodesB.length === 0){
    errorMessages.push("There are no nodes in either of these branches. You can manually add nodes in Table View, or upload a spreadsheet formatted to your project template in Map Upload.")
  } 

  const snapshotPair = [
    {
      nodes: branchNodesA,
      connections: branchConnectionsA,
      neighborhoods: branchNeighborhoodA,
      // linkageLevels: linkageLevelsA.data,
      branch: branchA,
    }
    ,
    {
      nodes: branchNodesB,
      connections: branchConnectionsB,
      neighborhoods: branchNeighborhoodB,
      // linkageLevels: linkageLevelsB.data,
      branch: branchB
    }
  ]
  return snapshotPair
}