/**
 * @summary CKEditorContentCell.js
 * @file Component that displays parsed html values from CKEditor component in KendoGridBase
 * @returns {JSX}
 * @usedBy KendoGridBase.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */
import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

const CKEditorContentCell = ({ dataItem, field }) => (
  <td>
    {dataItem === null || dataItem[field] === null
      ? ''
      : dataItem[field].replace(/(<([^>]+)>|&nbsp;)/gi, '')}
  </td>
);
CKEditorContentCell.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.any
};

export default CKEditorContentCell;
