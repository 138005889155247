/**
 * @summary KendoGridBase.js
 * @file Grid component that renders on UI when appropriate props are passed down
 * @returns {JSX}
 * @usedBy ConnectionCollectionGrid.js, LinkageLevelMediaGrid.js, NeighborhoodCollectionGrid.js, NeighborhoodMediaGrid.js, NeighborhoodNodesSettings.js, Memberships.js, NodeCollectionGrid.js, NodeConnectionsSettings.js, NodeMediaGrid.js, NestedGrid.js, MapUploadGrid.js, ViewCollectionGrid.js, DiffGrid.jsx, inactiveSettings.js, BranchListGrid.js, DjBranchListGrid.js, ArrayObjectCell.js, CKEditorContentCell.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import ColumnMenuFilter from './Grid/ColumnMenu';
import DraggableCell from './Grid/DraggableCell';
import BooleanCheckboxCell from './Grid/BooleanCheckboxCell';
import CKEditorContentCell from './Grid/CKEditorContentCell';
import ArrayObjectCell from './Grid/ArrayObjectCell';
import ArrayCell from './Grid/ArrayCell';
import PropTypes from 'prop-types';

export const GridContext = React.createContext({
  reorder: null,
  dragStart: null,
  isCheckboxDisabled: null,
  onCheckboxToggle: null,
  isCheckboxSelected: null
});

const KendoGridBase = ({
  data,
  gridColumns,
  setGridFilters,
  setGridSort,
  updateGridData,
  onSelectionChange,
  onRowSingleClick,
  onRowDoubleClick,
  onPageChange,
  onSelectAllChange,
  onReorder,
  onDragStart,
  isCheckboxDisabled,
  onCheckboxToggle,
  isCheckboxSelected,
  sorter,
  rowHeight,
  skip,
  take,
  total,
  pageSize,
  selectable,
  pageable,
  sortable,
  detailComponent,
  expandField,
  onExpandChange,
  disableMultiSelect,
  noMenu,
  selectAll
}) => {
  const filterOperators = {
    text: [
      { text: 'grid.filterContainsOperator', operator: 'contains' },
      { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
      { text: 'grid.filterEqOperator', operator: 'eq' },
      { text: 'grid.filterNotEqOperator', operator: 'neq' }
    ],
    'ck-editor-text': [
      { text: 'grid.filterContainsOperator', operator: 'contains' },
      { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' }
    ],
    numeric: [
      { text: 'grid.filterEqOperator', operator: 'eq' },
      { text: 'grid.filterNotEqOperator', operator: 'neq' },
      { text: 'grid.filterGteOperator', operator: 'gte' },
      { text: 'grid.filterGtOperator', operator: 'gt' },
      { text: 'grid.filterLteOperator', operator: 'lte' },
      { text: 'grid.filterLtOperator', operator: 'lt' }
    ],
    date: [
      { text: 'grid.filterEqOperator', operator: 'eq' },
      { text: 'grid.filterNotEqOperator', operator: 'neq' },
      { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
      { text: 'grid.filterAfterOperator', operator: 'gt' },
      { text: 'grid.filterBeforeOperator', operator: 'lt' },
      { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' },
      { text: 'grid.filterIsNullOperator', operator: 'isnull' },
      { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' }
    ],
    boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }]
  };

  const [gridData, setData] = useState(data || []);
  const [filter, setFilter] = useState({
    logic: 'and',
    filters: []
  });

  const [sorterState, setSorterState] = useState([]);
  const [skipState, setSkipState] = useState(skip || 0);
  const [takeState, setTakeState] = useState(take || 10);
  const [page, setPage] = useState({ page: [] });
  const [columns, setColumnsState] = useState(gridColumns);

  useEffect(() => {
    setColumnsState(gridColumns);
  }, [gridColumns]);
  useEffect(() => {
    if (data !== undefined) {
      setData(data);
    }
    if (skip) {
      setSkipState(skip);
    }
    if (take) {
      setTakeState(take);
    }
  }, [gridData, data, skip, take]);

  useEffect(() => {
    setSorterState(sorter);
  }, [sorter]);

  const headerSelectionChange = (e) => {
    const { checked } = e.syntheticEvent.target;
    if (gridData && gridData.length > 0) {
      setData(
        gridData.map((el, i) => {
          data[i].selected = checked;
          el.selected = checked;
          return el;
        })
      );
      updateGridData(gridData);
    }
  };

  const onColumnsSubmitFn = (custColumns) => {
    setColumnsState(custColumns);
  };

  const handlePageChange = (e) => {
    setPage(e.page);
    setSkipState(e.page.skip);
    setTakeState(e.page.take);
  };

  useEffect(() => {
    setSkipState(skip);
  }, [skip]);

  const handleFilterChange = (e) => {
    // Grid requires one format and gridfilters require another format
    // Safe check while clearing filters
    if (e.filter) {
      if (e.filter.filters) {
        const f = filter;
        f.filters = e.filter.filters;
        setFilter(f);
      }
      if (setGridFilters) {
        const f = {};
        f.logic = e.filter.logic;
        f.filters = e.filter.filters.map((fi) => fi.filters[0]);
        setGridFilters(f);
      }
    } else {
      setFilter({
        logic: 'and',
        filters: []
      });
      setGridFilters(filter);
    }
  };

  const handleSortChange = (e) => {
    if (setGridSort) {
      setGridSort(e.sort);
    } else {
      setSorterState(e.sort);
    }
  };

  return (
    <div>
      <GridContext.Provider
        value={{
          reorder: onReorder,
          dragStart: onDragStart,
          isCheckboxDisabled,
          onCheckboxToggle,
          isCheckboxSelected
        }}
      >
        <Grid
          {...filter}
          // This may stay, but a short-term solution to issue 419 is to comment this out until we know for sure.
          // reorderable
          resizable
          pageSize={pageSize || 1}
          data={
            gridData && filter && sorterState && takeState
              ? orderBy(filterBy(gridData, filter), sorterState).slice(
                  skipState,
                  takeState + skipState
                )
              : gridData
          }
          filter={filter}
          filterOperators={filterOperators}
          pageable={pageable}
          selectedField={selectable}
          skip={page.skip ? page.skip : skipState}
          sortable={sortable}
          rowHeight={rowHeight || null}
          take={page.take ? page.take : takeState}
          total={total}
          updateGridData={updateGridData}
          onHeaderSelectionChange={(e) => {
            disableMultiSelect ? null : headerSelectionChange(e);
            onSelectAllChange(e);
          }}
          onPageChange={onPageChange || handlePageChange}
          onRowClick={onRowSingleClick}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortChange}
          onSelectionChange={onSelectionChange}
          detail={detailComponent}
          expandField={expandField}
          onExpandChange={onExpandChange}
        >
          {columns &&
            columns.map((el) => {
              const editprops = {};
              if (el.editable) {
                editprops.editable = el.editable;
              } else {
                editprops.editable = false;
              }
              if (el.editor) {
                editprops.editor = el.editor;
              }
              if (el.show) {
                if (el.field === 'selected') {
                  return (
                    <Column
                      key={el}
                      field="selected"
                      resizable
                      locked={el.locked ? el.locked : undefined}
                      {...editprops}
                      headerSelectionValue={selectAll}
                    />
                  );
                }
                if (el.field === 'reorder') {
                  return (
                    <Column
                      key={el}
                      title=""
                      field=""
                      width="40px"
                      resizable
                      cell={DraggableCell}
                      {...editprops}
                    />
                  );
                }
                if (el.field === 'item_info.branching') {
                  return (
                    <Column
                      key={el}
                      title=""
                      field="Branching"
                      resizable
                      width={el.width}
                      cell={BooleanCheckboxCell}
                    />
                  );
                }
                if (el.cellType === 'ckeditor_content') {
                  return (
                    <Column
                      key={el}
                      title={el.title}
                      field={el.field}
                      resizable
                      width={el.width}
                      cell={CKEditorContentCell}
                      filter="text"
                      columnMenu={(props) => (
                        <ColumnMenuFilter
                          {...props}
                          gridColumns={columns}
                          onColumnsSubmit={onColumnsSubmitFn}
                        />
                      )}
                    />
                  );
                }
                if (el.cellType === 'array_object_content') {
                  return (
                    <Column
                      key={el}
                      title={el.title}
                      field={el.field}
                      resizable={true}
                      width={el.width}
                      cell={ArrayObjectCell}
                      filter="text"
                      columnMenu={(props) => (
                        <ColumnMenuFilter
                          {...props}
                          gridColumns={columns}
                          onColumnsSubmit={onColumnsSubmitFn}
                        />
                      )}
                    />
                  );
                }
                if (el.cellType === 'array_content') {
                  return (
                    <Column
                      key={el}
                      title={el.title}
                      field={el.field}
                      resizable
                      width={el.width}
                      cell={ArrayCell}
                      filter="text"
                      columnMenu={(props) => (
                        <ColumnMenuFilter
                          {...props}
                          gridColumns={columns}
                          onColumnsSubmit={onColumnsSubmitFn}
                        />
                      )}
                    />
                  );
                }

                if (noMenu) {
                  return (
                    <Column
                      key={el}
                      field={el.field}
                      resizable
                      title={el.title}
                      width={el.width ? el.width : '200px'}
                      locked={el.locked ? el.locked : undefined}
                      cell={el.cell}
                      {...editprops}
                    />
                  );
                }

                return (
                  <Column
                    key={el}
                    field={el.field}
                    resizable
                    title={el.title}
                    width={el.width ? el.width : '200px'}
                    locked={el.locked ? el.locked : undefined}
                    filter={el.filter ? el.filter : 'text'}
                    columnMenu={(props) => (
                      <ColumnMenuFilter
                        {...props}
                        filterUI={el.filterUI ? el.filterUI : undefined}
                        gridColumns={columns}
                        onColumnsSubmit={onColumnsSubmitFn}
                      />
                    )}
                    cell={el.cell}
                    {...editprops}
                  />
                );
              }
              return <></>;
            })}
        </Grid>
      </GridContext.Provider>
    </div>
  );
};

KendoGridBase.propTypes = {
  data: PropTypes.array,
  gridColumns: PropTypes.any,
  setGridFilters: PropTypes.func,
  setGridSort: PropTypes.func,
  updateGridData: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onRowSingleClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onPageChange: PropTypes.func,
  onSelectAllChange: PropTypes.func,
  onReorder: PropTypes.func,
  onDragStart: PropTypes.func,
  isCheckboxDisabled: PropTypes.func,
  onCheckboxToggle: PropTypes.func,
  isCheckboxSelected: PropTypes.func,
  sorter: PropTypes.array,
  rowHeight: PropTypes.number,
  skip: PropTypes.number,
  take: PropTypes.number,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  selectable: PropTypes.string,
  pageable: PropTypes.bool,
  sortable: PropTypes.bool,
  detailComponent: PropTypes.func,
  expandField: PropTypes.string,
  onExpandChange: PropTypes.func,
  disableMultiSelect: PropTypes.bool,
  noMenu: PropTypes.bool,
  selectAll: PropTypes.bool
};

export default KendoGridBase;
