/**
 * @summary connectionSettings.js
 * @file connection settings form component for new/edit project page
 * @returns {JSX}
 * @usedBy newForm.js
 * @author Dj Ritchey
 * @since 02/17/2021
 * @lastUpdated 08/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect, useCallback } from 'react';
import store from '../../../store/store';
import { useSelector } from 'react-redux';
import { MultiSelect, DropDownList } from '@progress/kendo-react-dropdowns';
import { toast } from 'react-toastify';

import { text, dropDown, multiSelect } from '../../../utility/constants';
import {
  updateNeighborhoodProjectSettings,
  setNeighborhoodsSelectedValues,
  removeCheckedValue,
  setNeighborhoodsColumnSettings
} from '../../../store/ProjectSetup/ProjectSetupActions';
import {
  updateCheckboxValues,
  updateSelectedDataType,
  updateMultiSelect,
  updateQtyValue,
  normalizeName,
  isChecked,
  updateColumnSettings,
  shouldDisable
} from '../../../utility/updateFields';
import {
  getSubjects,
  getFrameworks,
  getFrameworkLevels,
  getDimensions
} from '../../../utility/getMetaData';
import ValueHeader from '../shared/valueHeader';
import { projectNamesObj } from '../../../app_code/ProjectObjTypeNames';
import { setSelectedValues } from '../../../utility/setSelectedValues';
import { updateProjectSettings } from '../../../store/ProjectSetup/ProjectSetupActions';
import { useLocation } from 'react-router-dom';

const messageOptions = {
  messsageType: {
    success: 'Success'
  },
  messages: {
    errorOnCreate: 'Field name already in use!',
    underConstruction: 'This Function is Under Construction'
  }
};

const NeighborhoodSettings = () => {
  const location = useLocation();
  const subjects = useSelector((state) => state.metadataReducer.subject);
  const frameworks = useSelector((state) => state.metadataReducer.frameworkType);
  const cognitiveTaxonomies = useSelector((state) => state.metadataReducer.cognitiveTaxonomy);
  const dimensions = useSelector((state) => state.metadataReducer.cognitiveCategory);
  const mathematicalPractice = useSelector((state) => state.metadataReducer.mathematicalPractice);
  const frameworkLevel = useSelector((state) => state.metadataReducer.frameworkLevel);
  const linkagelevels = useSelector((state) => state.metadataReducer.cfcorrespondence);
  const settings = useSelector((state) => state.projectSetupReducer.settings);
  const selectedValues = useSelector((state) => state.projectSetupReducer.neighborhoodsSelectedValues);
  const project = useSelector((state) => state.projectSetupReducer);
  const neighborhoodColumnSettings = useSelector((state) => state.projectSetupReducer.neighborhoodSettings.columnSettings);

  const [selectedTaxonomies, setSelectedTaxonomies] = useState([]);

  const defaultCheckBoxObj = {
    neighborhood_details: ['observation', 'citation', 'citation_category'],
    content_metadata: ['subject', 'content_framework_type'],
    taxonomies_and_dimensions: [
      'cognitive_taxonomy',
      'dimension',
      'mathematical_practice',
      'science_and_engineering_practice',
      'text_complexity'
    ],
    tags: ['tags']
  };

  const [checkboxValues, setCheckBoxValue] = useState(defaultCheckBoxObj);

  const [defaultValues, setDefaultValues] = useState([
    'observation',
    'citation',
    'citation_category',
    'subject',
    'content_framework_type',
    'content_codes',
    'cognitive_taxonomy',
    'dimension',
    'mathematical_practice',
    'science_and_engineering_practice',
    'text_complexity',
    'vision',
    'hearing',
    'speech',
    'mobility',
    'dual_sensory',
    'tags'
  ]);

  const [order, setOrder] = useState(defaultValues);

  const [newField, setNewField] = useState({
    name: '',
    isTypeChangable: true,
    dataType: '',
    category: '',
    checked: true,
    hasQty: false,
    qty: 1,
    value: [],
    isNewField: true
  });

  const [metadataValues, setMetadataValues] = useState({
    //Linkage level name, Linkage Level sort order
    subject: getSubjects(subjects, settings),
    content_framework_type: getFrameworks(frameworks, settings),
    frameworkLevels: getFrameworkLevels(frameworkLevel, frameworks, settings),
    dimension: getDimensions(dimensions, selectedTaxonomies)
  });

  const changableTypes = [text, dropDown, multiSelect];

  const updateCheckboxValuesFn = (fieldName) => {
    const list = updateCheckboxValues(
      fieldName.category,
      fieldName,
      checkboxValues
    );
    setCheckBoxValue({ ...list });
  };

  // if name exists in the selected values array, render details to UI
  const shouldRender = (selectedValues, name) => {
    const foundObj = selectedValues.find((selected) => selected.name === name);
    return foundObj;
  };

  const updateSelectedDataTypeFn = ({ value }, obj) => {
    const newSelection = updateSelectedDataType(selectedValues, value, obj);
    store.dispatch(setNeighborhoodsSelectedValues([...newSelection]));
  };

  const updateSelectedValues = (attribute) => {
    if (attribute.checked) {
      store.dispatch(
        setNeighborhoodsSelectedValues([
          ...selectedValues,
          setSelectedValues(attribute)
        ])
      );
    } else {
      store.dispatch(
        removeCheckedValue('neighborhoodsSelectedValues', attribute)
      );
    }
  };

  const updateQtyValueFn = (value, obj) => {
    const list = updateQtyValue(selectedValues, value, obj);
    const updatedColumnSettings = [...neighborhoodColumnSettings];
    // update column settings qty, and remove from redux
    if (value < updatedColumnSettings.length) {
      updatedColumnSettings.pop();
      store.dispatch(
        setNeighborhoodsColumnSettings([...updatedColumnSettings])
      );
    }
    store.dispatch(setNeighborhoodsSelectedValues([...list]));
  };

  const updateValuesForFieldFn = (e, attribute) => {
    const list = updateMultiSelect(selectedValues, e, attribute);
    store.dispatch(setNeighborhoodsSelectedValues([...list]));

    if (attribute.name === 'cognitive_taxonomy') {
      setSelectedTaxonomies(e.value);
    }
  };

  const addCustomFields = useCallback(() => {
    selectedValues.forEach((val) => {
      updateCheckboxValues(val.category, val, checkboxValues);
      const inOrder = order.includes(val.name);
      if (!inOrder) {
        order.push(val.name);
      }
    });
  }, [checkboxValues, order, selectedValues]);

  // need to save framework, and framework level seperate
  const updateColumnSettingsFn = (e, idx, obj, elementType) => {
    const contentId = parseInt(e.target.value.id);
    const foundObj =
      elementType === 'framework'
        ? metadataValues.content_framework_type.find(
            (md) => md.id === contentId
          )
        : metadataValues.frameworkLevels.find((md) => md.id === contentId);
    if (contentId > 0) {
      const { list, newColumnSettings } = updateColumnSettings(
        foundObj,
        idx,
        obj,
        elementType,
        selectedValues,
        neighborhoodColumnSettings
      );
      store.dispatch(setNeighborhoodsSelectedValues([...list]));
      // dispatch to update column settings
      store.dispatch(setNeighborhoodsColumnSettings([...newColumnSettings]));
    }
  };

  const updateProject = useCallback(() => {
    store.dispatch(
      updateNeighborhoodProjectSettings({ attributes: selectedValues })
    );
  }, [selectedValues]);

  const saveField = () => {
    const existingField = selectedValues.find(
      (selected) => selected.name.toLowerCase() === newField.name.toLowerCase()
    );
    if (existingField) {
      toast.error(messageOptions.messages.errorOnCreate);
    } else {
      updateSelectedValues(newField);
      setOrder([...order, newField.name]);
      setDefaultValues([...defaultValues, newField.name]);
      updateCheckboxValuesFn(newField);
      setNewField({ ...newField, name: '', category: '' });
    }
  };

  const changeCoreInput = (e, idx) => {
    const { name, value } = e.target;
    const list = [...project.settings.linkageLevels];
    list[idx].project_id = project.id;
    list[idx].linkage_level_id = list[idx].id;

    if (name === 'levelOrder') {
      //always sets to a number, prevents string value from being saved
      list[idx][name] = Number(value);
    }
    if (name === 'name') {
      const stringName = linkagelevels.find((linkageLevel) =>
        linkageLevel.id === parseInt(value) ? linkageLevel.name : null
      );
      list[idx].name = stringName.name;
      list[idx].metadata_id = stringName.id;
      list[idx].metadataId = stringName.id;
    }
    store.dispatch(updateProjectSettings(projectNamesObj.linkageLevels, list));
  };

  useEffect(() => {
    setMetadataValues({
      ...metadataValues,
      subject: getSubjects(subjects, settings),
      content_framework_type: getFrameworks(frameworks, settings),
      frameworkLevels: getFrameworkLevels(frameworkLevel, frameworks, settings),
      dimension: getDimensions(dimensions, selectedTaxonomies)
    });
    // add custom fields to checkboxValues
    addCustomFields();
  }, [settings, selectedValues, addCustomFields, location.pathname]);

  useEffect(() => {
    updateProject();
  }, [selectedValues, updateProject]);

  useEffect(() => {
    setCheckBoxValue(defaultCheckBoxObj);
    setOrder(defaultValues);
  }, [location.pathname, project]);

  const getComplexValues = (attribute) => {
    switch (attribute.name) {
      case 'cognitive_taxonomy':
        return cognitiveTaxonomies;
      case 'mathematical_practice':
        return mathematicalPractice;
      default:
        return [];
    }
  };

  const handleRemoveMetadata = (idx) => {
    const updatedSettings = { ...project.settings };
    updatedSettings.linkageLevels.splice(idx, 1);
    store.dispatch(
      updateProjectSettings(
        projectNamesObj.linkageLevels,
        updatedSettings.linkageLevels
      )
    );
  };

  //render delete icon only for custom fields in checkbox area
  const renderRemoveIcon = (attr) => {
    return !defaultValues.some(
      (valueName) => valueName.toLowerCase() === attr.toLowerCase()
    );
  };

  // find custom attr, and remove references from order, checkboxes, and selectedValues
  const removeCustomField = (attr, key, isChecked) => {
    const copyCheckboxValues = [...checkboxValues[key]];
    const findCheckboxValueIdx = checkboxValues[key].findIndex(
      (attributeName) => attributeName.toLowerCase() === attr.toLowerCase()
    );
    if (isChecked) {
      const attrObj = selectedValues.find(
        (attribute) => attribute.name === attr.toLowerCase()
      );
      attrObj.checked = false;
      updateSelectedValues(attrObj);
    }
    setOrder(defaultValues);
    copyCheckboxValues.splice(findCheckboxValueIdx, 1);
    setCheckBoxValue({ ...checkboxValues, [key]: copyCheckboxValues });
  };

  const renderCheckboxesMarkup = () => {
    const keys = Object.keys(checkboxValues);
    return (
      <>
        {keys.map((key, idx) => (
          <div key={`neigh-keys-${idx}`} className="col">
            <label className="form-check-label">{normalizeName(key)}</label>
            {checkboxValues[key].map((attribute, $idx) => {
              return (
                <div key={`neigh-checked-${$idx}`} className="row-1 form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isChecked(selectedValues, attribute)}
                    id={`neighborhood-${attribute}`}
                    onChange={() =>
                      updateSelectedValues({
                        name: attribute,
                        checked: !isChecked(selectedValues, attribute),
                        category: key
                      })
                    }
                    disabled={shouldDisable(selectedValues, attribute)}
                  ></input>
                  <label
                    className="form-check-label"
                    htmlFor={`neighborhood-${attribute}`}
                  >
                    {normalizeName(attribute)}
                  </label>
                  {renderRemoveIcon(attribute, key) &&
                    !shouldDisable(selectedValues, attribute) && (
                      <span
                        onClick={() => {
                          removeCustomField(
                            attribute,
                            key,
                            isChecked(selectedValues, attribute)
                          );
                        }}
                        className="k-icon k-i-minus-outline"
                        style={{ hidden: true }}
                      />
                    )}
                </div>
              );
            })}
          </div>
        ))}

        <div className="row">
          <label className="form-label">New Field</label>
        </div>

        <div className="row">
          <div className="col">
            <label className="form-label" htmlFor="new-neighborhood-name">
              Name:
            </label>
            <input
              id="new-neighborhood-name"
              value={newField.name}
              onChange={(e) => {
                setNewField({ ...newField, name: e.target.value });
              }}
            />
          </div>
        </div>

        <div className="d-flex p-2 justify-content-between">
          <div className="text-end">
            <label className="form-label" htmlFor="new-neighborhood-type">
              Field Type:
              <i className="text-danger"> *</i>
            </label>
          </div>
          <div className="col">
            <select
              id="new-neighborhood-type"
              name="fieldCat"
              className="form-select form-select-sm"
              required
              value={newField.category}
              style={{ width: '200px' }}
              onChange={(e) => {
                setNewField({ ...newField, category: e.target.value });
              }}
            >
              <option value="">Select a Field Type</option>
              {Object.keys(checkboxValues).map((cat) => (
                <option key={`neigh-cat-${cat}`} value={normalizeName(cat)}>
                  {normalizeName(cat)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-1">
            <button
              onClick={(e) => {
                saveField();
              }}
              disabled={newField.name === '' || newField.category === ''}
            >
              Create Field
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderDataTypeInputMarkup = (attribute) => {
    if (attribute.isTypeChangable) {
      return (
        <select
          onChange={(e) => updateSelectedDataTypeFn(e.target, attribute)}
          title={`data-type-for-${attribute.name}`}
        >
          {attribute.id !== null || attribute.dataType ? (
            <option value={attribute.dataType}>{attribute.dataType}</option>
          ) : (
            <option value="0">Select a data type</option>
          )}
          {changableTypes
            .filter((type) => type !== attribute.dataType)
            .map((type) => (
              <option key={`neightype-${type}`} value={type}>
                {type}
              </option>
            ))}
        </select>
      );
    }
    return <div style={{ marginLeft: '8px' }}>{attribute.dataType}</div>;
  };

  // qty drop down HTML
  const renderQtyInputMarkup = (attribute) => {
    if (!project.id || !attribute.id) {
      if (attribute.hasQty && attribute.dataType === 'Text') {
        return (
          <input
            type="number"
            min="1"
            max="100"
            value={attribute.qty}
            onChange={(e) => {
              updateQtyValueFn(parseInt(e.target.value), attribute);
            }}
          />
        );
      } else if (attribute.hasQty) {
        return (
          <input
            type="number"
            min="1"
            max="100"
            value={attribute.qty}
            onChange={(e) => {
              updateQtyValueFn(parseInt(e.target.value), attribute);
            }}
          />
        );
      }
    }
    return <div style={{ marginLeft: '8px' }}>{attribute.qty}</div>;
  };

  const multiSelectMarkup = (
    idx,
    attribute,
    data,
    popupSettings,
    fieldName,
    dataKey
  ) => (
    <MultiSelect
      key={`neigh-multi${idx}`}
      id={`field-name-${attribute.name}`}
      data={data}
      textField={fieldName}
      allowCustom
      dataItemKey={dataKey}
      value={attribute.value}
      popupSettings={popupSettings}
      onChange={(e) => {
        updateValuesForFieldFn(e.target, attribute);
      }}
    />
  );

  const renderValuesMultiSelectMarkup = (attribute) => {
    const fromMetaDataState = metadataValues[attribute.name];
    if (attribute.dataType === 'Text') return;
    if (
      !fromMetaDataState &&
      !attribute.complexValues &&
      attribute.name !== 'cognitive_taxonomy'
      // &&
      // attribute.name !== "linkage_level"
    ) {
      return [...Array(attribute.qty)].map((attr, idx) =>
        multiSelectMarkup(
          idx,
          attribute,
          [],
          { popupClass: 'hidden-popup' },
          '',
          ''
        )
      );
    }

    if (!fromMetaDataState) {
      return [...Array(attribute.qty)].map((attr, idx) =>
        multiSelectMarkup(
          idx,
          attribute,
          getComplexValues(attribute),
          {},
          'name',
          'id'
        )
      );
    } else {
      return [...Array(attribute.qty)].map((attr, idx) =>
        multiSelectMarkup(
          idx,
          attribute,
          metadataValues[attribute.name],
          {},
          'name',
          'id'
        )
      );
    }
  };

  const renderLinkageLevels = () =>
    project.settings.linkageLevels.map((inputRow, idx) => {
      return (
        <div key={`renderedmeta${idx}`}>
          <div className="col d-flex p-2 justify-content-between">
            <div className="col-2 text-end">
              <label
                className="form-label"
                htmlFor={`linkage-level-sort-${idx}`}
              >
                Sort Order:
                <i className="text-danger"> *</i>
              </label>
            </div>
            <div className="col">
              <input
                id={`linkage-level-sort-${idx}`}
                type="number"
                name="levelOrder"
                value={inputRow.levelOrder}
                required
                onChange={(e) => changeCoreInput(e, idx)}
              />
            </div>
          </div>

          <div className="col d-flex p-2 justify-content-between">
            <div className="col-2 text-end">
              <label
                className="form-label"
                htmlFor={`linkage-level-name-${idx}`}
              >
                Name:
                <i className="text-danger"> *</i>
              </label>
            </div>
            <div className="col">
              <select
                // defaultValue="select a linkageLevel"
                value={linkagelevels.find((linkageLevel) =>
                  linkageLevel.id === inputRow.metadataId
                    ? linkageLevel.name
                    : null
                )}
                id={`linkage-level-name-${idx}`}
                name="name"
                className="form-select form-select-sm"
                required
                onChange={(e) => changeCoreInput(e, idx)}
                style={{ width: '200px' }}
              >
                {inputRow.name ? (
                  <option value={inputRow.name}>
                    {' '}
                    {
                      linkagelevels.find((link) =>
                        link.id === inputRow.metadataId ? link.name : ''
                      )?.name
                    }{' '}
                  </option>
                ) : (
                  <option>Select a linkage Level</option>
                )}
                {linkagelevels
                  .filter((link) => link.id !== inputRow.metadataId)
                  .map((md) => (
                    <option key={md.id} value={md.id}>
                      {' '}
                      {md.name}{' '}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          {idx > 0 && !project.id && (
            <span
              onClick={() => handleRemoveMetadata(idx)}
              className="k-icon k-i-minus-outline"
              style={{ paddingLeft: '10px', paddingTop: '45px' }}
            />
          )}
        </div>
      );
    });

  const getSelectableValueRows = () => {
    const selectedAttrs = order
      .map((name) => shouldRender(selectedValues, name))
      .filter(Boolean);
    return selectedAttrs.map((attribute) => (
      <div key={`selectedvalueneigh${attribute.name}`}>
        <div className="row p-2 justify-content-between">
          <div className="col">
            <div className="p-2">
              <label htmlFor={`field-name-${attribute.name}`}>
                {normalizeName(attribute.name)}
              </label>
            </div>
          </div>

          <div className="col">
            <div className="p-2">
              <div>{renderDataTypeInputMarkup(attribute)}</div>
            </div>
          </div>

          <div className="col-1">
            <div className="p-2">
              <div>{renderQtyInputMarkup(attribute)}</div>
            </div>
          </div>
          {attribute.name !== 'content_codes' ? (
            <div className="col-6">
              <div className="p-2">
                <div>{renderValuesMultiSelectMarkup(attribute)}</div>
              </div>
            </div>
          ) : (
            <div className="col-6">
              <div className="p-2">
                <div>{renderColumnSettingsMarkUp(attribute)}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    ));
  };

  const getAvailableFrameworks = metadataValues.content_framework_type.map(
    (framework) => (
      <option value={framework.id} key={`node-framelevel${framework.id}`}>
        {framework.name}
      </option>
    )
  );

  const renderColumnSettingsMarkUp = (attribute) => {
    return (
      <>
        {[...Array(attribute.qty)].map((attr, idx) => {
          return (
            <>
              <div className="form-group row">
                <label className="col-sm-2">Framework Type:</label>
                <div className="col-sm">
                  <DropDownList
                    name="framework"
                    defaultItem={{ id: 0, name: 'Select a Framework Type' }}
                    data={metadataValues.content_framework_type}
                    dataItemKey="id"
                    textField="name"
                    value={
                      neighborhoodColumnSettings[idx]?.framework
                        ? metadataValues.content_framework_type.find(
                            (md) =>
                              md.id ===
                              neighborhoodColumnSettings[idx]?.framework
                          )
                        : { id: 0, name: 'Select a Framework Type' }
                    }
                    onChange={(e) => {
                      updateColumnSettingsFn(e, idx, attribute, 'framework');
                    }}
                  />
                </div>

                <label className="col-sm-2">Framework Level:</label>
                <div className="col-sm">
                  <DropDownList
                    name="frameworkLevel"
                    defaultItem={{ id: 0, name: 'Select a Framework Level' }}
                    data={metadataValues.frameworkLevels.filter((framework) => {
                      const dependents = JSON.parse(framework.dependent) || [];
                      if (
                        dependents?.includes(
                          neighborhoodColumnSettings[idx]?.framework
                        )
                      )
                        return framework;
                    })}
                    dataItemKey="id"
                    textField="name"
                    value={
                      neighborhoodColumnSettings[idx]?.frameworkLevel
                        ? metadataValues.frameworkLevels.find(
                            (md) =>
                              md.id ===
                              neighborhoodColumnSettings[idx].frameworkLevel
                          )
                        : { id: 0, name: 'Select a Framework Level' }
                    }
                    onChange={(e) => {
                      updateColumnSettingsFn(
                        e,
                        idx,
                        attribute,
                        'frameworkLevel'
                      );
                    }}
                  />
                </div>
              </div>
            </>
          );
        })}
      </>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col p-2 justify-content-between">
          <fieldset className="bg-light p-3 rounded mt-1">
            <legend>Select Fields to add</legend>
            <div className="row p-2">{renderCheckboxesMarkup()}</div>
          </fieldset>

          <fieldset className="bg-light p-3 rounded mt-1">
            <legend>Data Types and Selectable Values</legend>
            <div className="row p-2">
              <ValueHeader />
              {getSelectableValueRows()}
            </div>
          </fieldset>

          <fieldset className="bg-light p-3 rounded mt-1">
            <legend>Linkage Levels</legend>
            <div className="col-2" style={{ marginTop: '10px' }}>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  store.dispatch(
                    //this is where the new linkageLevel onj will be plucked from projectSettings
                    updateProjectSettings(projectNamesObj.linkageLevels, [
                      ...project.settings.linkageLevels,
                      {
                        linkageLevelId: null,
                        name: '',
                        levelOrder: null,
                        projectId: null,
                        id: null,
                        subject: null,
                        metadataId: null
                      }
                    ])
                  );
                }}
              >
                Add Linkage Level
              </button>
            </div>
            {project.settings.linkageLevels?.length > 0 ? (
              <div>{renderLinkageLevels()}</div>
            ) : (
              <div></div>
            )}
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default NeighborhoodSettings;
