/**
 * @summary BranchListPage.js
 * @file Parent component provides grid column headers for the grids and renders in BranchListGrid for UI
 * @returns {JSX}
 * @usedBy routes.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BranchListGrid from './BranchListGrid';
import store from '../../../store/store';
import {
  getBranchesDetails,
  getBranchesInactiveDetails
} from '../../../store/branchesDetails/branchesDetailsActions';

const BranchListPage = (props) => {
  const [activeGridState, setActiveGridState] = useState({
    branchcollections: { data: [], total: 0 },
    dataState: { take: 10, skip: 1 },
    gridDynamicColumns: [
      {
        field: 'selected',
        show: false,
        filterable: false
      },
      {
        field: 'name',
        title: 'Name',
        show: true,
        filterable: true,
        filter: 'text',
        width: '200px'
      },
      {
        field: 'message',
        title: 'Message',
        show: true,
        filterable: true,
        filter: 'text',
        width: '300px'
      },
      {
        field: 'formattedTimestamp',
        title: 'Time Stamp',
        show: true,
        filterable: true,
        filter: 'text',
        width: '200px'
      },
      {
        field: 'user',
        title: 'User',
        show: true,
        filterable: true,
        filter: 'text',
        width: '200px'
      },
      {
        field: 'branchStatus',
        title: 'Status',
        show: true,
        filterable: true,
        filter: 'text',
        width: '200px'
      }
    ]
  });

  const [inactiveGridState, setInactiveGridState] = useState({
    branchcollections: { data: [], total: 0 },
    dataState: { take: 10, skip: 1 },
    gridDynamicColumns: [
      {
        field: 'selected',
        show: false,
        filterable: false
      },
      {
        field: 'name',
        title: 'Name',
        show: true,
        filterable: true,
        filter: 'text',
        width: '200px'
      },
      {
        field: 'message',
        title: 'Message',
        show: true,
        filterable: true,
        filter: 'text',
        width: '300px'
      },
      {
        field: 'formattedTimestamp',
        title: 'Time Stamp',
        show: true,
        filterable: true,
        filter: 'text',
        width: '200px'
      },
      {
        field: 'user',
        title: 'User',
        show: true,
        filterable: true,
        filter: 'text',
        width: '200px'
      },
      {
        field: 'branchStatus',
        title: 'Status',
        show: true,
        filterable: true,
        filter: 'text',
        width: '200px'
      }
    ]
  });

  const userObj = useSelector((state) => state.authReducer.userObj);
  const selectedProject = useSelector(
    (state) => state.authReducer.userObj.selectedProject[0]
  );

  const updateGridState = () => {
    // wrapped in promise purpose: allow async actions to finish prior to populating state
    if (selectedProject && userObj.selectedBranch[0].id) {
      store
        .dispatch(
          getBranchesInactiveDetails(
            selectedProject.id,
            userObj.selectedBranch[0].id
          )
        )
        .then(() => {
          const branches = { ...store.getState().branchesDetailsReducer };
          const branchcollections = {
            data: Object.values(branches.inactiveBranches).filter(Boolean),
            total: Object.values(branches.inactiveBranches).length
          };
          if (branchcollections.data.length !== undefined) {
            branchcollections.data = branchcollections.data.map((el) => {
              return {
                ...el,
                formattedTimestamp: new Date(el.timestamp).toLocaleString()
              };
            });
            setInactiveGridState({
              ...inactiveGridState,
              branchcollections,
              dataState: { take: 20, skip: 0 }
            });
          }
        });

      store
        .dispatch(
          getBranchesDetails(selectedProject.id, userObj.selectedBranch[0].id)
        )
        .then(() => {
          const branches = { ...store.getState().branchesDetailsReducer };
          const branchcollections = {
            data: Object.values(branches.activeBranches).filter(Boolean),
            total: Object.values(branches.activeBranches).length
          };
          if (branchcollections.data.length !== undefined) {
            branchcollections.data = branchcollections.data.map((el) => {
              return {
                ...el,
                formattedTimestamp: new Date(el.timestamp).toLocaleString()
              };
            });
            setActiveGridState({
              ...activeGridState,
              branchcollections,
              dataState: { take: 20, skip: 0 }
            });
          }
        });
    }
  };

  useEffect(() => {
    updateGridState();
  }, [selectedProject]);

  return (
    <div className="branch-list-page-container">
      <BranchListGrid
        gridState={activeGridState}
        setGridState={setActiveGridState}
        inactiveGridState={inactiveGridState}
        setInactiveGridState={setInactiveGridState}
        updateGridState={updateGridState}
      />
    </div>
  );
};

export default BranchListPage;
