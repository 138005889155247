/**
 * @summary BreadCrumb.jsx
 * @file This component serves to identify and display the user's current location within the app
 * @returns {JSX}
 * @usedBy Header.jsx
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Route, useLocation } from 'react-router-dom';
import './breadcrumb.scss';

export default function Breadcrumb() {
  const user = useSelector((store) => (store.authReducer.userObj));
  const { pathname } = useLocation();
  const crumbs = pathname.split('/').filter((a) => a !== '');

  if (!crumbs.length) {
    return null;
  }

  const displayName = (url) => {
    let segments = url.split('/');
    let capitalizedDisplayName = segments[segments.length - 1];
    return (
      capitalizedDisplayName.charAt(0).toUpperCase() +
      capitalizedDisplayName.slice(1)
    );
  };

  const breadcrumbitems = ({ match }) => (
    <>
      <li
        className={
          match.isExact ? 'breadcrumb-item active link-text' : 'breadcrumb-item'
        }
      >
        {match.url.split('/').length > 2 ? (
          <Link className="link-text" to={match.url || ''}>
            {displayName(match.url)}
          </Link>
        ) : (
          displayName(match.url)
        )}
      </li>
      {/* <Route path={`${match.url}/:path`} component={breadcrumbitems} /> */}
    </>
  );

  return (
    <nav aria-label="breadcrumb">
      <ol className="kite_breadcrumb">
        <li className="breadcrumb-item">
          {user.selectedProject[0]
            ? user.selectedProject[0].name
            : 'No Selected Project'}
        </li>
        {/* <Route path="/:path" component={breadcrumbitems} /> */}
      </ol>
    </nav>
  );
}
