/**
 * @summary axiosListComponents.js
 * @file Function returns a formatted axios response of node clusters according to the user's branch selection
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios'
export const axiosListComponents = async (projectId, branchSelection, ) => {
  
  ///////////////////////////////////////////////////////////////////////////////////////////
  // IF SELECTION IS THE PUBLISHED BRANCH, RETURN THE AXIOS RESPONSE NODES AND CONNECTIONS //
  ///////////////////////////////////////////////////////////////////////////////////////////
  if(branchSelection.type === "published branch"){

    const nodes = await axios.get(`/api/projects/${projectId}/branch/${branchSelection.id}/nodes`)
    const connections = await axios.get(`/api/projects/${projectId}/branch/${branchSelection.id}/connections`)
    let errorMessages = []

    if(nodes.data.length === 0){
      errorMessages.push("There are no nodes in this branch.")
    } else if(connections.data.length === 0){
      errorMessages.push("None of the nodes in this branch are connected. Try checking out a branch with connected nodes.")
    }
    
    const formattedAxiosResp = {
      nodes: nodes.data,
      connections: connections.data,
      branch: branchSelection.id,
      errorMessages,
      branchType: 'branch'
    }

    return formattedAxiosResp
  ////////////////////////////////////////////////////////////////////////////
  // IF SELECTION IS A CHECKED OUT BRANCH, NEED TO FILTER BRANCH ID MATCHES //
  ////////////////////////////////////////////////////////////////////////////
  } else if(branchSelection.type === "branch"){
    const nodes = await axios.get(`/api/projects/${projectId}/branch/${branchSelection.id}/nodes`)
    const connections = await axios.get(`/api/projects/${projectId}/branch/${branchSelection.id}/connections`)
    
    let errorMessages = []

    const branchNodes = nodes.data.filter(node => node.branchId === branchSelection.id)
    const branchConnections = connections.data.filter(connection => connection.branchId === branchSelection.id)
    if(branchNodes.length === 0){
      errorMessages.push("There are no nodes in this branch.")
    } else if(branchNodes.length === 1){
      errorMessages.push("There is only one node in this branch with no connections possible. Try choosing a branch with connected nodes.")
    } else if(branchConnections.length === 0){
      errorMessages.push("None of the nodes in this branch are connected. Try checking out a branch with connected nodes.")
    }

    const formattedAxiosResp = {
      nodes: branchNodes,
      connections: branchConnections,
      branch: branchSelection.id,
      errorMessages,
      branchType: 'branch'
    }
    return formattedAxiosResp
  ///////////////////////////////////////////////////////////////////////////
  // IF SELECTION IS A VIEW, RUN DIFFERENT AXIOS GET REQUESTS ON THAT VIEW //
  ///////////////////////////////////////////////////////////////////////////
  } else if (branchSelection.type === "view"){
    const nodes = await axios.get(`/api/projects/${projectId}/views/${branchSelection.id}`)
    const connections = await axios.get(`/api/projects/${projectId}/views/${branchSelection.id}`)
    let errorMessages = []
    if(nodes.length === 0){
      errorMessages.push("There are no nodes in this view.")
    } else if(connections.length === 0){
      errorMessages.push("None of the nodes in this view are connected. Try saving a view with connected nodes.")
    }
    const formattedAxiosResp = {
      nodes: nodes.data.savedNodes,
      connections: connections.data.savedConnections,
      branch: branchSelection.id,
      errorMessages,
      branchType: 'view'
    }
    return formattedAxiosResp
  }
}