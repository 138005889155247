/**
 * @summary CKEditor.js
 * @file Text component that allows users to change the node description in components
 * @returns {JSX}
 * @usedBy formHeader.js, LinkageLevelsSettings.js, NeighborhoodFormHeader.js, NodeFormHeader.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import { CKEditorBase } from 'kite-react-items';
import PropTypes from 'prop-types';

const toolbarItems = [
  'heading',
  '|',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'subscript',
  'superscript',
  'removeFormat',
  '|',
  'bulletedList',
  'numberedList',
  'outdent',
  'indent',
  'alignment',
  '|',
  'fontBackgroundColor',
  'fontColor',
  'fontFamily',
  'fontSize',
  'wproofreader',
  '|',
  'undo',
  'redo',
  'horizontalLine',
  'pageBreak',
  'blockQuote',
  'findAndReplace',
  '|',
  'specialCharacters',
  'insertTable',
  '|',
  'imageStyle:inline',
  'imageStyle:block',
  'imageStyle:side',
  'toggleImageCaption',
  'imageTextAlternative',
  '|',
  'insertResponse'
];

const CKEditor = ({
  onReady = (editor) => undefined,
  config = {},
  onChange = () => undefined,
  ...props
}) => {
  const [editor, setEditor] = useState(null);

  const { data, maxWordCount } = props;

  const handleEditorReady = (ckeditor) => {
    if (!editor) setEditor(ckeditor);
    return onReady(ckeditor);
  };

  // Pass through configuration;
  const configuration = {
    toolbar: {
      items: toolbarItems
    },
    ...config
  };

  const str = new DOMParser().parseFromString(data, 'text/html').body
    .textContent;

  return (
    <>
      <CKEditorBase
        config={configuration}
        onReady={handleEditorReady}
        onChange={onChange}
        {...props}
      />
      {str.length > maxWordCount && (
        <div className="alert alert-danger" role="alert">
          Description exceeds max allowance by {maxWordCount - str.length}
        </div>
      )}
    </>
  );
};

CKEditor.propTypes = {
  onReady: PropTypes.func,
  config: PropTypes.object,
  onChange: PropTypes.func,
  data: PropTypes.string,
  maxWordCount: PropTypes.number
};

export default CKEditor;
