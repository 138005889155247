/**
 * @summary branchesDetailsActions.jsx
 * @file Actions for branches
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios';
import {
  GET_BRANCHESDETAILSLIST,
  GET_BRANCHESINACTIVEDETAILSLIST,
  CLEAR_BRANCHESDETAILS,
  CLEAR_BRANCHESINACTIVEDETAILS,
  ADD_BRANCH
} from './branchesDetailsTypes';

export const clearBranchesDetails = () => ({ type: CLEAR_BRANCHESDETAILS });

export const getBranchesDetails = (projectId) => async (dispatch) => {
  const { data } = await axios.get(`/api/${projectId}/branchDetails`);
  if (data) {
    dispatch({
      type: GET_BRANCHESDETAILSLIST,
      branchesDetails: data
    });
  } else {
    dispatch(clearBranchesDetails());
  }
};

export const getCloneBranch = (projectId) => async (dispatch) => {
  const { data } = await axios.get(`/api/${projectId}/branchDetails`);
  if (data) {
    const publishedBranch = data.find(
      (branch) => branch.branchStatus === 'published'
    );
    return publishedBranch;
  } else {
  }
};

export const clearBranchesInactiveDetails = () => ({
  type: CLEAR_BRANCHESINACTIVEDETAILS
});

export const getBranchesInactiveDetails = (projectId) => async (dispatch) => {
  const { data } = await axios.get(`/api/${projectId}/branchDetails/inactive`);
  if (data) {
    dispatch({
      type: GET_BRANCHESINACTIVEDETAILSLIST,
      branchesDetails: data
    });
  } else {
    dispatch(clearBranchesInactiveDetails());
  }
};

export const addNewBranch = (newBranch) => ({
  type: ADD_BRANCH,
  newBranch
});

export const createBranch = (branchData) => async (dispatch, getState) => {
  const data = await axios.post(`/api/${branchData.projectId}/branches`, {
    ...branchData
  });
  if (data.status === 201) {
    // dispatch(getBranchesByProjectId(branchData.projectId))

    dispatch(getBranchesDetails(branchData.projectId));
  }
  return data;
};
