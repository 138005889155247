/**
 * @summary ThemeFontSelection.js
 * @file Contains All Theme Stylization Options for all Font Selectors - Displayed in CanvasThemeMenu.js
 * @returns {JSX}
 * @usedBy CanvasThemeMenu.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */


import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import '../../canvasPage.scss';
import ColorPicker from './ColorPicker'

{/* //////////////// */}
{/* NODE STYLIZATION */}
{/* //////////////// */}
const ThemeFontsSection = ({
    handleNodeFontSelector, 
    handleNeighborhoodFontSelector, 
    handleColorPalletteVisibility, 
    nodeFontColorPallete, 
    handleNodeFontColorChange,
    nodeFontColor, 
    neighborhoodFontColorPallete, 
    handleNeighborhoodFontColorChange, 
    neighborhoodFontColor, 
    handleNodeFontSizeSelector, 
    handleNeighborhoodFontSizeSelector,
    nodeFontDropdownLabel, 
    nodeFontSizeDropdownLabel, 
    neighborhoodFontDropdownLabel, 
    neighborhoodFontSizeDropdownLabel
}) => {

    return(
        <>
        <div className='col-4'>
            {/* NODE FONT STYLE */}
            <div className='theme-options-headers'>
                Node Fonts
            </div>
            <div className='row'>
                <div className='theme-option'>
                    Font:
                    <form className='font-style-form'>
                        <select
                        id="node-font-style-button"
                        className="font-style-form"
                        onChange={handleNodeFontSelector}
                        value={nodeFontDropdownLabel}
                        >
                            <option value="arial">Arial</option>
                            <option value="Trebuchet MS">Trebuchet</option>
                            <option value="verdana">Verdana</option>
                            <option value="times-new-roman">Times</option>
                            <option value="lucida sans">Lucida</option>
                        </select>
                    </form>
                </div>
            </div>
            {/* NODE FONT SIZE */}
            <div className='row'>
                <div className='theme-option'>
                    Font Size:    
                    <form className='font-size-form'>
                        <select
                            id="node-font-size-button"
                            className="font-size-selector"
                            onChange={handleNodeFontSizeSelector}
                            value={nodeFontSizeDropdownLabel}
                        >
                            <option value="Small">Small</option>
                            <option value="Medium">Medium</option>
                            <option value="Large">Large</option>
                        </select>
                    </form>
                </div>
            </div>
            {/* NODE FONT COLOR */}
            <div className='row'>
                <div className='theme-option'>
                    Font Color:
                
                    <button 
                        title={'Color Selector: Click this button to open the color pallette, select a color from the pallet, and click this button again to close the pallette.'}
                        onClick={handleColorPalletteVisibility} 
                        id="node-font-color-button" 
                        className={nodeFontColor === 'transparent' ? 'default-color-button' : 'selected-color-button'} 
                        style={{backgroundColor: nodeFontColor }}>
                    </button>
                    <div className={nodeFontColorPallete ? "pallette-visibility-on" : "pallette-visibility-off"}>
                        <ColorPicker 
                            pickerId={"node-font-color"}
                            color={nodeFontColor}
                            handleNodeFontColorChange={handleNodeFontColorChange}
                        />
                    </div>
                </div>
            </div>
        </div>
        {/* ////////////////// */}
        {/* NEIGHBORHOOD FONTS */}
        {/* ////////////////// */}
        <div className='col-4'>
            <div className='theme-options-headers'>
                Neighborhood Fonts
            </div>
            {/* NEIGHBORHOOD FONT STYLE */}
            <div className='row'>
                <div className='theme-option'>
                    Font:
                    <form className='font-style-form'>
                        <select
                            id="node-font-style-button"
                            className="font-style-form"
                            onChange={handleNeighborhoodFontSelector}
                            value={neighborhoodFontDropdownLabel}
                        >
                            <option value="arial">Arial</option>
                            <option value="Trebuchet MS">Trebuchet</option>
                            <option value="verdana">Verdana</option>
                            <option value="times-new-roman">Times</option>
                            <option value="lucida sans">Lucida</option>
                        </select>
                    </form>
                </div>
            </div>
            {/* NEIGHBORHOOD FONT SIZE */}
            <div className='row'>
                <div className='theme-option'>
                    Font Size:
                    <form className='font-size-form'>
                        <select
                            id="neighborhood-font-size-button"
                            className="font-size-selector"
                            onChange={handleNeighborhoodFontSizeSelector}
                            value={neighborhoodFontSizeDropdownLabel}
                        >
                            <option value="Small">Small</option>
                            <option value="Medium">Medium</option>
                            <option defaultChecked value="Large">Large</option>
                        </select>
                    </form>
                
                </div>
            </div>
            {/* NEIGHBORHOOD FONT COLOR */}
            <div className='row'>
                <div className='theme-option'>
                    Font Color:
                
                    <button 
                        title={'Color Selector: Click this button to open the color pallette, select a color from the pallet, and click this button again to close the pallette.'}
                        onClick={handleColorPalletteVisibility} 
                        id="neighborhood-font-color-button" 
                        className={neighborhoodFontColor === 'transparent' ? 'default-color-button' : 'selected-color-button'} 
                        style={{backgroundColor: neighborhoodFontColor }}>

                    </button>
                    <div className={neighborhoodFontColorPallete ? "pallette-visibility-on" : "pallette-visibility-off"}>
                        <ColorPicker 
                            pickerId={"neighborhood-font-color"}
                            color={neighborhoodFontColor}
                            handleNeighborhoodFontColorChange={handleNeighborhoodFontColorChange}
                        />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

ThemeFontsSection.propTypes = {
    handleNodeFontSelector: PropTypes.func,
    nodeFontColorPallete: PropTypes.bool,
    handleNodeFontColorChange: PropTypes.func,
    handleNeighborhoodFontSelector: PropTypes.func,
    nodeFontColor: PropTypes.string,
    neighborhoodFontColorPallete: PropTypes.bool,
    handleNeighborhoodFontColorChange: PropTypes.func,
    neighborhoodFontColor: PropTypes.string,
    handleColorPalletteVisibility: PropTypes.func,
    handleNodeFontSizeSelector: PropTypes.func,
    handleNeighborhoodFontSizeSelector: PropTypes.func,
    nodeFontDropdownLabel: PropTypes.string,
    nodeFontSizeDropdownLabel: PropTypes.any,
    neighborhoodFontDropdownLabel: PropTypes.string,
    neighborhoodFontSizeDropdownLabel: PropTypes.any,
};

export default ThemeFontsSection;