/**
 * @summary Wrapper.js
 * @file Wrapper component for Panel components
 * @returns {JSX}
 * @usedBy ConnectionForm.js, newForm.js, LinkageLevelsSettings.js, NeighborhoodForm.js, NodeForm.js, conflictResolution.js, BranchListGrid.js, DjBranchListGrid.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import PropTypes from 'prop-types';

const Accordion = ({ children }) => {
  return (
    <div className="accordion" id="contentItemCreation">
      {children}
    </div>
  );
};

Accordion.propTypes = {
  children: PropTypes.any
};

export default Accordion;
