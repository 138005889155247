/**
 * @summary LinkageLevelReducer.js
 * @file LinkageLevel Reducer
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import {
  GET_LINKAGE_LEVELS,
  CLEAR_LINKAGE_LEVELS,
  GET_LINKAGE_LEVEL
} from './LinkageLevelTypes';

const linkageLevels = {
  linkageLevels: [],
  selectedData: []
};

/**
 * @summary
 * This redux reducer is used for node CRUD based on the action type
 *
 * @param {nodes} state
 * @param {reduxAction} action
 */
const linkagelLevelReducer = (state = linkageLevels, action) => {
  switch (action.type) {
    case GET_LINKAGE_LEVELS:
      return {
        ...state,
        selectedData: [],
        linkageLevels: action.linkageLevels
      };

    case CLEAR_LINKAGE_LEVELS:
      return {
        linkageLevels: [],
        selectedData: []
      };

    case GET_LINKAGE_LEVEL:
      return {
        ...state,
        selectedData: [action.selectedData]
      };

    default:
      return state;
  }
};
export default linkagelLevelReducer;
