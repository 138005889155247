/**
 * @summary Uploader.js
 * @file Upload component that accepts 'media' resouce and saves it into state
 * @returns {JSX}
 * @usedBy file-extensions.js, LinkageLevelMediaGrid.js, NeighborhoodMediaGrid.js, NodeMediaGrid.js, MapUpload.js
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 06/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { Upload } from '@progress/kendo-react-upload';
import PropTypes from 'prop-types';
// import { AC } from '../../../../store/appConfiguration/AppConfigurationContainer';

/**
 * Uploader implements the kendo file uploader with default values
 *
 * @summary
 * A functional component that extends kendo file uploader with default accept parameters
 * @extends
 * @example
        <Uploader
          accept=""
          onUpdate={(files) => }
          multiple={true | false}
        />
 *
 */

const Uploader = ({
  mediaType,
  allowedFileTypes,
  inputSaveHandler,
  multiple,
  accept,
  autoUpload,
  fetchFiles,
  filesList,
  uploadCallBack,
  disableUpload
}) => {
  const [files, setFiles] = useState([]);
  const [defaultAccept, setDefaultAccept] = useState(
    'image/jpeg,image/jpg,image/png,image/gif,.svg,.wav, .webm,audio/mpeg,video/mp4,application/pdf,application/html'
  );
  const [allowedFileTypesState, setAllowedFileTypesState] = useState([
    'xlsx',
    '.csv',
    '.xls'
  ]);
  // const minimumFileSize = AC({
  //   m: 'common', at: 'min_value', ac: 'file_size', an: ''
  // });
  // const maximumFileSize = AC({
  //   m: 'common', at: 'max_value', ac: 'file_size', an: ''
  // });

  useEffect(() => {
    // if the caller has its own file types use it; otherwise use the default list.
    if (mediaType) {
      if (mediaType === 'resource') {
        setAllowedFileTypesState(['.xlsx', '.csv', '.xls']);
        setDefaultAccept(
          'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv'
        );
      } else {
        setAllowedFileTypesState([
          '.jpg',
          '.png',
          '.gif',
          '.svg',
          '.mp4',
          '.webm',
          '.mp3',
          '.wav'
        ]);
        setDefaultAccept(
          'image/jpeg,image/jpg,image/png,image/gif,.svg,.wav, .webm,audio/mpeg,video/mp4'
        );
      }
    } else if (allowedFileTypes) {
      setAllowedFileTypesState(allowedFileTypes);
    }
  }, [allowedFileTypes, mediaType]);

  useEffect(() => {
    setFiles(filesList);
  }, [filesList]);
  const onAdd = (event) => {
    setFiles(event.newState);
    fetchFiles(event.newState);
  };

  const onRemove = (event) => {
    setFiles(event.newState);
    fetchFiles(event.newState);
  };

  const onStatusChange = (event) => {
    setFiles(event.newState);
    // fetchFiles(files);
  };

  return (
    <div
      className={multiple ? 'upload' : 'upload-single'}
      data-testid="uploader"
    >
      <Upload
        accept={accept || defaultAccept}
        restrictions={{
          allowedExtensions: allowedFileTypesState
        }}
        batch={false}
        multiple={!!multiple}
        files={files}
        onAdd={onAdd}
        onRemove={onRemove}
        onStatusChange={onStatusChange}
        autoUpload={autoUpload || false}
        showActionButtons={false}
        disabled={disableUpload}
      />
    </div>
  );
};

export default Uploader;

Uploader.propTypes = {
  mediaType: PropTypes.string,
  allowedFileTypes: PropTypes.func,
  inputSaveHandler: PropTypes.func,
  multiple: PropTypes.bool,
  accept: PropTypes.func,
  autoUpload: PropTypes.bool,
  fetchFiles: PropTypes.func,
  filesList: PropTypes.array,
  uploadCallBack: PropTypes.func,
  disableUpload: PropTypes.bool
};
