/**
 * @summary InspectorTool.js
 * @file Node Minigrid is the Parent for All Node CRUD Functions in the Data Editor
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2024
 * @lastUpdated 8/8/2024
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../canvasPage.scss';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import axios from 'axios';

import AddModal from './crudModals/AddNodeCanvasModal';
import EditModal from './crudModals/EditNodeCanvasModal';
import DeleteModal from './crudModals/DeleteNodeCanvasModal'

import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { LuGlasses } from 'react-icons/lu';

const NodeMiniGrid = ({
    handleCrud,
    branchChangeData,
    canvasNodeSelections,
    tableNodeSelections,
    setCanvasNodeSelections,
    handleTableviewNavigation,
    handleCreateNeighborhoodFromNodes,
    handleConnectNodes,
    renderMapData,
    onPublishedBranch
  }) => {
    // CRUD STATES
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)

    // EVENT HANDLERS FOR OPENING/CLOSING CRUD MODALS
    // Add Modal
    const handleAddModalVisibility = () => {
        setAddModalVisible(!addModalVisible)
    }
    // Edit Modal 
    const handleEditModalVisibility = () => {
        if(tableNodeSelections[0].isCheckedOut === false && editModalVisible === false){
            toast.error("This node is not checked out and cannot be saved.")
        }
        setEditModalVisible(!editModalVisible)
    }
    // Delete Modal
    const handleDeleteModalVisibility = () => {
        let publishedNodes = tableNodeSelections.filter(node => node.isCheckedOut === false)
        if(publishedNodes.length > 0){
            publishedNodes.forEach(node => {
                toast.error(node.nodeKey + " is not checked out.")
            })
        } else {
            setDeleteModalVisible(!deleteModalVisible)
        }
        
    }
    
    // SELECTION HANDLER
    const handleDataEditorSelections = (e) => {
        if(e.dataItem?.nodeKey && e.dataItem.catgeory !== "Super"){        
            let newCanvasNodeSelections = canvasNodeSelections.map(node => {
                if(node.nodeKey === e.dataItem.nodeKey){
                    node.selected = !node.selected
                }
                return node
            })
            setCanvasNodeSelections(newCanvasNodeSelections)
        }
    };
    // SELECT ALL HANDLER 
    const handleSelectAll = () => {
        if(canvasNodeSelections.some(node => !node.selected)){
            let newCanvasNodeSelections = canvasNodeSelections.map(node => {
                node.selected = true
                return node
            })
            setCanvasNodeSelections(newCanvasNodeSelections)
        } else {
            let newCanvasNodeSelections = canvasNodeSelections.map(node => {
                node.selected = false
                return node
            })
            setCanvasNodeSelections(newCanvasNodeSelections)
        }
    }
    // FORMATS MESSAGING FOR CHANGE LOGS
    canvasNodeSelections.map(node => {
        // In formatCanvasChangeReview.js, we add changed items into an array and format that into a string with commas - this function adds the correct verb.
        if(node.deleted){
            node.checkedOutMessage = node.isCheckedOut ? "Yes" : "No"
            node.changeMessage = "Deleted"
            return node
        } else if(node.added){
            node.checkedOutMessage = node.isCheckedOut ? "Yes" : "No"
            node.changeMessage = "Added"
            return node
        } else if(node.edited){
            node.checkedOutMessage = node.isCheckedOut ? "Yes" : "No"
            let nodeChange = branchChangeData.edits.nodes.find(nodeChange => nodeChange.new.nodeKey === node.nodeKey)
            node.changeMessage = nodeChange.message + " Updated"
            return node
        } else {
            node.checkedOutMessage = node.isCheckedOut ? "Yes" : "No"
            node.changeMessage = "None"
            return node
        }
    })
    // Grid Variables
    // const DATA_ITEM_KEY = "nodeKey";
    // const SELECTED_FIELD = "selected";

    const [sort, setSort] = useState([{
        field: "nodeKey",
        dir: "asc",
      }]);

    return (
        <>
            <div>
                {/* Node Mini Grid */}
                <Grid
                    style={{
                        height: "300px",
                    }}
                    // Node Selections Enter Here
                    data={orderBy(canvasNodeSelections, sort)}

                    dataItemKey={"nodeKey"}
                    selectedField={"selected"}
                    onSelectionChange={handleDataEditorSelections}
                    onRowClick={handleDataEditorSelections}
                    onHeaderSelectionChange={handleSelectAll}

                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                        setSort(e.sort);
                    }}
                    
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: "multiple",
                    }}
                >
                    <GridNoRecords>
                        Select Elements on Canvas to Display Here
                    </GridNoRecords>
                    <Column field={"selected"} width="50px" id="select-all"/>
                    <Column field="nodeKey" title="Node Key" width="250px" />
                    <Column field="title" title="Title" />
                    <Column field="changeMessage" title="Branch Changes"/>
                    {/* <Column field="lastUpdated" title="Last Change" /> */}
                    <Column field="checkedOutMessage" title="Checked Out" />
                </Grid>
            </div>
            <div className="container-fluid p-0 d-flex justify-content-between mt-2">
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-2'>
                            <button
                                className="btn btn-primary btn-sm text-white w-100 me-2 ms-4"
                                title={onPublishedBranch ? "Creating Elements is disabled while on the published branch." : "Create a new Node."}
                                type="button"
                                onClick={handleAddModalVisibility}
                                disabled={onPublishedBranch}
                            >
                            <i className="bi bi-plus me-2" />
                                Add Node
                            </button>
                        </div>
                        <div className='col-2'>
                            <button
                                className="btn btn-primary btn-sm w-100 ms-4"
                                type="button"
                                title={onPublishedBranch ? "Editing is disabled while on the published branch." : "Edit a selected Node."}
                                onClick={handleEditModalVisibility}
                                disabled={onPublishedBranch || tableNodeSelections.length !== 1}
                            >
                                <i className="bi bi-pencil me-2" />
                                Edit
                            </button>
                        </div>
                        <div className='col-2'>
                            <button
                                className="btn btn-primary btn-sm w-100 ms-4"
                                type="button"
                                title={onPublishedBranch ? "Creating Elements is disabled while on the published branch." : tableNodeSelections.length !== 2 ? "Select two nodes you want to connect." : "Create a connection between the two selected nodes."}
                                onClick={handleConnectNodes}
                                disabled={onPublishedBranch || tableNodeSelections.length !== 2}
                            >
                                <i className="bi bi-pencil me-2" />
                                Connect Nodes
                            </button>
                        </div>
                        <div className='col-3'>
                            <button
                                className="btn btn-primary btn-sm w-150 ms-4"
                                type="button"
                                title={onPublishedBranch ? "Creating Elements is disabled while on the published branch." : "Create a Neighborhood using selected nodes above."}
                                onClick={handleCreateNeighborhoodFromNodes}
                                disabled={onPublishedBranch || tableNodeSelections.length < 1}
                            >
                                <i className="bi bi-pencil me-2" />
                                Create Neighborhood
                            </button>
                        </div>
                        <div className='col-2'>
                            <button
                            className="btn btn-danger btn-sm text-white w-100 ms-4"
                            type="button"
                            title={onPublishedBranch ? "Deleting is disabled while on the published branch." : "Delete Selected Nodes"}
                            onClick={handleDeleteModalVisibility}
                            disabled={onPublishedBranch || tableNodeSelections.length < 1}
                            >
                            <i className="bi bi-trash me-2" />
                                Delete
                            </button>
                        </div>
                        <div className='col-4'></div>
                    </div>
                </div>
            </div>
            <Modal show={addModalVisible} backdrop="static" centered size="xl">
                <AddModal 
                    handleCrud={handleCrud}
                    handleAddModalVisibility={handleAddModalVisibility}
                    handleTableviewNavigation={handleTableviewNavigation}
                />
            </Modal>
            <Modal show={editModalVisible} backdrop="static" centered size="xl">
                <EditModal 
                    handleCrud={handleCrud}
                    selectedNode={tableNodeSelections[0]}
                    handleEditModalVisibility={handleEditModalVisibility}
                    handleTableviewNavigation={handleTableviewNavigation}
                    renderMapData={renderMapData}
                />
            </Modal>
            <Modal show={deleteModalVisible} backdrop="static" centered >
                <DeleteModal
                    handleCrud={handleCrud}
                    selectedNodes={tableNodeSelections}
                    handleDeleteModalVisibility={handleDeleteModalVisibility}
                    renderMapData={renderMapData}
                />
            </Modal>
        </>
        
    );
  } 
//   return null
// }

NodeMiniGrid.propTypes = {
  canvasNodeSelections: PropTypes.array,
  tableNodeSelections: PropTypes.array,
  setCanvasNodeSelections: PropTypes.func,
  handleAddModal: PropTypes.func,
  handleEditModal: PropTypes.func,
  renderMapData: PropTypes.obj,
  handleCrud: PropTypes.func,
  branchChangeData: PropTypes.obj,
  handleTableviewNavigation: PropTypes.func,
  handleConnectNodes: PropTypes.array,
  handleCreateNeighborhoodFromNodes: PropTypes.func,
  onPublishedBranch: PropTypes.bool
};

export default NodeMiniGrid;