/**
 * @summary getMetaData.js
 * @file helper file for parsing metadata redux state to return specific data sets
 * @returns {JSX}
 * @usedBy NodeForm, ConnectionForm, NewForm, NeighborhoodForm
 * @author Dj Ritchey
 * @since 4/01/2021
 * @lastUpdated 04/2021
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const getSubjects = (subjects, settings) =>
  subjects.filter((subject) => {
    const foundSub = settings.coreMetaData.find(
      (meta) => parseInt(meta.subject) === subject.id
    );
    return foundSub ? subject : null;
  });

export const getFrameworks = (frameworks, settings) =>
  frameworks.filter((framework) => {
    const foundFw = settings.coreMetaData.find((meta) =>
      meta.frameworks.includes(framework.id)
    );
    return foundFw ? framework : null;
  });

export const getFrameworkLevels = (frameworkLevel, frameworkData, settings) =>
  frameworkLevel.filter((framework) => {
    const frameworks = getFrameworks(frameworkData, settings);
    const dependents = framework.dependent.split(/,|\[|\]/).filter(Boolean);
    const foundLevel = frameworks.filter((fw) =>
      dependents.includes(fw.id.toString())
    );
    return foundLevel.length > 0 ? framework : null;
  });

export const getDimensions = (demensions, selectedTaxonomies) =>
  demensions.filter((demension) => {
    const selectableDemensions = selectedTaxonomies.find(
      (tax) => tax.name === demension.dependent
    );
    return selectableDemensions || null;
  });
