/**
 * @summary renderHTML.js
 * @file Renders selection components to the UI
 * @returns {JSX}
 * @usedBy ConnectionAttributeSettings.js, LinkageLevelsSettings.js, NeighborhoodAttributeSettings.js, AttributeSettings.js
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 06/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import { MultiSelect } from '@progress/kendo-react-dropdowns';

export const renderHTMLElement = (key, updateValues, id) => {
  if (key.dataType === 'Text') {
    return (
      <input
        id={id}
        type="text"
        name="name"
        value={key.selectedValue[0]?.name || ''}
        onChange={(e) => {
          updateValues(e.target, key);
        }}
      ></input>
    );
  }
  if (key.dataType === 'Multi-Select' && key.possibleValue.length > 0) {
    return (
      <MultiSelect
        id={id}
        data={key.possibleValue}
        value={key.selectedValue}
        onChange={(e) => {
          updateValues(e.target, key);
        }}
        dataItemKey="id"
        textField="name"
      />
    );
  }

  if (key.dataType === 'Drop-Down' && key.possibleValue.length > 0) {
    return (
      <select
        id={id}
        className="form-select form-select-sm"
        onChange={(e) => {
          updateValues(e.target, key);
        }}
        value={key.selectedValue[0]?.id > -1 ? key.selectedValue[0]?.id : -1}
      >
        <option value="-1">Select</option>
        {key.possibleValue.map((posVal) => (
          <option key={posVal.id} value={posVal.id}>
            {posVal.name}
          </option>
        ))}
      </select>
    );
  }

  if (key.dataType === 'Custom' && key.possibleValue.length > 0) {
    return (
      <MultiSelect
        id={id}
        data={key.possibleValue}
        value={key.selectedValue}
        onChange={(e) => {
          updateValues(e.target, key);
        }}
        dataItemKey="id"
        textField="name"
      />
    );
  } else {
    return (
      <select
        id={id}
        className="form-select form-select-sm"
        value={key.selectedValue}
        onChange={(e) => {
          updateValues(e.target, key);
        }}
      >
        <option value="0">Select</option>
        {key.possibleValue.map((posVal) => (
          <option key={posVal.id} value={posVal.id}>
            {posVal.name}
          </option>
        ))}
      </select>
    );
  }
};
