/**
 * @summary Schema for creating new Nodes
 * @file newNode.schema.js
 * @returns {Schema}
 * @usedBy Application
 * @author Andrew Popp
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as yup from 'yup';
////////////////////////
// NEW NODE SCHEMA //
////////////////////////
const newNode = yup.object().shape({
  nodeKey: yup.string().required('Node Key is required'),
  name: yup.string().required('Node Name is required'),
  description: yup.string().notRequired()
});

export default newNode;
