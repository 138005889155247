/**
 * @summary superNodeLayout.js
 * @file Advanced Util Provided by GoJS - Runs Custom Functions Needed by GoJS to Wrap Neighborhoods around Nodes in the Map
 * @returns {JSX}
 * @usedBy Canvas.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as go from 'gojs';
//////////////////////////////////////////////
// GOJS SUPER NODE LAYOUT FOR NEIGHBORHOODS //
//////////////////////////////////////////////
export class SuperNodeLayout extends go.LayeredDigraphLayout {
  doLayout(coll) {
    coll = this.collectParts(coll);
    const nonsupers = new go.Set();
    coll.each((part) => {
      if (part instanceof go.Node && part.category === 'Super') return;
      if (part instanceof go.Link){
        if(part.fromNode && part.toNode){
          if(part.fromNode.category === 'Super' || part.toNode.category === 'Super') return;
        }
      }
      nonsupers.add(part);
    });
    super.doLayout(nonsupers);
    const supers = new go.Set(/*go.Node*/);
    coll.each((p) => {
      if (p instanceof go.Node && p.category === 'Super') supers.add(p);
    });
    function membersOf(sup, diag) {
      const set = new go.Set(/*go.Part*/);
      const arr = sup.data._members;
      for (let i = 0; i < arr.length; i++) {
        const d = arr[i];
        set.add(diag.findNodeForKey(d));
      }
      return set;
    }
    function isReady(sup, supers, diag) {
      const arr = sup.data._members;
      if (arr !== undefined) {
        for (let i = 0; i < arr.length; i++) {
          const key = arr[i];
          const n = diag.findNodeForKey(key);
          if (n === null) continue;
          if (n.category !== 'Super') continue;
          if (supers.has(n)) return false;
        }
      }
      return true;
    }
    // implementations of doLayout that do not make use of a LayoutNetwork
    // need to perform their own transactions
    this.diagram.startTransaction('Custom Layout');
    while (supers.count > 0) {
      let ready = null;
      const it = supers.iterator;
      while (it.next()) {
        if (isReady(it.value, supers, this.diagram)) {
          ready = it.value;
          break;
        }
      }
      supers.remove(ready);
      const b = this.diagram.computePartsBounds(membersOf(ready, this.diagram));
      ready.location = b.position;
      const body = ready.findObject('BODY');
      if (body) body.desiredSize = b.size;
    }
    this.diagram.commitTransaction('Custom Layout');
  }
}
