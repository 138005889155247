/**
 * @summary ConnectionCollectionGrid.js
 * @file grid component that populates and updates the UI with connections
 * @returns {JSX}
 * @usedBy ConnectionCollectionPage.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { snakeCase, startCase } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import KendoGridBase from '../../../shared/ui/kendoGridBase/KendoGridBase';
import {
  clearSelectedConnections,
  deleteConnection,
  deleteMultipleConnections,
  getConnections,
  gridSelectedDataActions,
  replaceTableviewConnections,
  setNewConnection
} from '../../../store/connections/ConnectionActions';
import store from '../../../store/store';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import DeleteConfirmation from '../modal/DeleteConfirmation';

const ConnectionCollectionGrid = ({
  setRefresh,
  refresh,
  headers,
  setSelectedConnections,
  selectedConnections
}) => {
  const defaultHeaders = [
    {
      field: 'selected',
      show: true,
      filterable: false
    },
    {
      field: 'name',
      title: 'Name',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'sourceNodeKey',
      title: 'Source Key',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'destinationNodeKey',
      title: 'Destination Key',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'sourceNodeName',
      title: 'Source Name',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'destinationNodeName',
      title: 'Destination Name',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'connectionType',
      title: 'Type',
      show: true,
      filterable: true,
      filter: 'text',
      cellType: 'text',
      width: '200px'
    },
    {
      field: 'checkedOutBy',
      title: 'Checked Out',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'lastUpdated',
      title: 'Last Updated',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    }
  ];
  const [gridState, setGridState] = useState({
    connectioncollections: { data: [], total: 0 },
    dataState: { take: 20, skip: 1 },
    gridDynamicColumns: defaultHeaders
  });
  const [filter, setFilter] = useState({});
  const navigate = useNavigate();
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 100 });
  const [, setLoading] = useState(false);
  const [, setSkipProcessing] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowsStateArray, setSelectedStateArray] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const messageOptions = {
    messsageType: {
      success: 'Success'
    },
    messages: {
      successOnDelete: 'Node Deleted',
      errorOnDelete: 'Unable to Delete Node Collection',
      errorOnFetch: 'Unable to Lode Nodes',
      underConstruction: 'This Function is Under Construction'
    }
  };

  const location = useLocation();

  const userObj = useSelector((state) => state.authReducer.userObj);
  const selectedBranch = useSelector((state) => state.authReducer.userObj.selectedBranch[0]);
  const selectedProject = useSelector((state) => state.authReducer.userObj.selectedProject[0]);
  const canvasSelections = useSelector((state) => state.canvasReducer);
  const selectedData = useSelector((state) => state.connectionReducer.selectedData);
  const connections = useSelector((state) => state.connectionReducer);

  useEffect(() => {
    if (headers) {
      const attrHeaders = headers.groupHeader
        .map((header) => {
          const key = Object.keys(header);
          return header[key].map((attribute) => {
            return {
              field: attribute.name,
              title: startCase(attribute.name),
              show: true,
              filterable: true,
              filter: 'text',
              width: '200px'
            };
          });
        })
        .flat();
      setGridState({
        ...gridState,
        gridDynamicColumns: [...defaultHeaders, ...attrHeaders]
      });
    }
  }, [headers, gridState.connectioncollections]);

  const canvasRedirectSelectionsUpdate = () => {
    const newSelection = canvasSelections.tableFormatted.connections.map(
      (connection) => {
        if(connection.selected){
          connection.selected = true;
          return connection;
        } else {
          return {...connection, selected: true};
        }
        
      }
    );
    store.dispatch(replaceTableviewConnections(newSelection));
  };

  useEffect(() => {
    if (
      Object.values(connections).length > 1 &&
      location.state?.from === 'canvasPage'
    ) {
      canvasRedirectSelectionsUpdate();
    }
  }, []);

  useEffect(() => {
    updateGridState();
  }, [
    selectedProject,
    canvasSelections.tableFormatted.nodes,
    canvasSelections.tableFormatted.neighborhoods
  ]);

  const handlePageChange = (e) => {
    e.page.skip = isNaN(e.page.skip) ? 1 : e.page.skip;
    setPage(e.page);
  };

  const handleGridFilterChange = (colFilter) => {
    setRefresh(true);
    setFilter(colFilter || {});
  };

  const handleGridSortChange = (obj) => {
    setRefresh(!refresh);
    // set initial sort order
    if (sort.length === 0 || sort[0].field !== obj[0].field) {
      setSort(obj);
      return;
    }

    // check if we should apply a desc, or reset sort completely
    if (sort[0].field === obj[0].field && sort[0].dir === 'asc') {
      sort[0].dir = 'desc';
      setSort(sort);
    } else if (sort[0].field === obj[0].field && sort[0].dir === 'desc') {
      setSort([]);
    }
  };

  const formatCanvasGridData = () => {
    let connectioncollections = {
      data: canvasSelections.tableFormatted.connections,
      total: canvasSelections.tableFormatted.connections.length
    };
    setGridState({
      ...gridState,
      connectioncollections,
      dataState: { take: 100, skip: 0 }
    });
  };

  const updateGridState = () => {
    // wrapped in promise purpose: allow async actions to finish prior to populating state
    if (
      selectedProject &&
      userObj.selectedBranch[0].id &&
      location.state?.from !== 'canvasPage'
    ) {
      store
        .dispatch(
          getConnections(selectedProject.id, userObj.selectedBranch[0].id)
        )
        .then(() => {
          const connections = { ...store.getState().connectionReducer };
          delete connections.selectedData;
          let connectioncollections = {
            data: Object.values(connections).filter(Boolean),
            total: Object.values(connections).length
          };
          if (!connectioncollections.data.length) {
            connectioncollections = { data: [], total: 0 };
          }
          setGridState({
            ...gridState,
            connectioncollections,
            dataState: { take: 20, skip: 0 }
          });
        });
    } else {
      formatCanvasGridData();
    }
  };

  const selectionChange = (e) => {
    const data = gridState.connectioncollections.data.map((collection) => {
      // Adds Selected Data to an Array to Control Button Visibility
      if (collection === e.dataItem) {
        collection.selected = !e.dataItem.selected;
        if (collection.selected === true) {
          // Sets connections into selectedConnections for canvas redirect //
          setSelectedConnections({
            ...selectedConnections,
            collection
          });
          setSelectedStateArray((selectedRowsStateArray) => [
            ...selectedRowsStateArray,
            collection
          ]);
        } else {
          if (selectAll) {
            setSelectAll(false);
          }
          const filteredSelectedState = selectedRowsStateArray.filter(
            (selecteCheck) => selecteCheck.selected === true
          );
          setSelectedStateArray(filteredSelectedState);

          // Removes unselected elements from selectedConnections //
          const filteredSelectedElements = [];
          selectedConnections?.connections?.filter((element) => {
            if (collection.id !== element.id) {
              filteredSelectedElements.push(element);
            }
          });
          setSelectedConnections({
            ...selectedConnections,
            ...filteredSelectedElements
          });
        }
      }
      return collection;
    });

    const gridObjs = { ...gridState.connectioncollections };
    gridObjs.data = data;
    store.dispatch(gridSelectedDataActions(e.dataItem));
    setGridState({ ...gridState, connectioncollections: gridObjs });
  };

  // Navigates user to either edit form or delete modal //
  // Passes in user's selected connections as a prop //
  const actionHandler = async (action, selectedCollections) => {
    if (action === 'edit' || action === 'doubleclick') {
      navigate(`/connections/edit`);
    }
    if (action === 'delete') {
      setShowModal(true);
    }
  };

  // Function for deleting connections //
  const deleteConnectionHandler = async () => {
    if (connections.selectedData.length > 1) {
      const connectionIds = connections.selectedData.map(
        (connection) => connection.id
      );
      await store.dispatch(
        deleteMultipleConnections(
          selectedProject,
          selectedBranch,
          connectionIds
        )
      );
      const newGridState = [...gridState.connectioncollections.data];
      connections.selectedData.forEach((deletedConnection) => {
        const connectionIdx = newGridState.findIndex(
          (connection) => connection.id === deletedConnection.id
        );
        if (connectionIdx > -1) {
          newGridState.splice(connectionIdx, 1);
        }
      });
      setSelectedStateArray([]);
      setGridState({
        ...gridState,
        connectioncollections: {
          data: newGridState,
          total: newGridState.length
        }
      });
      return;
    }

    await store.dispatch(deleteConnection(connections.selectedData[0]));
    const newGridState = [...gridState.connectioncollections.data];
    const connectionIdx = newGridState.findIndex(
      (connection) => connection.id === connections.selectedData[0].id
    );
    if (connectionIdx > -1) {
      setSelectedStateArray([]);
      newGridState.splice(connectionIdx, 1);
    }

    setGridState({
      ...gridState,
      connectioncollections: {
        data: newGridState,
        total: newGridState.length
      }
    });
  };

  // SETS ALL SELECTED ELEMENTS INTO STATE VAR //
  const onSelectAllChange = (e) => {
    if (!selectAll) {
      setSelectedConnections({
        ...selectedConnections,
        ...e.dataItems
      });
      setSelectAll(!selectAll);
      setSelectedStateArray([...e.dataItems]);
      store.dispatch(gridSelectedDataActions(e.dataItems));
    } else {
      setSelectedConnections({
        ...selectedConnections,
      });
      setSelectAll(!selectAll);
      setSelectedStateArray([]);
      store.dispatch(clearSelectedConnections());
    }
  };

  useEffect(() => {
    updateGridState();
  }, [selectedProject, selectedBranch]);

  useEffect(() => {
    setLoading(true);
    updateGridState();
    setLoading(false);
    setSkipProcessing(page.skip);
  }, [filter, sort, page, selectedProject, selectedBranch]); // eslint-disable-line
  
  return (
    <div
      style={{
        height: '61vh',
        margin: '16px',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}
    >
      <DeleteConfirmation
        showModal={showModal}
        setShowModal={setShowModal}
        deleteFn={deleteConnectionHandler}
      />
      <KendoGridBase
        data={gridState.connectioncollections.data || []}
        gridColumns={gridState.gridDynamicColumns}
        setGridFilters={handleGridFilterChange}
        setGridSort={handleGridSortChange}
        updateGridData={gridSelectedDataActions}
        onSelectionChange={selectionChange}
        onRowSingleClick={selectionChange}
        onSelectAllChange={onSelectAllChange}
        onPageChange={handlePageChange}
        sorter={sort}
        rowHeight={40}
        skip={page.skip}
        take={page.take}
        total={
          gridState.connectioncollections ? gridState.connectioncollections.total : 0
        }
        pageSize={100}
        selectable="selected"
        pageable={{
          pageSizes: [10, 25, 50, 75, 100],
          messages: {
            empty: 'no data to display'
          }
        }}
        selectAll={selectAll}
        sortable
      />
      <div className="container-fluid p-0 d-flex justify-content-between mt-2">
        <div>
          <button
            className="btn btn-primary btn-sm me-2"
            type="button"
            onClick={() => {
              actionHandler(
                'edit',
                selectedRowsStateArray[selectedRowsStateArray.length - 1]
              );
            }}
            disabled={
              !selectedRowsStateArray.length ||
              selectedRowsStateArray.length > 1 ||
              userObj.selectedBranch[0].branchStatus === 'published'
            }
          >
            <i className="bi bi-pencil me-2" />
            Edit
          </button>
        </div>
        <div>
          <button
            className="btn btn-danger btn-sm text-white"
            type="button"
            onClick={() => {
              actionHandler('delete', selectedRowsStateArray);
            }}
            disabled={
              !selectedRowsStateArray.filter(
                (connection) => connection.checkedOutBy === userObj.screenName
              ).length || userObj.selectedBranch[0].branchStatus === 'published'
            }
          >
            <i className="bi bi-trash me-2" />
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

ConnectionCollectionGrid.propTypes = {
  setRefresh: PropTypes.func,
  getConnections: PropTypes.func,
  gridSelectedDataActions: PropTypes.func,
  setNewConnection: PropTypes.func,
  refresh: PropTypes.bool,
  headers: PropTypes.object,
  setSelectedConnections: PropTypes.func,
  selectedConnections: PropTypes.object
};

export default ConnectionCollectionGrid;
