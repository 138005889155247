/**
 * @summary crudFormatter.js
 * @file Util: Formats Elements for Backend Saves
 * @returns {JSX}
 * @usedBy CanvasInspectorTool.js
 * @author Andy Greenhaw
 * @since 06/17/2021
 * @lastUpdated 06/17/2021
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */
import axios from 'axios'
import store from '../../../store/store';

import { createNode, editNode, deleteMultipleNodes } from '../../../store/nodes/NodeActions';
import { createConnection, deleteMultipleConnections } from 'store/connections/ConnectionActions';
import { createNeighborhood, editNeighborhood, deleteMultipleNeighborhoods } from 'store/neighborhoods/NeighborhoodActions';

import { toast } from 'react-toastify';

const crudFormatter = (canvasMapData, element, setEditedElement, setShowDataEditorMenu) => {
  /////////////////
  // NODES //
  /////////////////
  if(element.elementType === 'node'){  
    // CREATE NODE
    if(element.action === 'create'){
      delete element.action;
      delete element.elementType;
      store.dispatch(createNode(element)).then((res) => {
            if (res.status === 201) {
                if (res.data.message_type !== 'success') {
                    toast.error('An unexpected error occurred while creating Node: ${element.name}');
                } else {
                    toast.success(`Node: ${element.name} Created!`);
                    setEditedElement(element)
                    setShowDataEditorMenu(false)
                }
            } else {
              console.log(res)
            }
        }).catch((errors) => {
            // RETURNS ANY ERRORS FROM THE BACKEND THAT PREVENT SAVING
            const errorMessages = errors?.response?.data;
            if(errors.length){
              errors?.response.data.forEach(error => {
                toast.error(error);
              })
            } else {
              if(errorMessages[0] === "Node key already in use."){
                toast.error("This Node Key has been used in the past");
              } else {
                toast.error(errorMessages[0]);
              }
            }
        });
    // UPDATE NODE
    } else if(element.action === 'edit') {
      delete element.action;
      delete element.elementType;
      store.dispatch(editNode(element))
        .then((res) => {
            if (res.status === 201) {
                if (res.data.message_type !== 'success') {
                    toast.error('An unexpected error occurred while editing Node: ${element.name}');
                } else {
                    toast.success(`Node: ${element.name} Edited!`);
                    setEditedElement(element)
                    setShowDataEditorMenu(false)
                }
            }
        })
        .catch((errors) => {
          // RETURNS ANY ERRORS FROM THE BACKEND THAT PREVENT SAVING
          const errorMessages = errors?.response?.data;
          if(errors?.length){
            errors.forEach(error => {
              toast.error(error.message);
            })
          } else {
            toast.error(errorMessages);
          }
      });
    // DELETE NODE
    } else if(element.action === 'delete') {
      store.dispatch(deleteMultipleNodes(element.project, element.branch, element.data))
        .then((res) => {
            if (res.status === 201) {
                if (res.data.message_type !== 'success') {
                    toast.error('An unexpected error occurred while deleting Node: ${element.name}');
                } else {
                    toast.success(`Deleted!`);
                    setEditedElement(element)
                    setShowDataEditorMenu(false)
                }
            }
        })
        .catch((errors) => {
          // RETURNS ANY ERRORS FROM THE BACKEND THAT PREVENT SAVING
          const errorMessages = errors?.response?.data;
          console.log(errorMessages)
          if(errors?.length){
            errors.forEach(error => {
              toast.error(error.message);
            })
          } else {
            toast.error(errorMessages);
          }
      });
    }
  /////////////////
  // CONNECTIONS //
  /////////////////
  } else if (element.elementType === 'connection'){
    // CREATE CONNECTION
    if(element.action === 'create'){
      delete element.action;
      delete element.elementType;
      store
      .dispatch(createConnection(element))
      .then((res) => {
          if (res.status === 201) {
              if (res.data.message_type !== 'success') {
                  toast.error('An unexpected error occurred while creating Connection: ${element.name}');
              } else {
                  toast.success(`Connection: ${element.name} Created!`);
                  setEditedElement(element)
                  setShowDataEditorMenu(false)
              }
          }
      })
      .catch((errors) => {
          // RETURNS ANY ERRORS FROM THE BACKEND THAT PREVENT SAVING
           const errorMessages = errors?.response?.data;
           if(errors.length){
             errors?.response.data.forEach(error => {
               toast.error(error);
             })
           } else {
             if(errorMessages[0] === "Connection already exists!"){
               toast.error("This Connection Name has been used in the past");
             } else {
               toast.error(errorMessages[0]);
             }
           }
      });
    // DELETE CONNECTION
    } else if(element.action === 'delete') {
      store.dispatch(deleteMultipleConnections(element.project, element.branch, element.data))
        .then((res) => {
            if (res.status === 201) {
                if (res.data.message_type !== 'success') {
                    toast.error('An unexpected error occurred while deleting Connection: ${element.name}');
                } else {
                    toast.success(`Deleted!`);
                    setEditedElement(element)
                    setShowDataEditorMenu(false)
                }
            }
        })
        .catch((errors) => {
          // RETURNS ANY ERRORS FROM THE BACKEND THAT PREVENT SAVING
          const errorMessages = errors?.response?.data;
          if(errors?.length){
            errors.forEach(error => {
              toast.error(error.message);
            })
          } else {
            toast.error(errorMessages);
          }
      });
    }
  } else if (element.elementType === 'neighborhood'){
    // CREATE NEIGHBORHOOD
    if(element.action === 'create'){
      delete element.action;
      delete element.elementType;
      store.dispatch(createNeighborhood(element)).then((res) => {
          if (res.status === 201) {
              if (res.data.message_type !== 'success') {
                  toast.error('An unexpected error occurred while creating Neighborhood: ${element.name}');
              } else {
                  toast.success(`Neighborhood: ${element.name} Created!`);
                  setEditedElement(element)
                  setShowDataEditorMenu(false)
              }
          } else {
            // RETURNS ANY ERRORS FROM THE BACKEND THAT PREVENT SAVING
            const errorMessages = errors?.response?.data;
            if(errors.length){
              errors?.response.data.forEach(error => {
                toast.error(error);
              })
            } else {
                toast.error(errorMessages[0]);
            }
          }
        }).catch((errors) => {
           // RETURNS ANY ERRORS FROM THE BACKEND THAT PREVENT SAVING
          const errorMessages = errors?.response?.data;
          if(errors?.length){
            errors.forEach(error => {
              toast.error(error.message);
            })
          } else {
            toast.error(errorMessages);
          }
        });
    // EDIT NEIGHBORHOOD
    } else if(element.action === 'edit') {
      delete element.action;
      delete element.elementType;
      store.dispatch(editNeighborhood(element))
        .then((res) => {
            if (res.status === 201) {
                if (res.data.message_type !== 'success') {
                    toast.error('An unexpected error occurred while editing Node: ${element.name}');
                } else {
                    toast.success(`Neighborhood: ${element.name} Edited!`);
                    setEditedElement(element)
                    setShowDataEditorMenu(false)
                }
            }
        })
        .catch((errors) => {
          // RETURNS ANY ERRORS FROM THE BACKEND THAT PREVENT SAVING
          const errorMessages = errors?.response?.data;
          if(errors?.length){
            errors.forEach(error => {
              toast.error(error.message);
            })
          } else {
            toast.error(errorMessages);
          }
      });
    // DELETE NEIGHBORHOOD
    } else if (element.action ==="delete"){
      store
      .dispatch(deleteMultipleNeighborhoods(element.project, element.branch, element.data))
      .then((res) => {
          if (res.status === 201) {
              if (res.data.message_type !== 'success') {
                  toast.error('An unexpected error occurred while deleting Neighborhood: ${element.name}');
              } else {
                  toast.success(`Deleted!`);
                  setEditedElement(element)
                  setShowDataEditorMenu(false)
              }
          } else {
            console.log(res)
          }
      })
      .catch((errors) => {
          // RETURNS ANY ERRORS FROM THE BACKEND THAT PREVENT SAVING
          const errorMessages = errors?.response?.data;
          if(errors?.length){
            errors.forEach(error => {
              toast.error(error.message);
            })
          } else {
            toast.error(errorMessages);
          }
      });

  }
  ///////
  }
}

export default crudFormatter;