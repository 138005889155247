/**
 * @summary NodeSummary.js
 * @file Component returns a list of unique node attributes in relation to the comparison report of the two branches
 * @returns {JSX}
 * @usedBy SnapshotSummary.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';

import ElementIncongruencies from './sectionRenders/ElementIncongruencies'
import UniqueElements from './sectionRenders/UniqueElements'
import UniqueAttributes from './sectionRenders/UniqueAttributes'
import AttributeIncongruencies from './sectionRenders/AttributeIncongruencies'

const NodeSummary = ({
    uniqueElements, elementIncongruencies, uniqueAttributes, attributeIncongruencies
}) => {
    
    return(
        <>  
            {uniqueElements.length > 0 &&
                uniqueElements.map(element => {
                    return( 
                        <>
                        <UniqueElements
                            uniqueElement={element}
                        />
                        </>
                    )}
                )
            }
            {elementIncongruencies.length > 0 &&
                elementIncongruencies.map(incongruency => {
                    return( 
                        <>
                        <ElementIncongruencies
                            elementIncongruency={incongruency}
                        />
                        </>
                    )}
                )
            }
            {uniqueAttributes.length > 0 &&
                uniqueAttributes.map(attribute => {
                    return( 
                        <>
                        <UniqueAttributes
                            uniqueAttribute={attribute}
                        />
                        </>
                    )}
                )
            }
            {attributeIncongruencies.length > 0 &&
                attributeIncongruencies.map(incongruency => {
                    return( 
                        <>
                        <AttributeIncongruencies
                            attributeIncongruency={incongruency}
                        />
                        </>
                    )}
                )
            }
        </>
    )
}

export default NodeSummary;

NodeSummary.propTypes = {
    uniqueElements: PropTypes.array,
    elementIncongruencies: PropTypes.array,
    uniqueAttributes: PropTypes.array,
    attributeIncongruencies: PropTypes.array,
}