/**
 * @summary normalizeName.js
 * @file returns name from camel case to standard case
 * @returns {JSX}
 * @usedBy NodeForm, ConnectionForm, NewForm, NeighborhoodForm
 * @author Dj Ritchey
 * @since 4/01/2021
 * @lastUpdated 04/2021
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const normalizeName = (name) =>
  name
    .split('_')
    .map((ele) => `${ele.charAt(0).toUpperCase()}${ele.substr(1)}`)
    .join(' ');
