/**
 * @summary Project Setup Actions
 * @file ProjectSetupActions.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios';

import {
  GET_PROJECT_SETUP,
  RESET_PROJECT_SETUP,
  UPDATE_PROJECT_SETUP,
  UPDATE_ASSESSMENT_PROGRAM,
  UPDATE_PROJECT_SETTINGS,
  SET_SETTINGS,
  UPDATE_NODE_SETTINGS,
  UPDATE_CONNECTION_SETTINGS,
  UPDATE_NEIGHBORHOOD_SETTINGS,
  SET_SELECTED_CONNECTION_VALUES,
  SET_SELECTED_NODE_VALUES,
  SET_SELECTED_NEIGHBORHOOD_VALUES,
  HYDRATE_PROJECT,
  CLONE_PROJECT,
  UPDATE_ATTR_VALUE,
  REMOVE_CHECKED_VALUE,
  SET_PROJECT_ID,
  SET_NODE_COLUMN_SETTINGS,
  SET_NEIGHBORHOOD_COLUMN_SETTINGS
} from './ProjectSetupTypes';

export const getProjectSetup = () => ({
  type: GET_PROJECT_SETUP
});

export const resetProjectSetup = () => ({
  type: RESET_PROJECT_SETUP
});

export const updateProjectAssessmentProgram = (value) => ({
  type: UPDATE_ASSESSMENT_PROGRAM,
  setting: { name: 'assessmentProgramId', value }
});

export const updateProjectSetup = (data) => ({
  type: UPDATE_PROJECT_SETUP,
  setting: data
});

export const updateProjectSettings = (attrName, settingsObj) => ({
  type: UPDATE_PROJECT_SETTINGS,
  setting: { attrName, settingsObj }
});

export const setProjectSettings = (settingsObj) => ({
  type: SET_SETTINGS,
  settingsObj
});

export const updateNodeProjectSettings = (nodeSettings) => ({
  type: UPDATE_NODE_SETTINGS,
  nodeSettings
});

export const updateConnectionProjectSettings = (connectionSettings) => ({
  type: UPDATE_CONNECTION_SETTINGS,
  connectionSettings
});

export const updateNeighborhoodProjectSettings = (neighborhoodSettings) => ({
  type: UPDATE_NEIGHBORHOOD_SETTINGS,
  neighborhoodSettings
});

export const setConnectionSelectedValues = (selectedValues) => ({
  type: SET_SELECTED_CONNECTION_VALUES,
  selectedValues
});

export const setNodesSelectedValues = (selectedValues) => ({
  type: SET_SELECTED_NODE_VALUES,
  selectedValues
});

export const setNodesColumnSettings = (columnSettings) => ({
  type: SET_NODE_COLUMN_SETTINGS,
  columnSettings
});

export const setNeighborhoodsSelectedValues = (selectedValues) => ({
  type: SET_SELECTED_NEIGHBORHOOD_VALUES,
  selectedValues
});

export const setNeighborhoodsColumnSettings = (columnSettings) => ({
  type: SET_NEIGHBORHOOD_COLUMN_SETTINGS,
  columnSettings
});

const setHydrateProject = (projectData) => ({
  type: HYDRATE_PROJECT,
  projectData
});

const setCloneProject = (projectData) => ({
  type: CLONE_PROJECT,
  projectData
});

export const hydrateProject = (userObj) => async (dispatch) => {
  const { data } = await axios.get(
    `/api/${userObj.currentAssessmentProgram}/projects/${userObj.selectedProject[0]?.id}/branch/${userObj.selectedBranch[0]?.id}`
  );
  if (data) {
    dispatch(setHydrateProject(data));
  } else {
    //if data is null, return that null so we can handle the error
    return data;
  }
};

export const hydrateCloneProject =
  (userObj, project, branch) => async (dispatch) => {
    const { data } = await axios.get(
      `/api/${userObj.currentAssessmentProgram}/projects/${project.id}/branch/${branch.id}`
    );
    if (data) {
      dispatch(setCloneProject(data));
    } else {
      const errorObj = 'No data on published branch.';
      return errorObj;
      //handle error
    }
  };

export const updateAttrValue = (attribute) => ({
  type: UPDATE_ATTR_VALUE,
  attribute
});

export const removeCheckedValue = (arrayType, attribute) => ({
  type: REMOVE_CHECKED_VALUE,
  arrayType,
  attribute
});

export const setProjectId = (id) => ({
  type: SET_PROJECT_ID,
  id
});
