/**
 * @summary customDragTool.js
 * @file Util: GoJS Custom Element Drag Functionality - Provided by GoJS: Performs All Custom Features Requested by Client
 * @returns {JSX}
 * @usedBy Canvas.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as go from 'gojs';
///////////////////////
// DRAG-N-DROP LOGIC //
///////////////////////
export class CustomDragTool extends go.DraggingTool {
  moveParts(parts, offset, check) {
    super.moveParts(parts, offset, check);
    // layout containing neighborhoods if a node is being dragged
    const coll = new go.Set(/*go.Part*/);
    parts.each((kvp) => {
      var p = kvp.key;
      // we don't care about links or neighborhoods
      if (p instanceof go.Link) return;
      if (p instanceof go.Node && p.category === 'Super') return;
      // p is a regular node
      var sups = p.data.supers || [];
      for (let i = 0; i < sups.length; i++) {
        var sup = sups[i];
        coll.add(this.diagram.findNodeForKey(sup));
      }
    });
    this.diagram.layout.doLayout(coll);
  }
  computeEffectiveCollection(parts) {
    const coll = new go.Set(/*go.Part*/).addAll(parts);
    parts.each((p) => this.walkSubTree(p, coll));
    return super.computeEffectiveCollection(coll);
  }
  walkSubTree(sup, coll) {
    if (sup === null) return;
    coll.add(sup);
    if (sup.category !== 'Super') return;
    const mems = sup.data._members;
    if (mems) {
      for (let i = 0; i < mems.length; i++) {
        const mdata = mems[i];
        this.walkSubTree(this.diagram.findNodeForKey(mdata.nodeKey), coll);
      }
    }
  }
}
