/**
 * @summary NodeKeyAutosuggest.js
 * @file Input component finds all potential nodekey matches to a user's keystroke input, which is then mapped and displayed on the UI to the user
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Input,
} from "@progress/kendo-react-inputs";
import { toast } from 'react-toastify';

const NodeKeyAutosuggest = ({
    selectedNodeKey,
    allNodeKeysAvailable,
    nodeKeySuggestions,
    setNodeKeySuggestions,
    setSelectedNodeKey,
}) => {  
    
        const [selectedInput, setSelectedInput] = useState(null)

        useEffect(()=> {
            if(selectedNodeKey === null){
                setSelectedInput("")
            }
        },[selectedNodeKey])

        ////////////////////
        // Node Key Input //
        ////////////////////
        const handleNodeKeyInput = (e) => {
            if(allNodeKeysAvailable !== null){
                // As User Types, This Function Matches Inputs to Node Keys in the Array
                let input = e.target.value;
                let matches = [];
                if (input.length >= 1) {
                    const regex = new RegExp(`${input}`, "gi");
                    for(let i=0; i<allNodeKeysAvailable.length; i++){
                        regex.lastIndex = -1;
                        if(regex.test(allNodeKeysAvailable[i].nodeKey) === true){
                            matches.push(allNodeKeysAvailable[i])
                        }
                    }
                }
                // ChatGPT-Generated Sort Function that 
                // Sorts "String" Characters Alphabetically and looks at "Numbers" with multiple digits instead of individually
                // Can sort any complex Node Key into an order that's more intuitive for humans
                let newOrder = matches.sort((a, b) => {
                    let aParts = a.nodeKey.match(/(\D+)|(\d+)/g);
                    let bParts = b.nodeKey.match(/(\D+)|(\d+)/g);
                    let aLen = aParts.length;
                    let bLen = bParts.length;
                    for (let i = 0; i < aLen && i < bLen; i++) {
                        let aPart = aParts[i];
                        let bPart = bParts[i];
                        if (isNaN(aPart) && isNaN(bPart)) {
                            if (aPart < bPart) {
                                return -1;
                            } else if (aPart > bPart) {
                                return 1;
                            }
                        } else if (!isNaN(aPart) && !isNaN(bPart)) {
                            let aNum = parseInt(aPart);
                            let bNum = parseInt(bPart);
                            if (aNum < bNum) {
                                return -1;
                            } else if (aNum > bNum) {
                                return 1;
                            }
                        } else {
                            return isNaN(aPart) ? 1 : -1;
                        }
                    }
                    return aLen - bLen;
                });
                setNodeKeySuggestions(newOrder);
                setSelectedInput(input)
                setSelectedNodeKey(input)
                // if(matches.length === 0 && input.length >=1){
                //     toast.error(`'` + input + `'` + ' does not match any node keys in this map.')
                // }
            } else {
                toast.error('One moment... We are retreiving nodes for this map.')
            }
        }
        const selectValue = (item) => {
            setSelectedNodeKey(item)
            setSelectedInput(item)
            setNodeKeySuggestions([])
        }

        // const suggestions = renderSuggestions()

        return(
            
            <div className='autosuggest-form-wrapper'>
                <Input 
                    style={{minHeight: 37, width: "90%"}}
                    placeholder={"Type a Node Key"}
                    value={selectedNodeKey ? selectedNodeKey : selectedInput}
                    className='autosuggest-input'
                    onChange={(e) => {
                        handleNodeKeyInput(e)
                    }}
                />
            
            {
                nodeKeySuggestions.length > 0 ? (
                <div className='autosuggest-suggestion-wrapper'>
                    {
                        nodeKeySuggestions.map(item => {
                            return (
                                <div 
                                    className='autosuggest-suggestions' 
                                    key={item.id}
                                    onClick={() => selectValue(item.nodeKey)}
                                >
                                    {item.nodeKey}
                                </div>
                            )
                        })
                    }
                </div>
                ) : null
            }
            </div>
        )
        
}

NodeKeyAutosuggest.propTypes = {
    selectedNodeKey: PropTypes.string,
    allNodeKeysAvailable: PropTypes.array,
    setSelectedNodeKey: PropTypes.func,
    selectedMap: PropTypes.string,
    nodeKeySuggestions: PropTypes.array,
    handleNodeKeyInput: PropTypes.func,
    setNodeKeySuggestions: PropTypes.func
};
    
export default NodeKeyAutosuggest;