/**
 * @summary Neighborhood Action Types
 * @file NeighborhoodTypes.js
 * @returns {String}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const CREATE_NEIGHBORHOOD = 'CREATE_NEIGHBORHOOD';

export const GET_NEIGHBORHOODS = 'GET_NEIGHBORHOODS';

export const DELETE_NEIGHBORHOOD = 'DELETE_NEIGHBORHOOD';

export const GRID_DATA_ACTIONS_SELECTIONS_NEIGHBORHOODS_FULLFILLED =
  'GRID_DATA_ACTIONS_SELECTIONS_NEIGHBORHOODS_FULLFILLED';

export const CLEAR_NEIGHBORHOODS = 'CLEAR_NEIGHBORHOODS';

export const EDIT_NEIGHBORHOODS = 'EDIT_NEIGHBORHOODS';

export const GET_NEIGHBORHOOD = 'GET_NEIGHBORHOOD';

export const GET_NEIGHBORHOODLINKAGELEVELS = 'GET_NEIGHBORHOODLINKAGELEVELS';

export const EDIT_NEIGHBORHOODLINKAGELEVELS = 'EDIT_NEIGHBORHOODLINKAGELEVELS';

export const ADD_NEIGHBORHOOD_MEDIA = 'ADD_NEIGHBORHOOD_MEDIA';

export const SELECT_ALL_NEIGHBORHOOD = 'SELECT_ALL_NEIGHBORHOOD';

export const ADD_LINKAGELEVEL_MEDIA = 'ADD_LINKAGELEVEL_MEDIA';

export const GET_NEIGHBORHOOD_NODE_IDS = 'GET_NEIGHBORHOOD_NODE_IDS';

export const SET_NEW_NEIGHBORHOOD = 'SET_NEW_NEIGHBORHOOD';

export const ADD_NODES_TO_NEIGHBORHOOD = 'ADD_NODES_TO_NEIGHBORHOOD';

export const UPDATE_SELECTED_LINKAGE_LEVELS = 'UPDATE_SELECTED_LINKAGE_LEVELS';

export const REMOVE_NODES_FROM_NEIGHBORHOOD = 'REMOVE_NODES_FROM_NEIGHBORHOOD';

export const REMOVE_NODES_FROM_NEIGHBORHOODS_MUTLIPLE =
  'REMOVE_NODES_FROM_NEIGHBORHOODS_MUTLIPLE';

export const DELETE_MULTIPLE_NEIGHBORHOOD = 'DELETE_MULTIPLE_NEIGHBORHOOD';

export const DELETE_NEIGHBORHOOD_NODES = 'DELETE_NEIGHBORHOOD_NODES';

export const REPLACE_TABLEVIEW_NEIGHBORHOODS =
  'REPLACE_TABLEVIEW_NEIGHBORHOODS';

export const CLEAR_SELECTED_NEIGHBORHOODS = 'CLEAR_SELECTED_NEIGHBORHOODS';

export const GET_NEIGHBORHOOD_COPY = 'GET_NEIGHBORHOOD_COPY';
