/**
 * @summary AttributeSettings.js
 * @file attributes panel for node form
 * @returns {JSX}
 * @usedBy NodeForm.js
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useEffect, useRef, useState } from 'react';
import { getNode, getNodeSettings } from 'store/nodes/NodeActions';
import store from '../../../store/store';
import PropTypes from 'prop-types';
import { normalizeName } from 'utility/updateFields';
import { renderHTMLElement } from '../utils/renderHTML';
import {
  clearContentCodes,
  loadContentCodes
} from 'store/metadata/MetadataAction';
import Modal from 'react-bootstrap/Modal';


const AttributeSettings = ({
  userObj,
  node,
  nodeDetails,
  setNodeDetails,
  contentMetadata,
  setContentMetadata,
  taxonomiesDimensions,
  setTaxonomiesDimensions,
  accessibilityFlags,
  setAccessibilityFlags,
  nodeArray,
  handleCloneSelect,
  cloneConfirmationVisible,
  setCloneConfirmationVisible,
  setSelectedClone,
  lastHref,
  selectedClone
}) => {
  const didFetchRef = useRef(null);

  //TODO: can be refactored into util fn
  const updateRadioButton = (value, idx) => {
    const newList = [...accessibilityFlags];
    if (!newList[idx].selectedValue.length) {
      newList[idx].selectedValue.push({
        id: idx,
        name: value
      });
    } else {
      newList[idx].selectedValue[0].name = value;
    }
    setAccessibilityFlags([...newList]);
  };

  // takes in data array to sort out all possible values for each attribute input
  const setPossibleValueObject = (data, metaDataId, selectedData, name) => {
    if (name !== 'content_codes') {
      if (metaDataId?.length > 0) {
        let output = metaDataId.map((id, value) => ({ id, name: data[value] }));
        return output;
      } else {
        const nodeValue = (val) => {
          const foundVal = selectedData.find((obj) => obj.val === val);
          return foundVal?.valueId || undefined;
        };

        return data?.map((val, idx) => {
          return {
            id: idx,
            nodeValueId: selectedData.length > 0 ? nodeValue(val) : undefined,
            name: val
          };
        });
      }
    } else {
      const selectedContentCodes =
        store.getState().metadataReducer.selectedContentCodes;
      if (selectedContentCodes && selectedContentCodes.length > 0) {
        const CCArray = selectedContentCodes[0].map((element) => ({
          id: element.id,
          name: element.content_code
        }));
        if (CCArray.length > 0) {
          return CCArray;
        } else {
          const nodeValue = (val) => {
            const foundVal = selectedData.find((obj) => obj.val === val);
            return foundVal?.valueId || undefined;
          };
          return selectedContentCodes[0].map((val, idx) => {
            return {
              id: val.id,
              nodeValueId: selectedData.length > 0 ? nodeValue(val) : undefined,
              name: val.content_code
            };
          });
        }
      }
    }
  };

  //can be refactored into util fn
  const applySettingToState = (setting, updatedList) => {
    //need a check for the format of the content code bs
    setting.possibleValue = setPossibleValueObject(
      setting.value,
      setting.metaDataId,
      setting.selectedValue,
      setting.name
    );
    if (setting.category === 'Node Details') {
      setNodeDetails((nodeDetails) => {
        const existingSetting = nodeDetails.findIndex(
          (existingSetting) =>
            existingSetting.attributeId === setting.attributeId
        );
        if (existingSetting < 0) {
          return [...nodeDetails, setting];
        } else {
          nodeDetails[existingSetting] = setting;
          return [...nodeDetails];
        }
      });
    }
    if (setting.category === 'Content Metadata') {
      if (setting.name === 'content_codes') {
        if (setting.possibleValue) {
          setting.possibleValue.forEach((e) => {
            setting.metaDataId.push(e.id);
          });
        }
        if (updatedList) {
          const oldSelectedCC = updatedList.filter(
            (element) => element.name === 'content_codes'
          );
          if (oldSelectedCC.length >= 1) {
            const oldData = updatedList;
            const pos = oldData.map((e) => e.name).indexOf('content_codes');
            oldData.splice(pos, 1);
            setContentMetadata(oldData);
          }
        }
        if (!setting.possibleValue) {
          return;
        } else {
          setting.possibleValue.forEach((element) => {
            setting.value = [element.name];
          });
        }
      }
      setContentMetadata((contentMetadata) => {
        const existingSetting = contentMetadata.findIndex(
          (existingSetting) =>
            existingSetting.attributeId === setting.attributeId
        );
        if (existingSetting < 0) {
          return [...contentMetadata, setting];
        } else {
          contentMetadata[existingSetting] = setting;
          return [...contentMetadata];
        }
      });
    }
    if (setting.category === 'Taxonomies and Dimensions') {
      setTaxonomiesDimensions((taxonomiesDimensions) => {
        const existingSetting = taxonomiesDimensions.findIndex(
          (existingSetting) =>
            existingSetting.attributeId === setting.attributeId
        );
        if (existingSetting < 0) {
          return [...taxonomiesDimensions, setting];
        } else {
          taxonomiesDimensions[existingSetting] = setting;
          return [...taxonomiesDimensions];
        }
      });
    }
    if (setting.category === 'Accessibility Flags') {
      setAccessibilityFlags((accessibilityFlags) => {
        const existingSetting = accessibilityFlags.findIndex(
          (existingSetting) =>
            existingSetting.attributeId === setting.attributeId
        );
        if (existingSetting < 0) {
          return [...accessibilityFlags, setting];
        } else {
          accessibilityFlags[existingSetting] = setting;
          return [...accessibilityFlags];
        }
      });
    }
  };

  const setAttributeSelectableValues = (settingsData) => {
    settingsData?.forEach((setting) => {
      applySettingToState(setting);
    });
  };

  const setExistingNodeSettings = (nodeData) => {
    const nodeValues = nodeData[0].nodeSettings?.map((setting) => {
      setting.selectedValue = [];
      if (nodeData[0].selectedValue) {
        const foundSelection = nodeData[0].selectedValue?.filter(
          (value) => setting.attributeId === value.attrId
        );
        setting.nodeAttrId = foundSelection?.nodeAttrId || undefined;
        if (foundSelection) {
          setting.selectedValue = foundSelection?.map((value, idx) => {
            const attr = value.values[0];
            return {
              id: attr.selectedId || setting.value.indexOf(attr.selectedValue),
              nodeValueId: attr.valueId,
              name: attr.selectedValue ? attr.selectedValue : '',
              nodeAttrId: foundSelection[idx].nodeAttrId
            };
          });
        }
      }
      return setting;
    });
    setAttributeSelectableValues(nodeValues);
  };

  const setBaseSelections = (nodeData) => {
    const nodeValues = nodeData.map((setting) => ({
      ...setting,
      selectedValue: []
    }));
    setAttributeSelectableValues(nodeValues);
  };

  const getAttributeSettings = async () => {
    if (node?.id < 1 && !selectedClone) {
      store.dispatch(clearContentCodes());
      const nodeSettings = await store.dispatch(
        getNodeSettings(
          userObj.selectedProject[0].id,
          userObj.selectedBranch[0].id
        )
      );
      for (const element of nodeSettings) {
        if (element.name === 'content_codes') {
          applySettingToState(element);
        }
      }
      setBaseSelections(nodeSettings);
    } else {
      // editing a node
      store.dispatch(clearContentCodes());
      const nodeData = await store.getState().nodeReducer.selectedData;
      setExistingNodeSettings(nodeData);
    }
  };

  const getUpdatedValues = (values, key) => {
    if (key.category === 'Node Details') {
      if (key.dataType === 'Text') {
        let updatedList = nodeDetails.map((item) => {
          if (item.name === key.name) {
            const newObj = { name: values };
            return { ...item, selectedValue: [newObj] };
          }
          return item;
        });
        setNodeDetails(updatedList);
      }
      if (key.dataType === 'Drop-Down') {
        let updatedList = nodeDetails.map((item) => {
          if (item.name === key.name) {
            const value = parseInt(values);
            const newSelectedValue = item.possibleValue.find((element) => {
               return element.id === value
              }
            );
            return { ...item, selectedValue: [newSelectedValue] };
          }
          return item;
        });
        setNodeDetails(updatedList);
      }
      if (key.dataType === 'Multi-Select') {
        let updatedList = nodeDetails.map((item) => {
          if (item.name === key.name) {
            return { ...item, selectedValue: values };
          }
          return item;
        });
        setNodeDetails(updatedList);
      }
    }
    if (key.category === 'Content Metadata') {
      if (key.dataType === 'Text') {
        let updatedList = contentMetadata.map((item) => {
          if (item.name === key.name) {
            const newObj = { name: values };
            return { ...item, selectedValue: [newObj] };
          }
          return item;
        });
        setContentMetadata(updatedList);
      }
      if (key.dataType === 'Drop-Down') {
        if (key.name === 'subject') {
          let updatedList = contentMetadata.map((item) => {
            if (item.name === key.name) {
              const value = parseInt(values);
              const newSelectedValue = item.possibleValue.find(
                (element) => element.id === value
              );
              return { ...item, selectedValue: [newSelectedValue] };
            }
            return item;
          });
          setContentMetadata(updatedList);

          const found = updatedList.find(
            (element) => element.name === 'subject'
          );
          const loadCC = async () => {
            if (found.selectedValue[0] !== undefined) {
              await store.dispatch(
                loadContentCodes(
                  found.selectedValue[0].id,
                  userObj.selectedProject[0]?.id,
                  userObj.selectedBranch[0]?.id,
                  'node'
                )
              );
            }
            const nodeSettings = await store.dispatch(
              getNodeSettings(
                userObj.selectedProject[0].id,
                userObj.selectedBranch[0].id
              )
            );
            for (const element of nodeSettings) {
              if (element.name === 'content_codes') {
                applySettingToState(element, updatedList);
              }
            }
          };
          loadCC();
        } else {
          let updatedList = contentMetadata.map((item) => {
            if (item.name === key.name) {
              const value = parseInt(values);
              const newSelectedValue = item.possibleValue.find(
                (element) => element.id === value
              );
              return { ...item, selectedValue: [newSelectedValue] };
            }
            return item;
          });
          setContentMetadata(updatedList);
        }
      }
      if (key.dataType === 'Multi-Select') {
        let updatedList = contentMetadata.map((item) => {
          if (item.name === key.name) {
            return { ...item, selectedValue: values };
          }
          return item;
        });
        setContentMetadata(updatedList);
      }
      if (key.dataType === 'Custom') {
        let updatedList = contentMetadata.map((item) => {
          if (item.name === key.name) {
            return { ...item, selectedValue: values };
          }
          return item;
        });
        setContentMetadata(updatedList);
      }
    }
    if (key.category === 'Taxonomies and Dimensions') {
      if (key.dataType === 'Text') {
        let updatedList = taxonomiesDimensions.map((item) => {
          if (item.name === key.name) {
            const newObj = { name: values };
            return { ...item, selectedValue: [newObj] };
          }
          return item;
        });
        setTaxonomiesDimensions(updatedList);
      }
      if (key.dataType === 'Drop-Down') {
        let updatedList = taxonomiesDimensions.map((item) => {
          if (item.name === key.name) {
            const value = parseInt(values);
            const newSelectedValue = item.possibleValue.find(
              (element) => element.id === value
            );
            return { ...item, selectedValue: [newSelectedValue] };
          }
          return item;
        });
        setTaxonomiesDimensions(updatedList);
      }
      if (key.dataType === 'Multi-Select') {
        let updatedList = taxonomiesDimensions.map((item) => {
          if (item.name === key.name) {
            return { ...item, selectedValue: values };
          }
          return item;
        });
        setTaxonomiesDimensions(updatedList);
      }
    }
  };

  const updateValues = (e, key) => {
    const { value } = e;
    getUpdatedValues(value, key);
  };

  const onCloneChange = (e) => {
    const selectedCloneNode = nodeArray.find(
      (node) => node.id === parseInt(e.target.value)
    );
    setSelectedClone(selectedCloneNode);
  };

  const toggleDialog = () => {
    setCloneConfirmationVisible(!cloneConfirmationVisible);
  };

  useEffect(() => {
    getAttributeSettings();
  }, [selectedClone, node]);

  return (
    <div className="container-fluid">
      {nodeArray.length > 1 && lastHref === 'new' && (
        <div className="col-12 pb-1 hstack">
          <div className='col-1-lg pr-1'>
            <label className="form-label" htmlFor="clone-node">
              Clone Attributes from Node:{' '}
            </label>
          </div>
          <div className='col-3 ms-2'>
            <select
              name="clone-node"
              className="form-select form-select-sm"
              onChange={onCloneChange}
              value={selectedClone?.id || null}
              id="clone-project"
            >
              <option value="0">Select a Node</option>
              {nodeArray.map((node) => (
                <option key={node.id} value={node.id}>
                  {node.nodeKey + ": " + node.name}
                </option>
              ))}
            </select>
          </div>
          <div className='col-1 ms-3'>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={toggleDialog}
            >
              <i className="bi bi-box-arrow-in-right"></i> Clone
            </button>
          </div>
          {cloneConfirmationVisible && (
            <Modal show={cloneConfirmationVisible} backdrop="static" centered size="md">
              <Modal.Header>
                <Modal.Title>
                  <h2 className='text-white text-center'>
                    Please Confirm
                  </h2>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5 className='text-center pt-3 pb-3'>
                  Are you sure you want to clone this node?
                </h5>
              </Modal.Body>
              <Modal.Footer>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='ms-2 text-right'>
                        <button
                          className="btn btn-danger btn-sm text-white w-75"
                          onClick={() => toggleDialog()}
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='me-2 text-left'>
                        <button
                          className="btn btn-primary btn-sm text-white w-75 "
                          // disabled={branchName.length < 1}
                          onClick={() => {handleCloneSelect()}}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      )}
      <div className="row">
        <div className="col p-2 justify-content-between">
          <fieldset className="bg-light p-3 rounded">
            <legend>Content Metadata</legend>
            {!contentMetadata.length ? (
              <p>no attributes selected</p>
            ) : (
              contentMetadata.map((key, idx) => (
                <>
                  <div className="col d-flex p-2 justify-content-between">
                    <div className="col-1 text-end">
                      <label
                        className="form-label"
                        htmlFor={`metadata-attribute-${key.name}-${idx}`}
                      >
                        {normalizeName(key.name)}
                      </label>
                    </div>
                    <div className="col-10">
                      {renderHTMLElement(
                        key,
                        updateValues,
                        `metadata-attribute-${key.name}-${idx}`
                      )}
                    </div>
                  </div>
                </>
              ))
            )}
          </fieldset>
        </div>
        <div className="col p-2 justify-content-between">
          <fieldset className="bg-light p-3 rounded">
            <legend>Taxonomies and Dimensions</legend>
            {!taxonomiesDimensions.length ? (
              <p>no attributes selected</p>
            ) : (
              taxonomiesDimensions.map((key, idx) => (
                <>
                  <div className="col d-flex p-2 justify-content-between">
                    <div className="col-2 text-end">
                      <label
                        className="form-label"
                        htmlFor={`taxonomy-attribute-${key.name}-${idx}`}
                      >
                        {normalizeName(key.name)}
                      </label>
                    </div>
                    <div className="col-9">
                      {renderHTMLElement(
                        key,
                        updateValues,
                        `taxonomy-attribute-${key.name}-${idx}`
                      )}
                    </div>
                  </div>
                </>
              ))
            )}
          </fieldset>
        </div>
      </div>

      <div className="row">
        <div className="col p-2">
          <fieldset className="bg-light p-3 rounded">
            <legend>Accessability Flags</legend>
            <div className="row">
              {!accessibilityFlags.length ? (
                <p>no attributes selected</p>
              ) : (
                accessibilityFlags.map((flag, idx) => (
                  <>
                    <p key={flag}>{normalizeName(flag.name)}:</p>
                    <div className="col" style={{ display: 'flex' }}>
                      <div className="form-check mx-4">
                        <input
                          id={`accessibility-attribute-${flag.name}-${idx}-1`}
                          className="form-check-input"
                          type="radio"
                          name={`scoring-${idx}`}
                          value={'True'}
                          checked={
                            flag.selectedValue[0]
                              ? flag.selectedValue[0].name === 'True'
                              : ''
                          }
                          onChange={(e) =>
                            updateRadioButton(e.target.value, idx, flag)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`accessibility-attribute-${flag.name}-${idx}-1`}
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check mx-4">
                        <input
                          id={`accessibility-attribute-${flag.name}-${idx}-2`}
                          className="form-check-input"
                          type="radio"
                          name={`scoring-${idx}`}
                          value={'False'}
                          checked={
                            flag.selectedValue[0]
                              ? flag.selectedValue[0].name === 'False'
                              : ''
                          }
                          onChange={(e) =>
                            updateRadioButton(e.target.value, idx)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`accessibility-attribute-${flag.name}-${idx}-2`}
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </>
                ))
              )}
            </div>
          </fieldset>
        </div>

        <div className="col p-2">
          <fieldset className="bg-light p-3 rounded">
            <legend>Node Details</legend>
            {!nodeDetails.length ? (
              <p>no attributes selected</p>
            ) : (
              nodeDetails.map((key, idx) => (
                <>
                  <div className="col d-flex p-2 justify-content-between">
                    <div className="col-2 text-end">
                      <label
                        className="form-label"
                        htmlFor={`node-details-attribute-${key.name}-${idx}`}
                      >
                        {normalizeName(key.name)}
                      </label>
                    </div>
                    <div className="col-9">
                      {renderHTMLElement(
                        key,
                        updateValues,
                        `node-details-attribute-${key.name}-${idx}`
                      )}
                    </div>
                  </div>
                </>
              ))
            )}
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default AttributeSettings;

AttributeSettings.propTypes = {
  getNode: PropTypes.func,
  getNodeSettings: PropTypes.func,
  nodeDetails: PropTypes.array,
  setNodeDetails: PropTypes.func,
  contentMetadata: PropTypes.array,
  setContentMetadata: PropTypes.func,
  taxonomiesDimensions: PropTypes.array,
  setTaxonomiesDimensions: PropTypes.func,
  accessibilityFlags: PropTypes.array,
  setAccessibilityFlags: PropTypes.func,
  node: PropTypes.object,
  userObj: PropTypes.object,
  attrsLoaded: PropTypes.bool,
  cloneAttrsLoaded: PropTypes.bool,
  setCloneAttrsLoaded: PropTypes.func,
  applySettingToState: PropTypes.func,
  nodeArray: PropTypes.array,
  handleCloneSelect: PropTypes.func.isRequired,
  cloneConfirmationVisible: PropTypes.bool,
  setCloneConfirmationVisible: PropTypes.func,
  setSelectedClone: PropTypes.func,
  getCloneAttributeSettings: PropTypes.func,
  lastHref: PropTypes.string,
  selectedClone: PropTypes.object
};
