/**
 * @summary Project Setup Reducer
 * @file ProjectSetupReducer.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import {
  GET_PROJECT_SETUP,
  RESET_PROJECT_SETUP,
  UPDATE_PROJECT_SETUP,
  UPDATE_ASSESSMENT_PROGRAM,
  UPDATE_PROJECT_SETTINGS,
  SET_SETTINGS,
  UPDATE_NODE_SETTINGS,
  UPDATE_CONNECTION_SETTINGS,
  UPDATE_NEIGHBORHOOD_SETTINGS,
  SET_SELECTED_CONNECTION_VALUES,
  SET_SELECTED_NODE_VALUES,
  SET_SELECTED_NEIGHBORHOOD_VALUES,
  HYDRATE_PROJECT,
  CLONE_PROJECT,
  UPDATE_ATTR_VALUE,
  REMOVE_CHECKED_VALUE,
  SET_PROJECT_ID,
  SET_NODE_COLUMN_SETTINGS,
  SET_NEIGHBORHOOD_COLUMN_SETTINGS
} from './ProjectSetupTypes';

import { setSelectedValues } from '../../utility/setSelectedValues';

const defaultProjectObj = {
  id: null,
  version: null,
  lastUpdated: '',
  status: '',
  description: '',
  assessmentProgramId: null,
  name: '',
  displayTitle: '',
  projectPurpose: [],
  branch: '',
  cloneFrom: '',
  settings: {
    coreMetaData: [
      {
        prefixCode: '',
        subject: '',
        frameworks: [],
        eduLevels: []
      }
    ],
    minAge: 1,
    maxAge: 1,
    applicationUsage: [],
    themeId: null,
    themeName: '',
    themeColors: [
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF'
    ],
    nodeBorderStyle: '',
    nodeBorderStylePx: '',
    nodeBorderColor: '#FFFFFF',
    nodeFillColor: '#FFFFFF',
    nodeConnectionLineStyle: '',
    nodeConnectionLinePx: '',
    nodeConnectionLineColor: '#FFFFFF',
    nodeConnectionArrows: '',
    nodeConnectionCorners: '',
    nodeHighlightColor: '#FFFFFF',
    nodeShape: '',
    fontName: null,
    layout: '',
    linkageLevels: []
  },
  nodeSettings: {
    columnSettings: []
  },
  connectionSettings: {},
  neighborhoodSettings: {
    columnSettings: []
  },
  connectionsSelectedValues: [],
  nodesSelectedValues: [],
  neighborhoodsSelectedValues: []
};

/**
 * @summary
 * This redux reducer is used for project CRUD based on the action type
 *
 * @param {projects} state
 * @param {reduxAction} action
 */
const projectSetupReducer = (state = defaultProjectObj, action) => {
  switch (action.type) {
    case GET_PROJECT_SETUP:
      return {
        state
      };

    case RESET_PROJECT_SETUP:
      return {
        ...defaultProjectObj,
        settings: {
          ...defaultProjectObj.settings,
          coreMetaData: [
            {
              prefixCode: '',
              subject: '',
              frameworks: [],
              eduLevels: []
            }
          ]
        }
      };

    case UPDATE_ASSESSMENT_PROGRAM:
      return {
        ...state,
        [action.setting.name]: action.setting.value
      };

    case UPDATE_PROJECT_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.setting.attrName]: action.setting.settingsObj
        }
      };

    case UPDATE_PROJECT_SETUP:
      return {
        ...state,
        [action.setting.name]: action.setting.value
      };

    case SET_SETTINGS:
      return {
        ...state,
        settings: {
          ...action.settingsObj
        }
      };

    case UPDATE_NODE_SETTINGS:
      return {
        ...state,
        nodeSettings: {
          columnSettings: state.nodeSettings.columnSettings,
          attributes: action.nodeSettings.attributes
        }
      };

    case UPDATE_CONNECTION_SETTINGS:
      return {
        ...state,
        connectionSettings: {
          ...action.connectionSettings
        }
      };

    case UPDATE_NEIGHBORHOOD_SETTINGS:
      return {
        ...state,
        neighborhoodSettings: {
          columnSettings: state.neighborhoodSettings.columnSettings,
          attributes: action.neighborhoodSettings.attributes
        }
      };

    case SET_SELECTED_CONNECTION_VALUES:
      return {
        ...state,
        connectionsSelectedValues: [...action.selectedValues]
      };

    case SET_SELECTED_NODE_VALUES:
      return {
        ...state,
        nodesSelectedValues: [...action.selectedValues]
      };

    case SET_NODE_COLUMN_SETTINGS:
      return {
        ...state,
        nodeSettings: {
          ...state.nodeSettings.attributes,
          columnSettings: action.columnSettings
        }
      };

    case SET_NEIGHBORHOOD_COLUMN_SETTINGS:
      return {
        ...state,
        neighborhoodSettings: {
          ...state.neighborhoodSettings.attributes,
          columnSettings: action.columnSettings
        }
      };

    case SET_SELECTED_NEIGHBORHOOD_VALUES:
      return {
        ...state,
        neighborhoodsSelectedValues: [...action.selectedValues]
      };

    case HYDRATE_PROJECT:
      return {
        ...defaultProjectObj,
        id: action.projectData.id,
        branch: action.projectData.branchId,
        lastUpdated: action.projectData.lastUpdated,
        name: action.projectData.name,
        description: action.projectData.description,
        displayTitle: action.projectData.displayTitle,
        assessmentProgramId: action.projectData.assessmentProgramId,
        projectPurpose: action.projectData.projectPurposes,
        settings: {
          coreMetaData: action.projectData.coreMetaData,
          minAge: action.projectData.minAge,
          maxAge: action.projectData.maxAge,
          themeId: action.projectData.themeId,
          applicationUsage: action.projectData.applicationUsage,
          linkageLevels: action.projectData.linkageLevels || []
        },
        nodesSelectedValues: action.projectData.nodeData.map((attr) =>
          setSelectedValues(attr)
        ),
        connectionsSelectedValues: action.projectData.connectionData.map(
          (attr) => setSelectedValues(attr)
        ),
        neighborhoodsSelectedValues: action.projectData.neighborhoodData.map(
          (attr) => setSelectedValues(attr)
        ),
        nodeSettings: {
          columnSettings: action.projectData.nodeColumnSettings || []
        },
        neighborhoodSettings: {
          columnSettings: action.projectData.neighborhoodColumnSettings || []
        }
      };

    case CLONE_PROJECT:
      const settingsArr = [
        action.projectData.nodeData,
        action.projectData.connectionData,
        action.projectData.neighborhoodData
      ];
      const [nodeSettings, connectionSettings, neighborhoodSettings] =
        settingsArr.map((dataArr) => {
          return dataArr.map((attr) => {
            const newAttrObj = {
              ...attr,
              attributeId: null,
              attributeSelectionLevelId: null,
              isCloned: true
            };
            return setSelectedValues(newAttrObj);
          });
        });

      return {
        ...state,
        lastUpdated: action.projectData.lastUpdated,
        description: action.projectData.description,
        assessmentProgramId: action.projectData.assessmentProgramId,
        projectPurpose: action.projectData.projectPurposes,
        settings: {
          ...state.settings,
          coreMetaData: action.projectData.coreMetaData,
          minAge: action.projectData.minAge,
          maxAge: action.projectData.maxAge,
          themeId: action.projectData.themeId,
          applicationUsage: action.projectData.applicationUsage,
          linkageLevels: action.projectData.linkageLevels || []
        },
        nodesSelectedValues: nodeSettings,
        nodeSettings: { attributes: nodeSettings },
        connectionsSelectedValues: connectionSettings,
        connectionSettings: { attributes: connectionSettings },
        neighborhoodsSelectedValues: neighborhoodSettings,
        neighborhoodSettings: { attributes: neighborhoodSettings }
      };

    case REMOVE_CHECKED_VALUE:
      const filteredSelection = state[action.arrayType].filter(
        (selection) => selection.name !== action.attribute.name
      );
      return {
        ...state,
        [action.arrayType]: filteredSelection
      };

    case SET_PROJECT_ID:
      return {
        ...state,
        id: action.id
      };

    default:
      return state;
  }
};
export default projectSetupReducer;
