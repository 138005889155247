/**
 * @summary Views String Types
 * @file ViewTypes.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const CREATE_VIEW = 'CREATE_VIEW';

export const GET_VIEWS = 'GET_VIEWS';

export const GET_VIEW = 'GET_VIEW';

export const DELETE_VIEW = 'DELETE_VIEW';

export const GRID_DATA_ACTIONS_SELECTIONS_VIEWS_FULLFILLED =
  'GRID_DATA_ACTIONS_SELECTIONS_VIEWS_FULLFILLED';

export const CLEAR_VIEWS = 'CLEAR_VIEWS';

export const EDIT_VIEWS = 'EDIT_VIEWS';

export const RESET_SELECTED_VIEW = 'RESET_SELECTED_VIEW';

export const ACTIVATE_VIEW = 'ACTIVATE_VIEW';
