/**
 * @summary findLogChanges.js
 * @file This function is responsible for checking if changes were made to a node
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

/////////////////////////////////////////////////
// FINDS FOR ANY CHANGES BETWEEN NODE VERSIONS //
/////////////////////////////////////////////////
// 1) Was a node's name or description changed?
// 2) Was a node's description changes?
// 3) Were a node's attributes added or removed?
// 4) Were the values of a node's attributes changes?
export const findLogChanges = (formattedPairs) => {
  // FINAL RETURN: Array of all Changes Logs between node versions
  let allFormattedWithChanges =[]
  // LOOPING All Pairs to find changes
  for(let i=0; i < formattedPairs.length; i++){
    /////////////////////////////////////////////////////////
    // CHANGES: Each Formatted Change Pushed to this Array //
    /////////////////////////////////////////////////////////
    
    let left = formattedPairs[i].leftNode
    let right = formattedPairs[i].rightNode
    
    let changes = []
    if(formattedPairs[i].origin === false){
      ////////////////////////////
      // CHANGE 1) NAME UPDATED //
      ////////////////////////////
      if (left.name !== right.name){
        let change = {
          property: "Node Name",
          original: left.name,
          updated: right.name,
          change: "Name Updated",
          checkedOutBy: right.checkedOutBy
        }
        changes.push(change)
      } 
      ///////////////////////////////////
      // CHANGE 2) DESCRIPTION UPDATED //
      ///////////////////////////////////
      function stripHtml(html) {
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
      }
      if (left.description !== right.description){
        let change = {
          property: "Node Description",
          original: stripHtml(left.description),
          updated: stripHtml(right.description),
          change: "Description Updated",
          checkedOutBy: right.checkedOutBy
        }
        changes.push(change)
      }
      // ATTRIBUTES //
      if (left.attributes.length > 0 || right.attributes.length > 0){
        ////////////////////////////////
        // CHANGE 3) ATTRIBUTES ADDED //
        ////////////////////////////////
        const addedAttributes = right.attributes.filter((rightAttribute) => !left.attributes.some((leftAttribute) => leftAttribute.name === rightAttribute.name));
        if(addedAttributes.length){
          addedAttributes.forEach(attribute => {
            let attributeValue = attribute.value.map(value => value ? value : "No Values Entered")
            let change = {
              property: "Attribute",
              original: {
                name: "Did Not Exist Until",
                value: "N/A"
                // value: formattedPairs[i].shortFormDate
              },
              updated: {
                name: attribute.name,
                value: attributeValue
              },
              change: attribute.name + " Added",
              newAtttribute: attribute,
              checkedOutBy: right.checkedOutBy
            }
            changes.push(change)
          })
        }
        ////////////////////////////////////////
        // CHANGE 4) ATTRIBUTE VALUES UPDATED //
        ////////////////////////////////////////
        for (let i = 0; i < right.attributes.length; i++) {
          let rightAttribute = right.attributes[i];
          let leftAttribute = left.attributes.find(obj => obj.name === rightAttribute.name);
          if(leftAttribute){
            if (JSON.stringify(leftAttribute.value) !== JSON.stringify(rightAttribute.value)) {
              let leftAttributeValues = leftAttribute.value.map(value => value ? value : "No Values Entered")
              let rightAttributeValues = rightAttribute.value.map(value => value ? value : "No Values Entered")
              let change = {
                property: "Attribute",
                original: {
                  name: leftAttribute.name,
                  value: leftAttributeValues,
                },
                updated: {
                  name: rightAttribute.name,
                  value: rightAttributeValues,
                },
                change: rightAttribute.name + " Updated",
                newAtttribute: rightAttribute,
                checkedOutBy: right.checkedOutBy
              }
              changes.push(change);
            }
          } 
        }
      }
    } else {
      let change = {
          property: "Node Creation",
          original: "N/A",
          updated: right.nodeKey,
          change: "Node Created",
          checkedOutBy: right.checkedOutBy
      }
      changes.push(change)
    }
      //////////////////////////
      // FORMATTED CHANGE LOG //
      //////////////////////////
      // All changes between each pair of nodes are formatted into this object
      // After looping through every pair, the final return will be an array of these objects
      if(changes.length > 0){
        let newChangeLogObj = {
          leftNode: formattedPairs[i].leftNode,
          rightNode: formattedPairs[i].rightNode,
          changes,
          longFormDate: formattedPairs[i].longFormDate,
          shortFormDate: formattedPairs[i].shortFormDate,
          timeStamp: formattedPairs[i].timeStamp
        }
        allFormattedWithChanges.push(newChangeLogObj)
      }
  } 
  return(allFormattedWithChanges);
}