/**
 * @summary filterViewData.js
 * @file Util: Formats View Data (when Loaded) into a Useable Map
 * @returns {JSX}
 * @usedBy CanvasThemeMenu.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

// VIEW FILTERING
// This component takes the loaded view and uses the id's to find nodes, connections and neighborhoods in the project.
// It then reformats this data into the GoJS format and returns it as an object that is set as the new map display.
import formatMapViewData from './formatViewMapData';

const filterViewData = (view) => {
  const { nodeDataArray, linkDataArray, neighborhoods } = formatMapViewData(
    view.savedNodes,
    view.savedConnections,
    view.savedNeighborhoods || [],
  );
  let visibleNodeDataArray = nodeDataArray.map(element => {
    element.visible = true
    return element
  })
  // RETURNING THE DATA WITHIN AN OBJECT TO BE SET AS THE NEW MAP
  const newFilteredObj = {
    viewName: view.name,
    parentSource: view.parentSource,
    parentSourceType: view.parentSourceType,
    nodeDataArray: visibleNodeDataArray,
    linkDataArray: linkDataArray,
    // neighborhoodLegend: neighborhoodLegend
  };
  return newFilteredObj;
};

export default filterViewData;
