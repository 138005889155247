/**
 * @summary HistogramHeadlineRender.js
 * @file This component provides rows for nodes that fall under the min/max amount of connections specified by user
 * @returns {JSX}
 * @usedBy DegreeHistogramReturn.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react'
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';

const DegreeHistogramItemRender = ({ 
  degreeHistogramDataArray,
  min,
  max
}) => {
  return(
    <div>
  {degreeHistogramDataArray.map(degreeHistogramData => {
    return (
      <div
        key={degreeHistogramData.node.key}
        className="k-listview-item row p-2 border-bottom align-middle"
        style={{
          margin: 0,
        }}
      >
        <div className="col-2">
          <div
            style={{
              fontSize: 16,
              fontWeight: 400,
              color: "black",
              marginLeft: 30,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            {degreeHistogramData.node.nodeKey}
          </div>
        </div>
        
        <div className="col-1">
          <div
            style={{
              fontSize: 16,
              fontWeight: 400,
              color: "black",
              marginLeft: 30,
              paddingTop: 10,
              paddingBottom: 10
            }}
            className="text-uppercase"
          >
            {degreeHistogramData.incomingCount}
          </div>
        </div>
        <div className="col-2" style={{padding: 0}}>
          <div
            style={{
                fontSize: 16,
                fontWeight: 400,
                color: "black",
                marginLeft: 30,
                paddingTop: 10,
                paddingBottom: 10
            }}
          >

            {degreeHistogramData.incomingCount >= min && degreeHistogramData.incomingCount <= max ? 
              degreeHistogramData.incomingNodes.map(node => {
                return(
                <Row key={node.id}>
                  <div style={{position: "inline-block", fontWeight: 400, paddingBottom: "5px" }}>{node.nodeKey}</div>
                </Row>
                )
              })
              : 
              <div style={{position: "inline-block", fontWeight: 400, paddingBottom: "5px" }}>
                {degreeHistogramData.incomingCount === 0 ? "None" : "Data Outside " + min + "-to-" + max + " Range"}
              </div>
            }
          </div>
        </div>
        <div className="col-1">
          <div
            style={{
              fontSize: 16,
              fontWeight: 400,
              color: "black",
              marginLeft: 30,
              paddingTop: 10,
              paddingBottom: 10
            }}
            className="text-uppercase"
          >
            {degreeHistogramData.outgoingCount}
          </div>
        </div>
        <div className="col-4" style={{padding: 0}}>
          <div
            style={{
                fontSize: 16,
                fontWeight: 400,
                color: "black",
                marginLeft: 30,
                paddingTop: 10,
                paddingBottom: 10
            }}
          >
            {degreeHistogramData.outgoingCount >= min && degreeHistogramData.outgoingCount <= max ?
              degreeHistogramData.outgoingNodes.map(node => {
                return(
                <Row key={node.id}>
                  <div style={{position: "inline-block", fontWeight: 400, paddingBottom: "5px" }}>{node.nodeKey}</div>
                </Row>
                )
              }) : 
              <div style={{position: "inline-block", fontWeight: 400, paddingBottom: "5px" }}>
                {degreeHistogramData.outgoingCount === 0 ? "None" : "Data Outside " + min + "-to-" + max + " Range"}
              </div>
            }
          </div>
        </div>
      </div>
    );
  })}
  </div>
  )}

  

  export default DegreeHistogramItemRender;

  DegreeHistogramItemRender.propTypes = {
    degreeHistogramDataArray: PropTypes.array,
    min: PropTypes.number,
    max: PropTypes.number
  }