/**
 * @summary normalizeName.js
 * @file action handlers for user inputs in forms
 * @returns {JSX}
 * @usedBy NodeForm, ConnectionForm, NewForm, NeighborhoodForm
 * @author Dj Ritchey
 * @since 4/01/2021
 * @lastUpdated 04/2021
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

const getCategory = (name, checkboxValues) =>
  Object.keys(checkboxValues).find((key) => checkboxValues[key].includes(name));

// add new value to checkbox state, unless it is already present
export const updateCheckboxValues = (
  newFieldCat,
  fieldName,
  checkboxValues
) => {
  const category = newFieldCat
    ? newFieldCat.toLocaleLowerCase().replaceAll(' ', '_')
    : getCategory(fieldName.name, checkboxValues);
  const list = { ...checkboxValues };
  const foundValue = list[category].find(
    (attrName) => attrName === fieldName.name
  );
  if (!foundValue) {
    list[category].push(fieldName.name);
  }
  return list;
};

export const getIndex = (selectedValues, obj) => selectedValues.indexOf(obj);

export const updateDataType = (idx, value, newSelection) => {
  newSelection[idx].value = [];
  if (value === 'Text' || value === 'Drop-Down') {
    newSelection[idx].hasQty = true;
  } else {
    newSelection[idx].hasQty = false;
  }
  return newSelection;
};

// update the passed in value data type, and increment/decrement qty
export const updateSelectedDataType = (selectedValues, value, obj) => {
  const newSelection = [...selectedValues];
  const idx = getIndex(selectedValues, obj);
  if (idx >= 0) {
    newSelection[idx].qty = 1;
    updateDataType(idx, value, newSelection);

    newSelection[idx].dataType = value;
  }
  return newSelection;
};

export const updateMultiSelect = (selectedValues, e, obj) => {
  const { value } = e;
  const idx = getIndex(selectedValues, obj);
  const list = [...selectedValues];
  list[idx].value.splice(0, list[idx].value.length, ...value);
  list[idx].metaDataId = list[idx].value.map((item) => item.id).filter(Boolean);
  list[idx].valueNames = list[idx].value.map((item) =>
    item.name ? item.name : item
  );
  return list;
};

export const updateQtyValue = (selectedValues, value, obj) => {
  const idx = getIndex(selectedValues, obj);
  const list = [...selectedValues];
  const elementsToRemove = list[idx].qty - value;
  list[idx].qty = value;
  if (elementsToRemove >= 0) {
    list[idx].value.length = value;
  }
  if (elementsToRemove < 0) {
    if (list[idx].dataType === 'Multi-Select') {
      list[idx].value.push([]);
    }
  }
  return list;
};

export const shouldRender = (selectedValues, name) => {
  const foundObj = selectedValues.find((selected) => selected.name === name);
  return foundObj;
};

export const normalizeName = (name) =>
  name
    .split(/[ _]+/)
    .map((ele) => {
      if (ele === 'and') {
        return ele;
      }
      return `${ele.charAt(0).toUpperCase()}${ele.substr(1)}`;
    })
    .join(' ');

export const isChecked = (selectedValues, value) => {
  const checked = selectedValues.find((selection) => selection.name === value);
  return !!checked;
};

export const shouldDisable = (selectedValues, value) => {
  const attribute = selectedValues.find(
    (selection) => selection.name === value
  );
  return !!attribute?.id;
};

export const updateColumnSettings = (
  foundObj,
  idx,
  obj,
  elementType,
  selectedValues,
  columnSettings
) => {
  const list = [...selectedValues];
  const newColumnSettings = [...columnSettings];
  const itemIdx = getIndex(selectedValues, obj);

  //set attribute state
  if (!list[itemIdx].value[idx]) {
    list[itemIdx].value[idx] = { [elementType]: foundObj };
  } else {
    list[itemIdx].value[idx][elementType] = foundObj;
  }

  if (!newColumnSettings[idx] || elementType === 'framework') {
    newColumnSettings[idx] = { [elementType]: foundObj.id };
  } else {
    newColumnSettings[idx][elementType] = foundObj.id;
  }
  return { list, newColumnSettings };
};
