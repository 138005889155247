/**
 * @summary axiosCycleCheck.js
 * @file Under construction. Function returns formatted data of nodes and node connections
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const formatMapData = (
  nodes,
  connections,
) => {
    //////////////////////
    // NODE FORMATTING //
    //////////////////////
    const formatNodes = (nodeItem) => {
      if (nodeItem !== undefined) {
        let formattedNodes = {};
        // ADDING KEY DATA PROPERTIES
        formattedNodes.key = nodeItem.id;
        formattedNodes.nodeKey = nodeItem.nodeKey;
        formattedNodes.title = nodeItem.name;
        formattedNodes.description = nodeItem.description
          ? new DOMParser().parseFromString(nodeItem.description, 'text/html')
              .body.textContent
          : 'No Description';
        formattedNodes.markdownDescription = nodeItem.description;
        formattedNodes.text = nodeItem.title;
        formattedNodes.checkedOutBy = nodeItem.checkedOutBy
          ? nodeItem.checkedOutBy
          : 'Open';
        formattedNodes.status = nodeItem.status;
        return formattedNodes;
      } // close top if
    }; //close fn

    const gojsNodes = nodes.map(formatNodes);

    ////////////////////////////
    // CONNECTIONS FORMATTING //
    ////////////////////////////
    const formatConnections = (connectionItem) => {
      if (connectionItem !== undefined) {
        const formatConnections = {};
        formatConnections.type = 'Connection'
        formatConnections.id = connectionItem.id;
        formatConnections.key = connections.indexOf(connectionItem) + 1;
        formatConnections.from = connectionItem.sourceNodeKey;
        formatConnections.to = connectionItem.destinationNodeKey;
        return formatConnections;
      }
    };

    const gojsConnections = connections.map(formatConnections);
    ////////////////////////////////
    // FINAL FORMATTED MAP OBJECT //
    ////////////////////////////////
    // if(gojsNodes.length < 1000){
      const mapDataObj = {
        nodeDataArray: gojsNodes,
        linkDataArray: gojsConnections,
        skipsDiagramUpdate: false
      };
      return mapDataObj;
    // } else {
    //   let cappedNodes = gojsNodes.slice(0,2500)
    //   let cappedNodeKeys = cappedNodes.reduce((accumulator, currentValue) => {
    //     accumulator.push(currentValue.nodeKey);
    //     return accumulator;
    //   }, [])
    //   let cappedConnections = gojsConnections.filter(connection => {
    //     if(cappedNodeKeys.includes(connection.to) && cappedNodeKeys.includes(connection.from)){
    //       return connection
    //     }
    //   })
    //   const mapDataObj = {
    //     projectHeadline: selectedProject.displayTitle,
    //     selectedBranch: userObj.selectedBranch[0].name,
    //     nodeDataArray: cappedNodes,
    //     fullNodeDataArray: gojsNodes,
    //     linkDataArray: cappedConnections,
    //     modelData: defaultTheme,
    //     neighborhoodLegend: neighborhoodLegend,
    //     skipsDiagramUpdate: false
    //   };
      // return mapDataObj;
    // }
};

