/**
 * @summary axiosChangeLog.js
 * @file This function is the responsible for checking the branch selection which will return a value that'll be later used to see if changes were made to a node
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios'
export const axiosChangeLog = async (changeLogInputData) => {
    const {project, branchSelection, nodeKey } = changeLogInputData
    ///////////////////////////////////////////////////////////////////////////////////////////////
    // IF SELECTION IS A BRANCH - PUBLISHED OR BRANCHY BRANCH - MAKE SURE IT EXISTS AND GET DATA //
    ///////////////////////////////////////////////////////////////////////////////////////////////
    if(branchSelection.type === "published branch" || branchSelection.type === "branch"){
        const nodes = await axios.get(`/api/projects/${project.id}/branch/${branchSelection.id}/nodes`)
        if(nodes.data.find(node => node.nodeKey === nodeKey) !== undefined){
            const nodePair = await axios.post(`/api/projects/${project.id}/branch/${branchSelection.id}/nodes/change_log`, { ...changeLogInputData });
            if(nodePair.data !== null){
                return {nodePair: nodePair.data, errorMessage: null}
            } else {
                return {errorMessage: "No changes were made to this node within this time frame. Try expanding your start and end dates."}
            }
        } else {
            return {errorMessage: "This node key does not exist in this branch."}
        }
    ///////////////////////////////////////////////////////////////////////////
    // IF SELECTION IS A VIEW, RUN DIFFERENT AXIOS GET REQUESTS ON THAT VIEW //
    ///////////////////////////////////////////////////////////////////////////
    } else if (branchSelection.type === "view"){
    const view = await axios.get(`/api/projects/${project.id}/views/${branchSelection.id}`)
    if(view.data.savedNodes.find(node => node.nodeKey === nodeKey) !== undefined){
        const nodePair = await axios.post(`/api/projects/${project.id}/branch/${branchSelection.id}/nodes/change_log`, { ...changeLogInputData });
        if(nodePair.data !== null){
            return {nodePair: nodePair.data, errorMessage: null}
        } else {
            return {errorMessage: "No changes were made to this node within this time frame. Try expanding your start and end dates."}
        }
    } else {
        return {errorMessage: "This view does not include this node key."}
    }
    }
}