/**
 * @summary Neighborhood Actions
 * @file NeighborhoodActions.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios';
import {
  CLEAR_NEIGHBORHOODS,
  CREATE_NEIGHBORHOOD,
  EDIT_NEIGHBORHOODS,
  GET_NEIGHBORHOOD,
  GET_NEIGHBORHOODS,
  GRID_DATA_ACTIONS_SELECTIONS_NEIGHBORHOODS_FULLFILLED,
  GET_NEIGHBORHOODLINKAGELEVELS,
  EDIT_NEIGHBORHOODLINKAGELEVELS,
  SELECT_ALL_NEIGHBORHOOD,
  GET_NEIGHBORHOOD_NODE_IDS,
  SET_NEW_NEIGHBORHOOD,
  ADD_NODES_TO_NEIGHBORHOOD,
  UPDATE_SELECTED_LINKAGE_LEVELS,
  DELETE_NEIGHBORHOOD,
  DELETE_MULTIPLE_NEIGHBORHOOD,
  DELETE_NEIGHBORHOOD_NODES,
  REPLACE_TABLEVIEW_NEIGHBORHOODS,
  CLEAR_SELECTED_NEIGHBORHOODS,
  GET_NEIGHBORHOOD_COPY
} from './NeighborhoodTypes';

export const createNeighborhood = (formValues) => async (dispatch) => {
  const resp = await axios.post(
    `/api/projects/${formValues.projectId}/branch/${formValues.branchId}/neighborhoods`,
    { ...formValues }
  );
  dispatch({
    type: CREATE_NEIGHBORHOOD,
    neighborhood: formValues,
    meta: resp.data.neighborhood
  });
  return resp;
};

export const editNeighborhood = (formValues) => async (dispatch) => {
  const resp = await axios.put(
    `/api/projects/${formValues.projectId}/branch/${formValues.branchId}/neighborhoods/${formValues.id}`,
    { ...formValues }
  );
  dispatch({
    type: EDIT_NEIGHBORHOODS,
    neighborhoods: formValues,
    meta: resp.data
  });
  return resp;
};

export const editLinkageLevelNeighborhood =
  (formValues) => async (dispatch) => {
    const resp = await axios.put(
      `/api/projects/${formValues.projectId}/branch/${formValues.branchId}/neighborhood/${formValues.id}`,
      { ...formValues }
    );
    dispatch({
      type: EDIT_NEIGHBORHOODLINKAGELEVELS,
      neighborhoods: formValues,
      meta: resp.data
    });
    return resp;
  };

export const clearNeighborhoods = () => ({ type: CLEAR_NEIGHBORHOODS });

export const getNeighborhoods = (projectId, branchId) => async (dispatch) => {
  const { data } = await axios.get(
    `/api/projects/${projectId}/branch/${branchId}/neighborhoods`
  );
  if (data) {
    dispatch({
      type: GET_NEIGHBORHOODS,
      neighborhoods: data
    });
    getNeighborhoodNodeIds(projectId, branchId);
  } else {
    dispatch(clearNeighborhoods());
  }
};

export const getNeighborhoodNodeIds =
  (projectId, branchId) => async (dispatch) => {
    const { data } = await axios.get(
      `/api/projects/${projectId}/branch/${branchId}/neighborhoods/nodes`
    );
    if (data) {
      dispatch({
        type: GET_NEIGHBORHOOD_NODE_IDS,
        neighborhoodNodes: data.data
      });
    }
  };

export const getLinkagelevelNeighborhood =
  (projectId, branchId, neighborhoodId) => async (dispatch) => {
    const { data } = await axios.get(
      `/api/projects/${projectId}/branch/${branchId}/neighborhoodLinkageLevel/${neighborhoodId}`
    );
    if (data) {
      dispatch({
        type: GET_NEIGHBORHOODLINKAGELEVELS,
        selectedLinkageLevels: data
      });
    } else {
      dispatch(clearNeighborhoods());
    }
  };

export const getNeighborhood =
  (projectId, branchId, neighborhoodId) => async (dispatch) => {
    const { data } = await axios.get(
      `/api/projects/${projectId}/branch/${branchId}/neighborhood/${neighborhoodId}`
    );
    if (data) {
      dispatch({
        type: GET_NEIGHBORHOOD,
        selectedNeighborhood: data
      });
      return data;
    } else {
      dispatch(clearNeighborhoods());
    }
  };

export const getNeighborhoodCopy =
  (projectId, branchId, neighborhoodId) => async (dispatch) => {
    const { data } = await axios.get(
      `/api/projects/${projectId}/branch/${branchId}/neighborhood/${neighborhoodId}`
    );
    if (data) {
      dispatch({
        type: GET_NEIGHBORHOOD_COPY,
        selectedNeighborhood: data
      });
      return data;
    } else {
      dispatch(clearNeighborhoods());
    }
  };

export const replaceTableviewNeighborhoods = (neighborhoods) => ({
  type: REPLACE_TABLEVIEW_NEIGHBORHOODS,
  neighborhoods
});

const updateSelectedGrid = (data) => ({
  type: GRID_DATA_ACTIONS_SELECTIONS_NEIGHBORHOODS_FULLFILLED,
  neighborhoods: {
    data
  }
});

export const gridSelectedDataActions = (data) => (dispatch) => {
  dispatch(updateSelectedGrid(data));
};

const updateAllSelections = (data) => ({
  type: SELECT_ALL_NEIGHBORHOOD,
  neighborhoods: {
    data
  }
});

export const selectAllNeighbrohoods = (data) => (dispatch) => {
  dispatch(updateAllSelections(data));
};

export const deleteNeighborhood = (neighborhood) => async (dispatch) => {
  try {
    const resp = await axios.delete(
      `/api/projects/${neighborhood.projectId}/branch/${neighborhood.branchId}/neighborhoods/${neighborhood.id}`
    );

    dispatch({
      type: DELETE_NEIGHBORHOOD,
      neighborhood
    });
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteMultipleNeighborhoods =
  (project, branch, neighborhoodIds) => async (dispatch) => {
    try {
      const resp = await axios.post(
        `/api/projects/${project.id}/branch/${branch.id}/neighborhoods/multiple`,
        { neighborhoodIds }
      );
      dispatch({
        type: DELETE_MULTIPLE_NEIGHBORHOOD,
        neighborhoodIds
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

export const deleteNeighborhoodNodes = (neighborhood) => async (dispatch) => {
  const nodeIds = neighborhood.nodes.map((node) => node.id);
  try {
    const resp = await axios.post(
      `/api/projects/${neighborhood.projectId}/branch/${neighborhood.branchId}/neighborhoods/${neighborhood.id}/nodes`,
      nodeIds
    );

    dispatch({
      type: DELETE_NEIGHBORHOOD_NODES,
      neighborhood
    });
    return resp;
    // return neighborhood
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getNeighborhoodSettings =
  (projectId, branchId) => async (dispatch) => {
    const { data } = await axios.get(
      `/api/projects/${projectId}/branch/${branchId}/neighborhoodSettings`
    );
    return data;
  };

export const setNewNeighborhood = () => ({ type: SET_NEW_NEIGHBORHOOD });

export const addNodesToNeighborhood = (nodes) => ({
  type: ADD_NODES_TO_NEIGHBORHOOD,
  nodes
});

export const updateSelectedLinkageLevelsData = (linkageLevelData) => ({
  type: UPDATE_SELECTED_LINKAGE_LEVELS,
  linkageLevelData
});

export const clearSelectedNeighborhoods = () => ({
  type: CLEAR_SELECTED_NEIGHBORHOODS
});
