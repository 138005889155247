/**
 * @summary HelpPortal.js
 * @file Component that opens up a help window with documentation on how to interact with a target component
 * @returns {JSX}
 * @usedBy HelpWindow.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';

const copyStyles = (sourceDoc, targetDoc) => {
  Array.from(sourceDoc.styleSheets).forEach((styleSheet) => {
    if (styleSheet.href) {
      // true for stylesheets loaded from a URL
      const newLinkEl = sourceDoc.createElement('link');
      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = styleSheet.href;
      targetDoc.head.appendChild(newLinkEl);
    } else if (styleSheet.cssRules) {
      // true for inline styles
      const newStyleEl = sourceDoc.createElement('style');

      Array.from(styleSheet.cssRules).forEach((cssRule) => {
        newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
      });

      targetDoc.head.appendChild(newStyleEl);
    }
  });
};

const HelpPortal = (props) => {
  const containerEl = document.createElement('div');

  React.useEffect(() => {
    let externalWindow = null;
    externalWindow = window.open(
      '',
      '',
      'width=800,height=400,left=50,top=200'
    );
    if (externalWindow) {
      externalWindow.document.body.appendChild(containerEl);

      externalWindow.document.title = 'Help';
      copyStyles(document, externalWindow.document);
      externalWindow.addEventListener('beforeunload', () => {
        if (containerEl.innerHTML !== '') {
          props.closeWindowPortal();
        }
      });
      return () => {
        externalWindow.close();
      };
    } else {
      return toast.error(
        'Unable to open help window. Browser might be blocking popups.'
      );
    }
  }, [props]); // eslint-disable-line

  return ReactDOM.createPortal(<div>{props.children}</div>, containerEl);
};

HelpPortal.propTypes = {
  closeWindowPortal: PropTypes.func,
  children: PropTypes.any
};

export default HelpPortal;
