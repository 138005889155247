/**
 * @summary LinkageLevelTypes.js
 * @file LinkageLevel string types
 * @returns {String}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const CREATE_LINKAGE_LEVEL = 'CREATE_LINKAGE_LEVEL';

export const GET_LINKAGE_LEVELS = 'GET_LINKAGE_LEVELS';

export const DELETE_LINKAGE_LEVEL = 'DELETE_LINKAGE_LEVEL';

export const GRID_DATA_ACTIONS_SELECTIONS_LINKAGE_LEVELS_FULLFILLED =
  'GRID_DATA_ACTIONS_SELECTIONS_LINKAGE_LEVELS_FULLFILLED';

export const CLEAR_LINKAGE_LEVELS = 'CLEAR_LINKAGE_LEVELS';

export const EDIT_LINKAGE_LEVELS = 'EDIT_LINKAGE_LEVELS';

export const GET_LINKAGE_LEVEL = 'GET_LINKAGE_LEVEL';

export const GET_LINKAGE_LEVELLINKAGELEVELS = 'GET_LINKAGE_LEVELLINKAGELEVELS';

export const EDIT_LINKAGE_LEVELLINKAGELEVELS =
  'EDIT_LINKAGE_LEVELLINKAGELEVELS';
