/**
 * @summary ListComponentItemRender.js
 * @file This component provides rows for the list of node clusters that fall under the max node-radius specified by the user
 * @returns {JSX}
 * @usedBy ListComponentsReturn.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react'
import PropTypes from 'prop-types';

const ListComponentItemRender = (data) => {
    return(
        <div
        key={data.dataItem.index}    
        className="row k-listview-item p-2 border-bottom align-middle"
            style={{
                marginTop: 0,
                marginBottom: 0,
            }}
        >
            <div className="col-2" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {data.dataItem.nodeKey}
                </div>
            </div>
            <div className="col-2" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {data.dataItem.count}
                </div>
            </div>
            <div className="col-8" style={{padding: 0}}>
                {data.dataItem.clusterArray?.map(node => {
                return(
                    <div key={node.id} className='row'
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                    >
                    {node.nodeKey}
                    </div>
                )
                })}
            </div>
        </div>
    )
}

export default ListComponentItemRender;

ListComponentItemRender.propTypes = {
    data: PropTypes.object,
}