/**
 * @summary Project Setup Types
 * @file ProjectSetupTypes.js
 * @returns {String}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const GET_PROJECT_SETUP = 'GET_PROJECT_SETUP';

export const RESET_PROJECT_SETUP = 'RESET_PROJECT_SETUP';

export const UPDATE_PROJECT_SETUP = 'UPDATE_PROJECT_SETUP';

export const UPDATE_ASSESSMENT_PROGRAM = 'UPDATE_ASSESSMENT_PROGRAM';

export const UPDATE_PROJECT_SETTINGS = 'UPDATE_PROJECT_SETTINGS';

export const SET_SETTINGS = 'SET_SETTINGS';

export const UPDATE_NODE_SETTINGS = 'UPDATE_NODE_SETTINGS';

export const UPDATE_CONNECTION_SETTINGS = 'UPDATE_CONNECTION_SETTINGS';

export const UPDATE_NEIGHBORHOOD_SETTINGS = 'UPDATE_NEIGHBORHOOD_SETTINGS';

export const SET_SELECTED_CONNECTION_VALUES = 'SET_SELECTED_CONNECTION_VALUES';

export const SET_SELECTED_NODE_VALUES = 'SET_SELECTED_NODE_VALUES';

export const SET_SELECTED_NEIGHBORHOOD_VALUES =
  'SET_SELECTED_NEIGHBORHOOD_VALUES';

export const HYDRATE_PROJECT = 'HYDRATE_PROJECT';

export const CLONE_PROJECT = 'CLONE_PROJECT';

export const UPDATE_ATTR_VALUE = 'UPDATE_ATTR_VALUE';

export const REMOVE_CHECKED_VALUE = 'REMOVE_CHECKED_VALUE';

export const SET_PROJECT_ID = 'SET_PROJECT_ID';

export const SET_NODE_COLUMN_SETTINGS = 'SET_NODE_COLUMN_SETTINGS';

export const SET_NEIGHBORHOOD_COLUMN_SETTINGS =
  'SET_NEIGHBORHOOD_COLUMN_SETTINGS';
