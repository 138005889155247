/**
 * @summary DegreeHistogramReturn.js
 * @file Component that provides a layout for the results after the report is generated
 * @returns {JSX}
 * @usedBy GenerateReport.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, {useRef} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import HistogramItemRender from './HistogramItemRender'
import HistogramHeadlineRender from './HistogramHeadlineRender'
import { PDFExport } from "@progress/kendo-react-pdf";

const DegreeHistogramReturn = ({ reportData }) => {
  const pdfRef = useRef(null);
  
  const exportReport = () => {
    if (pdfRef.current) {
      pdfRef.current.save();
    }
  }  
  return (
    <PDFExport
      ref={pdfRef}
      paperSize="auto"
      margin={40}
      fileName={`Degree Histogram Report for ${new Date().getFullYear()}`}
    >
      <Row >
        <Row>
          <div className='generated-report-header'>
            Degree Histogram Report
          </div>
          <div className='generated-report-subhead'>
            <div>{reportData.degreeHistogramData.length} Nodes have Between {reportData.minConnections} and {reportData.maxConnections} Incoming or Outgoing Connections</div>
          </div>
          <div className='generated-report-button-container'>
              {
                <button
                  type="button"
                  className="download-report-button"
                  onClick={exportReport}
                  style={{fontSize: 18}}
                  // disabled={!createReport}
                >
                  Download Report
                </button>
              }
          </div>
        </Row>
        <Row>
          <Col>
            <HistogramHeadlineRender />
          </Col>
        </Row>
        <Row>
          <Col>
            <HistogramItemRender 
              degreeHistogramDataArray={reportData.degreeHistogramData}
              min={reportData.minConnections}
              max={reportData.maxConnections}
            />
          </Col>
        </Row>
      </Row>
    </PDFExport>
  )
}

export default DegreeHistogramReturn;

DegreeHistogramReturn.propTypes = {
  reportData: PropTypes.object,
}
