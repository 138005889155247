/**
 * @summary UniqueElements.js
 * @file Component breaks down a unique element down to its properties in order to populate columns with info according to its header
 * @returns {JSX}
 * @usedBy ConnectionSummary.js, NeighborhoodSummary.js, NodeSummary.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import PropTypes from 'prop-types';

const uniqueElement = ({
    uniqueElement
}) => {
    return(
        <div
            className="row k-listview-item p-1 border-bottom "
            style={{
                marginTop: 0,
                marginBottom: 0,
            }}
            >
            <div className="col-2">
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                >
                    {uniqueElement.property}
                </div>
            </div>
            <div className="col-2" >
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                >
                    {uniqueElement.change}
                </div>
                
            </div>
            <div className="col-2">
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                >
                    {uniqueElement.key}
                </div>
            </div>
            <div className="col-3" >
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                >
                {uniqueElement.snapshotA.length > 0 ? 
                    <>
                    {uniqueElement.snapshotA.map(element=>{
                        return(
                            <div key={element.key}>
                                {element.key}
                            </div>
                        )
                    })} 
                    </> : 
                    <div>
                        No Unique {uniqueElement.property}s 
                    </div>
                }
                </div>
            </div>
            <div className="col-3" >
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "black",
                        // marginLeft: 30,
                    }}
                >
                {uniqueElement.snapshotB.length > 0 ? 
                    <>
                    {uniqueElement.snapshotB.map(element=>{
                        return(
                            <div key={element.key}>
                                {element.key}
                            </div>
                        )
                    })} 
                    </> : 
                    <div>
                        No Unique {uniqueElement.property}s 
                    </div>
                }
                </div>
            </div>
            
        </div>
    )
}

export default uniqueElement;

uniqueElement.propTypes = {
    uniqueElement: PropTypes.object,
}
