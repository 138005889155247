/**
 * @summary NeighborhoodFilterUtil.js
 * @file Filter Util: Takes Input Data from NeighborhoodFilter.js and Renders Map Elements into a Filtered Map
 * @returns {JSX}
 * @usedBy CanvasFilter.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { gojsFormatter } from "./gojsFormatter";

function neighborhoodFilterUtil (diagramData, neighborhoodSelection) {
  
  let headlessDiagram = gojsFormatter(diagramData)  
  

  let newNodeArray = []
  let newLinkArray = []
  
  if(neighborhoodSelection){
    neighborhoodSelection._members.forEach(nodeKey => {
      let memberNodeFormetted = diagramData.nodeDataArray.find(node => node.nodeKey === nodeKey)
      newNodeArray.push(memberNodeFormetted)
      let memberNode = headlessDiagram.findNodeForKey(nodeKey)
      let connectedLinks = new go.List(memberNode.findLinksConnected());
      connectedLinks?._dataArray?.forEach(connection => {
        if(!newLinkArray?.includes(connection.data)){
          newLinkArray.push(connection.data)
        }
      })
      if(memberNode.data.supers.length > 0){
        memberNode.data.supers.forEach(neighborhoodId => {
          let associatedNeighborhood = headlessDiagram.model.nodeDataArray.find(node => node.key === neighborhoodId)
          if(!newNodeArray.includes(associatedNeighborhood)){
            if(associatedNeighborhood.nodeKey === neighborhoodSelection.nodeKey){
              associatedNeighborhood.visible = true;
              newNodeArray.push(associatedNeighborhood);
            } else {
              associatedNeighborhood.visible = false;
              newNodeArray.push(associatedNeighborhood);
            }
          }
        })
      }
    })
  } else {
    return {errorMessage: "This neighborhood is outside your current filter. You will need to first reset."}
  }

  const newFilterData = {
    nodeDataArray: newNodeArray,
    linkDataArray: newLinkArray,
  };
  return newFilterData;
}

export default neighborhoodFilterUtil;