/**
 * @summary RadiusAndDiameterItemRender.js
 * @file This component provides a row for nodes that are within the user's specified radius
 * @returns {JSX}
 * @usedBy RadiusAndDiameterReturn.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react'
import PropTypes from 'prop-types';
//////////////////////////
// PROFILE CHECK RETURN //
//////////////////////////
export const RadiusAndDiameterItemRender = ({dataItem}) => {
    return (
        
        <div
            className="row k-listview-item p-2 border-bottom align-middle"
            style={{
                marginTop: 0,
                marginBottom: 0,
            }}
        >
            <div className="col-2" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {dataItem.selectedNodeKey}
                </div>
            </div>

            <div className="col-2" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                        color: "black",
                        marginLeft: 50,
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    {dataItem.radius}
                </div>
            </div>

            <div className="col-6" style={{padding: 0}}>
                <div
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                        color: "black",
                        marginLeft:50,
                        paddingTop: 10,
                        paddingBottom: 10,
                    }}
                >
                        {dataItem.nodeDataArray.map(node => {
                            return(
                                <div key={node.id}>
                                    {node.nodeKey}
                                </div>
                            )
                        })}
                </div>
            </div>

        </div>
    );
};

export default RadiusAndDiameterItemRender;

RadiusAndDiameterItemRender.propTypes = {
    dataItem: PropTypes.object,
}