/**
 * @summary NeighborhoodNodesSettings.js
 * @file allows user to add or delete a node from a neighborhood
 * @returns {JSX}
 * @usedBy NeighborhoodForm.js
 * @author Sam Lee
 * @since 2/17/2023
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import KendoGridBase from '../../../shared/ui/kendoGridBase/KendoGridBase';
import { gridSelectedDataActions } from '../../../store/nodes/NodeActions';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import AddNodeModal from 'pages/project/modal/addNodeModal';
import DeleteConfirmation from '../modal/DeleteConfirmation';
import { deleteNeighborhoodNodes } from 'store/neighborhoods/NeighborhoodActions';
import store from 'store/store';

const NeighborhoodNodesSettings = ({ setRefresh, gridSelectedDataActions }) => {
  const [gridState, setGridState] = useState({
    nodecollections: { data: [], total: 0 },
    dataState: { take: 20, skip: 1 },
    gridDynamicColumns: [
      {
        field: 'selected',
        show: true,
        filterable: false
      },
      {
        field: 'nodeKey',
        title: 'Key',
        show: true,
        filterable: false,
        filter: 'text',
        width: '100px'
      },
      {
        field: 'name',
        title: 'Name',
        show: true,
        filterable: false,
        filter: 'text',
        width: '200px'
      },
      {
        field: 'description',
        title: 'Description',
        show: true,
        filterable: false,
        filter: 'text',
        cellType: 'ckeditor_content',
        width: '300px'
      },
      {
        field: 'checkedOutBy',
        title: 'Checked Out',
        show: true,
        filterable: false,
        filter: 'text',
        width: '200px'
      }
    ]
  });
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 30 });
  const [, setLoading] = useState(false);
  const [, setSkipProcessing] = useState(0);
  const [selectedRowsStateArray, setSelectedStateArray] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  

  const messageOptions = {
    messsageType: {
      success: 'Success'
    },
    messages: {
      successOnDelete: 'Form Deleted',
      errorOnDelete: 'Unable to Delete Node Collection',
      errorOnFetch: 'Unable to Load Nodes',
      underConstruction: 'This Function is Under Construction'
    }
  };

  const userObj = useSelector((state) => (state.authReducer.userObj))
  const selectedProject = useSelector(
    (state) => state.authReducer.userObj.selectedProject[0]
  );
  const selectedBranch = useSelector(
    (state) => state.authReducer.userObj.selectedBranch[0]
  );
  const nodes = useSelector((state) => state.nodeReducer);
  const selectedNeighborhood = useSelector(
    (state) => state.neighborhoodReducer.selectedData[0]
  );

  const handlePageChange = (e) => {
    e.page.skip = isNaN(e.page.skip) ? 1 : e.page.skip;
    setPage(e.page);
  };

  const handleGridFilterChange = (colFilter) => {
    setRefresh(true);
    setFilter(colFilter || {});
  };

  const handleGridSortChange = (obj) => {
    setRefresh(true);
    setSort(obj);
  };

  const gridColumns = [
    {
      field: 'selected',
      show: true,
      filterable: false
    },
    {
      field: 'nodeKey',
      title: 'Key',
      show: true,
      filterable: true,
      filter: 'text',
      width: '100px'
    },
    {
      field: 'name',
      title: 'Name',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'description',
      title: 'Description',
      show: true,
      filterable: true,
      filter: 'text',
      cellType: 'ckeditor_content',
      width: '300px'
    },
    {
      field: 'checkedOutBy',
      title: 'Checked Out',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'lastUpdated',
      title: 'Last Updated',
      show: true,
      filterable: true,
      filter: 'text',
      width: '200px'
    }
  ];

  const updateGridState = () => {
    // wrapped in promise purpose: allow async actions to finish prior to populating state
    if (selectedProject && userObj.selectedBranch[0].id) {
      const gridDynamicColumnsArr = [];
      const selectedNodes = selectedNeighborhood.nodeIds
        ? selectedNeighborhood.nodeIds.map((nodeId) => nodes[nodeId])
        : [];
      const nodecollections = {
        data: selectedNodes,
        total: selectedNodes.length
      };
      if (nodecollections.data.length) {
        // assign each neighborhood node its checked out user name via the node collection in redux
        nodecollections.data.forEach((item) => {
          item.checkedOutBy = nodes[item?.id]?.checkedOutBy;
          item.selected = false;
        });
        const gridDynamicColumns = [...gridColumns, ...gridDynamicColumnsArr];
        setGridState({
          ...gridState,
          nodecollections,
          dataState: { take: 20, skip: 0 },
          gridDynamicColumns
        });
      }
    }
  };

  // When a Row is Selected...
  const selectionChange = (e) => {
    const data = gridState.nodecollections.data.map((collection) => {
      // Adds Selected Data to an Array to Control Button Visibility
      if (collection === e.dataItem) {
        collection.selected = !e.dataItem.selected;
        if (collection.selected === true) {
          setSelectedStateArray((selectedRowsStateArray) => [
            ...selectedRowsStateArray,
            collection
          ]);
        } else {
          const filteredSelectedState = selectedRowsStateArray.filter(
            (selecteCheck) => selecteCheck.selected === true
          );
          setSelectedStateArray(filteredSelectedState);
        }
      }
      return collection;
    });
    const gridObjs = { ...gridState.nodecollections };
    gridObjs.data = data;
    setGridState({ ...gridState, nodecollections: gridObjs });
  };


  const handleDeleteNeighborhoodNodes = async () => {
    await store.dispatch(deleteNeighborhoodNodes({ projectId: selectedProject.id, branchId: selectedBranch.id, 
      id: selectedNeighborhood.id, nodes: selectedRowsStateArray}))

    const newGridState = [...gridState.nodecollections.data]

    selectedRowsStateArray.forEach((selectedNode) => {
      const nodeIdx = newGridState.findIndex((node) => node.id === selectedNode.id)

      if (nodeIdx > -1) {
        newGridState.splice(nodeIdx, 1)
      }
    })

    setSelectedStateArray([])
    setGridState({
      ...gridState,
      nodecollections: {
        data: newGridState,
        total: newGridState.length
      },
    });
  }

  // Handles All Button and Row Click Actions
  const actionHandler = (action) => {
    if (action === 'addNode') {
      setShow(true);
    }
    if (action === 'delete') {
      setShowModal(true);
    }
  };

  useEffect(() => {
    updateGridState();
  }, [selectedProject, nodes, selectedNeighborhood]);

  useEffect(() => {
    updateGridState();
  }, []);

  useEffect(() => {
    setLoading(true);
    setLoading(false);
    setSkipProcessing(page.skip);
  }, [filter, sort, page, selectedProject]); // eslint-disable-line

  function sortByNodeKey(arr) {
    return arr.sort((a, b) => {
        const aParts = a.nodeKey.split(/[-\s]/).map(part => isNaN(part) ? part : Number(part));
        const bParts = b.nodeKey.split(/[-\s]/).map(part => isNaN(part) ? part : Number(part));

        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            if (aParts[i] !== bParts[i]) {
                if (aParts[i] === undefined) return -1;
                if (bParts[i] === undefined) return 1;
                if (typeof aParts[i] === 'number' && typeof bParts[i] === 'number') {
                    return aParts[i] - bParts[i];
                }
                return aParts[i]?.localeCompare(bParts[i], undefined, { numeric: true, sensitivity: 'base' });
            }
        }
        return 0;
    });
}

const sortedData = gridState?.nodecollections?.data ? sortByNodeKey(gridState.nodecollections.data) : [];

return (
    <div className="container-fluid">
      <AddNodeModal show={show} setShow={setShow} />
      <DeleteConfirmation showModal={showModal} setShowModal={setShowModal} deleteFn={handleDeleteNeighborhoodNodes}/>
      <div className="row">
        <div
          style={{
            margin: '16px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <KendoGridBase
            data={sortedData || []}
            gridColumns={gridState.gridDynamicColumns}
            setGridFilters={handleGridFilterChange}
            setGridSort={handleGridSortChange}
            updateGridData={gridSelectedDataActions}
            onSelectionChange={selectionChange}
            onRowSingleClick={selectionChange}
            onPageChange={handlePageChange}
            sorter={sort}
            rowHeight={40}
            skip={page.skip}
            take={page.take}
            total={
              gridState.nodecollections ? gridState.nodecollections.total : 0
            }
            pageSize={100}
            selectable="selected"
            pageable
            sortable
            filterable
          />
          <div className="container-fluid p-0 d-flex justify-content-between mt-2">
            <div>
              <button
                className="btn btn-success btn-sm text-white me-2"
                type="button"
                onClick={() => {
                  actionHandler('addNode', '(need function)');
                }}
              >
                <i className="bi bi-plus me-2" />
                Add Node
              </button>
            </div>
            <div>
              <button
                className="btn btn-danger btn-sm text-white"
                type="button"
                onClick={() => {
                  actionHandler('delete', selectedRowsStateArray);
                }}
                disabled={!selectedRowsStateArray.length}
              >
                <i className="bi bi-trash me-2" />
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
NeighborhoodNodesSettings.propTypes = {
  setRefresh: PropTypes.func,
  gridSelectedDataActions: PropTypes.func
};

export default connect(null, { gridSelectedDataActions })(
  NeighborhoodNodesSettings
);
