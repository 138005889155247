/**
 * @summary ConnectionAttributeSettings.js
 * @file allows for changes in the connection's attributes
 * @returns {JSX}
 * @usedBy ConnectionForm.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useEffect, useRef } from 'react';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import {
  getConnection,
  getConnectionSettings
} from 'store/connections/ConnectionActions';
import store from '../../../store/store';
import PropTypes from 'prop-types';
import { normalizeName } from 'utility/updateFields';
import { renderHTMLElement } from '../utils/renderHTML';

const ConnectionAttributeSettings = ({
  userObj,
  connection,
  profileDetails,
  setProfileDetails,
  attrsLoaded,
  setAttrsLoaded
}) => {
  const updateValues = (e, key) => {
    const { value } = e;
    getUpdatedValues(value, key);
  };
  
  const didFetchRef = useRef(null);

  // refactor with nodes/neighborhoods?
  const setPossibleValueObject = (data, metaDataId, selectedData) => {
    if (metaDataId.length > 0) {
      let output = metaDataId.map((id, value) => ({ id, name: data[value] }));
      return output;
    } else {
      const connValue = (name) => {
        return selectedData.find((obj) => obj.name === name)?.valueId;
      };

      return data.map((val, idx) => ({
        id: idx,
        connValueId: selectedData.length > 0 ? connValue(val) : undefined,
        name: val
      }));
    }
  };

  // refactor with nodes/neighborhoods?
  const applySettingToState = (setting) => {
    setting.possibleValue = setPossibleValueObject(
      setting.value,
      setting.metaDataId,
      setting.selectedValue
    );
    if (setting.category === 'Profile Details') {
      setProfileDetails((profileDetails) => [...profileDetails, setting]);
    }
  };

  // refactor with nodes/neighborhoods?
  const setAttributeSelectableValues = (settingsData) => {
    settingsData.forEach((setting) => {
      applySettingToState(setting);
    });
  };

  // refactor with nodes/neighborhoods? -- this would be the worst i think
  const setExistingConnectionSettings = (connectionData) => {
    const connectionValues = connectionData.settings.map((setting) => {
      setting.selectedValue = [];
      if (connectionData.selectedValue) {
        const foundSelection = connectionData.selectedValue?.filter(
          (value) => setting.attributeId === value.attrId
        );
        setting.connAttrId = foundSelection?.connAttrId || undefined;
        if (foundSelection) {
          setting.selectedValue = foundSelection?.map((value, idx) => {
            const attr = value.values[0];
            return({
            id:
              attr.selectedId ||
              setting.value.indexOf(attr.selectedValue),
              connValueId: attr.valueId,
            name: attr.selectedValue ? attr.selectedValue : '',
            connectionAttrId: foundSelection[0].connectionAttrId
          })
        }) 
        }
      }
      return setting;
    });
    setAttributeSelectableValues(connectionValues);
  };

  // refactor with nodes/neighborhoods?
  const setBaseSelections = (connectionData) => {
    const connectionValues = connectionData.map((setting) => ({
      ...setting,
      selectedValue: []
    }));
    setAttributeSelectableValues(connectionValues);
  };

  // refactor with nodes/neighborhoods?
  const getAttributeSettings = () => {
    if (connection.id < 1) {
      store
        .dispatch(
          getConnectionSettings(
            userObj.selectedProject[0].id,
            userObj.selectedBranch[0].id
          )
        )
        .then((resp) => {
          if (!attrsLoaded) {
            setAttrsLoaded(true);
            setBaseSelections(resp);
          }
        });
    } else {
      // editing a connection
      store
        .dispatch(
          getConnection(
            userObj.selectedProject[0].id,
            userObj.selectedBranch[0].id,
            connection?.id
          )
        )
        .then(() => {
          if (!attrsLoaded) {
            setAttrsLoaded(true);
            const connectionData =
              store.getState().connectionReducer.selectedConn;
            setExistingConnectionSettings(connectionData);
          }
        });
    }
  };

  useEffect(() => {
    if (didFetchRef.current) return; 
    getAttributeSettings();
    didFetchRef.current = true;
  }, []);

  // -- stretch refactor
  const getUpdatedValues = (values, key) => {
    if (key.category === 'Profile Details') {
      if (key.dataType === 'Text') {
        let updatedList = profileDetails.map((item) => {
          if (item.name === key.name) {
            const newObj = { name: values };
            return { ...item, selectedValue: [newObj] };
          }
          return item;
        });
        setProfileDetails(updatedList);
      }
      if (key.dataType === 'Drop-Down') {
        let updatedList = profileDetails.map((item) => {
          if (item.name === key.name) {
            const value = parseInt(values);
            const newSelectedValue = item.possibleValue.find(
              (element) => element.id === value
            );
            return { ...item, selectedValue: [newSelectedValue] };
          }
          return item;
        });
        setProfileDetails(updatedList);
      }
      if (key.dataType === 'Multi-Select') {
        let updatedList = profileDetails.map((item) => {
          if (item.name === key.name) {
            return { ...item, selectedValue: values };
          }
          return item;
        });
        setProfileDetails(updatedList);
      }
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col p-2 justify-content-between">
          <fieldset className="bg-light p-3 rounded">
            <legend>Connection Details</legend>
            {!profileDetails.length ? (
              <p>no attributes selected</p>
            ) : (
              profileDetails.map((key, idx) => (
                <>
                  <div className="col d-flex p-2 justify-content-between">
                    <div className="col-2 text-end">
                      <label
                        className="form-label"
                        htmlFor={`details-attribute-${key.name}-${idx}`}
                      >
                        {normalizeName(key.name)}
                      </label>
                    </div>
                    <div className="col-9">
                      {renderHTMLElement(
                        key,
                        updateValues,
                        `details-attribute-${key.name}-${idx}`
                      )}
                    </div>
                  </div>
                </>
              ))
            )}
          </fieldset>
        </div>

        <div className="col p-2 justify-content-between">
          <fieldset className="bg-light p-3 rounded">
            <legend>Tags</legend>
            <div className="col d-flex p-2 justify-content-between">
              <div className="col-2 text-end">
                <label className="form-label" htmlFor="framework">
                  Keywords:
                </label>
              </div>
              <div className="col-9">
                <MultiSelect
                  id="framework"
                  name="frameworks"
                />
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default ConnectionAttributeSettings;

ConnectionAttributeSettings.propTypes = {
  getConnection: PropTypes.func,
  profileDetails: PropTypes.array,
  setProfileDetails: PropTypes.func,
  connection: PropTypes.object,
  userObj: PropTypes.object,
  setAttrsLoaded: PropTypes.func,
  attrsLoaded: PropTypes.bool
};
