/**
 * @summary ListComponentsReturn.js
 * @file Component provides a layout for the results after the report is generated
 * @returns {JSX}
 * @usedBy GenerateReport.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, {useRef} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import {
  ListView,
} from "@progress/kendo-react-listview";

import ListComponentItemRender from "./ListComponentItemRender"
import ListComponentHeaderRender from "./ListComponentHeaderRender"

const ListComponentsReturn = ({ reportData }) => {

  return (
      <Row >
          <div className='generated-report-header'>Nodes with a Maximum of {reportData.maxCluster} Connected Nodes</div>
          <Col>
            <ListView 
              data={reportData.clusterData}
              item={ListComponentItemRender}
              header={ListComponentHeaderRender}
            />
          </Col>
      </Row>
  )
}

export default ListComponentsReturn;

ListComponentsReturn.propTypes = {
  reportData: PropTypes.object,
  dataItem: PropTypes.object,
}
