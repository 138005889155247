/**
 * @summary HomePage.jsx
 * @file Homepage component that provides links and general information for the users
 * @returns {JSX}
 * @usedBy routes.js, c3Routes.js 
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2023 University of Kansas
 */

import React from 'react';
import './Home.scss';
import QuickLinks from './components/QuickLinks.jsx';
import News from './components/News.jsx';

const HomePage = () => {
  return (
    <div className="home-page-display">
      <div className="kite-background">
        <div className="d-flex p-4 justify-content-between row">
          <div className="col-8">
            <News />
          </div>
          <div className="col-4">
            <QuickLinks />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
