import {
  ADD_OBJECT,
  RESET_CANVAS_SELECTIONS,
  SET_OBJECTS,
  UPDATE_CANVAS_OPTIONS
} from './CanvasTypes';

const defaultState = {
  canvasFormatted: {},
  tableFormatted: {
    nodes: [],
    connections: [],
    neighborhoods: []
  }
};

/**
 * @summary
 * This redux reducer is used for connection CRUD based on the action type
 *
 * @param {connections} state
 * @param {reduxAction} action
 */

const canvasReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_OBJECT:
      state[action.objType].push(action.obj);
      return {
        ...state
      };

    case RESET_CANVAS_SELECTIONS:
      return {
        canvasFormatted: {},
        tableFormatted: {
          nodes: [],
          connections: [],
          neighborhoods: []
        }
      };

    case SET_OBJECTS:
      state[action.objType] = action.objs;
      return {
        ...state
      };

    case UPDATE_CANVAS_OPTIONS:
      const newOptions = { ...state.canvasOptions };
      newOptions[action.optionKey] = action.optionValue;
      return {
        ...state,
        canvasOptions: newOptions
      };

    default:
      return state;
  }
};
export default canvasReducer;
