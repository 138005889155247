/**
 * @summary InspectorTool.js
 * @file Modal for Containing Injuey Data for Selected Element(s). Opened through CanvasInterface.js After at least one Element is Selected
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../canvasPage.scss';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import axios from 'axios';
import { LuGlasses } from 'react-icons/lu';

import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';

import AddModal from './crudModals/AddConnectionCanvasModal'
import DeleteModal from './crudModals/DeleteConnectionCanvasModal'

const ConnectionMiniGrid = ({
    canvasConnectionSelections,
    tableConnectionSelections,
    setCanvasConnectionSelections,
    tableNodeSelections,
    handleCrud,
    branchChangeData,
    renderMapData,
    handleTableviewNavigation,
    selectedNodesIncluded,
    onPublishedBranch
  }) => {

    const DATA_ITEM_KEY = "nodeKey";
    const SELECTED_FIELD = "selected";

    const [addModalVisible, setAddModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)

    const handleAddModalVisibility = () => {
        setAddModalVisible(!addModalVisible)
    }

    const handleDeleteModalVisibility = () => {
        setDeleteModalVisible(!deleteModalVisible)
    }

    useEffect(() => {
        if(selectedNodesIncluded){
            setAddModalVisible(true)
        }
    },[selectedNodesIncluded])

    const handleDataEditorSelections = (e) => {
        if(e.dataItem?.type === "Connection"){        
            let newCanvasConnectionSelections = canvasConnectionSelections.map(connection => {
                if(connection.id === e.dataItem.id){
                    connection.selected = !connection.selected
                }
                return connection
            })
            setCanvasConnectionSelections(newCanvasConnectionSelections)
        }
    };

    const handleSelectAll = () => {
        if(canvasConnectionSelections.some(connection => !connection.selected)){
            let newCanvasConnectionSelections = canvasConnectionSelections.map(connection => {
                connection.selected = true
                return connection
            })
            setCanvasConnectionSelections(newCanvasConnectionSelections)
        } else {
            let newCanvasConnectionSelections = canvasConnectionSelections.map(connection => {
                connection.selected = false
                return connection
            })
            setCanvasConnectionSelections(newCanvasConnectionSelections)
        }
    }
    // FORMAT CHANGE LOG MESSAGING
    canvasConnectionSelections.map(connection => {
        if(connection.deleted){
            connection.checkedOutMessage = connection.isCheckedOut ? "Yes" : "No"
            connection.changeMessage = "Deleted"
            return connection
        } else if(connection.added){
            connection.checkedOutMessage = connection.isCheckedOut ? "Yes" : "No"
            connection.changeMessage = "Added"
            return connection
        } else if(connection.edited){
            connection.checkedOutMessage = connection.isCheckedOut ? "Yes" : "No"
            connection.changeMessage = "Replaced"
            return connection
        } else {
            connection.checkedOutMessage = connection.isCheckedOut ? "Yes" : "No"
            connection.changeMessage = "None"
            return connection
        }
    })

    const [sort, setSort] = useState([{
        field: "name",
        dir: "asc",
      }]);

    return (
        <>
        <div>
            <Grid
                style={{
                    height: "300px",
                }}
                data={orderBy(canvasConnectionSelections, sort)}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                onSelectionChange={handleDataEditorSelections}
                onRowClick={handleDataEditorSelections}
                onHeaderSelectionChange={handleSelectAll}

                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                    setSort(e.sort);
                }}

                selectable={{
                    enabled: true,
                    drag: true,
                    cell: false,
                    mode: "multiple",
                }}
            >
                <GridNoRecords>
                    Select Elements on Canvas to Display Here
                </GridNoRecords>
                <Column field={SELECTED_FIELD} width="50px" 
                />
                <Column field="name" title="Name" width="250px" />
                <Column field="from" title="Source" />
                <Column field="to" title="Destination" />
                <Column field="changeMessage" title="Branch Changes" />
                <Column field="checkedOutMessage" title="Checked Out" />  
                {/* <Column field="lastUpdated" title="Last Change" /> */}
                {/* <Column field="checkedOutBy" title="Checked Out By" /> */}
            </Grid>
        </div>
        <div className="container-fluid p-0 d-flex justify-content-between mt-2">
        <div className='col-12'>
            <div className='row'>
                <div className='col-2'>
                    <button
                    className="btn btn-primary btn-sm text-white me-2 ms-4"
                    type="button"
                    onClick={handleAddModalVisibility}
                    title={onPublishedBranch ? "Creating Elements is disabled while on the published branch." : "Create a new Connection."}
                    disabled={onPublishedBranch}
                    >
                    <i className="bi bi-plus me-2" />
                        Add Connection
                    </button>
                </div>
                
                <div className='col-2 text-right'>
                    <button
                    className="btn btn-danger btn-sm text-white w-100 ms-4"
                    type="button"
                    title={onPublishedBranch ? "Deleting is disabled while on the published branch." : "Delete Selected Connection"}
                    onClick={handleDeleteModalVisibility}
                    disabled={onPublishedBranch || tableConnectionSelections.length < 1}
                    >
                    <i className="bi bi-trash me-2" />
                        Delete
                    </button>
                </div>
                <div className='col-8'></div>
            </div>
        </div>
    </div>
    <Modal show={addModalVisible} backdrop="static" centered size="xl">
        <AddModal 
            handleCrud={handleCrud}
            handleAddModalVisibility={handleAddModalVisibility}
            handleTableviewNavigation={handleTableviewNavigation}
            renderMapData={renderMapData}
            tableNodeSelections={tableNodeSelections}
            selectedNodesIncluded={selectedNodesIncluded}
        />
    </Modal>
    <Modal show={deleteModalVisible} backdrop="static" centered>
        <DeleteModal 
            handleCrud={handleCrud}
            selectedConnections={tableConnectionSelections}
            tableConnectionSelections={tableConnectionSelections}
            handleDeleteModalVisibility={handleDeleteModalVisibility}
        />
    </Modal>
    </>
    );
  } 

ConnectionMiniGrid.propTypes = {
  canvasConnectionSelections: PropTypes.array,
  tableConnectionSelections: PropTypes.array,
  setCanvasConnectionSelections: PropTypes.func,
  tableNodeSelections: PropTypes.array,
  handleCrud: PropTypes.func,
  branchChangeData: PropTypes.obj,
  renderMapData: PropTypes.obj,
  handleTableviewNavigation: PropTypes.func,
  selectedNodesIncluded: PropTypes.bool,
  onPublishedBranch: PropTypes.bool
};

export default ConnectionMiniGrid;