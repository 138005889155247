/**
 * @summary fileTypeConstants.js
 * @file Defines all media file types for the application
 * @returns {JSX}
 * @usedBy MeidaModal.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

// Forms
export const FORM_VALIDATION_ERROR_MESSAGE = 'Error Validating Form';

// Media
export const IMAGE_TYPES = ['jpeg', 'jpg', 'png', 'gif', 'svg'];
export const VIDEO_TYPES = ['mp4', 'webm'];
export const AUDIO_TYPES = ['mp3', 'wav'];
export const MEDIA_FORMAT_ERROR_MESSAGE =
  'Cannot save media content. Format invalid';

// Other
export const VALID_FILE_NAME_REGEX = new RegExp('^[a-z0-9()!_.*-]+', 'gi');
