/**
 * @summary ConnectionStylization.js
 * @file Contains All Theme Stylization Options for Connections - Displayed in CanvasThemeMenu.js
 * @returns {JSX}
 * @usedBy CanvasThemeMenu.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import { ChromePicker  } from 'react-color';
import '../../canvasPage.scss';
import ColorPicker from './ColorPicker'
{/* //////////////// */}
{/* NODE STYLIZATION */}
{/* //////////////// */}
const ConnectionStylization = ({
    handleColorPalletteVisibility, 
    connectionColorPallete, 
    connectionColor, 
    handleConnectionColorChange, 
    handleConnectionThicknessChange, 
    handleConnectionArrowHeadStyleChange,
    connectionWidthDropdownLabel, 
    connectionArrowStyleDropdownLabel
}) => {
    return(
        <div className='col-4'>
            {/* CONNECTION COLOR */}
            <div className='theme-options-headers'>
                Connections
            </div>
            <div className='row'>
                <div className='theme-option'>
                    Color:                 
                    <button 
                        title={'Color Selector: Click this button to open the color pallette, select a color from the pallet, and click this button again to close the pallette.'}
                        onClick={handleColorPalletteVisibility} 
                        id="connection-color-button" 
                        className={connectionColor === 'transparent' ? 'default-color-button' : 'selected-color-button'} 
                        style={{backgroundColor: connectionColor }}>

                    </button>
                
                    <div className={connectionColorPallete ? "pallette-visibility-on" : "pallette-visibility-off"}>
                    <ColorPicker
                        pickerId={"connection-color"} 
                        color={connectionColor}
                        handleConnectionColorChange={handleConnectionColorChange}
                    />
                </div>
                </div>
            </div>
            {/* CONNECTION THICKNESS */}
            <div className='row'>
                <div className='theme-option'>
                    Width:
                    <form className='connection-width-form'>
                        <select
                            className="connection-width-selector"
                            onChange={handleConnectionThicknessChange}
                            value={connectionWidthDropdownLabel}
                        >
                        <option className="node-border-width-option" value={1}>1</option>
                        <option className="node-border-width-option" value={2}>2</option>
                        <option className="node-border-width-option" value={3}>3</option>
                        <option className="node-border-width-option" value={4}>4</option>
                        <option className="node-border-width-option" value={5}>5</option>
                        <option className="node-border-width-option" value={6}>6</option>
                        </select>
                    </form>
                </div>
            </div>
            {/* CONNECTION ARROW STYLE */}
            <div className='row'>
                <div className='theme-option'>
                    Arrow:
                    <form className='arrow-style-form'>
                        <select
                        id="node-border-width-button"
                        className="arrow-style-selector"
                        onChange={handleConnectionArrowHeadStyleChange}
                        value={connectionArrowStyleDropdownLabel}
                        >
                        <option className="node-border-width-option" value="Triangle">Triangle</option>
                        <option className="node-border-width-option" value="Standard">Pointer</option>
                        <option className="node-border-width-option" value="OpposingDirectionDoubleArrow">Two-Way</option>
                        <option className="node-border-width-option" value="Circle">Circle</option>
                        </select>
                    </form>
                </div>
            </div>
        </div>
    )
}

ConnectionStylization.propTypes = {
    handleColorPalletteVisibility: PropTypes.func,
    connectionColorPallete: PropTypes.bool,
    connectionColor: PropTypes.string,
    handleConnectionColorChange: PropTypes.func,
    handleConnectionThicknessChange: PropTypes.func,
    handleConnectionArrowHeadStyleChange: PropTypes.func,
    connectionWidthDropdownLabel: PropTypes.any,
    connectionArrowStyleDropdownLabel: PropTypes.string
};

export default ConnectionStylization;