/**
 * @summary cycleCheckUtil.js
 * @file Function iterates through nodes to find potential loops, which is then returned in cycleCheckReport
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { formatMapData } from "../formatMapData";
import { toast } from 'react-toastify';

export const getCycleCheck = (nodes, connections) => {
  const {nodeDataArray, linkDataArray} = formatMapData(nodes, connections)
  const $ = go.GraphObject.make;

  const diagram = $(go.Diagram, // no div!
      {
        model: $(go.GraphLinksModel,
          {   
              nodeKeyProperty: 'nodeKey',
              linkKeyProperty: 'key' 
          })
      });
  
  // need to merge in the data since the props are immutable and GoJS normally adds some internal properties
  
  diagram.model.commit(m => {
      m.mergeNodeDataArray(nodeDataArray);
    if (linkDataArray !== undefined && m instanceof go.GraphLinksModel) {
      m.mergeLinkDataArray(linkDataArray);
    }
  });



  diagram.nodes.each(node => {
    // SOURCE node 
    // let one = node.findNodesConnected()
    let two = node.findNodesInto()
    let three = node.findNodesOut()
  })

    function hasLoop(node, visited, stack) {
        if(node.data){
        visited.add(node);
        stack.add(node);

        let connectedNodes = node.findNodesConnected();
        let iterator = connectedNodes.iterator;
        while (iterator.next()) {
            let neighbor = iterator.key;
            if (!visited.has(neighbor)) {
                if (hasLoop(neighbor, visited, stack)) {
                    return true;
                }
            } else if (stack.has(neighbor)) {
                return true;
            }
        }

        stack.delete(node);
        return false;
        }
    }

    function findLoops(diagram) {
        let loops = [];
        diagram.nodes.each(node => {
            let visited = new Set();
            let stack = new Set();
            if (hasLoop(node, visited, stack)) {
                loops.push({
                    nodeKey: node.data.nodeKey,
                    cycle: Array.from(stack)
                });
            }
        });
        return loops;
    }

    const cycleCheckReport = findLoops(diagram)

    return cycleCheckReport
  
  ////////////////////////////////////////
  // REPORTING - RADIUS FILTER - FOR DJ //
  ////////////////////////////////////////
  // INPUT DATA FROM USER //
  
}


