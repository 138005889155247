/**
 * @summary constants.js
 * @file Global Constants for application
 * @returns {JSX}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 11/01/2021
 * @lastUpdated 11/2021
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const nodeDetails = 'Node Details';

export const contentMetadata = 'Content Metadata';

export const taxonomiesDimensions = 'Taxonomies and Dimensions';

export const accessibilityFlags = 'Accessibility Flags';

export const neighborhoodDetails = 'Neighborhood Details';

export const text = 'Text';

export const dropDown = 'Drop-Down';

export const multiSelect = 'Multi-Select';
