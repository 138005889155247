/**
 * @summary InspectorTool.js
 * @file Modal for Containing Injuey Data for Selected Element(s). Opened through CanvasInterface.js After at least one Element is Selected
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../canvasPage.scss';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { toast } from 'react-toastify';

const AddConnectionCanvasModal = ({
    handleAddModalVisibility,
    handleCrud,
    renderMapData,
    handleTableviewNavigation,
    tableNodeSelections,
    selectedNodesIncluded
  }) => {

    const userObj = useSelector((state) => state.authReducer.userObj);
    const reduxConnections = useSelector((state) => Object.values(state.connectionReducer));
    let allNodesArray = renderMapData.nodeDataArray.filter(node => node.category !== "Super")

    const [saveConnectionName, setSaveConnectionName] = useState(null)
    const [selectedOriginNode, setSelectedOriginNode] = useState(null)
    const [selectedDestinationNode, setSelectedDestinationNode] = useState(null)
    const [availableSourceNodes, setAvailableSourceNodes] = useState([])
    const [availableDestinationNodes, setAvailableDestinationNodes] = useState([])

    // FORMATS SAVED CONNECTION NAME
    const formatInputString = (inputString) => {
        if(inputString !== null && inputString.length){
            inputString.trim()
            if(inputString.trim().length > 0){
                return inputString.trim()
            } else {
                return null
            }         
        } else {
            return null
        }
    }

    // SAVE OBJECT - WHAT GETS SAVED
    const jsonConnection = {
        action: 'create',
        elementType: 'connection',
        projectId: userObj.selectedProject[0].id,
        branchId: userObj.selectedBranch[0].id,
        name: formatInputString(saveConnectionName),
        sourceNodeId: selectedOriginNode,
        destinationNodeId: selectedDestinationNode,
        connectionType: "linear",
        settings: {
          profileDetails: []
        }
    };
    // EVENT HANDLERS FOR ALL INPUTS
    const handleConnectionNameInputChange = (e) => {
        if(e.target.value.trim().length > 0){
            setSaveConnectionName(e.target.value)
        } else {
            setSaveConnectionName(null)
        }
    }
    const handleOriginSelection = (e) => {
        let originId = Number(e.target.value)
        setSelectedOriginNode(originId)
    }
    const handleDestinationSelection = (e) => {
        let destinationId = Number(e.target.value)
        setSelectedDestinationNode(destinationId)
    }

    // SAVE HANDLER
    const handleSaveNewConnection = () => {
        const checkDuplicateOriginDestinations = () => {
            let isMatch = renderMapData.linkDataArray.map(connection => {
                if(connection.sourceNodeId === jsonConnection.sourceNodeId && connection.destination_node_id === jsonConnection.destinationNodeId){
                    return true
                } else {
                    return false
                }
            })
            return isMatch.includes(true)
        }
        
        let hasDuplicateOriginDestination = checkDuplicateOriginDestinations(jsonConnection)
        
        if(hasDuplicateOriginDestination === false){
            if(saveConnectionName && selectedOriginNode && selectedDestinationNode){ 
                // here
                delete reduxConnections.selectedData
                let reduxConnectionArray = Object.values(reduxConnections);
                if(reduxConnectionArray.find(connection => {return connection?.name === saveConnectionName}) === undefined){       
                    handleCrud(jsonConnection)
                    handleAddModalVisibility()
                } else {
                    return toast.error("Duplicate Connection Name")
                }
            } else {
                if(saveConnectionName === null){
                    toast.error("Enter a Name for your Connection")
                }
                if(selectedOriginNode === null){
                    toast.error("Select an Origin Node for your Connection.")
                }
                if(selectedDestinationNode === null){
                    toast.error("Select a Destination Node for your Connection.")
                }
            }
        } else {
            toast.error("A Connection in your map already has this same origin and destination.")
        }
    }
    // USE EFFECT: ORIGIN AND DESTINATION SETTERS
    useEffect(() => {
        let originNodes = []
        let destinationNodes = []
        if(allNodesArray.length > 0){
            allNodesArray.forEach(node => {
                if(selectedDestinationNode !== node.key){
                    if(node.branchId === userObj.selectedBranch[0].id){
                        originNodes.push(node)
                    }
                } 
                if(selectedOriginNode !== node.key){
                    if(node.branchId === userObj.selectedBranch[0].id){
                        destinationNodes.push(node)
                    }
                }
            })
            setAvailableSourceNodes(originNodes)
            setAvailableDestinationNodes(destinationNodes)
        } else {
            setAvailableSourceNodes([])
            setAvailableDestinationNodes([])
        }
    }, [selectedOriginNode, selectedDestinationNode])

    useEffect(() => {
        if(selectedNodesIncluded){
            setSelectedOriginNode(tableNodeSelections[0].id)
            setSelectedDestinationNode(tableNodeSelections[1].id)
        }
    },[selectedNodesIncluded])

    const handleSwitchNodes = () => {
        if(selectedOriginNode !== null && selectedDestinationNode !== null){
            setSelectedOriginNode(selectedDestinationNode)
            setSelectedDestinationNode(selectedOriginNode)
        }
    }
    return (
        <>
            <Modal.Header>
                <Modal.Title>
                    <div className='modal-header-text'>
                        Add New Connection
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='row'>
                    <div className='col-12'>
                        <div className='instructional-blue pt-3 pb-3'>
                            <div className='col-12'>
                                Enter the data for your connection. Redirect to Tableview for more configuration options on the tableview page.
                            </div>
                            
                        </div>
                        <div className='ps-4 pr-3 pb-4'>
                            <div className='row mt-1'>
                                
                                <div className='col-9'>
                                    <div className='ms-3 pt-3 bold-instruction'>
                                            Redirecting to the Tableview Page will open a new tab where you can 
                                            customize your connection with more in-depth options.
                                    </div>
                                </div>
                                <div className="col-3 pe-5">   
                                    <button onClick={handleTableviewNavigation} className="btn btn-success w-100 w-75 h-75">Redirect to Tableview</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container pb-4 px-4'>
                    <div className='row pt-3 px-2 mr-1'>
                        <div className="col-12 data-editor-subheader pb-2">
                            Connection Name
                        </div>
                        <div className="col-12 pb-2">
                                <input 
                                    placeholder="Enter name for your Connection." 
                                    onChange={handleConnectionNameInputChange} 
                                    value={saveConnectionName}
                                    type="text">
                                </input>            
                        </div>
                        <div className='col-5'>
                            <div className="data-editor-section">
                                <div className="col-12 data-editor-subheader pb-2">
                                    Origin Node
                                </div>
                                <select className="col-12" onChange={handleOriginSelection}>
                                    <option selected={selectedOriginNode === null} key={"default-source"}>
                                        Select a Source Node
                                    </option>
                                    {availableSourceNodes.map(node => {
                                        return(
                                            <option 
                                                selected={selectedOriginNode === node.id}
                                                key={node.nodeKey}
                                                value={node.key}
                                                type="text"
                                                onChange={handleOriginSelection}
                                            > 
                                                {node.nodeKey}
                                            </option>
                                        )
                                    })}        
                                </select>
                            </div>
                        </div>
                        <div className='col-2'>
                            <div className='text-center'>
                                <button disabled={selectedOriginNode === null || selectedDestinationNode === null} className='btn btn-primary mt-4 text-white text-center' style={{width: "50px", height: "50px"}}>
                                    <HiOutlineSwitchHorizontal size={20} onClick={handleSwitchNodes}/>
                                </button>
                            </div>
                        </div>
                        <div className='col-5'>
                            <div className="data-editor-section">
                                <div className="col-12 data-editor-subheader pb-2">
                                    Destination Node
                                </div>
                                <select className="col-12" onChange={handleDestinationSelection}>
                                    <option selected={selectedDestinationNode === null} key={"default-destination"}>
                                        Select a Destination Node
                                    </option>   
                                    {availableDestinationNodes.map(node => {
                                        return(
                                            <option
                                                // selected={selectedDestinationNode === node.nodeKey}
                                                selected={selectedDestinationNode === node.id}
                                                key={node.nodeKey}
                                                value={node.key}
                                                type="text"
                                                onChange={handleDestinationSelection}
                                            > 
                                                {node.nodeKey}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6'>
                            <button 
                                className="btn btn-primary btn-sm text-white"
                                onClick={handleSaveNewConnection}
                            >
                                Save and Close
                            </button>
                        </div>
                        <div className='col-6'>
                            <button 
                                className="btn btn-danger btn-sm text-white" 
                                onClick={handleAddModalVisibility}
                            >
                                Cancel
                            </button>
                        </div>             
                    </div>
                </div>
            </Modal.Footer>
        </>
    );
} 

AddConnectionCanvasModal.propTypes = {
  tableConnectionSelections: PropTypes.array,
  handleAddModalVisibility: PropTypes.func,
  handleCrud: PropTypes.func,
  renderMapData: PropTypes.obj,
  handleTableviewNavigation: PropTypes.func,
  selectedNodesIncluded: PropTypes.bool,
  tableNodeSelections: PropTypes.array
};

export default AddConnectionCanvasModal;