/**
 * @summary NodeCollectionPage.js
 * @file Wrapper component for the node grid on tableview page
 * @returns {JSX}
 * @usedBy tableviewLayout.js
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 09/25/2024
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import NodeCollectionGrid from './NodeCollectionGrid';
import PropTypes from 'prop-types';

// DJ QUESTION: DO WE NEED THIS EXTRA PARENT?

// headers come from API call in tableviewLayout.js for attribute columns, and setShow handles showing branch modal
const NodeCollectionPage = ({ headers, nodes, setSelectedNodes, selectedNodes, selectedNeighborhoods }) => {
  // state for triggering re-renders if required
  const [refresh, setRefresh] = useState(false);
  return (
    <div>
      <NodeCollectionGrid
        setRefresh={setRefresh}
        refresh={refresh}
        headers={headers}
        nodes={nodes}
        setSelectedNodes={setSelectedNodes}
        selectedNodes={selectedNodes}
        selectedNeighborhoods={selectedNeighborhoods}
      />
    </div>
  );
};

NodeCollectionPage.propTypes = {
  headers: PropTypes.object,
  nodes: PropTypes.array,
  setSelectedNodes: PropTypes.func,
  selectedNodes: PropTypes.object,
  selectedNeighborhoods: PropTypes.object
};

export default NodeCollectionPage;
