/**
 * @summary CycleCheckReturn.js
 * @file Component provides a layout/view for results after the report is generated
 * @returns {JSX}
 * @usedBy GenerateReport.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, {useRef} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import {
  ListView,
} from "@progress/kendo-react-listview";
import { startCase } from 'lodash';

const CycleCheckReturn = ({ reportData }) => {

  const MyItemRender = ({ dataItem }) => {
    return (
      <div
        className="k-listview-item row p-2 border-bottom align-middle"
        style={{
          margin: 0,
        }}
      >
        <div className="col-6">
          <h2
            style={{
              fontSize: 14,
              color: "#454545",
              marginBottom: 0,
            }}
            className="text-uppercase"
          >
            {dataItem.flag ? `${dataItem.flag} - ` : null}{dataItem.nodeKey}
          </h2>
        </div>
      </div>
    );
  };

  const categoryMapper = () => {
    return Object.keys(reportData).map((category, idx) => {
      return (
        <Row key={`${category}-${idx}`}>
          <h3>Category: {startCase(category)}</h3>
          <Col>
            <ListView 
              data={reportData[category]}
              item={MyItemRender}
            />
          </Col>
        </Row>
      )
    })
  }

  return (
      <Row>
        {
          categoryMapper()
        }
      </Row>
  )
}

export default CycleCheckReturn;

CycleCheckReturn.propTypes = {
  reportData: PropTypes.object,
  dataItem: PropTypes.object,
}
