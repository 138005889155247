/**
 * @summary formatFullChangeLogList.js
 * @file Function formats the full list of versioned nodes into an array of "Before and After" pairs
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const formatFullChangeLogList = (unformattedResponse, startDate, endDate) => {
    
    let date1 = new Date(startDate).getTime();
    let date2 = new Date(endDate).getTime();

    let changeLogArray = [];
    //////////////////////////////////////
    // TEMPORARY PLACEHOLDER FORMATTING //
    //////////////////////////////////////

    // for(let i = 1; i < placeholderArray.length; i++){
    for(let i = 0; i < unformattedResponse.length; i++){

        let versionDate = new Date(unformattedResponse[i].lastupdated).getTime();
        if(versionDate > date1 && versionDate < date2){
            if(unformattedResponse[i-1] !== undefined){

                let pairDateAndNodeKey = [];
                pairDateAndNodeKey.push(unformattedResponse[i-1]);
                pairDateAndNodeKey.push(unformattedResponse[i]);
            
                let pairObj = {
                    origin: false,
                    pair: [pairDateAndNodeKey[0].node, pairDateAndNodeKey[1].node],
                    timeStamp: pairDateAndNodeKey[1].lastupdated,
                    nodeKey: pairDateAndNodeKey[1].node.nodeKey
                }
                changeLogArray.push(pairObj)

            } else {
                let originNode = {
                    nodeKey: "Created"
                }
                let originObj = {
                    origin: true,
                    pair: [originNode, unformattedResponse[i].node],
                    timeStamp: unformattedResponse[i].lastupdated,
                    nodeKey: unformattedResponse[i].node.nodeKey
                }
                changeLogArray.push(originObj)
            }
        }
    }
    if(changeLogArray.length > 0){
        changeLogArray.map(pairObj => {
            // FORMAT DATES
            const date = new Date(pairObj.timeStamp);
            // const date = new Date('2023-07-02T16:46:53.78904-05:00');
            const longFormDate = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            const utcDateLong = date.toLocaleString('en-US', longFormDate);

            const shortFormDate = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            const utcDateShort = date.toLocaleString('en-US', shortFormDate);

            pairObj.nodeKey = pairObj.nodeKey
            pairObj.timeStamp = pairObj.timeStamp
            pairObj.longFormDate = utcDateLong
            pairObj.shortFormDate = utcDateShort
        })
    } else {
        return {errorMessage: "No changes were made to this node within this time frame. Try expanding your start and end dates."}
    }
    return changeLogArray;

}