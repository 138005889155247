/**
 * @summary listComponentsUtil.js
 * @file Under construction. Function loops through nodes in three different levels and then returns a cluster of nodes
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { formatMapData } from "../formatMapData";

export const getListComponents = (nodes, connections, maxCluster, branchType) => {
  const {nodeDataArray, linkDataArray} = formatMapData(nodes, connections)
  const $ = go.GraphObject.make;
  const diagram = $(go.Diagram, // no div!
      {
        model: $(go.GraphLinksModel,
          {   
              nodeKeyProperty: 'nodeKey',
              linkKeyProperty: 'key' 
          })
      });
  
    // need to merge in the data since the props are immutable and GoJS normally adds some internal properties
    diagram.model.commit(m => {
        m.mergeNodeDataArray(nodeDataArray);
        if (linkDataArray !== undefined && m instanceof go.GraphLinksModel) {
        m.mergeLinkDataArray(linkDataArray);
        }
    });

    // diagram.nodes.each(node => {
    //     // LEVEL 1 - LOOPING THROUGH 5000 NODES (this part's fine...)
    //     let arrayOfOutgoingNodes = node.findNodesOutOf()
    //     arrayOfOutgoingNodes.each(node => {
    //         // LEVEL 2 - LOOPING THROUGH 1-9 NODES FOR EACH OF THE 5000 (gettin hairy...)
    //         let arrayOfOutgoingNodes2 =node.findNodesOutOf()
    //         arrayOfOutgoingNodes2.each(node => {
    //             // LEVEL 3 - LOOPINg THROUGH 1-9 NODES FOR EACH OF THE 1-9 NODES FOR EACH OF THE 5000 (somewhere this ongoing system inevitably crashes)
    //             // Obviously we aren't looping this way, but this illustrates what our more sustainbly written logic is doing.
    //             // And it crashes the app every time.
    //         })
    //     })
        
    // })


    let outgoingNodesArray = []
    diagram.nodes.each(node => {
        // LEVEL 1
        let nodesOutOf = node.findNodesOutOf()
        
        let clusterStart = {
            node: node.data.nodeKey,
            array: nodesOutOf
        }
        outgoingNodesArray.push(clusterStart)
    })      

    
    return ({
        clusterData,
        maxCluster
    })
}


