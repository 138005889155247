/**
 * @summary LinkageLevelNeighborhoodTypes.jsx
 * @file LinkageLevelNeighborhood action types
 * @returns {String}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const GET_LINKAGE_LEVEL_NEIGHBORHOODS =
  'GET_LINKAGE_LEVEL_NEIGHBORHOODS';

export const CLEAR_LINKAGE_LEVELS_NEIGHBORHOODS =
  'CLEAR_LINKAGE_LEVELS_NEIGHBORHOODS';
