/**
 * @summary NodeRadiusFilterUtil.js
 * @file Filter Util: Takes Input Data from NodeRadiusFilter.js and Renders Map Elements into a Filtered Map
 * @returns {JSX}
 * @usedBy CanvasFilter.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { gojsFormatter } from "./gojsFormatter";
import { toast } from 'react-toastify';

const flatten = function (collectionArray, result = []) {
  for (let i = 0, length = collectionArray.length; i < length; i++) {
    const value = collectionArray[i];
    if (Array.isArray(value)) {
      flatten(value, result);
    } else {
      result.push(value);
    }
  }
  return result;
};

function nodeRadiusFilterUtil(
  diagramData,
  unfilteredMapData,
  handleFilterReset,
) {

  // CREATE A FULL FAKE MAP //
  let headlessDiagram = gojsFormatter(diagramData)
  let allNeighborhoods = diagramData.nodeDataArray.filter(node => node.category === "Super")
  ////////////////////////////////////////
  // IF FILTER ELEMENT IS A NODE //
  ////////////////////////////////////////
      // Take incoming nodeKey from nodeRadiusFocus and get GOJS obj from nodeKey
      let selectedNodeObj = headlessDiagram.findNodeForKey(unfilteredMapData.nodeRadiusFocus.nodeKey)
      //check for if AutoComplete Node Key does not exist in dropdown
      if (!selectedNodeObj) {
        handleFilterReset();
        return toast.error("Filtered Node Key doesn't exist");
      }

      let nodeCollectionArray = [];

      // GOJS fn that finds all Nodes and Links from out selected Node
      let startingNodeList = new go.List(selectedNodeObj.findNodesConnected());
      // let startingLinkList = new go.List(selectedNodeObj.findLinksConnected());
      
      // set them to an array
      let startingNodeArray = startingNodeList.toArray();
      // let startingLinkArray = startingLinkList.toArray();

      // add our starting node to the array, because GOJS findNodesConnected disregards the selected Node
      startingNodeArray.unshift(selectedNodeObj);
      nodeCollectionArray.push(startingNodeArray);
      // linkCollectionArray.push(startingLinkArray);

      //if there is a radius of 1, only send back the starting arrays of data
      if (unfilteredMapData.radiusNumber > 1) {
        //we loop for each number in our radius
        for (let i = 1; i < unfilteredMapData.radiusNumber; i++) {
          //GOJS handles the data as arrays of arrays, so we need to flatten it down
          //to just one array inorder to calculate
          let flattenedNodes = flatten(nodeCollectionArray);
          flattenedNodes.forEach((nodeCollection) => {
            // each node we want to find all nodes connected
            //Yes this causes duplicates, we clean those up later
            let recurviseNodeList = new go.List(
              nodeCollection.findNodesConnected()
            );
            let recursiveNodeArray = recurviseNodeList.toArray();

            nodeCollectionArray.push(recursiveNodeArray);

          });
        }
      }

      //Once again, properly format the GOJS array of arrays into a format we can process
      let mergedNodeArray = flatten(nodeCollectionArray);
      // let mergedLinkArray = flatten(linkCollectionArray);
      //find all the nodes from out GOJS calculations that match our nodeDataArray
      let nodeMatches = mergedNodeArray
        .map((x) => {
          let item = headlessDiagram.model.nodeDataArray.find(
            (item) => item.nodeKey === x.key
          );
          if (item) {
            return item;
          }
        })
        .filter((item) => item !== undefined);
      //here we remove any duplicates our loop created at the top wit the same key
      let nodeDataArray = nodeMatches.reduce((unique, o) => {
        if (!unique.some((obj) => obj.nodeKey === o.nodeKey)) {
          unique.push(o);
        }
        return unique;
      }, []);

      let linkDataArray = []
      let nodeKeys = nodeDataArray.map(node => node.nodeKey)
      nodeKeys.forEach(nodeKey => {
        let node = headlessDiagram.findNodeForKey(nodeKey)
        let nodeConnections = new go.List(node.findLinksConnected());
        nodeConnections.each(connection => {
          if(!linkDataArray.includes(connection.data) && nodeKeys.includes(connection.data.to) && nodeKeys.includes(connection.data.from)){
            linkDataArray.push(connection.data)
          }
        })
      })
      // Run Through All Neighborhoods Isolated Above
  let selectedNeighborhoods = []
  allNeighborhoods.forEach(neighborhood => {
    neighborhood._members.forEach(memberNode => {
      // Grab Any Neighborhoods Associated with Nodes if Checked
      let nodeExists = nodeDataArray?.find(node => node.nodeKey === memberNode)
      if(nodeExists && !selectedNeighborhoods.includes(neighborhood)){
        selectedNeighborhoods.push(neighborhood)
      }
    })
  })
  let newNodeDataArray = nodeDataArray.concat(selectedNeighborhoods)
      const newFilterData = {
        nodeDataArray: newNodeDataArray,
        linkDataArray: linkDataArray,
      };
      return newFilterData;

}

export default nodeRadiusFilterUtil;
