/**
 * @summary NavBar.js
 * @file Navigation component that renders links based on mapping either menuItems or c3MenuItems
 * @returns {JSX}
 * @usedBy layout.jsx
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import { useSelector } from 'react-redux';
import menuItems from './menuItems.json';
import c3MenuItems from './c3MenuItems.json';
import Tab from './Tab';

const NavBar = () => {
  const user = useSelector((store) => (store.authReducer.userObj));

  const navItems = user.mode === 'Learning Maps' ? menuItems : c3MenuItems;

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-primary">
      <div className="container-fluid">
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {navItems.map((item, $index) => Tab(item, $index, user))}
          </ul>
        </div>

        {/* Hamburger menu icon */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
      </div>
    </nav>
  );
};

export default NavBar;
