/**
 * @summary MetadataActions.js
 * @file Metadata Actions
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios';
import {
  LOAD_METADATA,
  LOAD_CONTENT_CODES,
  CLEAR_CONTENT_CODES
} from './MetadataType';

/**
 * @param {JsonObject} metadataDetails
 *
 * @summary
 * This redux action will be called for load the messages in to store
 */
export const loadMetadata = (metadataDetails) => ({
  type: LOAD_METADATA,
  metadataDetails
});

/**
 *
 * @param {string} assessmentProgramId
 *
 * @summary
 * This redux action will call dispatch another action using thunk middle-ware
 *
 * Before dispatching will retrieve data from the node endpoint.
 */
export const retrieveMetadata = (assessmentProgramId) => (dispatch) => {
  if (assessmentProgramId !== undefined) {
    axios
      .get(`/api/metadatadetails/${assessmentProgramId}`)
      .then((response) => {
        const metadataDetails = response.data;
        dispatch(loadMetadata(metadataDetails));
      })
      .catch((error) => {
        if (error?.response?.data) {
          console.log(err);
        }
      });
  }
};

export const loadContentCodes =
  (subjectId, projectId, branchId, objectType) => async (dispatch) => {
    const { data } = await axios.get(
      `/api/subject/${subjectId}/project/${projectId}/branch/${branchId}/objectType/${objectType}/content_codes`
    );
    if (data) {
      dispatch({
        type: LOAD_CONTENT_CODES,
        selectedContentCodes: [data]
      });
    }
  };

export const clearContentCodes = () => ({ type: CLEAR_CONTENT_CODES });
