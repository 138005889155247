/**
 * @summary BranchListGrid.js
 * @file Component provides a grid layout for active branches and passes down inactive branches to a child component which also provides those branches a grid layout
 * @returns {JSX}
 * @usedBy BranchListPage.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import KendoGridBase from '../../../shared/ui/kendoGridBase/KendoGridBase';
import { getBranchesDetails } from '../../../store/branchesDetails/branchesDetailsActions';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Accordion from '../../../shared/accordian/Wrapper';
import store from '../../../store/store';
import { updateUser } from '../../../store/auth/AuthActions';
import Panel from '../../../shared/accordian/Panel';
import InactiveSettings from '../inactiveBranches/inactiveSettings';
import PropTypes from 'prop-types';
import MergeConfirmation from '../modals/MergeModal';

const BranchListGrid = ({
  gridState,
  setGridState,
  inactiveGridState,
  setInactiveGridState,
  updateGridState
}) => {
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const [, setLoading] = useState(false);
  const [, setSkipProcessing] = useState(0);
  const [selectedRowsStateArray, setSelectedStateArray] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [mergeComplete, setMergeComplete] = useState(false);
  const [value, setValue] = React.useState(0);

  const messageOptions = {
    messsageType: {
      success: 'Success'
    },
    messages: {
      successOnDeactivate: 'Branch Deactivated',
      underConstruction: 'This Function is Under Construction'
    }
  };
  
  function useInterval(ms) {
    const intervalRef = React.useRef(null);
    let val = 0;
    const start = React.useCallback(() => {
      if (intervalRef.current !== null) {
        return;
      }
      intervalRef.current = setInterval(() => {
        if (val < 100) {
          setValue((v) => v + 1);
          val = val + 1;
        }
      }, ms);
    }, []);
    const stop = React.useCallback(() => {
      if (intervalRef.current === null) {
        return;
      }
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }, []);
    const reset = React.useCallback(() => {
      setValue(0);
      val = 0;
    }, []);
    return {
      value,
      start,
      stop,
      reset,
    };
  }
  
  useEffect(() => {
    if (value > 98 && isLoading && mergeComplete) {
    setShowModal(false);
    setIsLoading(false);
    }
  }, [value])

  const handlePageChange = (e) => {
    e.page.skip = isNaN(e.page.skip) ? 1 : e.page.skip;
    setPage(e.page);
  };

  const handleGridFilterChange = (colFilter) => {
    setFilter(colFilter || {});
  };

  const handleGridSortChange = (obj) => {
    setSort(obj);
  };

  const userObj = useSelector((state) => state.authReducer.userObj);
  const selectedProject = useSelector(
    (state) => state.authReducer.userObj.selectedProject[0]
  );

  const selectionChange = (e) => {
    const data = gridState.branchcollections.data.map((collection) => {
      // Adds Selected Data to an Array to Control Button Visibility
      if (collection === e.dataItem) {
        if (selectedRowsStateArray.some((item) => item === collection)) {
          setSelectedStateArray([]);
          collection.selected = false;
        } else {
          setSelectedStateArray([collection]);
          collection.selected = true;
        }
      } else {
        collection.selected = false;
      }
      return collection;
    });

    const gridObjs = { ...gridState.branchcollections };
    gridObjs.data = data;
    setGridState({ ...gridState, branchcollections: gridObjs });
  };
  
  const deactivateBranchHandler = () => {
    setIsLoading(true);
    axios
    .put(
      `/api/${selectedProject.id}/branchDetails/${selectedRowsStateArray[0].id}/deactivateBranch`
    )
    .then((res) => {
      if (res.status === 201) {
        if (res.data.message_type != 'success') {
          toast.error('An unexpected error occurred deactivating branch');
        } else {
          const publishedBranch = gridState.branchcollections.data.find(
            (br) => br.name === 'published'
          );
          const newUserData = { ...userObj };
          newUserData.selectedBranch = [publishedBranch];
          axios
            .post('/api/saveUserPreference', newUserData)
            .then(() => {
              updateGridState();
              setSelectedStateArray([]);
              store.dispatch(updateUser(newUserData));
              toast.success(messageOptions.messages.successOnDeactivate);
              setMergeComplete(true)
            })
            .catch((err) => console.log(err));
        }
      } else {
        toast.error('An unexpected error occurred deactivating branch');
      }
    })
    .catch((err) => {
      toast.error('An unexpected error occurred deactivating branch');
    });
  }
  
  const mergeBranchHandler = () => {
    setIsLoading(true);
    axios
    .put(`/api/${selectedProject.id}/mergeBranch/${selectedRowsStateArray[0].id}`, {
      message: `Merged branch ${selectedRowsStateArray[0].name} into published`
    })
    .then((res) => {
      if (res.status === 200) {
        if (res.data.message_type != 'success') {
          toast.error('An unexpected error occurred merging changes.');
        } else {
          const publishedBranch = gridState.branchcollections.data.find(
            (br) => br.name === 'published'
          );
          const newUserData = { ...userObj };
          newUserData.selectedBranch = [publishedBranch];
          axios
            .post('/api/saveUserPreference', newUserData)
            .then(() => {
              updateGridState();
              setSelectedStateArray([]);
              store.dispatch(updateUser(newUserData));
              toast.success('Branch merged.');
              setMergeComplete(true)
            })
            .catch((err) => console.log(err));
        }
      } else {
        toast.error('An unexpected error occurred merging changes.');
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error('An unexpected error occurred merging changes.');
    });
  }

  const actionHandler = (action, selectedRow) => {
    if (action === 'edit' || action === 'doubleclick') {
      return toast.success(messageOptions.messages.underConstruction);
    }
    if (action === 'deactivate') {
      if (selectedRow.branchStatus === 'draft') {
        setModalType('deactivate');
        setShowModal(true);
      }
    }
    if (action === 'merge') {
      if (selectedRow.branchStatus === 'draft') {
        setModalType('merge');
        setShowModal(true);
      }
    }
  };

  const onRowDoubleClick = (e) => {
    actionHandler('doubleclick', e);
  };

  useEffect(() => {
    setLoading(true);
    setLoading(false);
    setSkipProcessing(page.skip);
  }, [filter, sort, page]); // eslint-disable-line

  return (
    <>
      <div
        style={{
          margin: '16px',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        <MergeConfirmation
          isLoading={isLoading}
          showModal={showModal}
          setShowModal={setShowModal}
          deactivateFn={deactivateBranchHandler}
          modalType={modalType}
          mergeFn={mergeBranchHandler}
          useInterval={useInterval}
        />
        <KendoGridBase
          data={gridState.branchcollections.data || []}
          gridColumns={gridState.gridDynamicColumns}
          setGridFilters={handleGridFilterChange}
          setGridSort={handleGridSortChange}
          updateGridData={() => {}}
          onSelectionChange={selectionChange}
          onRowSingleClick={selectionChange}
          onRowDoubleClick={onRowDoubleClick}
          onPageChange={handlePageChange}
          sorter={sort}
          rowHeight={40}
          skip={page.skip}
          take={page.take}
          total={
            gridState.branchcollections ? gridState.branchcollections.total : 0
          }
          pageSize={100}
          selectable="selected"
          disableMultiSelect={true}
          pageable
          sortable
        />
        <div className="container-fluid p-0 d-flex justify-content-between mt-2">
          <div className="d-flex justify-content-between">
            <div>
              <button
                className="btn btn-danger btn-sm text-white"
                type="button"
                onClick={() => {
                  actionHandler(
                    'deactivate',
                    selectedRowsStateArray[selectedRowsStateArray.length - 1]
                  );
                }}
                disabled={
                  !selectedRowsStateArray.length ||
                  selectedRowsStateArray.length > 1 ||
                  selectedRowsStateArray[selectedRowsStateArray.length - 1]
                    .branchStatus != 'draft' ||
                  selectedRowsStateArray[selectedRowsStateArray.length - 1]
                    .name === 'edit' ||
                  selectedRowsStateArray[selectedRowsStateArray.length - 1]
                    .name === 'edit_draft'
                }
              >
              
                <i className="bi bi-file-minus me-2" />
                Deactivate Branch
              </button>
            </div>
            <div>
              <button
                className="btn btn-primary btn-sm text-white ms-1"
                type="button"
                onClick={() => {
                  actionHandler(
                    'merge',
                    selectedRowsStateArray[selectedRowsStateArray.length - 1]
                  );
                }}
                disabled={
                  !selectedRowsStateArray.length ||
                  selectedRowsStateArray.length > 1 ||
                  selectedRowsStateArray[selectedRowsStateArray.length - 1]
                    .branchStatus != 'draft'
                }
              >
                Merge Branch
              </button>
            </div>
          </div>

          {/* TODO add this button in next iteration */}
          {/* <div>
          <button
            className="btn btn-primary btn-sm me-2"
            type="button"
            onClick={() => {
              actionHandler('edit', selectedRowsStateArray[(selectedRowsStateArray.length - 1)]);
            } }
            disabled={!selectedRowsStateArray.length || selectedRowsStateArray.length > 1}
          >
            <i className="bi bi-pencil me-2" />
            Edit Branch Name
          </button>
        </div> */}
        </div>
      </div>
      <br />
      <br />
      <br />

      <Accordion>
        <Panel
          title="Inactive Branches"
          help_code={'INACTIVE'}
          panel_type={'project_settings'}
          body={
            <InactiveSettings
              gridState={inactiveGridState}
              setGridState={setInactiveGridState}
              updateGridState={updateGridState}
            />
          }
        />
      </Accordion>
    </>
  );
};

BranchListGrid.propTypes = {
  getBranchesDetails: PropTypes.func,
  setGridState: PropTypes.func,
  gridState: PropTypes.object,
  setInactiveGridState: PropTypes.func,
  inactiveGridState: PropTypes.object,
  updateGridState: PropTypes.func
};

export default connect(null, { getBranchesDetails })(BranchListGrid);
