/**
 * @summary SnapshotComparisonReturn.js
 * @file Component that provides a layout/view for the results after the report is generated
 * @returns {JSX}
 * @usedBy GenerateReport.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, {useRef} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import { PDFExport } from "@progress/kendo-react-pdf";

// LIST COMPONENTS
import SnapshotSummary from './SnapshotSummary'

const SnapshotComparisonReturn = ({reportData}) => {
  const pdfRef = useRef(null);
  
  const exportReport = () => {
    if (pdfRef.current) {
      pdfRef.current.save();
    }
  }

  return(
    <PDFExport
      ref={pdfRef}
      paperSize="auto"
      margin={40}
      fileName={`Snapshot Comparison Report for ${new Date().getFullYear()}`}
    >
      {/* //////////////////////////////////// */}
      {/* LIST OF ALL CHANGES WITH TIME STAMPS */}
      {/* //////////////////////////////////// */}
      <Row>
        <div className='row'>
            <div className='generated-report-header'>
              Snapshot Comparison Report
            </div>
            <div className='generated-report-subhead'>
              {reportData.branchA.name} <span style={{color: "#1560b7"}}>VS</span> {reportData.branchB.name} 
                
              
            </div>
            <div className='generated-report-button-container'>
              {
                <button
                  type="button"
                  className="download-report-button"
                  onClick={exportReport}
                  style={{fontSize: 18}}
                  // disabled={!createReport}
                >
                  Download Report
                </button>
              }
          </div>
        </div>
      </Row>
      <Row>
          <SnapshotSummary 
            comparisonLogsObj={reportData.comparisonLogsObj}
          />
      </Row>

    </PDFExport>
  )
}

export default SnapshotComparisonReturn;

SnapshotComparisonReturn.propTypes = {
  reportData: PropTypes.object,
  branchId: PropTypes.number,
}
  