/**
 * @summary RadiusAndDiameterInputs.js
 * @file Input options for Radius-and-Diameter Report, which returns all nodes within a user's specified radius of connections.
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import store from 'store/store';
import PropTypes from 'prop-types';
import { getViews } from 'store/views/ViewActions'
import axios from 'axios'
import {
    NumericTextBox 
  } from "@progress/kendo-react-inputs";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
// IMPORTED COMPONENTS: Autosuggest for NodeKey Input
import NodeKeyAutosuggest from '../reportingUtils/NodeKeyAutosuggest'
import { useNavigate } from 'react-router-dom';

const RadiusAndDiameterInputs = ({
    generateReport,
    project,
    userObj,
}) => {
    let navigate = useNavigate()
    // USE SELECTOR: Pulls branch and project data from Redux
    const branches = useSelector((state) => {
        const branchesList = { ...state.branchesDetailsReducer.activeBranches };
        delete branchesList?.error;
        delete branchesList?.selectedData;
        return Object.values(branchesList).filter(Boolean);
    }, shallowEqual)
    
    const views = useSelector((state) => {
        const views = { ...state.viewReducer };
        delete views?.selectedData;
        return Object.values(views).filter((view) => view.isActive)
    }, shallowEqual)

    // USE EFFECT: Gets VIews for the Use Selector (for Menus)
    useEffect(() => {
        store.dispatch(getViews(userObj.selectedProject[0].id))
    }, [])

    // PRIMARY STATE HOOKS //
    // Map Dropdown States (Full and Selected)
    const [mapDropdownOptions, setMapDropdownOptions] = useState([])
    const [selectedMap, setSelectedMap] = useState(null)
    // Node Key Selector States used in AutoSuggest
    const [allNodeKeysAvailable, setAllNodeKeysAvailable] = useState([])
    const [nodeKeySuggestions, setNodeKeySuggestions] = useState([])
    const [selectedNodeKey, setSelectedNodeKey] = useState(null)
    // Report Data: Radius Number
    const [radius, setRadius] = useState(1)
    /////////////////////////////////////////////////////////
    // BUILDS MAP DROPDOWN OPTIONS WITH IDENTIFYING VALUES //
    /////////////////////////////////////////////////////////
    useEffect(() => {
        setSelectedNodeKey(null)
        if(branches.length > 0 || views.length > 0){
            let branchOptions = branches.map(branch => {
                if(branch.name === "published"){
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        menuName: "Published Branch",
                        name: branch.name,
                        type: "published branch"
                    }
                    return branchObj
                } else {
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        menuName: "Branch: " + branch.name,
                        name: branch.name,
                        type: "branch"
                    }  
                    return branchObj
                }
            })
            let viewOptions = views.map(view => {
                let viewObj = {
                    id: view.id,
                    menuId: "view" + view.id.toString(),
                    menuName: "View: " + view.name,
                    name: view.name,
                    type: "view"
                }
                return viewObj
            })
            let mapOptions = branchOptions.concat(viewOptions)
            mapOptions.unshift({
                id: 0,
                menuId: 0,
                menuName: "Select Map Branch or View",
                name: "placeholder",
                type: "placeholder"
            })
            setMapDropdownOptions(mapOptions)
            setRadius(1)
        } else {
            setMapDropdownOptions([])
            setRadius(1)
        }
    }, [project.id])
    ///////////////////////////////////////////////////////
    // SELECTS MAP FROM DROPDOWN OPTIONS: Branch or View //
    ///////////////////////////////////////////////////////
    const handleMapSelection = (selectedMenuId) => {
        if(selectedMenuId !== "0"){
            let selection = mapDropdownOptions.find(branch => branch.menuId === selectedMenuId)
            setSelectedMap(selection)
            setRadius(1)
        } else {
            setSelectedMap(null)
            setAllNodeKeysAvailable([])
            setRadius(1)
        }
    }
    ///////////////////////////////////////////////////////////////////
    // Axios Request for Autosuggest Nodes Based on Branch Selection //
    ///////////////////////////////////////////////////////////////////
    useEffect(() => {
        if(selectedMap !== null){
            if(selectedMap.type === "branch" || selectedMap.type === "published branch"){
                setAllNodeKeysAvailable(null)
                axios.get(`/api/projects/${project.id}/branch/${selectedMap.id}/nodes`).then(nodes => {
                    const branchNodes = nodes.data.filter(node => node.branchId === selectedMap.id)
                    setAllNodeKeysAvailable(branchNodes)
                })
            } else if(selectedMap.type === "view") {
                setAllNodeKeysAvailable(null)
                axios.get(`/api/projects/${project.id}/views/${selectedMap.id}`).then(selectedView => {
                    setAllNodeKeysAvailable(selectedView.data.savedNodes)
                })
            }   
        }
    }, [selectedMap])
    //////////////////////////////////////////
    // FINAL RETURN OBJ: Sent to ReportPage //
    //////////////////////////////////////////
    const onClickRadiusAndDiameterCheck = () => {
        const sendData = {
            project, 
            branchSelection: selectedMap,
            radius,
            selectedNodeKey,
        }
        generateReport(sendData)
    }

    useEffect(() => {
        if(allNodeKeysAvailable !== null){
            setSelectedNodeKey(null)
            setNodeKeySuggestions([])
            if(allNodeKeysAvailable.length === 0 && selectedMap !== null){
                toast.error('There are no nodes in the map you selected.')
            }
        }
    }, [allNodeKeysAvailable])

    function canvasRedirect(){
        navigate('/canvas')
    }

        
    return(
        <Row>
            <Col>
                <div className='reporting-input-section'>
                    <div className='reporting-instructions'>
                        <div className="selected-report-header">
                            {'Radius and Diameter Report: ' + project.name}
                        </div>
                        <div>
                            <p>This report will list all the nodes connected to a source node entered below - within a radius of connections you specify below.</p>
                            <p>Radius refers to the number of connections outward from the source node that you want to count in your collection of nodes. Entering a radius of 3 would return all the nodes within three levels of connections.</p>
                            <p>You can see a visual example of this report using the radius filter on the <strong style={{color: "#1560b7", cursor: "pointer"}} onClick={canvasRedirect}>Canvas Page</strong>, which is a direct reflection of this report.</p>
                        </div>
                    </div>
                    <div className='reporting-input-menus-container'>
                        <Row>
                            <div className='col-3'>
                                <div className="reporting-option-header">
                                    Select a Map Option
                                </div>
                                <select
                                    className="form-select form-select-sm"
                                    aria-label=".form-select-sm example"
                                    value={selectedMap?.menuId}
                                    style={{width: '90%', height: '37px', marginLeft: '-2px'}}
                                    onChange={(e) => {
                                        handleMapSelection(e.target.value)
                                    }}
                                    name="mapSelection"
                                >       
                                    {mapDropdownOptions.map((map) => (
                                    <option key={map.menuId} value={map.menuId}>
                                        {map.menuName}
                                    </option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-3'>
                                <div className="reporting-option-header">
                                    Enter Source Node
                                </div>
                                {/* NODE KEY AUTOSUGGEST */}
                                <NodeKeyAutosuggest
                                    selectedNodeKey={selectedNodeKey}
                                    allNodeKeysAvailable={allNodeKeysAvailable}
                                    setSelectedNodeKey={setSelectedNodeKey}
                                    setNodeKeySuggestions={setNodeKeySuggestions}
                                    nodeKeySuggestions={nodeKeySuggestions}
                                />
                            </div>
                            <div className='col-3'>
                                <div className="reporting-option-header">
                                    Enter Radius
                                </div>
                                <input
                                    className='histogram-counter'                                    
                                    type="number"
                                    name="max_connections"
                                    max={5}
                                    min={1}
                                    value={radius}
                                    onChange={(e) => {
                                        setRadius(e.target.value)
                                    }}
                                />
                                {/* <NumericTextBox
                                    max={5}
                                    min={1}
                                    className='numeric-text-box'
                                    value={radius}
                                    onChange={(e) => {
                                        setRadius(e.target.value)
                                    }}
                                /> */}
                            </div>  
                            
                        </Row>
                    </div>
                    <Row>
                        <div className='col-2'>
                            <div className='generate-report-button-container'>
                                <button
                                    type="button"
                                    className="k-button k-primary generate-report-button"
                                    onClick={onClickRadiusAndDiameterCheck}
                                    disabled={!selectedMap || !selectedNodeKey || !radius}
                                >
                                    Generate Report
                                </button>
                            </div>
                        </div>
                        <div className='col-10'>
                            <div className='map-options-fine-print-container'>
                                <p className="map-options-fine-print">
                                    {`Map options are limited to the project you have selected under your Profile Settings in the upper right corner. You currently have "` + project.name + `" selected.`}
                                </p>
                            </div>
                        </div>
                    </Row>
                </div>
            </Col>
        </Row>
    )
}

export default RadiusAndDiameterInputs;

RadiusAndDiameterInputs.propTypes = {
    generateReport: PropTypes.func,
    setMapType: PropTypes.func,
    mapType: PropTypes.any,
    project: PropTypes.obj,
    branches: PropTypes.array,
    views: PropTypes.array,
    userObj: PropTypes.obj
};