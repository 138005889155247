/**
 * @summary conflictResolution.js
 * @file Component provides a layout for conflict resolutions for branchs that have merge conflicts
 * @returns {JSX}
 * @usedBy routes.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import Accordion from '../../shared/accordian/Wrapper';
import Panel from '../../shared/accordian/Panel';
import ResolutionForm from './resolutionForm';

const ConflictResolution = () => {
  //not sure this will be how it works long term
  const [nodeIssues, setNodeIssues] = useState([1]);
  const [nodeResolved, setNodeResolved] = useState(0);
  const [nodeConflicts, setNodeConflicts] = useState([
    { id: 1, name: 'name', source: 'node 001', destination: 'ELA 122' },
    {
      id: 1,
      name: 'description',
      source: 'a new desc',
      destination: 'other desc'
    }
  ]);
  const [connectionIssues, setConnectionConflicts] = useState([
    {
      id: 1,
      name: 'conn name',
      source: 'connection 001',
      destination: 'ELA 122'
    },
    {
      id: 1,
      name: 'description',
      source: 'a new desc',
      destination: 'other desc'
    }
  ]);
  const [neighborhoodIssues, setNeighborhoodConflicts] = useState([
    {
      id: 1,
      name: 'neigh name',
      source: 'neighborhood 001',
      destination: 'ELA 122'
    },
    {
      id: 1,
      name: 'description',
      source: 'a new desc',
      destination: 'other desc'
    }
  ]);

  // fetch requests to get branches, and diffs
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-5 col-md-5">
            <label className="form-label">Branch:</label>
            <label className="form-label">CURRENT BRANCH NAME</label>
          </div>
          <div className="col-md-4">
            <label className="form-label">Last Saved:</label>
            <label className="form-label">SAVED TIME</label>
          </div>
          <div className="col-md-2">
            <label className="form-label">Branches:</label>
            <label className="form-label">NUM BRANCHES</label>
          </div>
          <div className="col-md-1 hstack gap-2 justify-content-end">
            <button type="button" className="btn btn-primary btn-sm" onClick>
              <i className="bi bi-x"></i> Close
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div className="col pt-1 pb-1 hstack gap-2" />
      <div className="row">
        <div className="col-sm-8 col-md-8">
          <label className="form-label">Merge Branches:</label>
        </div>
        <div className="col-4 hstack gap-2 justify-content-end">
          <button type="button" className="btn btn-primary btn-sm" onClick>
            <i className="bi bi-x"></i> Commit
          </button>
          <button type="button" className="btn btn-primary btn-sm" onClick>
            <i className="bi bi-x"></i> Cancel
          </button>
        </div>
      </div>
      <div>
        <Accordion>
          {nodeIssues.length > 0 && (
            <Panel
              title={`Nodes ${nodeIssues.length} conflicts ${nodeResolved} resolved`}
              help_code={'NODE'}
              panel_type={'Nodes'}
              body={<ResolutionForm conflicts={nodeConflicts} />}
            />
          )}

          {connectionIssues.length > 0 && (
            <Panel
              title={`Connections ${nodeIssues.length} conflicts ${nodeResolved} resolved`}
              help_code={'CONNECTION'}
              panel_type={'Connections'}
              body={<ResolutionForm conflicts={nodeConflicts} />}
            />
          )}

          {neighborhoodIssues.length > 0 && (
            <Panel
              title={`Neighborhood ${nodeIssues.length} conflicts ${nodeResolved} resolved`}
              help_code={'NEIGHBORHOOD'}
              panel_type={'Neighborhoods'}
              body={<ResolutionForm conflicts={nodeConflicts} />}
            />
          )}
        </Accordion>
      </div>
    </>
  );
};

export default ConflictResolution;
