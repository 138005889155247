/**
 * @summary NewPw.jsx
 * @file Allows user to input a new password for login
 * @returns {JSX}
 * @usedBy routes.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import { NewPassword } from 'ats-react-login';
import { useNavigate, useLocation } from 'react-router';
import { toast } from 'react-toastify';

export default function NewPw() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <NewPassword
      logo={process.env.PUBLIC_URL + '/kite-logo.svg'}
      onSubmit={(event, newPassword) => {
        // get email, oldPassword entered on the login page from navigate
        let email = location.state.email;
        let oldPassword = location.state.oldPassword;

        // call API to create new password
        const response = UserRepository.newPassword({
          userAttributes: { email },
          oldPassword,
          newPassword
        });
        // handle error
        if (response.status === 'error') {
          return toast.error(
            'Unsuccessful new password request. Please try again.'
          );
        }

        toast.success(
          'New password created successfully. Please login using new password.'
        );
        navigate('/login');
      }}
    />
  );
}
