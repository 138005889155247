/**
 * @summary GlobalFilterMainMenu.js
 * @file CanvasFilter.js Coomponent: Controls All Global Filter Functionality - Selected by User in CanvasFilter.ks
 * @returns {JSX}
 * @usedBy CanvasFilter.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as React from 'react';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';

const GlobalFilterMainMenu = ({
    renderMapData,
    globalKeywordInput,
    handleFilterData,
}) => {

    // Primary Checkbox States
    const [keywordInput, setKeywordInput] = useState(null)
    const [nodeKeysChecked, setNodeKeysChecked] = useState(false)
    const [nodeNamesChecked, setNodeNamesChecked] = useState(false)
    const [nodeDescriptionsChecked, setNodeDescriptionsChecked] = useState(false)
    const [nodeAttributes, setNodeAttributes] = useState(false)
    const [includeNeighborhoods, setIncudeNeighborhoods] = useState(true)
    const [includeConnections, setIncludeConnections] = useState(true)

    const [neighborhoodKeysChecked, setNeighborhoodKeysChecked] = useState(false)
    const [neighborhoodNamesChecked, setNeighborhoodNamesChecked] = useState(false)
    const [neighborhoodDescriptionsChecked, setNeighborhoodDescriptionsChecked] = useState(false)
    const [neighborhoodAttributesChecked, setNeighborhoodAttributesChecked] = useState(false)
    const [includeNodes, setIncudeNodes] = useState(false)

    const [connectionKeysChecked, setConnectionKeysChecked] = useState(false)
    const [connectionNamesChecked, setConnectionNamesChecked] = useState(false)
    
    useEffect(()=>{
      if(renderMapData.filterType === "Global Filter"){
        setNodeKeysChecked(renderMapData.globalData.nodes.keys)
        setNodeNamesChecked(renderMapData.globalData.nodes.names)
        setNodeDescriptionsChecked(renderMapData.globalData.nodes.descriptions)
        setIncudeNeighborhoods(renderMapData.globalData.nodes.includeNeighborhoods)
        setIncludeConnections(renderMapData.globalData.nodes.includeConnections)
        setNeighborhoodKeysChecked(renderMapData.globalData.neighborhoods.keys)
        setNeighborhoodNamesChecked(renderMapData.globalData.neighborhoods.names)
        setNeighborhoodDescriptionsChecked(renderMapData.globalData.neighborhoods.descriptions)
        setIncudeNodes(renderMapData.globalData.neighborhoods.includeNodes)
        setConnectionKeysChecked(renderMapData.globalData.connections.keys)
        setConnectionNamesChecked(renderMapData.globalData.connections.names)
      }
    },[renderMapData])

    function handleKeywordInput(e){
      setKeywordInput(e.target.value)
    }

    function handleCheckboxOptions(e){
      if(e.target.value === "Node Keys"){
        setNodeKeysChecked(!nodeKeysChecked)
      }
      
      if (e.target.value === "Node Names"){
        setNodeNamesChecked(!nodeNamesChecked)
      }
      
      if (e.target.value === "Node Descriptions"){
        setNodeDescriptionsChecked(!nodeDescriptionsChecked)
      }

      if (e.target.value === "Node Attributes"){
        setNodeAttributes(!nodeAttributes)
      }
      
      if (e.target.value === "Include Neighborhoods"){
        setIncudeNeighborhoods(!includeNeighborhoods)
      }
      
      if (e.target.value === "Include Connections"){
        setIncludeConnections(!includeConnections)
      }
      
      if (e.target.value === "Neighborhood Keys"){
        setNeighborhoodKeysChecked(!neighborhoodKeysChecked)
      }
      
      if (e.target.value === "Neighborhood Names"){
        setNeighborhoodNamesChecked(!neighborhoodNamesChecked)
      }
      
      if (e.target.value === "Neighborhood Descriptions"){
        setNeighborhoodDescriptionsChecked(!neighborhoodDescriptionsChecked)
      }

      if (e.target.value === "Neighborhood Attributes"){
        setNeighborhoodAttributesChecked(!neighborhoodAttributesChecked)
      }
      
      if (e.target.value === "Include Nodes"){
        setIncudeNodes(!includeNodes)
      }
      
      if (e.target.value === "Connection Keys"){
        setConnectionKeysChecked(!connectionKeysChecked)
      } 
      
      if (e.target.value === "Connection Names"){
        setConnectionNamesChecked(!connectionNamesChecked)
      }
    }
    const globalFilterData = {
      keywordInput: keywordInput !== null ? !keywordInput.includes(`,`) ? keywordInput : keywordInput.trim().split(/\s*,\s*/)
      : globalKeywordInput,
      nodes: { 
        keys: nodeKeysChecked,
        names: nodeNamesChecked,
        descriptions: nodeDescriptionsChecked,
        attributes: nodeAttributes,
        includeNeighborhoods: includeNeighborhoods,
        includeConnections: includeConnections,
      },
      neighborhoods: {
        keys: neighborhoodKeysChecked,
        names: neighborhoodNamesChecked,
        descriptions: neighborhoodDescriptionsChecked,
        attributes: neighborhoodAttributesChecked,
        includeNodes: includeNodes,
      },
      connections: {
        keys: connectionKeysChecked,
        names: connectionNamesChecked, 
      }
    }

    useEffect(() => {
        // Nodes
        globalFilterData.nodes.keys = nodeKeysChecked;
        globalFilterData.nodes.names = nodeNamesChecked;
        globalFilterData.nodes.descriptions = nodeDescriptionsChecked;
        globalFilterData.nodes.includeNeighborhoods = includeNeighborhoods;
        globalFilterData.nodes.includeConnections = includeConnections;
        // Neighborooods
        globalFilterData.neighborhoods.keys = neighborhoodKeysChecked;
        globalFilterData.neighborhoods.names = neighborhoodNamesChecked;
        globalFilterData.neighborhoods.descriptions = neighborhoodDescriptionsChecked;
        globalFilterData.neighborhoods.attributes = neighborhoodAttributesChecked;
        globalFilterData.neighborhoods.includeNodes = includeNodes;
        // Connections
        globalFilterData.connections.keys = connectionNamesChecked;
        globalFilterData.connections.names = connectionNamesChecked;

        handleFilterData(globalFilterData)
    },[nodeKeysChecked, nodeNamesChecked, nodeDescriptionsChecked, nodeAttributes, includeNeighborhoods, includeConnections, 
      neighborhoodKeysChecked, neighborhoodNamesChecked, neighborhoodDescriptionsChecked, includeNodes, 
      connectionKeysChecked, connectionNamesChecked])

    useEffect(() => {
        globalFilterData.keywordInput = keywordInput !== null ? !keywordInput.includes(`,`) ? keywordInput : keywordInput.trim().split(/\s*,\s*/)
        : globalKeywordInput
        handleFilterData(globalFilterData)
    },[keywordInput])

    return(
      <>
        <div className="row">
          
          {/* ENTER CONTENT COLUMN */}
          <div className="col-12">
            <div className="global-filter-keyword-section">
                <div className="col-12">
                  <label className="global-filter-primary-title" htmlFor="filterContext">
                    Enter a Keyword or Phrase to Search
                  </label>
                </div>
                <div className="row">
                  <div className='col-12'>
                    <input 
                      className="global-filter-input" 
                      placeholder="Enter a keyword or phrase, and check any parameters below you want to inspect..."
                      value={keywordInput}
                      onChange={handleKeywordInput}
                    />
                  </div>
                </div>
            </div>
          </div>
          
          {/* FILTER RADIUS COLUMN */}
          {/* When the filter element is "node," the user must enter a radius. This determines how many connections outward from the target node we should be collecting nodes for the filter */}
          {/* When the filter element is "neighborhood," this option is disabled */}
        </div>
        
        {/* Modal Titles */}
        <div className="row">
          <div className='col-12'>
            <div className='global-filter-primary-title'>
              Select Properties to Inspect for Keyword Matches 
            </div>
          </div>
          {/* Modal Content */}
          <div className="row">
            <div className='col-4'>
              <div className='col-12'>
                <div className='global-filter-secondary-title'>
                  Nodes
                </div>
              </div>
              <ul className="global-filter-properties">
                <li><input onChange={handleCheckboxOptions} checked={globalFilterData.nodes.keys} className="global-filter-checkbox" type="checkbox" id="node-keys" name="Node Keys" value="Node Keys"/><label htmlFor="Node Keys">Node Keys</label></li>
                <li><input onChange={handleCheckboxOptions} checked={globalFilterData.nodes.names} className="global-filter-checkbox" type="checkbox" id="node-names" name="Node Names" value="Node Names"/><label htmlFor="Node Names">Node Names</label></li>
                <li><input onChange={handleCheckboxOptions} checked={globalFilterData.nodes.descriptions} className="global-filter-checkbox" type="checkbox" id="node-descriptions" name="Node Descriptions" value="Node Descriptions"/><label htmlFor="Node Descriptions">Node Descriptions</label></li>
                <li><input onChange={handleCheckboxOptions} checked={globalFilterData.nodes.attributes} className="global-filter-checkbox" type="checkbox" id="node-attributes" name="Node Attributes" value="Node Attributes"/><label htmlFor="Node Attributes">Node Attributes</label></li>
                <li><input onChange={handleCheckboxOptions} checked={globalFilterData.nodes.includeNeighborhoods} className="global-filter-checkbox" type="checkbox" id="include-neighborhoods" name="Include Neighborhoods" value="Include Neighborhoods"/><label htmlFor="Include Neighborhoods">Include Neighborhoods</label></li>
                <li><input onChange={handleCheckboxOptions} checked={globalFilterData.nodes.includeConnections} className="global-filter-checkbox" type="checkbox" id="include-connections" name="Include Connections" value="Include Connections"/><label htmlFor="Include Connections">Include Connections</label></li>

              </ul>
            </div>
            <div className='col-4'>
              <div className='col-12'>
                <div className='global-filter-secondary-title'>
                  Neighborhoods
                </div>
              </div>
              <ul className="global-filter-properties">
                <li><input onChange={handleCheckboxOptions} checked={globalFilterData.neighborhoods.keys} className="global-filter-checkbox" type="checkbox" id="neighborhood-ids" name="Neighborhood IDs" value="Neighborhood Keys"/><label htmlFor="Neighborhood Keys">Neighborhood IDs</label></li>
                <li><input onChange={handleCheckboxOptions} checked={globalFilterData.neighborhoods.names} className="global-filter-checkbox" type="checkbox" id="neighborhood-names" name="Neighborhood Names" value="Neighborhood Names"/><label htmlFor="Neighborhood Names">Neighborhood Names</label></li>
                <li><input onChange={handleCheckboxOptions} checked={globalFilterData.neighborhoods.descriptions} className="global-filter-checkbox" type="checkbox" id="neighborhood-descriptions" name="Neighborhood Descriptions" value="Neighborhood Descriptions"/><label htmlFor="Neighborhood Descriptions">Neighborhood Descriptions</label></li>
                <li><input onChange={handleCheckboxOptions} checked={globalFilterData.neighborhoods.attributes} className="global-filter-checkbox" type="checkbox" id="neighborhood-attributes" name="Neighborhood Attributes" value="Neighborhood Attributes"/><label htmlFor="Neighborhood Attributes">Neighborhood Attributes</label></li>
                <li><input onChange={handleCheckboxOptions} checked={globalFilterData.neighborhoods.includeNodes} className="global-filter-checkbox" type="checkbox" id="include-nodes" name="Include Nodes" value="Include Nodes"/><label htmlFor="Include Nodes">Include Associated Nodes</label></li>
              </ul>
            </div>
            <div className='col-4'>
              <div className='col-12'>
                <div className='global-filter-secondary-title'>
                  Connections
                </div>
              </div>
              <ul className="global-filter-properties">
                <li><input onChange={handleCheckboxOptions} checked={globalFilterData.connections.keys} className="global-filter-checkbox" type="checkbox" id="connection-ids" name="Connection IDs" value="Connection Keys"/><label htmlFor="Connection Keys">Connection IDs</label></li>
                <li><input onChange={handleCheckboxOptions} checked={globalFilterData.connections.names} className="global-filter-checkbox" type="checkbox" id="connection-names" name="Connection Names" value="Connection Names"/><label htmlFor="Connection Names">Connection Names</label></li>

              </ul>
            </div>
            
          </div>
        </div>
      </>
    )
}

export default GlobalFilterMainMenu;

GlobalFilterMainMenu.propTypes = {
  renderMapData: PropTypes.obj,
  globalKeywordInput: PropTypes.string,
  handleFilterData: PropTypes.func,
};