/**
 * @summary InspectorTool.js
 * @file Modal for Containing Injuey Data for Selected Element(s). Opened through CanvasInterface.js After at least one Element is Selected
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../canvasPage.scss';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

const DeleteConnectionCanvasModal = ({
    selectedConnections,
    handleCrud,
    handleDeleteModalVisibility
  }) => {
    const userObj = useSelector((state) => state.authReducer.userObj);
    const connectionIds = selectedConnections.map(node => node.id)

    const checkBranchIds = (selectedConnections) => {
        let connectionStatus = selectedConnections.map(connection => {
            if(connection.branchId === userObj.selectedBranch[0].id){
                return { checkedOut: true, connection: connection }
            } else {
                return { checkedOut: false, connection: connection }
            }
        })
        if(connectionStatus.find(connection => connection.checkedOut === false)){
            return {checkedOut: false, connection: connectionStatus.find(connection => connection.checkedOut === false)?.connection}
        } else {
            return {checkedOut: true, connection: null}
        }
    }

    const handleDeleteConnection = () => {
        let branchCheck = checkBranchIds(selectedConnections)
        if(branchCheck.checkedOut){
            let saveJson = {
                action: 'delete',
                elementType: 'connection',
                source: 'Canvas Deleted',
                project: userObj.selectedProject[0],
                branch: userObj.selectedBranch[0],
                data: connectionIds
            }
            handleCrud(saveJson)
        } else {
            return toast.error(branchCheck.connection.name + " is not checked out.")
        }
    }

    return (
        <>
            <Modal.Header>
                <Modal.Title>
                    <div className='modal-header-text'>
                        Please Confirm
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5 className='text-center pt-3 pb-3'>
                    Are you sure you want to delete this Connection?
                </h5>
            </Modal.Body>
            <Modal.Footer>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6 text-center'>
                            <button 
                                className="btn btn-primary btn-sm text-white w-50 "
                                onClick={handleDeleteModalVisibility}
                            >
                                No
                            </button>
                        </div>
                        <div className='col-6 text-center'>
                            <button 
                                className="btn btn-danger btn-sm text-white w-50" 
                                onClick={handleDeleteConnection}
                            >
                                Yes
                            </button>
                        </div>             
                    </div>
                </div>
            </Modal.Footer>
        </>
    );
} 

DeleteConnectionCanvasModal.propTypes = {
  selectedConnections: PropTypes.array,
  handleCrud: PropTypes.func,
  handleDeleteModalVisibility: PropTypes.func
};

export default DeleteConnectionCanvasModal;