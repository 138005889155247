/**
 * @summary Metadata Reducer
 * @file MetadataReducer.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import {
  LOAD_METADATA,
  LOAD_CONTENT_CODES,
  CLEAR_CONTENT_CODES
} from './MetadataType';

const metadata = {};

/**
 * @summary
 * This redux reducer is used for load the metadata based on the action type
 *
 * @param {metadata} state
 * @param {reduxAction} action
 */
const MetadataReducer = (state = metadata, action) => {
  switch (action.type) {
    case LOAD_METADATA:
      const formattedData = action.metadataDetails.reduce((newObj, md) => {
        const camelName = md.metadata_type_name
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
        newObj[camelName] = md.metadata_info;
        return newObj;
      }, {});
      return {
        ...formattedData
      };

    case LOAD_CONTENT_CODES:
      return {
        ...state,
        selectedContentCodes: action.selectedContentCodes
      };

    case CLEAR_CONTENT_CODES:
      return {
        ...state,
        selectedContentCodes: []
      };

    default:
      return state;
  }
};
export default MetadataReducer;
