/**
 * @summary Schema for creating new Media
 * @file newMedia.schema.js
 * @returns {Schema}
 * @usedBy Application
 * @author Andrew Popp
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as yup from 'yup';
////////////////////////
// NEW NODE SCHEMA //
////////////////////////
const newMediaSchema = yup.array().of(
  yup.object().shape({
    extension: yup.string().required('Please select a file to upload.'),
    size: yup
      .number()
      .max(
        450000000,
        'This file is too large. Please compress your file to under 400 megabytes.'
      )
  })
);

export default newMediaSchema;
