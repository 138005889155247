/**
 * @summary SnapshotComparisonInputs.js
 * @file Input options for SnapShot Comparison Report, which will generate a list of differences between two branches selected by the user.
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react'
import store from 'store/store';
import { getViews } from 'store/views/ViewActions'
import { useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';

const SnapshotComparisonInputs = ({
    generateReport,
    project,
    userObj,
}) => {    
    const [mapDropdownOptions, setMapDropdownOptions] = useState([])

    // USE SELECTOR: Pulls branch and project data from Redux
    const branches = useSelector((state) => {
        const branchesList = { ...state.branchesDetailsReducer.activeBranches };
        delete branchesList?.error;
        delete branchesList?.selectedData;
        return Object.values(branchesList).filter(Boolean);
    }, shallowEqual)
    
    const views = useSelector((state) => {
        const views = { ...state.viewReducer };
        delete views?.selectedData;
        return Object.values(views).filter((view) => view.isActive)
    }, shallowEqual)

    // USE EFFECT: Gets VIews for the Use Selector (for Menus)
    useEffect(() => {
        store.dispatch(getViews(userObj.selectedProject[0].id))
    }, [])

    ///////////////////////////////////////////////////////////////
    // SNAPSHOTS A AND B REPRESEMT BRANCH ID'S IN THIS COMPONENT //
    ///////////////////////////////////////////////////////////////
    // SNAPSHOT A: Is set as "mapType" to be used as the Redux branch id
    // SNAPSHOT B: Is set as state below, and formatted into an axios getRequest 
    // BOTH: Will Return data objects for each selection that we compare in ReportPage.js
    const [selectedBranchA, setSelectedBranchA] = useState(null)
    const [selectedBranchB, setSelectedBranchB] = useState(null)

    useEffect(() => {
        if(branches.length > 0 || views.length > 0){
            let branchOptions = branches.map(branch => {
                if(branch.name === "published"){
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        formattedName: "Published Branch",
                        name: branch.name,
                        type: "published branch"
                    }
                    return branchObj
                } else {
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        formattedName: "Branch: " + branch.name,
                        name: branch.name,
                        type: "branch"
                    }  
                    return branchObj
                }
            })
            let viewOptions = views.map(view => {
                let viewObj = {
                    id: view.id,
                    menuId: "view" + view.id.toString(),
                    formattedName: "View: " + view.name,
                    name: view.name,
                    type: "view"
                }
                return viewObj
            })
            let mapOptions = branchOptions.concat(viewOptions)
            mapOptions.unshift({
                id: 0,
                menuId: 0,
                formattedName: "Select Map Branch or View",
                name: "placeholder",
                type: "placeholder"
            })
            setMapDropdownOptions(mapOptions)
            setSelectedBranchA(null)
            setSelectedBranchB(null)
        } else {
            setMapDropdownOptions([])
            setSelectedBranchA(null)
            setSelectedBranchB(null)
        }
    }, [project])

    const handleASelection = (selectedMenuId) => {
        if(selectedMenuId !== "0"){
            let selectionA = mapDropdownOptions.find(branch => branch.menuId === selectedMenuId)
            setSelectedBranchA(selectionA)  
        } else {
            setSelectedBranchA(null)
        }
    }

    const handleBSelection = (selectedMenuId) => {
        if(selectedMenuId !== "0"){
            let selectionB = mapDropdownOptions.find(branch => branch.menuId === selectedMenuId)
            setSelectedBranchB(selectionB)
        } else {
            setSelectedBranchA(null)
        }
    }

    const onClickCycleCheck = () => {
        let branchData = {
            project,
            branchA: selectedBranchA,
            branchB: selectedBranchB
        }
        generateReport(branchData)
    }

    let projectName = project.name;

    // REDIRECT LINKS //
    let navigate = useNavigate()
    function revisionsRedirect(){
        navigate('/revisions/branchlist')
    }

    return(
    <Row>
        <Col>
            <div className='reporting-input-section'>
                <div className='reporting-instructions'>
                    <div className='selected-report-header'>
                        {'Snapshot Comparison Report: ' + projectName}
                    </div>
                    <div>
                        <p>This report will generate a list of differences between two branches or views you select below. You can compare different branches, different views, and even compare a branch to a view.</p>
                        <p>
                            Branches and views are only associated with the data checked out to them, so comparing a branch or view to the Published Branch may result in a report full of mostly unique nodes and connections. 
                        </p>
                        <p>Be mindful that changes will not appear in the Published branch until you merge an edited branch 
                            through the <strong onClick={revisionsRedirect} style={{color: "#1560b7", cursor: "pointer"}}>Revisions</strong> page.  
                        </p>            
                    </div>
                </div>
                <div className='reporting-input-menus-container'>
                    <Row>
                        <div className='col-3'>
                            <div className="reporting-option-header">
                                Map Snapshot A
                            </div>
                            <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                value={selectedBranchA?.menuId}
                                style={{width: '90%'}}
                                onChange={(e) => {
                                    handleASelection(e.target.value)
                                }}
                                name="mapSelection"
                            >
                                {mapDropdownOptions.map((map) => (
                                <option key={map.menuId} value={map.menuId}>
                                    {map.formattedName}
                                </option>
                                ))}
                            </select>
                        </div>
                        <div className='col-3'>
                            <div className="reporting-option-header">
                                Map Snapshot B
                            </div>
                            <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                value={selectedBranchB?.menuId}
                                style={{width: '90%'}}
                                onChange={(e) => {
                                    handleBSelection(e.target.value)
                                }}
                                name="mapSelection"
                            >
                                
                                {mapDropdownOptions.map((map) => (
                                <option key={map.menuId} value={map.menuId}>
                                    {map.formattedName}
                                </option>
                                ))}
                            </select>
                        </div>
                        
                    </Row>
                </div>
                <Row>
                    <div className='col-2'>
                        <div className="generate-report-button-container">
                            <button
                                type="button"
                                className="k-button k-primary generate-report-button"
                                onClick={onClickCycleCheck}
                                disabled={selectedBranchA === null || selectedBranchB === null}
                            >
                                Generate Report
                            </button>
                        </div>
                    </div>
                    <div className='col-10'>
                        <div className='map-options-fine-print-container'>
                            <p className="map-options-fine-print">
                                {`Map options are limited to the project you have selected under your Profile Settings in the upper right corner. You currently have "` + projectName + `" selected.`}
                            </p>
                        </div>
                    </div>
                </Row>
            </div>
        </Col>
    </Row>
    )
}

export default SnapshotComparisonInputs;

SnapshotComparisonInputs.propTypes = {
    generateReport: PropTypes.func,
    setMapType: PropTypes.func,
    mapType: PropTypes.any,
    project: PropTypes.object,
    branches: PropTypes.array,
    views: PropTypes.array,
    userObj: PropTypes.obj
};