import { ADD_OBJECT, RESET_CANVAS_SELECTIONS, SET_OBJECTS, UPDATE_CANVAS_OPTIONS } from "./CanvasTypes";

export const addDataToCanvasSelection = (objType, obj) => ({
  type: ADD_OBJECT,
  obj,
  objType
})

export const resetCanvasSelection = () => ({
  type: RESET_CANVAS_SELECTIONS,
})

export const setCanvasSelections = (objType, objs) => ({
  type: SET_OBJECTS,
  objs,
  objType
})

export const updateCanvasOptions = (optionKey, optionValue) => ({
  type: UPDATE_CANVAS_OPTIONS,
  optionKey,
  optionValue
})