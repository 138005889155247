/**
 * @summary GenerateReport.jsx
 * @file Component serves as a container for all of the Return components
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

//////////////////////////////////////////////////////////////////
// GENERATE REPORTS COMPONENT: Container for RETURN Components. //
// This component's main purpose is to render one of the RETURN Components based on the "reportType" String and then display the "reportData" Object //
//////////////////////////////////////////////////////////////////

// MISC IMPORTS: Things that Give the App Its Elbow Grease
import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';

///////////////////////////////////////////////////////////
// RETURN COMPONENTS: Display the Formatted "reportData" //
///////////////////////////////////////////////////////////
import CycleCheckReturn from "./reportingReturns/CycleCheckReturn"
import RadiusAndDiameterReturn from './reportingReturns/RadiusAndDiameterReturns/RadiusAndDiameterReturn';
import ShortestPathReturn from './reportingReturns/shortestPathReturns/ShortestPathReturn'
import DegreeHistogramReturn from './reportingReturns/degreeHistogramReturns/DegreeHistogramReturn'
import ListComponentsReturn from './reportingReturns/listComponentReturns/ListComponentsReturn'
import ChangeLogReturn from './reportingReturns/changeLogReturns/ChangeLogReturn';
import ProfileCheckReturn from './reportingReturns/profileCheckReturns/ProfileCheckReturn';
import SnapshotComparisonReturn from './reportingReturns/snapshotComparisonReturns/SnapshotComparisonReturn';

// Container for All RETURN Components
const GeneratedReport = ({ reportData, reportType, }) => {

  ///////////////////////////////////////////////////////
  // REPORT EXPORTER: Incomplete - Need to Rework This //
  ///////////////////////////////////////////////////////
 const exportReport = () => {
    let textStr;
    if (reportType === 'Cycle Check') {
      textStr = Object.keys(reportData).map((category) => {
        return reportData[category].map((node) => (`${node.subject} - ${node.nodeKey} \n`))
      })
    } else if (reportType === 'Profile Check') {
      textStr = Object.keys(reportData).map((category) => {
        return reportData[category].map((node) => (`${startCase(node.flag)} - ${node.subject} - ${node.nodeKey} \n`))
      })
    } else {
      textStr = Object.keys(reportData).map((category) => {
        return reportData[category].map((node) => (`${node.nodeKey} \n`))
      })
    }
    const date = new Date();
    const element = document.createElement("a");
    const file = new Blob([textStr.flat().join('')], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = `${reportType}-${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
  ///////////////
  // RETURN(s) //
  ///////////////
  return (
    <div className='report'>
      
      <Row>
        {/* CHANGE LOG RETURN */}
        {reportType === 'Change Log' && (
          <ChangeLogReturn
            reportData={reportData}
            exportReport={exportReport}
          />
          )
        }
        {/* CYCLE CHECK RETURN */}
        {reportType === 'Cycle Check' && (
          <CycleCheckReturn 
            reportData={reportData}
          />
          )
        }
        {/* DEGREE HISTOGRAM RETURN */}
        {reportType === 'Degree Histogram' && (
          <DegreeHistogramReturn 
            reportData={reportData}
          />
          )
        }
        {/* LIST COMPONENTS RETURN */}
        {reportType === 'List Components' && (
          <ListComponentsReturn
            reportData={reportData}
          />
          )
        }
        {/* PROFILE CHECK RETURN */}
        {reportType === 'Profile Check' && (
          <ProfileCheckReturn 
            reportData={reportData}
          />
          )
        }
        {/* RADIUS AND DIAMETER RETURN */}
        {reportType === 'Radius and Diameter' && (
          <RadiusAndDiameterReturn 
            reportData={reportData}
          />
          )
        }
        {/* SHORTEST PATH RETURN */}
        {reportType === 'Shortest Path' && (
          <ShortestPathReturn 
            reportData={reportData}
          />
          )
        }{/* SNAPSHOT COMPARISON PATH RETURN */}
        {reportType === 'Snapshot Comparison' && (
          <SnapshotComparisonReturn 
            reportData={reportData}
          />
          )
        }
      </Row>
      
    </div>
  )
}

export default GeneratedReport;

GeneratedReport.propTypes = {
  reportData: PropTypes.object,
  dataItem: PropTypes.object,
  reportType: PropTypes.string,
}
