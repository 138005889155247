/**
 * @summary LinkageLevelNeighborhoodReducer.jsx
 * @file LinkageLevelNeighborhood Reducer
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import {
  GET_LINKAGE_LEVEL_NEIGHBORHOODS,
  CLEAR_LINKAGE_LEVELS_NEIGHBORHOODS
} from './LinkageLevelNeighborhoodTypes';

const linkageLevels = {
  linkageLevels: []
};

/**
 * @summary
 * This redux reducer is used for node CRUD based on the action type
 *
 * @param {nodes} state
 * @param {reduxAction} action
 */
const linkagelLevelNeighborhoodReducer = (state = linkageLevels, action) => {
  switch (action.type) {
    case GET_LINKAGE_LEVEL_NEIGHBORHOODS:
      return {
        ...state,
        linkageLevels: action.linkageLevels
      };

    case CLEAR_LINKAGE_LEVELS_NEIGHBORHOODS:
      return {
        linkageLevels: []
      };

    default:
      return state;
  }
};
export default linkagelLevelNeighborhoodReducer;
