/**
 * @summary NodeStylization.js
 * @file Contains All Theme Stylization Options for Nodes - Displayed in CanvasThemeMenu.js
 * @returns {JSX}
 * @usedBy CanvasThemeMenu.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import '../../canvasPage.scss';
import ColorPicker from './ColorPicker'

{/* //////////////// */}
{/* NODE STYLIZATION */}
{/* //////////////// */}
const NodeStylization = ({
    handleColorPalletteVisibility, 
    nodeBackgroundColorPallete, 
    nodeBorderColorPallete, 
    nodeBackgroundColor, 
    nodeBorderColor, 
    handleNodeShapeSelector, 
    handleNodeBackgroundColorChange, 
    handleNodeBorderColorChange, 
    handleNodeBorderWidthSelector,
    nodeShapeDropdownLabel, 
    nodeBorderWidthDropdownLabel
}) => {
    return(
        
        <div className='col-4'>
            {/* NODE SHAPE */}
            <div className='theme-options-headers'>
                Nodes
            </div>
            <div className='row'>
                <div className='theme-option'>
                    Shape:
                    <form className='shape-form'>
                        <select
                        id="node-shape-button"
                        className="shape-theme-dropdown"
                        onChange={handleNodeShapeSelector}
                        value={nodeShapeDropdownLabel}
                        >
                            <option className="node-shape-option" value="RoundedRectangle">Rectangle</option>
                            <option className="node-shape-option" value="Ellipse">Oval</option>
                            <option className="node-shape-option" value="Square">Square</option>
                        </select>
                    </form>
                </div>
            </div>
            {/* NODE BACKGROUND COLOR */}
            <div className='row'>
            <div className='theme-option'>
                Background:
                <button 
                onClick={handleColorPalletteVisibility} 
                title={'Color Selector: Click this button to open the color pallette, select a color from the pallet, and click this button again to close the pallette.'}
                id="node-fill-color-button" 
                className={nodeBackgroundColor === 'transparent' ? 'default-color-button' : 'selected-color-button'} 
                style={{backgroundColor: nodeBackgroundColor}}>
                </button>
                <div className={nodeBackgroundColorPallete ? "pallette-visibility-on" : "pallette-visibility-off"}>
                    <ColorPicker 
                        pickerId={"node-color"}
                        color={nodeBackgroundColor}
                        handleNodeBackgroundColorChange={handleNodeBackgroundColorChange}
                    />
                </div>
            </div>
            </div>
            {/* NODE BORDER COLOR */}
            <div className='row'>
            <div className='theme-option'>
                Border Color:
                <button 
                    onClick={handleColorPalletteVisibility} 
                    title={'Color Selector: Click this button to open the color pallette, select a color from the pallet, and click this button again to close the pallette.'}
                    id="node-border-color-button" 
                    className={nodeBorderColor === 'transparent' ? 'default-color-button' : 'selected-color-button'} 
                    style={{backgroundColor: nodeBorderColor }}>
                </button>
                <div className={nodeBorderColorPallete ? "pallette-visibility-on" : "pallette-visibility-off"}>
                    <ColorPicker 
                        pickerId={"node-border-color"}
                        color={nodeBorderColor}
                        handleNodeBorderColorChange={handleNodeBorderColorChange}
                    />
                </div>
            </div>
            </div>
            {/* NODE BORDER WIDTH */}
            <div className='row'>
            <div className='theme-option'>
                Border Width:
                <form className='border-width-form'>
                    <select
                    id="node-border-width-button"
                    className="shape-theme-dropdown"
                    onChange={handleNodeBorderWidthSelector}
                    value={nodeBorderWidthDropdownLabel}
                    >
                    <option className="node-border-width-option" value="1">1</option>
                    <option className="node-border-width-option" value="2">2</option>
                    <option className="node-border-width-option" value="3">3</option>
                    <option className="node-border-width-option" value="4">4</option>
                    <option className="node-border-width-option" value="5">5</option>
                    <option className="node-border-width-option" value="5">6</option>
                    </select>
                </form>
            </div>
            </div>
        </div>
    )
}

NodeStylization.propTypes = {
    handleColorPalletteVisibility: PropTypes.func,
    nodeBackgroundColorPallete: PropTypes.bool,
    nodeBorderColorPallete: PropTypes.bool,
    nodeBackgroundColor: PropTypes.string,
    nodeBorderColor: PropTypes.string,
    handleNodeShapeSelector: PropTypes.func,
    handleNodeBackgroundColorChange: PropTypes.func,
    handleNodeBorderColorChange: PropTypes.func,
    handleNodeBorderWidthSelector: PropTypes.func,
    nodeShapeDropdownLabel: PropTypes.string,
    nodeBorderWidthDropdownLabel: PropTypes.any,
};

export default NodeStylization;