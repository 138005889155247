/**
 * @summary ArrayObjectCell.js
 * @file Customized component to display cell values in KendoGrid from an array of object name properties
 * @returns {JSX}
 * @usedBy KendoGridBase.js
 * @author Sam Lee
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React from 'react';
import PropTypes from 'prop-types';

const ArrayObjectCell = ({ dataItem, field }) => (
  <td>
    {dataItem === null
      ? ''
      : dataItem[field].map((el, idx, values) =>
          values.length === 1
            ? el.name
            : values.length >= 1 && values.length !== idx + 1
            ? `${el.name}, `
            : el.name
        )}
  </td>
);
ArrayObjectCell.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.any
};

export default ArrayObjectCell;
