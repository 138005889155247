/**
 * @summary Theme String Types
 * @file ThemeTypes.js
 * @returns {String}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const CREATE_THEME = 'CREATE_THEME';

export const GET_THEMES = 'GET_THEMES';

export const DELETE_THEME = 'DELETE_THEME';

export const CLEAR_THEMES = 'CLEAR_THEMES';
