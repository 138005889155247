/**
 * @summary Project Reducer
 * @file Project Reducer .js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import {
  CREATE_PROJECT,
  GET_PROJECTS,
  GET_PROJECT,
  GRID_DATA_ACTIONS_SELECTIONS_FULLFILLED,
  CLEAR_SELECTION,
  CLEAR_PROJECTS,
  UPDATE_PROJECT
} from './ProjectTypes';

const projects = {
  selectedData: undefined,
  error: null
};

/**
 * @summary
 * This redux reducer is used for project CRUD based on the action type
 *
 * @param {projects} state
 * @param {reduxAction} action
 */
const projectReducer = (state = projects, action) => {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        // keep all incoming projects inside a single level
        ...action.projectsList?.reduce((newObj, project) => {
          newObj[project.id] = project;
          return newObj;
        }, {})
      };

    case CREATE_PROJECT:
      // action.project.id = action.meta.id;
      // action.project.lastUpdated = action.meta.lastUpdated;
      return {
        ...state,
        // projectsList: state.projectsList.concat(action.project)
        ...state.projectsList,
        [action.project.id]: action.project
      };

    case UPDATE_PROJECT:
      let data = {
        ...action.project,
        themeId: action.project.settings.themeId
      };
      return {
        ...state,
        [action.meta.id]: data
      };

    case GRID_DATA_ACTIONS_SELECTIONS_FULLFILLED:
      return {
        ...state,
        selectedData: action.project.data,
        error: null
      };

    case CLEAR_SELECTION:
      return {
        ...state,
        selectedData: undefined,
        error: null
      };

    case CLEAR_PROJECTS:
      return {
        selectedData: undefined,
        error: null
      };

    default:
      return state;
  }
};
export default projectReducer;
