/**
 * @summary axiosCycleCheck.js
 * @file Function sends a request for nodes and connections based on the user's branch selection
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios'
export const axiosCycleCheck = async (cycleCheckInputData) => {
  const {project, branchSelection } = cycleCheckInputData;
  
  if(branchSelection.type === "published branch" || branchSelection.type === "branch"){
    
    const nodes = await axios.get(`/api/projects/${project.id}/branch/${branchSelection.id}/nodes`)
    const connections = await axios.get(`/api/projects/${project.id}/branch/${branchSelection.id}/connections`)
    //////////////////////////////////////////////////////////////////////////
    // IF SELECTION IS THE PUBLISHED BRANCH, RETURN THE FULL AXIOS RESPONSE //
    //////////////////////////////////////////////////////////////////////////
    if(branchSelection.type === "published branch"){
      
      // If No Nodes or Connections, Throw an Error Because the Report Will Have No Data
      let errorMessages = []
      if(nodes.data.length === 0){
        errorMessages.push("There are no nodes in this branch.")
      } else if(connections.data.length === 0){
        errorMessages.push("None of the nodes in this branch are connected. Try checking out a branch with connected nodes.")
      }
      
      // FINAL RESPONSE: PUBLISHED BRANCH //
      const formattedAxiosResp = {
        nodes: nodes.data,
        connections: connections.data,
        branch: branchSelection.id,
        errorMessages,
      }

      return formattedAxiosResp
      
    ////////////////////////////////////////////////////////////////////////////
    // IF SELECTION IS A CHECKED OUT BRANCH, NEED TO FILTER BRANCH ID MATCHES //
    ////////////////////////////////////////////////////////////////////////////
    } else if (branchSelection.type === "branch"){

      // Filter Id Matches to Only Display Elements Included in Branch
      const branchNodes = nodes.data.filter(node => node.branchId === branchSelection.id)
      const branchConnections = connections.data.filter(connection => connection.branchId === branchSelection.id)
      
      // If No Nodes or Connections, Throw an Error Because the Report Will Have No Data
      let errorMessages = []
      if(branchNodes.length === 0){
        errorMessages.push("There are no nodes in this branch.")
      } else if(branchConnections.length === 0){
        errorMessages.push("None of the nodes in this branch are connected. Try checking out a branch with connected nodes.")
      }

      // FINAL RESPONSE: CHECKED OUT BRANCH //
      const formattedAxiosResp = {
        nodes: branchNodes,
        connections: branchConnections,
        branch: branchSelection.id,
        errorMessages,
      }

      return formattedAxiosResp
    }
  ///////////////////////////////////////////////////////////////////////////
  // IF SELECTION IS A VIEW, RUN DIFFERENT AXIOS GET REQUESTS ON THAT VIEW //
  ///////////////////////////////////////////////////////////////////////////
  } else if (branchSelection.type === "view"){
    const nodes = await axios.get(`/api/projects/${project.id}/views/${branchSelection.id}`)
    const connections = await axios.get(`/api/projects/${project.id}/views/${branchSelection.id}`)
    
    // If No Nodes or Connections, Throw an Error Because the Report Will Have No Data
    let errorMessages = []
    if(nodes.length === 0){
      errorMessages.push("There are no nodes in this view.")
    } else if(connections.length === 0){
      errorMessages.push("None of the nodes in this view are connected. Try saving a view with connected nodes.")
    }
    
    // FINAL RESPONSE: VIEW //
    const formattedAxiosResp = {
      nodes: nodes.data.savedNodes,
      connections: connections.data.savedConnections,
      branch: branchSelection.id,
      errorMessages,
    }
    return formattedAxiosResp
  }
}