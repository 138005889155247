/**
 * @summary fileUploadService.js
 * @file contains functions that handles uploading logic for both media and map
 * @returns {JSX}
 * @usedBy MapUpload.js
 * @author Sam Lee
 * @since 2/17/2023
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios';
import { getMetadata } from '../../../store/metadata/MetadataContainer';
import store from '../../../store/store';
import { toast } from 'react-toastify';

export const createMedia = (mediaObj) => axios.post('/api/medias', mediaObj);
export const createMediaAndUpload = async ({
  contentType,
  file,
  fileName,
  assessmentProgramId,
  fileType,
  mediaFormat,
  fileSize,
  mediaId,
  metadata,
  imgWidth,
  imgHeight
}) => {
  let newAttachmentID;
  let newAttachmentVersion;
  const data = {};

  const userObj = store.getState().authReducer.userObj;

  return new Promise((resolve, reject) => {
    const statuses = getMetadata('STATUS');
    const mediaObj = {
      id: mediaId,
      assessment_program_id: assessmentProgramId,
      user_id: userObj.id,
      name: fileName,
      attachment_json: {
        id: -1,
        version: -1,
        file_name: fileName,
        file_modified_time:
          file.lastModifiedDate !== undefined ? file.lastModifiedDate : null,
        file_type: fileType,
        attributes_list: [
          {
            width: imgWidth,
            height: imgHeight
          }
        ]
      },
      ...metadata, // this gets media type, media format, grade, content area etc
      status: statuses[0].metadata_info.find((e) => e.code === 'draft').id
    };

    // create the media object
    createMedia(mediaObj)
      .then((res) => {
        // all done - save the id we got
        // if (res.data.length > 0 && res?.data[0]?.reason === DUPLICATE_OBJECT_RESPONSE_CODE) {
        //   return toast.error("Already media name Existing");
        // } else {
        mediaId = res.data.id;
        const attachmentId = res.data.attachment_id;
        const attachmentVersion = res.data.attachment_version;
        newAttachmentID = res.data.attachment_id;
        newAttachmentVersion = res.data.attachment_version;
        // get a pre-signed url with media id
        return axios.post(
          `/api/signedputurl/${contentType.id}/${fileType}/${assessmentProgramId}/${mediaId}/${attachmentId}/${attachmentVersion}/${fileName}`
        );
        // }
        // return res;
      })
      .then((response) => {
        if (response) {
          const returnData = response;
          const signedRequest = returnData.data.data.signedRequest;
          const url = returnData.data.data.url;
          const success = response.data.success;
          if (success) {
            data.url = url;
            const fd = new FormData();
            fd.append('file', fileName);
            const options = {
              headers: {
                'Content-Type': fileType
              }
            };
            // use signed URL to upload the file
            return axios.put(signedRequest, file, options);
          }
        }
        return response;
      })
      .then((res) => {
        if (res) {
          const URL = res.config.url;
          const splitPath = URL.split('/');
          if (splitPath.length === 9) {
            return axios.post(
              `/api/signedgeturl/media/${assessmentProgramId}/${mediaId}/${splitPath[6]}/${splitPath[7]}/${fileName}`
            );
          }
          toast.error('Invalid path');
        }
        return res;
      })
      .then((res) => {
        if (res) {
          toast.error('Saved');
          resolve({
            fileName,
            mediaFormat,
            fileSize,
            mediaId,
            attachmentId: newAttachmentID,
            attachmentVersion: newAttachmentVersion,
            url: res.data.data
          });
        }
      })
      .catch((error) => {
        toast.error('Error uploading');
        reject(error);
      });
  });
};

export const commonUpload = (filePath, file, fileName, fileType) =>
  new Promise((resolve, reject) =>
    axios
      .post('/api/signedputurl', {
        params: {
          filePath,
          fileType
        }
      })
      .then((response) => {
        const returnData = response;
        const signedRequest = returnData.data.data.signedRequest;
        const url = returnData.data.data.url;
        const success = response.data.success;
        if (success) {
          const data = {};
          data.url = url;
          const fd = new FormData();
          fd.append('file', fileName);
          const options = {
            headers: {
              'Content-Type': fileType
            }
          };
          return resolve(axios.put(signedRequest, file, options));
        }
        return response;
      })
      .catch((error) => {
        toast.error('Unable to get SignedURL');
        reject(error);
      })
  );

// TODO: this piece of code need to fixed for uploading multiple video files
export const uploadAccessibility = (props) => {
  const contentType = props.accessibilityObj.contentType;
  let accommodationListResp = [];
  let id = null;
  let assessmentProgramId = null;
  if (contentType === 'media') {
    accommodationListResp = props.resp.data[0].accessibility_details;
    id = props.resp.data[0].id;
    assessmentProgramId = props.mediaObj.mediaObj.assessment_program_id;
  } else {
    accommodationListResp = props.resp.data.accessibility_details;
    id = props.resp.data.id;
    assessmentProgramId = props.itemObj.assessment_program_id;
  }

  let accommodationListReq = [];
  props.accessibilityObj.accessibility_json.forEach((el) => {
    const accomadationList = el.accommodation_list;
    accommodationListReq = accommodationListReq.concat(
      accomadationList.filter(
        (accmd) =>
          accmd.attachment_json &&
          accmd.attachment_json.file_name &&
          accmd.attachment_json.file_name !== null
      )
    );
  });

  const accommodationListF = accommodationListResp
    .filter((resp) => resp.attachment_id !== null)
    .map((resp) => {
      const accommodationFilteredList = accommodationListReq.filter(
        (req) => resp.file_name === req.attachment_json.file_name
      );
      return { ...accommodationFilteredList[0], ...resp };
    });

  accommodationListF.forEach((accomodation) => {
    const {
      file_type: fileType,
      file_name: fileName,
      attachment_id: attachmentId,
      attachment_version: attachmentVersion
    } = accomodation;

    const file = accomodation.attachment_json.file;
    const filePath = `${contentType}/${assessmentProgramId}/${id}/${attachmentId}/${attachmentVersion}/${fileName}`;
    // get a pre-signed url with media id
    commonUpload(filePath, file, fileName, fileType);
  });
};

export const uploadMultipleFiles = (files) =>
  Promise.all(files.map((f) => createMediaAndUpload(f)));
