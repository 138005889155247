/**
 * @summary ShortestPathInputs.js
 * @file Input options for Shortest Path Report, which charts the shortest path from a specified starting node to a destination node
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import store from 'store/store';
import PropTypes from 'prop-types';
import { getViews } from 'store/views/ViewActions'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// IMPORTED COMPONENTS: Autosuggest for NodeKey Input
import NodeKeyAutosuggest from '../reportingUtils/NodeKeyAutosuggest'

const ShortestPathInputs = ({
    generateReport,
    project,
    userObj,
}) => {
    let navigate = useNavigate()
    // USE SELECTOR: Pulls branch and project data from Redux
    const branches = useSelector((state) => {
        const branchesList = { ...state.branchesDetailsReducer.activeBranches };
        delete branchesList?.error;
        delete branchesList?.selectedData;
        return Object.values(branchesList).filter(Boolean);
    }, shallowEqual)
    
    const views = useSelector((state) => {
        const views = { ...state.viewReducer };
        delete views?.selectedData;
        return Object.values(views).filter((view) => view.isActive)
    }, shallowEqual)
    
    // USE EFFECT: Gets VIews for the Use Selector (for Menus)
    useEffect(() => {
        store.dispatch(getViews(userObj.selectedProject[0].id))
    }, [])

    /////////////////////////
    // PRIMARY STATE HOOKS //
    /////////////////////////
    // Map Dropdown States (Full and Selected)
    const [mapDropdownOptions, setMapDropdownOptions] = useState([])
    const [selectedMap, setSelectedMap] = useState(null)
    // Node Key Selector States used in AutoSuggest
    const [allNodeKeysAvailable, setAllNodeKeysAvailable] = useState([])
    const [startingNodeKeySuggestions, setStartingNodeKeySuggestions] = useState([])
    const [startingNode, setStartingNode] = useState(null)
    const [destinationNodeKeySuggestions, setDestinationNodeKeySuggestions] = useState([])
    const [destinationNode, setDestinationNode] = useState(null)

    /////////////////////////////////////////////////////////
    // BUILDS MAP DROPDOWN OPTIONS WITH IDENTIFYING VALUES //
    /////////////////////////////////////////////////////////
    useEffect(() => {
        setStartingNode(null)
        setDestinationNode(null)
        if(branches.length > 0 || views.length > 0){
            let branchOptions = branches.map(branch => {
                if(branch.name === "published"){
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        menuName: "Published Branch",
                        name: branch.name,
                        type: "published branch"
                    }
                    return branchObj
                } else {
                    let branchObj = {
                        id: branch.id,
                        menuId: "branch" + branch.id.toString(),
                        menuName: "Branch: " + branch.name,
                        name: branch.name,
                        type: "branch"
                    }  
                    return branchObj
                }
            })
            let viewOptions = views.map(view => {
                let viewObj = {
                    id: view.id,
                    menuId: "view" + view.id.toString(),
                    menuName: "View: " + view.name,
                    name: view.name,
                    type: "view"
                }
                return viewObj
            })
            let mapOptions = branchOptions.concat(viewOptions)
            mapOptions.unshift({
                id: 0,
                menuId: 0,
                menuName: "Select Map Branch or View",
                name: "placeholder",
                type: "placeholder"
            })
            setMapDropdownOptions(mapOptions)
        } else {
            setMapDropdownOptions([])
        }
    }, [project])
    
    // SELECTS MAP FROM DROPDOWN OPTIONS: Branch or View //
    const handleMapSelection = (selectedMenuId) => {
        if(selectedMenuId !== "0"){
            let selection = mapDropdownOptions.find(branch => branch.menuId === selectedMenuId)
            setSelectedMap(selection)
            setStartingNode(null)
        setDestinationNode(null)
        } else {
            setSelectedMap(null)
            setAllNodeKeysAvailable([])
            setStartingNode(null)
            setDestinationNode(null)
        }  
    }

    ///////////////////////////////////////////////////////////////////
    // Axios Request for Autosuggest Nodes Based on Branch Selection //
    ///////////////////////////////////////////////////////////////////
    useEffect(() => {
        if(selectedMap !== null){
            // IF BRANCH
            if(selectedMap.type === "branch" || selectedMap.type === "published branch"){
                setAllNodeKeysAvailable(null)
                axios.get(`/api/projects/${project.id}/branch/${selectedMap.id}/nodes`).then(nodes => {
                    const branchNodes = nodes.data.filter(node => node.branchId === selectedMap.id)
                    setAllNodeKeysAvailable(branchNodes)
                })
            // IF VIEW
            } else if(selectedMap.type === "view") {
                setAllNodeKeysAvailable(null)
                axios.get(`/api/projects/${project.id}/views/${selectedMap.id}`).then(selectedView => {
                    setAllNodeKeysAvailable(selectedView.data.savedNodes)
                })
            }   
        } 
    // CHANGES WHEN MAP IS RESELECTED
    }, [selectedMap])

    ///////////////////////////////////////////////
    // FINAL RETURN OBJ: Data Sent to ReportPage //
    ///////////////////////////////////////////////
    const onClickShortestPath = () => {
        const shortestPathObj = {
            project,
            selectedBranch: selectedMap,
            startingNode: startingNode,
            destinationNode: destinationNode,
        }
        generateReport(shortestPathObj)
    }

    useEffect(() => {
        if(allNodeKeysAvailable !== null){
            setStartingNode(null)
            setStartingNodeKeySuggestions([])
            setDestinationNode(null)
            setDestinationNodeKeySuggestions([])
            if(allNodeKeysAvailable.length === 0 && selectedMap !== null){
                toast.error('There are no nodes in the map you selected.')
            }
        }
    }, [allNodeKeysAvailable])

    function canvasRedirect(){
        navigate('/canvas')
    }
    
    return(
        <Row>
            <Col>
                <div className='reporting-input-section'>
                    <div className='reporting-instructions'>
                        <div className="selected-report-header">
                            {'Shortest Path Report: ' + project.name}
                        </div>
                        <div>
                            <p>This report will chart the shortest path of connected nodes between the starting node and destination node you enter below.</p>
                            <p>The results will outline the shortest path as a list of nodes in sequential order, from start to destination, with each numerical position listed.</p>
                            <p>Use the <strong style={{color: "#1560b7", cursor: "pointer"}} onClick={canvasRedirect}>Canvas</strong> to find nodes that seem to be connected through a long distance, and see if this report can find a shorter path.</p>
                        </div>
                    </div>
                    <div className='reporting-input-menus-container'>
                        <Row>
                            <div className='col-3'>
                                <div className="reporting-option-header">
                                    Select a Map Option
                                </div>
                                <select
                                    className="form-select form-select-sm"
                                    aria-label=".form-select-sm example"
                                    value={selectedMap?.menuId}
                                    style={{width: '90%', height: '37px', marginLeft: '-2px'}}
                                    onChange={(e) => {
                                        handleMapSelection(e.target.value)
                                    }}
                                    name="mapSelection"
                                >       
                                    {mapDropdownOptions.map((map) => (
                                    <option key={map.menuId} value={map.menuId}>
                                        {map.menuName}
                                    </option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-3'>
                                <div className="reporting-option-header">
                                    Enter Starting Node
                                </div>
                                {/* NODE KEY AUTOSUGGEST */}
                                <NodeKeyAutosuggest
                                    selectedNodeKey={startingNode}
                                    allNodeKeysAvailable={allNodeKeysAvailable}
                                    setSelectedNodeKey={setStartingNode}
                                    setNodeKeySuggestions={setStartingNodeKeySuggestions}
                                    nodeKeySuggestions={startingNodeKeySuggestions}
                                />
                            </div>
                            <div className='col-3'>
                                <div className="reporting-option-header">
                                    Enter Destination Node
                                </div>
                                {/* NODE KEY AUTOSUGGEST */}
                                <NodeKeyAutosuggest
                                    selectedNodeKey={destinationNode}
                                    allNodeKeysAvailable={allNodeKeysAvailable}
                                    setSelectedNodeKey={setDestinationNode}
                                    setNodeKeySuggestions={setDestinationNodeKeySuggestions}
                                    nodeKeySuggestions={destinationNodeKeySuggestions}
                                />
                            </div>  
                            
                        </Row>
                    </div>
                    <Row>
                        <div className="col-2">
                            <div className='generate-report-button-container'>
                                <button
                                    type="button"
                                    className="k-button k-primary generate-report-button"
                                    onClick={onClickShortestPath}
                                    disabled={!selectedMap || !startingNode || !destinationNode}
                                >
                                    Generate Report
                                </button>
                            </div>
                        </div>
                        <div className='col-10'>
                            <div className='map-options-fine-print-container'>
                                <p className="map-options-fine-print">
                                    {`Map options are limited to the project you have selected under your Profile Settings in the upper right corner. You currently have "` + project.name + `" selected.`}
                                </p>
                            </div>
                        </div>
                    </Row>
                </div>
            </Col>
        </Row>
    )
}

export default ShortestPathInputs;

ShortestPathInputs.propTypes = {
    generateReport: PropTypes.func,
    setMapType: PropTypes.func,
    mapType: PropTypes.any,
    nodes: PropTypes.array,
    connections: PropTypes.array,
    project: PropTypes.obj,
    branches: PropTypes.array,
    views: PropTypes.array,
    userObj: PropTypes.obj
};