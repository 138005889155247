/**
 * @summary Project String Types
 * @file ProjectTypes.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

export const CREATE_PROJECT = 'CREATE_PROJECT';

export const GET_PROJECTS = 'GET_PROJECTS';

export const GET_PROJECT = 'GET_PROJECT';

export const DELETE_PROJECT = 'DELETE_PROJECT';

export const GRID_DATA_ACTIONS_SELECTIONS_FULLFILLED =
  'GRID_DATA_ACTIONS_SELECTIONS_FULLFILLED';

export const CLEAR_SELECTION = 'CLEAR_SELECTION';

export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
