/**
 * @summary Schema for creating new connections
 * @file newConnection.schema.js
 * @returns {Schema}
 * @usedBy Application
 * @author Andrew Popp
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as yup from 'yup';

// Schema
const newConnectionSchema = yup.object().shape({
  name: yup.string().required('Connection Name is required'),
  sourceNodeId: yup.string().required('Source Node is required'),
  destinationNodeId: yup.string().required('Destination Node is required')
});

export default newConnectionSchema;
