/**
 * @summary projectSettings.js
 * @file form component for project settings panel
 * @returns {JSX}
 * @usedBy newForm.js
 * @author Dj Ritchey
 * @since 02/17/2021
 * @lastUpdated 06/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState } from 'react';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { useSelector, shallowEqual } from 'react-redux';
import store from '../../../store/store';
import { projectNamesObj } from '../../../app_code/ProjectObjTypeNames';
import { updateProjectSettings } from '../../../store/ProjectSetup/ProjectSetupActions';

const ProjectSettings = () => {
  const [frameworksIsOpen, frameworksSetIsOpen] = useState([false]);
  const [eduLevelIsOpen, eduLevelSetIsOpen] = useState([false]);
  const [useageIsOpen, useageSetIsOpen] = useState(false);

  const subjects = useSelector((state) => state.metadataReducer.subject);
  const frameworks = useSelector((state) => state.metadataReducer.frameworkType);
  const eduLevels = useSelector((state) => state.metadataReducer.gradeCourse);
  const projectSetup = useSelector((state) => state.projectSetupReducer);

  const availableMetaData = (metadata, inputRow) => {
    const subjectObj = subjects.find(
      (sub) => sub.id === parseInt(inputRow.subject)
    );
    if (metadata?.length && subjectObj) {
      return metadata.filter((fr) => {
        if (fr.dependent) {
          const dependents = fr.dependent?.split(',');
          return dependents.includes(subjectObj?.code);
        }
      });
    }
  };

  const project = useSelector((state) => state.projectSetupReducer);
  const themes = useSelector((state) => {
    return Object.values(state.themeReducer).filter(Boolean)
  }, shallowEqual);
  const applicationList = [
    'Navigator',
    'Content Portal',
    'Educator Portal',
    'Dashboard',
    'Non-Kite Use'
  ];

  const changeCoreInput = (e, idx) => {
    const { name, value } = e.target;
    const list = [...project.settings.coreMetaData];
    list[idx][name] = value;
    store.dispatch(updateProjectSettings(projectNamesObj.coreMetaData, list));
  };

  const updateFrameworksAndEduLevel = (e, idx) => {
    const { value, name } = e;
    const ids = value.map(({ id }) => id);
    const list = [...project.settings.coreMetaData];
    list[idx][name].splice(0, list[idx][name].length, ...ids);
    store.dispatch(updateProjectSettings(projectNamesObj.coreMetaData, list));
  };

  const setTheme = (value) => {
    store.dispatch(updateProjectSettings(projectNamesObj.themeId, value.id));
  };

  const handleRemoveMetadata = (idx) => {
    const updatedSettings = { ...project.settings };
    updatedSettings.coreMetaData.splice(idx, 1);
    store.dispatch(
      updateProjectSettings(
        projectNamesObj.coreMetaData,
        updatedSettings.coreMetaData
      )
    );
  };

  const getAvailSubs = () =>
    subjects.filter(
      (sub) =>
        !project.settings.coreMetaData.some(
          (m) => sub.id === parseInt(m.subject)
        )
    );

  const renderMetaData = () =>
    project.settings.coreMetaData.map((inputRow, idx) => {
      return (
        <div key={`renderedmeta${idx}`}>
          {idx > 0 && (
            <span
              onClick={() => handleRemoveMetadata(idx)}
              className="k-icon k-i-minus-outline removeSubject"
              style={{ paddingLeft: '10px', paddingTop: '45px' }}
            />
          )}
          <div className="col d-flex p-2">
            <div className="col-2 text-end">
              <label className="form-label" htmlFor={`prefix-code-${idx}`}>
                Prefix Code:
                <i className="text-danger"> *</i>
              </label>
            </div>
            <div className="col-xs-2">
              <input
                id={`prefix-code-${idx}`}
                type="text"
                className="form-control form-control-sm"
                name="prefixCode"
                value={inputRow.prefixCode}
                maxLength="3"
                required
                onChange={(e) => changeCoreInput(e, idx)}
              />
            </div>
          </div>

          <div className="col d-flex p-2 justify-content-between">
            <div className="col-2 text-end">
              <label className="form-label" htmlFor={`subject-${idx}`}>
                Subject:
                <i className="text-danger"> *</i>
              </label>
            </div>
            <div className="col">
              <select
                // defaultValue="select a subject"
                id={`subject-${idx}`}
                value={subjects.find((subject) =>
                  subject.id === inputRow.subject ? subject.name : null
                )}
                name="subject"
                className="form-select form-select-sm"
                required
                style={{ width: '160px' }}
                onChange={(e) => changeCoreInput(e, idx)}
              >
                {inputRow.subject !== '' ? (
                  <option value={inputRow.subject}>
                    {
                      subjects.find(
                        (sub) => sub.id === parseInt(inputRow.subject)
                      ).name
                    }
                  </option>
                ) : (
                  <option>Select a subject</option>
                )}
                {getAvailSubs().map((md) => (
                  <option key={md.id} value={md.id}>
                    {' '}
                    {md.name}{' '}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col d-flex p-2 justify-content-between">
            <div className="col-2 text-end">
              <label className="form-label" htmlFor={`framework-${idx}`}>
                Frameworks:
                <i className="text-danger"> *</i>
              </label>
            </div>
            <div className="col">
              <MultiSelect
                data={availableMetaData(frameworks, inputRow)}
                id={`framework-${idx}`}
                name="frameworks"
                dataItemKey="id"
                textField="name"
                opened={frameworksIsOpen[idx]}
                onFocus={() => {
                  const newState = [...frameworksIsOpen];
                  newState.splice(idx, 1, true);
                  frameworksSetIsOpen(newState);
                }}
                onBlur={() => {
                  const newState = [...frameworksIsOpen];
                  newState.splice(idx, 1, false);
                  frameworksSetIsOpen(newState);
                }}
                value={frameworks.filter(
                  (md) =>
                    project.settings.coreMetaData[idx].frameworks.indexOf(
                      md.id
                    ) > -1
                )}
                onChange={(e) => {
                  updateFrameworksAndEduLevel(e.target, idx);
                }}
              />
            </div>
          </div>

          <div className="col d-flex p-2 justify-content-between">
            <div className="col-2 text-end">
              <label className="form-label" htmlFor={`eduLevel-${idx}`}>
                Educational Level:
                <i className="text-danger"> *</i>
              </label>
            </div>
            <div className="col">
              <MultiSelect
                data={availableMetaData(eduLevels, inputRow)}
                id={`eduLevel-${idx}`}
                name="eduLevels"
                textField="name"
                dataItemKey="id"
                opened={eduLevelIsOpen[idx]}
                onFocus={() => {
                  const newState = [...eduLevelIsOpen];
                  newState.splice(idx, 1, true);
                  eduLevelSetIsOpen(newState);
                }}
                onBlur={() => {
                  const newState = [...eduLevelIsOpen];
                  newState.splice(idx, 1, false);
                  eduLevelSetIsOpen(newState);
                }}
                fieldName="educational_level"
                value={eduLevels.filter(
                  (edu) =>
                    project.settings.coreMetaData[idx].eduLevels.indexOf(
                      edu.id
                    ) > -1
                )}
                onChange={(e) => {
                  updateFrameworksAndEduLevel(e.target, idx);
                }}
              />
            </div>
          </div>
        </div>
      );
    });

  const renderMisc = () => (
    <div>
      <div className="col d-flex p-2 justify-content-between">
        <div className="col-2 text-end">
          <label className="form-label" htmlFor="age-range">
            Age Range:
            <i className="text-danger"> *</i>
          </label>
        </div>
        <div className="col">
          <input
            title="start-age"
            type="number"
            name="min_age"
            min="1"
            max="100"
            required
            value={project.settings.minAge}
            onChange={(e) => {
              if (e.target.value > 0) {
                store.dispatch(
                  updateProjectSettings(
                    projectNamesObj.minAge,
                    parseInt(e.target.value)
                  )
                );
                if (
                  parseInt(e.target.value) >
                  parseInt(projectSetup.settings.maxAge)
                ) {
                  store.dispatch(
                    updateProjectSettings(
                      projectNamesObj.maxAge,
                      parseInt(e.target.value)
                    )
                  );
                }
              } else {
                store.dispatch(
                  updateProjectSettings(projectNamesObj.minAge, 1)
                );
              }
            }}
          />
          {' - '}
          <input
            title="end-age"
            type="number"
            name="max_age"
            min={project.settings.minAge}
            max="100"
            required
            value={project.settings.maxAge}
            onChange={(e) => {
              if (e.target.value > 0) {
                store.dispatch(
                  updateProjectSettings(
                    projectNamesObj.maxAge,
                    parseInt(e.target.value)
                  )
                );
              } else {
                store.dispatch(
                  updateProjectSettings(projectNamesObj.maxAge, 1)
                );
              }
            }}
          />
        </div>
      </div>
      <div className="col d-flex p-2 justify-content-between">
        <div className="col-2 text-end">
          <label className="form-label" htmlFor="suite-usage">
            Kite Suite Usage:
            <i className="text-danger"> *</i>
          </label>
        </div>
        <div className="col">
          <MultiSelect
            data={applicationList}
            id="suite-usage"
            name="suiteUsage"
            opened={useageIsOpen}
            onFocus={() => useageSetIsOpen(true)}
            onBlur={() => useageSetIsOpen(false)}
            value={project.settings.applicationUsage}
            fieldName="educational_level"
            onChange={(e) =>
              store.dispatch(
                updateProjectSettings(projectNamesObj.applicationUsage, e.value)
              )
            }
          />
        </div>
      </div>
    </div>
  );

  const selectThemeMarkup = () => (
    <div>
      <div className="col d-flex p-2 justify-content-between">
        <div className="col-2 text-end">
          <label className="form-label" htmlFor="theme">
            Saved Themes:
            <i className="text-danger"> *</i>
          </label>
        </div>
        <div className="col">
          <select
            name="theme"
            id="theme"
            className="form-select form-select-sm"
            style={{ width: '160px' }}
            required
            value={
              themes.find((t) => t.id === project.settings.themeId)?.id || ''
            }
            onChange={(e) => {
              setTheme(themes.find((t) => t.id === parseInt(e.target.value)));
            }}
          >
            <option value="0"> Select a Theme </option>
            {themes.map((theme) => (
              <option key={`themes-${theme.name}`} value={theme.id}>
                {' '}
                {theme.name}{' '}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col p-2 justify-content-between">
          <fieldset className="bg-light p-3 rounded mt-1">
            <legend>Core Metadata</legend>
            <div className="col" style={{ marginTop: '10px' }}>
              <div className='col-1'>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  store.dispatch(
                    updateProjectSettings(projectNamesObj.coreMetaData, [
                      ...project.settings.coreMetaData,
                      {
                        prefixCode: '',
                        subject: '',
                        frameworks: [],
                        eduLevels: []
                      }
                    ])
                  );
                  frameworksSetIsOpen([...frameworksIsOpen, false]);
                  eduLevelSetIsOpen([...eduLevelIsOpen, false]);
                }}
              >
                Add Subject
              </button>
              </div>
            </div>
            {renderMetaData()}
            {renderMisc()}
          </fieldset>
        </div>
      </div>
      <div className="row">
        <div className="col p-2 justify-content-between">
          <fieldset className="bg-light p-3 rounded">
            <legend style={{ marginBottom: '37px' }}>Theme Selection</legend>
            {selectThemeMarkup()}
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default ProjectSettings;
