/**
 * @summary CanvasTableviewRedirect.js
 * @file Parent Modal for the table used to redirect selected elements to the tableview page
 * @returns {JSX}
 * @usedBy CanvasPage.js
 * @author Andy Greenhaw
 * @since 07/01/2024
 * @lastUpdated 8/1/2024
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import { React, useState } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../canvasPage.scss';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import axios from 'axios';

const CanvasTableviewRedirect = ({
    selectedNode,
    multiSelectArray,
    show, 
    setShow,
    handleTableviewNavigation 
  }) => {

  if (multiSelectArray.length > 0) {
    return (
        <Modal show={show} backdrop="static" centered size="xl">
            <Modal.Header className='inspector-header'>
                Redirect to Tableview Page
            <div
                className="bi bi-x submenu-close-x"
                onClick={() => {
                setShow(false)
                }}
            >
            </div>
            </Modal.Header>
            <Modal.Body>
                
            <div className='inspector-id'>
                <div className='instructional-blue'>
                    Navigate to the tableview page with these elements selected. When checking out these nodes, connections will automatically be included.
                </div>
            </div>
            <div className='tableview-redirect-details'>
                <div className='table-redirect-headers-container'>
                    <div className='row'>
                        <div className='col-3'>
                            <div className='table-redirect-headers-column'>
                                Key
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className='table-redirect-headers-column'>
                                Title
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='table-redirect-headers-column'>
                                Description
                            </div>
                        </div>
                    </div>
                </div>
                {multiSelectArray.map(element=> {
                    if(element.data.type !== "Connection"){
                        if(element.nodeKey){
                            return(
                                <>
                                
                                <div key={element.id} className='row tableview-redirect-data-row'>
                                    <div className='col-3'>
                                        <div className='table-redirect-data'>
                                            {element.nodeKey}
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='table-redirect-data'>
                                            {element.name}
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='table-redirect-data'>
                                            {element.description}
                                        </div>
                                    </div>
                                </div>
                                </>
                            )
                        } else {
                            return(
                                <>
                                
                                <div key={element.data.id} className='row tableview-redirect-data-row'>
                                    <div className='col-3'>
                                        <div className='table-redirect-data'>
                                            {element.data.nodeKey}
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='table-redirect-data'>
                                            {element.data.name}
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='table-redirect-data'>
                                            {element.data.description}
                                        </div>
                                    </div>
                                </div>
                                </>
                            )                        
                        }
                    }
                })}
            </div>
            </Modal.Body>
            <Modal.Footer>
            <div className='container'>
                <div className='row'>
                    <div className='bootstrap-modal-footer-button-alignment'>
                        <button
                            className="btn btn-primary btn-sm bootstrap-footer-button-stylization"
                            onClick={handleTableviewNavigation}
                        >
                            Navigate to Tableview Page
                        </button>
                        <button
                            className="btn btn-danger btn-sm text-white bootstrap-footer-button-stylization"
                            onClick={() => {
                            setShow(false)
                            }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
            </Modal.Footer>
        </Modal>
    );
  } 
  return null
}

CanvasTableviewRedirect.propTypes = {
  canvasMapData: PropTypes.object,
  selectedNode: PropTypes.object,
  multiSelectArray: PropTypes.array,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  handleTableviewNavigation: PropTypes.func
};

export default CanvasTableviewRedirect;